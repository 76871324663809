import { Alert, Typography } from '@mui/material';
import { FormTab, FormTabProps, useRecordContext } from 'react-admin';

import CompanySubscriptionsSection from './sections/CompanySubscriptions';
import { CompanySubscriptionSetRecord } from 'src/types';

const allCompaniesAlert = (
  <Alert severity="info" sx={{ mt: 1 }}>
    This Set includes{' '}
    <Typography component="span" sx={{ fontWeight: '900' }}>
      all companies
    </Typography>
    . Company Subscriptions in this Set cannot be modified.
  </Alert>
);

const CompaniesTab = (props: Omit<FormTabProps, 'label'>) => {
  const companySubscriptionSetRecord = useRecordContext<CompanySubscriptionSetRecord>();

  const isAllCompaniesSet = companySubscriptionSetRecord.all_companies;

  return (
    <FormTab label="Companies" {...props}>
      {isAllCompaniesSet ? allCompaniesAlert : <CompanySubscriptionsSection />}
    </FormTab>
  );
};

export default CompaniesTab;
