import React, { useState, ChangeEvent } from 'react';
import { Button, SaveButton, TextInput, useNotify, SimpleForm } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import { LibraryAdd as LibraryAddIcon, Dataset as DatasetIcon } from '@mui/icons-material';
import { client } from '../../../data/api';
import gql from 'graphql-tag';
import IdListImport from './IdListImport';
import CsvImport from './CsvImport';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import LoadingOverlay from 'src/components/LoadingOverlay';
import {
  getOrgId,
  formatTopics,
  normalizeTopic,
  validateTopics,
  generateErrorMessage,
  buttonStyles,
} from 'src/utils/organizationSubscriptions';
import BulkToolbar from './BulkToolbar';
import { AssetLink } from './AssetLink';
import { DialogInner } from './DialogInner';

type POCCompany = {
  company_id: number;
};

const BulkImportToolbar = ({
  handleCloseClick,
  records,
  toolbarInfo,
}: {
  handleCloseClick: () => void;
  records: { topics: string };
  toolbarInfo: string;
}) => {
  const buttonComponents = <SaveButton alwaysEnable label="import" />;

  const infoComponents = (
    <Typography variant="body2" color="textSecondary">
      {toolbarInfo}: {formatTopics(records).length?.toLocaleString()}
    </Typography>
  );

  return (
    <BulkToolbar
      handleCloseClick={handleCloseClick}
      infoComponents={infoComponents}
      buttonComponents={buttonComponents}
    />
  );
};

const BulkImport = ({ refresh }: { refresh: () => void }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [records, setRecords] = useState({ topics: '' });
  const [isLoading, setIsLoading] = useState(false);

  const notify = useNotify();
  const buttonClasses = buttonStyles();

  const handleBulkImportButtonClick = () => {
    setIsLoading(false);
    setShowDialog(true);
  };

  const handleBulkImportCloseClick = () => {
    setIsLoading(false);
    setShowDialog(false);
  };

  let debounceTimeout: NodeJS.Timeout;

  const handleTopicsChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(debounceTimeout);
    const topics = event?.target?.value ?? '';
    debounceTimeout = setTimeout(() => {
      setRecords({ topics });
    }, 500);
  };

  const handleIdListChange = (topics: string) => {
    setRecords({ topics });
    setIsLoading(false);
  };

  const handlePOCDataset = async () => {
    setIsLoading(true);
    const { data } = await client.query<{
      signals_get_most_popular_companies_view: POCCompany[];
    }>({
      query: gql`
        query POCDataset {
          signals_get_most_popular_companies_view {
            company_id
          }
        }
      `,
    });
    setIsLoading(false);
    const pocDatasetArr = data?.signals_get_most_popular_companies_view ?? [];
    if (!pocDatasetArr.length) return notify('No POC Dataset found');
    const pocDataset = pocDatasetArr.map((company: POCCompany) => `company:${company.company_id}`).join('\n');
    return setRecords({ topics: pocDataset });
  };

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    setIsLoading(true);
    const topicList: string[] = formatTopics(formData);

    try {
      const orgId = formData.org_id ?? getOrgId();
      if (!orgId || !orgId.length) {
        throw new Error('No organization found');
      }
      const objects = [...new Set(topicList)].map((topic: string) => ({
        org_id: orgId,
        topic: normalizeTopic(topic),
      }));

      const { data } = await client.mutate<{
        signals_insert_organization_subscriptions: { affected_rows: number };
      }>({
        mutation: gql`
          mutation SignalsInsertOrganizationSubscriptions(
            $objects: [organization_subscriptions_insert_input!]!
            $on_conflict: organization_subscriptions_on_conflict
          ) {
            signals_insert_organization_subscriptions(objects: $objects, on_conflict: $on_conflict) {
              affected_rows
            }
          }
        `,
        variables: {
          objects,
          on_conflict: {
            constraint: 'org_subs_unique_topics',
            update_columns: ['company_id'],
            where: {
              org_id: {
                _eq: orgId,
              },
            },
          },
        },
      });

      const numAffectedRows = data?.signals_insert_organization_subscriptions?.affected_rows || 0;
      setShowDialog(false);
      notify(`Successfully imported ${numAffectedRows.toLocaleString()} organizations subscriptions`);
      setRecords({ topics: '' });
      refresh();
    } catch (error) {
      setIsLoading(false);
      const errorMessage = generateErrorMessage({
        error,
        prependMessage: 'Failed to import organization subscriptions.\n',
      });
      notify(errorMessage, { type: 'error' });
    }
  };

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem' }}>
      <Button onClick={handleBulkImportButtonClick} label="Import" size="medium" className={buttonClasses.saveButton}>
        <LibraryAddIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleBulkImportCloseClick}
        aria-label="Import Organization Subscriptions"
      >
        {isLoading && <LoadingOverlay open />}
        {!isLoading && (
          <DialogInner>
            <DialogTitle>Import Organization Subscriptions</DialogTitle>
            <SimpleForm
              record={records}
              onSubmit={handleSubmit}
              toolbar={
                <BulkImportToolbar
                  handleCloseClick={handleBulkImportCloseClick}
                  records={records}
                  toolbarInfo="Total Records to Import"
                />
              }
            >
              <DialogContent sx={{ width: '100%' }}>
                <Box sx={{ marginTop: '-1rem' }}>
                  <AssetLink href="/assets/subscriptions_sample.csv">Download sample CSV</AssetLink>
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ marginBottom: '1rem' }}>
                  <IdListImport
                    onChange={handleIdListChange}
                    setIsLoading={setIsLoading}
                    toolbarInfo="Total Records to Import"
                    buttonClassName={buttonClasses.saveButton}
                  />
                  <CsvImport
                    onChange={handleIdListChange}
                    setIsLoading={setIsLoading}
                    title="CSV File Upload for Import"
                    buttonClassName={buttonClasses.saveButton}
                  />
                  <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem' }}>
                    <Button label="POC Dataset" onClick={handlePOCDataset} className={buttonClasses.saveButton}>
                      <DatasetIcon />
                    </Button>
                  </Box>
                </Box>
                <TextInput
                  source="topics"
                  validate={validateTopics}
                  label="Topics"
                  fullWidth
                  isRequired
                  multiline
                  resettable
                  rows={10}
                  onChange={handleTopicsChange}
                />
              </DialogContent>
            </SimpleForm>
          </DialogInner>
        )}
      </Dialog>
    </Box>
  );
};

export default BulkImport;
