import { createBrowserHistory as createHistory } from 'history';
import React, { useEffect, useState, FC } from 'react';
import { Admin, DataProvider, Loading, RaThemeOptions, Resource, Notification } from 'react-admin';
import { isEqual } from 'lodash';

import authProvider from './authProvider';
import CraftAdminLayout from './components/CraftAdminLayout';
import buildProvider from './data/provider';
import Login from './pages/login/Login';
import Homepage from './components/Homepage';
import theme from './theme';
import resources from './resources';
import { restoreToken, toUserPermissions } from './utils/auth';
import { i18nProvider } from './i18nProvider';
import { CraftAdminPermission, CraftAdminUserPermissions, ResourcePermissionMap } from './types';
import { config } from './config/config.local';

console.info(`[${config.app.hash}] Welcome to Craft Admin!`);

const history = createHistory();

const App: FC = () => {
  const [dataProvider, setDataProvider] = useState<null | DataProvider>(null);
  const [userPermissions, setUserPermissions] = useState<CraftAdminUserPermissions>();

  // Check for pre-existing user data on app load
  useEffect(() => {
    const preExistingToken = restoreToken();
    setUserPermissions(new Set(preExistingToken ? toUserPermissions(preExistingToken) : []));
  }, []);

  // Build DataProvider
  useEffect(() => {
    // We don't want to build/set the DataProvider until we've set user permissions, since the
    // user's permissions are part of the build context.
    if (!userPermissions) return;

    (async () => {
      const reactAdminDataProvider = await buildProvider(userPermissions);
      setDataProvider(reactAdminDataProvider);
    })();
  }, [userPermissions]);

  if (!dataProvider)
    return <Loading loadingPrimary="Loading Craft Admin" loadingSecondary="Loading obscene amounts of JavaScript..." />;
  // eslint-disable-next-line react/no-unstable-nested-components
  const MyNotification = () => (
    <Notification
      multiLine
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    />
  );

  return (
    <Admin
      notification={MyNotification}
      disableTelemetry
      layout={CraftAdminLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      dashboard={Homepage}
      requireAuth
      history={history}
      theme={theme as RaThemeOptions}
    >
      {(userPerms: CraftAdminPermission[]) => {
        // permissions provided after successful auth
        const userPermsSet = new Set(userPerms);
        if (!isEqual(userPermsSet, userPermissions)) setUserPermissions(userPermsSet);

        return resources.map((resource) => {
          const verifiedPermissions = verifyResourcePermissions(resource.permissions ?? {}, userPerms);
          if (!verifiedPermissions.list) return null;

          const { create, edit, ...rest } = resource;
          const viewProps: Partial<typeof resource> = {
            ...(create && verifiedPermissions.create ? { create } : {}),
            ...(edit && verifiedPermissions.edit ? { edit } : {}),
          };

          return verifiedPermissions.list ? <Resource key={resource.name} {...viewProps} {...rest} /> : null;
        });
      }}
    </Admin>
  );
};

function verifyResourcePermissions(
  requiredPermissionsMap: ResourcePermissionMap,
  userPermissions: string[],
): Record<keyof ResourcePermissionMap, boolean> {
  const resourceViews = Object.keys(requiredPermissionsMap) as Array<keyof ResourcePermissionMap>;
  return resourceViews.reduce(
    (result, view) => ({
      ...result,
      [view]: requiredPermissionsMap[view]?.every((perm) => userPermissions.includes(perm)) ?? true,
    }),
    { list: true, create: true, edit: true },
  );
}

export default App;
