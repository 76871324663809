import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useNotify, useRedirect } from 'react-admin';

import DetailsTab from './formTabs/Details';
import FieldsTab from './formTabs/Fields';
import client from 'src/data/api';
import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { DataPackageRecord } from 'src/types';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { dataPackageSchema, resolveMutationParams } from './helpers';

export const DataPackageCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [createDataPackage, { loading: createDataPackageLoading }] = useMutation<{
    insert_data_packages: { returning: DataPackageRecord[] };
  }>(
    gql`
      mutation insert_data_packages($dataPackageParams: data_packages_insert_input!) {
        insert_data_packages(objects: [$dataPackageParams]) {
          returning {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = dataPackageSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const { variables } = resolveMutationParams(values);
    const { data, errors } = await createDataPackage({ variables });

    if (errors) {
      notify(errors[0].message, { type: 'error' });
      return undefined;
    }

    const dataPackageID = data?.insert_data_packages.returning[0].id;
    if (!dataPackageID) {
      notify('Unable to redirect to new Data Package: ID not returned', { type: 'error' });
      return redirect('list', 'data_packages');
    }

    notify('The Data Package has been successfully created', { type: 'success' });
    return redirect(`/data_packages/${dataPackageID}`);
  };

  return (
    <CraftCreate header="Create Data Package">
      <CraftTabbedForm formType="create" onSubmit={handleSave} shouldUnregister>
        <DetailsTab mode="create" />
        <FieldsTab />
      </CraftTabbedForm>

      <LoadingOverlay open={createDataPackageLoading} />
    </CraftCreate>
  );
};

export default DataPackageCreate;
