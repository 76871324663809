import { FormTab, FormTabProps } from 'react-admin';

import { CraftPageSection } from 'src/components/CraftPageSection';
import BooleanInput from 'src/inputs/BooleanInput';
import { EXTERNAL_API_CLIENT_FEATURES } from 'src/utils/defaults/Constants';

const FeaturesTab = (props: Omit<FormTabProps, 'label'>) => (
  <FormTab label="Features" {...props}>
    <CraftPageSection title="Features">
      {EXTERNAL_API_CLIENT_FEATURES.map((feat) => {
        // we use "/" instead of "." to avoid conversion of form values to obj.
        const valuePath = `features.${feat.id.replaceAll('.', '/')}`;
        return <BooleanInput key={valuePath} source={valuePath} label={feat.name} helperText={false} />;
      })}
    </CraftPageSection>
  </FormTab>
);

export default FeaturesTab;
