import { ImageField, ImageInput, ImageInputProps, useRecordContext } from 'react-admin';
import { getCompanyProfileBaseUrl } from 'src/resources/companies/CompanyHelper';

export const LogoInput = (props: ImageInputProps) => {
  const record = useRecordContext();
  if (!record?.id) return null;
  return (
    <ImageInput
      {...props}
      accept="image/png, image/jpg, image/jpeg"
      maxSize={5000000}
      multiple={false}
      format={(val) => {
        if (typeof val === 'string') {
          return {
            src: `${getCompanyProfileBaseUrl(parseInt(record.id.toString(), 10))}/${val}`,
          };
        }
        return val;
      }}
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};

export default LogoInput;
