import {
  TextField,
  TextInput,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  SingleFieldList,
  SortPayload,
  ArrayField,
  FunctionField,
  WrapperField,
  EditButton,
} from 'react-admin';
import { Chip } from '@mui/material';
import { Center } from 'src/components/Center';
import { CraftList, useCraftList } from 'src/components/CraftList';
import { TransformedArrayField } from 'src/fields/TransformedArrayField';
import { isWildcardTopic, optionText, queryFilter } from '../topicsMetadata/TopicMetadataDisplayModel';
import { TopicMetadataField } from '../topicsMetadata/TopicMetadataField';
import { TopicMetadata } from '../topicsMetadata/TopicMetadataTypes';
import { useLocation } from 'react-router-dom';
import { dateParser } from 'src/utils/dateUtil';
import { CraftDateInput } from 'src/components/CraftDateInput';

export const PublishedAlertsFilters = [
  <TextInput key="idFilter" label="Id" source="id@_eq" alwaysOn />,
  <CraftDateInput key="dateFrom" label="Newer than" source="date@_gte" alwaysOn parse={dateParser} />,
  <CraftDateInput key="dateTo" label="Older than" source="date@_lte" alwaysOn parse={dateParser} />,
  <ReferenceInput
    key="topicsFilter"
    reference="topics_metadata"
    source="topics#topic@_eq"
    alwaysOn
    perPage={20}
    sort={{ field: 'topic', order: 'ASC' }}
  >
    <AutocompleteInput
      label="Topic"
      source="topic"
      optionValue="topic"
      optionText={optionText}
      filterToQuery={queryFilter}
      sx={{
        minWidth: '15rem',
      }}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="clientsFilter"
    reference="signals_clients"
    source="clients@_contains"
    alwaysOn
    sort={{ field: 'client', order: 'ASC' }}
  >
    <AutocompleteInput
      label="Client"
      optionText="client"
      source="client"
      filterToQuery={(q: string) => ({ 'client@_ilike': q })}
      sx={{
        minWidth: '15rem',
      }}
    />
  </ReferenceInput>,
];

const defaultSort: SortPayload = {
  field: 'date',
  order: 'DESC',
};

const columnStyles = {
  id: {
    width: '2rem',
  },
  signal_title: {
    width: '20rem',
  },
  signal_text: {
    width: '20rem',
  },
};

export const PublishedAlertsList = () => {
  const list = useCraftList();
  const location = useLocation();

  return (
    <CraftList
      ref={list}
      title="Published Alerts"
      filters={PublishedAlertsFilters}
      sort={defaultSort}
      columnStyles={columnStyles}
      dataGridProps={{ empty: <Center sx={{ p: 2 }}>No published alerts found</Center> }}
    >
      <TextField source="id" />
      <TextField label="Title" source="signal_title" />
      <TextField label="Text" source="signal_text" />
      <TransformedArrayField<TopicMetadata>
        sortable={false}
        source="topics"
        filter={(t) => !isWildcardTopic(t.topic)}
        sort={defaultSort}
      >
        <SingleFieldList linkType={false} sx={{ gap: 1 }}>
          <TopicMetadataField
            // source="metadata"
            chipProps={{ size: 'small' }}
            onClick={(_, model) => {
              const params = new URLSearchParams(location.search);
              const filterString = params.get('filter');
              const filter = filterString ? JSON.parse(decodeURIComponent(filterString)) : {};
              list.current?.setFilters(
                {
                  ...filter,
                  'topics#topic@_eq': model.topic,
                },
                [],
              );
            }}
          />
        </SingleFieldList>
      </TransformedArrayField>
      <ArrayField source="clients">
        <SingleFieldList linkType={false} sx={{ gap: 1 }}>
          <FunctionField
            render={(record: string) => (
              <Chip
                label={record}
                onClick={() => {
                  const params = new URLSearchParams(location.search);
                  const filterString = params.get('filter');
                  const filter = filterString ? JSON.parse(decodeURIComponent(filterString)) : {};
                  list.current?.setFilters(
                    {
                      ...filter,
                      'clients@_contains': record,
                    },
                    [],
                  );
                }}
              />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      <DateField source="date" />
      <WrapperField label="Actions">
        <EditButton resource="signals" />
      </WrapperField>
    </CraftList>
  );
};

export default PublishedAlertsList;
