import AddRoadIcon from '@mui/icons-material/AddRoad';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import CompanyRequestsList from './List';

export const CompanyRequestsResource: ResourceDefinition = {
  name: 'company_requests',
  section: ResourceSections.PORTAL,
  icon: AddRoadIcon,

  list: CompanyRequestsList,

  permissions: {
    list: ['companyRequest:read'],
  },

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'portal_company_requests',
      },
      [GET_ONE]: {
        operationName: 'portal_company_requests',
      },
      [GET_MANY]: {
        operationName: 'portal_company_requests',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'portal_company_requests',
      },
    },
  },
};
