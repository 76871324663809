import { Box, Card } from '@mui/material';
import { DateField, Labeled } from 'react-admin';
import { CraftTwoColumns } from 'src/components/CraftTwoColumns';
import { AuditEntriesEmbeddedList } from 'src/resources/portal/auditEntries/EmbeddedList';

export const OrgsEditAside = () => (
  <Box ml={2} minWidth="25rem" sx={{ marginTop: { xs: '2rem', sm: '2rem', md: 0 } }}>
    <Card sx={{ p: 2 }}>
      <CraftTwoColumns
        left={
          <>
            <Box>
              <Labeled label="Created">
                <DateField source="created_at" showTime />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Deleted">
                <DateField source="deleted_at" showTime emptyText="N/A" />
              </Labeled>
            </Box>
          </>
        }
        right={
          <>
            <Box>
              <Labeled label="Updated">
                <DateField source="updated_at" showTime />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Archived">
                <DateField source="archived_at" showTime emptyText="N/A" />
              </Labeled>
            </Box>
          </>
        }
      />
    </Card>
    <h3>Audit Entries</h3>
    <AuditEntriesEmbeddedList from="meta@_contains@actor#organization" />
  </Box>
);

export default OrgsEditAside;
