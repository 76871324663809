import React, { useState } from 'react';
import { Button, SaveButton, useNotify, SimpleForm, FileInput, FileField } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { normalizeTopic } from 'src/utils/organizationSubscriptions';
import { makeStyles } from '@mui/styles';
import BulkToolbar from './BulkToolbar';

const useStyles = makeStyles({
  saveButton: {
    backgroundColor: '#478b60',
    '&:hover': {
      backgroundColor: '#3c7b53',
    },
  },
});

const CsvImportToolbar = ({ handleCloseClick }: { handleCloseClick: () => void }) => {
  const classes = useStyles();

  const buttonComponents = <SaveButton alwaysEnable className={classes.saveButton} label="Apply" />;

  return <BulkToolbar handleCloseClick={handleCloseClick} buttonComponents={buttonComponents} />;
};

const validateCompanyId = (companyId: string, rowNumber: number): void => {
  const companyIdRegEx = /^\d+$/;
  if (!companyIdRegEx.test(companyId)) {
    throw new Error(`Invalid company_id: ${companyId} (row number: ${rowNumber}))`);
  }
};

const validateTopic = (topic: string, rowNumber: number): void => {
  const topicRegEx = /^company:\d+$/;
  if (!topicRegEx.test(topic)) {
    throw new Error(`Invalid topic: ${topic} (row number: ${rowNumber}))`);
  }
};

const COLUMN_NAMES = {
  TOPIC: 'topic',
  COMPANY_ID: 'company_id',
};

const validateAndNormalizeData = {
  [COLUMN_NAMES.TOPIC]: ({ data, rowNumber }: { data: string; rowNumber: number }): string => {
    validateTopic(data, rowNumber);
    return data;
  },

  [COLUMN_NAMES.COMPANY_ID]: ({ data, rowNumber }: { data: string; rowNumber: number }): string => {
    validateCompanyId(data, rowNumber);
    return `company:${data}`;
  },
};

const parseCsvData = (csvData: string) => {
  const topics: string[] = [];
  const lines = csvData.split('\n');
  const headers = lines[0].split(',').map(normalizeTopic);
  lines.slice(1).forEach((line) => {
    const values = line.split(',').map(normalizeTopic);
    headers.forEach((header, index) => {
      const value = values[index];
      if (!value || value === '') return;
      if (!validateAndNormalizeData[header]) {
        throw new Error(`Invalid header: ${header}`);
      }
      topics.push(validateAndNormalizeData[header]({ data: value, rowNumber: index + 2 }));
    });
  });

  return topics;
};

const CsvImport = ({
  onChange,
  setIsLoading,
  title,
  buttonClassName = '',
}: {
  onChange: (topics: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  title: string;
  buttonClassName?: string;
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const notify = useNotify();

  const handleCsvImportButtonClick = () => {
    setShowDialog(true);
  };

  const handleCsvImportCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit: SubmitHandler<FieldValues> = async (data) => {
    setIsLoading(true);
    const csvFile = data.csvFile?.rawFile;

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          // Doing reader.readAsText(file) will render the
          // contents of the file as a string using the
          // event.target.result property.
          const csvData = event?.target?.result as string;
          const topics = parseCsvData(csvData);
          onChange([...new Set(topics)].join('\n'));

          setIsLoading(false);
          setShowDialog(false);
          resolve(true);
        } catch (error) {
          setIsLoading(false);
          setShowDialog(true);
          const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
          notify(errorMessage, { type: 'error' });
          resolve(true);
        }
      };
      reader.onerror = (error) => {
        setIsLoading(false);
        setShowDialog(true);
        const errorMessage = error.target?.error?.message ?? 'An error occurred while reading the file';
        notify(`Error: ${errorMessage}`, { type: 'error' });
        resolve(true);
      };

      if (csvFile) {
        reader.readAsText(csvFile);
      } else {
        setIsLoading(false);
        setShowDialog(false);
      }
    });
  };

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem' }}>
      <Button onClick={handleCsvImportButtonClick} label="CSV File Upload" size="medium" className={buttonClassName}>
        <FileUploadIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCsvImportCloseClick} aria-label="CSV Import">
        <DialogTitle>{title}</DialogTitle>
        <SimpleForm
          onSubmit={handleSubmit}
          warnWhenUnsavedChanges
          toolbar={<CsvImportToolbar handleCloseClick={handleCsvImportCloseClick} />}
        >
          <DialogContent sx={{ width: '100%' }}>
            <FileInput accept=".csv" source="csvFile" fullWidth>
              <FileField source="src" title="title" fullWidth />
            </FileInput>
          </DialogContent>
        </SimpleForm>
      </Dialog>
    </Box>
  );
};

export default CsvImport;
