import React, { useEffect } from 'react';
import { TextInput, SelectInput, required, useRecordContext } from 'react-admin';
import { CommodityChoices } from './types';
import { normalizeCommodity } from 'src/utils/companyCommodities';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { fetchPublishedCommodities } from './fetchPublishedCommodities';

export const SharedInputs = ({
  publishedCommodities,
  setPublishedCommodities,
}: {
  publishedCommodities: CommodityChoices;
  setPublishedCommodities: React.Dispatch<React.SetStateAction<CommodityChoices>>;
}) => {
  const record = useRecordContext();

  useEffect(() => {
    (async () => {
      const commodities = await fetchPublishedCommodities();
      setPublishedCommodities((prevCommodities) =>
        uniqWith(
          [
            ...prevCommodities,
            ...commodities.map((commodity) => ({
              id: commodity.resource_id,
              name: normalizeCommodity(commodity.resource_id),
            })),
          ].sort((a, b) => (a.name > b.name ? 1 : -1)),
          isEqual,
        ),
      );
    })();
  }, []);

  useEffect(() => {
    if (!record?.commodity_id) return;

    setPublishedCommodities((prevCommodities) =>
      uniqWith(
        [
          ...prevCommodities,
          {
            id: record?.commodity_id,
            name: normalizeCommodity(record?.commodity_id || ''),
          },
        ].sort((a, b) => (a.name > b.name ? 1 : -1)),
        isEqual,
      ),
    );
  }, [record]);

  return (
    <>
      <TextInput source="company_id" validate={required()} label="Company" />
      <SelectInput source="commodity_id" validate={required()} label="Commodity" choices={publishedCommodities} />
    </>
  );
};

export default SharedInputs;
