import DatasetIcon from '@mui/icons-material/Dataset';
import gql from 'graphql-tag';

import DataPackagesShow from './Show';
import DataPackagesList from './List';
import DataPackagesCreate from './Create';
import DataPackagesEdit from './Edit';
import { ResourceDefinition, ResourceSections } from 'src/types';

export const DataPackagesResource: ResourceDefinition = {
  name: 'data_packages',
  options: { label: 'Data Packages' },
  section: ResourceSections.DATA_PACKAGES,
  icon: DatasetIcon,

  list: DataPackagesList,
  show: DataPackagesShow,
  create: DataPackagesCreate,
  edit: DataPackagesEdit,

  permissions: {
    list: ['dataPackage:read'],
    create: ['dataPackage:edit'],
    edit: ['dataPackage:edit'],
  },

  extension: {
    extraFields: gql`
      {
        api_fields_xrefs: api_fields {
          id
          api_field_id
          policies
          api_field {
            id
            path
          }
        }
      }
    `,
  },
};
