import { BooleanInputProps as BooleanInputRaProps, BooleanInput as BooleanInputRA } from 'react-admin';

import WithInfoTooltip from 'src/components/WithInfoTooltip';
import { CommonInputProps } from './types';

export interface BooleanInputProps extends BooleanInputRaProps, CommonInputProps {}

export const BooleanInput = ({ infoTooltip, ...rest }: BooleanInputProps) => {
  if (!infoTooltip) return <BooleanInputRA {...rest} />;

  return (
    <WithInfoTooltip title={infoTooltip} sx={{ ml: -1 }}>
      <BooleanInputRA {...rest} />
    </WithInfoTooltip>
  );
};

export default BooleanInput;
