import { NumberInput, TextInput, required, SelectInput } from 'react-admin';

type SharedInputsProps = {
  userId?: string;
};

export const SharedInputs = ({ userId }: SharedInputsProps) => (
  <>
    <TextInput
      source="user_id"
      label="User Id"
      fullWidth
      disabled
      validate={required()}
      {...(userId && { defaultValue: userId })}
    />
    <NumberInput source="company_id" label="Company Id" fullWidth validate={required()} />
    <SelectInput source="attribute" choices={[{ id: 'company', name: 'company' }]} defaultValue="company" disabled />
  </>
);

export default SharedInputs;
