import { BulkUpdateButton } from 'react-admin';
import ActionDelete from '@mui/icons-material/Delete';

interface CraftBulkUpdateButtonProps {
  data?: Partial<unknown>;
  label?: string;
  dialogTitle?: string;
  dialogContent?: string;
}

const BulkSoftDeleteButton = ({
  data = { deleted_at: new Date().toISOString() },
  label = 'Delete',
  dialogTitle = 'ra.message.bulk_delete_title',
  dialogContent = 'ra.message.bulk_delete_content',
}: CraftBulkUpdateButtonProps) => (
  <BulkUpdateButton
    label={label}
    confirmTitle={dialogTitle}
    confirmContent={dialogContent}
    data={data}
    icon={<ActionDelete />}
    mutationMode="optimistic" // TODO: doesn't always refresh list when set to "pessimistic"
    sx={(theme) => ({ color: theme.palette.error.main })}
  />
);

export default BulkSoftDeleteButton;
