import { CREATE, GET_LIST, GET_ONE, UPDATE, DELETE, GET_MANY_REFERENCE, UPDATE_MANY, DELETE_MANY } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import UserSubscriptionsCreate from './Create';
import UserSubscriptionsEdit from './Edit';

export const UserSubscriptionsResource: ResourceDefinition = {
  name: 'user_subscriptions',
  section: ResourceSections.ALERTS,
  create: UserSubscriptionsCreate,
  edit: UserSubscriptionsEdit,
  permissions: {
    create: ['alerts:edit'],
    edit: ['alerts:edit'],
  },

  extension: {
    operations: {
      [CREATE]: {
        operationName: 'signals_insert_user_subscriptions',
      },
      [GET_LIST]: {
        operationName: 'signals_user_subscriptions',
      },
      [GET_ONE]: {
        operationName: 'signals_user_subscriptions',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_user_subscriptions',
      },
      [UPDATE]: {
        operationName: 'signals_update_user_subscriptions',
      },
      [UPDATE_MANY]: {
        operationName: 'signals_update_user_subscriptions_many',
      },
      [DELETE]: {
        operationName: 'signals_delete_user_subscriptions',
      },
      [DELETE_MANY]: {
        operationName: 'signals_delete_user_subscriptions',
      },
    },
  },
};
