import React, { forwardRef, useMemo, useRef } from 'react';
import { Box, Card, SxProps, Theme, Typography } from '@mui/material';
import {
  Datagrid,
  ListBase,
  ListToolbar,
  Pagination,
  ListProps,
  DatagridProps,
  ListControllerResult,
  PaginationProps,
} from 'react-admin';

import { CraftAdminPermission } from 'src/types';
import { usePermissions } from 'src/hooks';
import { CraftPageHeader } from './CraftPageHeader';
import { ClearFiltersButton } from 'src/components/ClearFiltersButton';
import { ListRefHandle } from './ListRefHandle';
import { Center } from './Center';
import ListInfoSection from './ListInfoSection';

export type CraftListProps = {
  title?: string;
  children: React.ReactNode;
  infoItems?: React.ReactElement;
  dataGridProps?: DatagridProps;
  paginationProps?: PaginationProps;
  columnStyles?: SxProps<Theme>;
  showClearButton?: boolean;
  /**
   * Permission checked when displaying the **default** bulk action buttons.
   */
  deletePermission?: CraftAdminPermission;
};

/**
 * Default page layout for the "List" views,
 * supports same properties as the original List, but also
 * splits filters into their own header component, applies
 * custom styling to the Datagrid and allows easier column styling
 * customization.
 */
export const CraftList = forwardRef<ListControllerResult, Omit<ListProps, 'children'> & CraftListProps>(
  (
    {
      title,
      children,
      filters,
      actions,
      infoItems,
      dataGridProps = {},
      columnStyles = {},
      showClearButton = true,
      deletePermission,
      paginationProps,
      ...rest
    },
    ref,
  ) => {
    const { permissions } = usePermissions();

    const canDelete = !deletePermission || permissions.has(deletePermission);
    const showDefaultBulkActions = dataGridProps.bulkActionButtons === undefined;

    const finalDatagridProps = useMemo<DatagridProps>(
      () => ({
        empty: <Center sx={{ p: 2 }}>No data found</Center>,
        ...dataGridProps,
        ...(showDefaultBulkActions && !canDelete && { bulkActionButtons: false }),
        sx: {
          ...dataGridProps.sx,
          ...makeColumnStyles(columnStyles),
        },
      }),
      [dataGridProps, columnStyles],
    );

    const showBulkActions = finalDatagridProps.bulkActionButtons !== false;
    const listContents = (
      <>
        <Datagrid
          size="small"
          isRowSelectable={(record) => !record.deleted_at && !record.archived_at}
          {...finalDatagridProps}
          rowSx={(record) => ({
            backgroundColor: record.deleted_at || record.archived_at ? '#fcb1b1bb' : undefined,
            '&.MuiTableRow-hover:hover': {
              backgroundColor: record.deleted_at || record.archived_at ? '#fcb1b1ff' : undefined,
            },
          })}
        >
          {children}
        </Datagrid>
        <Pagination {...paginationProps} />
      </>
    );

    return (
      <Box>
        <ListBase {...rest}>
          <ListRefHandle ref={ref} />
          <CraftPageHeader>
            {title && <Typography variant="h5">{title}</Typography>}
            <ListToolbar filters={filters ?? []} actions={actions ?? false} />
            {showClearButton && <ClearFiltersButton />}
          </CraftPageHeader>
          {infoItems && <ListInfoSection sx={{ mx: 2 }}>{infoItems}</ListInfoSection>}

          <Card sx={{ m: 2, ...(showBulkActions ? { overflow: 'visible' } : {}) }}>{listContents}</Card>
        </ListBase>
      </Box>
    );
  },
);

CraftList.displayName = 'CraftList';

function makeColumnStyles(columns: SxProps<Theme>) {
  if (!columns) {
    return {};
  }

  return Object.fromEntries(Object.entries(columns).map(([key, styles]) => [`& .column-${key}`, styles]));
}

export function useCraftList() {
  return useRef<ListControllerResult>(null);
}

export default CraftList;
