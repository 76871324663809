import { makeStyles } from '@mui/styles';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, TextInput, useNotify, SimpleForm, Confirm } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  FormHelperText,
  Snackbar,
  SnackbarContent,
} from '@mui/material';
import { DeleteSweep as DeleteSweepIcon, RemoveCircle as RemoveCircleIcon } from '@mui/icons-material';
import { client } from '../../../data/api';
import gql from 'graphql-tag';
import IdListImport from './IdListImport';
import CsvImport from './CsvImport';
import LoadingOverlay from 'src/components/LoadingOverlay';
import {
  getOrgId,
  formatTopics,
  validateTopics,
  generateErrorMessage,
  buttonStyles,
} from 'src/utils/organizationSubscriptions';
import BulkToolbar from './BulkToolbar';
import { AssetLink } from './AssetLink';
import { DialogInner } from './DialogInner';

const CustomDeleteButton = ({
  handleSubmit,
  records,
  loading,
  setInvalidMessage,
}: {
  handleSubmit: () => void;
  records: { topics: string };
  loading: boolean;
  setInvalidMessage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [open, setOpen] = useState(false);
  const buttonClasses = buttonStyles();

  const handleClick = () => {
    const invalidMessage = validateTopics(records.topics);
    if (invalidMessage) {
      setInvalidMessage(invalidMessage);
    } else {
      setInvalidMessage('');
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    setInvalidMessage('');
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    handleSubmit();
  };

  return (
    <>
      <Button
        label="Delete Bulk"
        onClick={handleClick}
        disabled={loading}
        size="medium"
        className={buttonClasses.deleteButton}
      >
        <RemoveCircleIcon />
      </Button>
      <Confirm
        isOpen={open}
        title="Delete Organization Subscriptions"
        content="Are you sure you want to delete these organization subscriptions?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirm="Delete"
        className={buttonClasses.confirmDeleteButton}
      />
    </>
  );
};

const useStyles = makeStyles({
  textInputError: {
    '& .MuiInputLabel-root': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '& > fieldset': { borderColor: '#d32f2f' },
    },
    '&:hover .MuiOutlinedInput-root': {
      '& > fieldset': { borderColor: '#d32f2f' },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        borderColor: '#d32f2f',
      },
    },
  },
});

const BulkDeleteToolbar = ({
  handleCloseClick,
  handleSubmit,
  records,
  loading,
  setInvalidMessage,
  toolbarInfo,
}: {
  handleCloseClick: () => void;
  handleSubmit: () => void;
  setInvalidMessage: React.Dispatch<React.SetStateAction<string>>;
  records: {
    topics: string;
  };
  loading: boolean;
  toolbarInfo: string;
}) => {
  const buttonComponents = (
    <CustomDeleteButton
      loading={loading}
      handleSubmit={handleSubmit}
      records={records}
      setInvalidMessage={setInvalidMessage}
    />
  );

  const infoComponents = (
    <Typography variant="body2" color="textSecondary">
      {toolbarInfo}: {formatTopics(records).length?.toLocaleString()}
    </Typography>
  );

  return (
    <BulkToolbar
      handleCloseClick={handleCloseClick}
      infoComponents={infoComponents}
      buttonComponents={buttonComponents}
    />
  );
};

const BulkDelete = ({ refresh }: { refresh: () => void }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [records, setRecords] = useState({ topics: '' });

  const notify = useNotify();
  const classes = useStyles();
  const buttonClasses = buttonStyles();

  useEffect(() => {
    if (invalidMessage) {
      setShowNotification(true);
    }
  }, [invalidMessage]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleBulkDeleteButtonClick = () => {
    setIsLoading(false);
    setShowDialog(true);
  };

  const handleBulkDeleteCloseClick = () => {
    setIsLoading(false);
    setShowDialog(false);
    setInvalidMessage('');
  };

  let debounceTimeout: NodeJS.Timeout;

  const handleTopicsChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(debounceTimeout);
    const topics = event?.target?.value ?? '';
    debounceTimeout = setTimeout(() => {
      setRecords({ topics });
      setInvalidMessage('');
    }, 500);
  };

  const handleIdListChange = (topics: string) => {
    setRecords({ topics });
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const topicList: string[] = formatTopics(records);

    try {
      const topicsFormatted = [...new Set(topicList)];

      const orgId = getOrgId();
      if (!orgId) {
        throw new Error('No organization id');
      }

      const { data } = await client.mutate<{
        signals_delete_organization_subscriptions: { affected_rows: number };
      }>({
        mutation: gql`
          mutation SignalsDeleteOrganizationSubscriptions($where: organization_subscriptions_bool_exp!) {
            signals_delete_organization_subscriptions(where: $where) {
              affected_rows
            }
          }
        `,
        variables: {
          where: {
            org_id: {
              _eq: orgId,
            },
            topic: {
              _in: topicsFormatted,
            },
          },
        },
      });

      const numAffectedRows = data?.signals_delete_organization_subscriptions?.affected_rows || 0;
      setShowDialog(false);
      notify(
        `Successfully deleted ${numAffectedRows.toLocaleString()} organization subscription${
          numAffectedRows > 1 ? 's' : ''
        }`,
      );
      setRecords({ topics: '' });
      refresh();
    } catch (error) {
      setIsLoading(false);
      const errorMessage = generateErrorMessage({
        error,
        prependMessage: 'Error deleting organization subscriptions\n',
      });
      notify(errorMessage, { type: 'error' });
    }
  };

  const isDeleteButtonDisabled = !records.topics.length || isLoading;
  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem', minWidth: '10rem' }}>
      <Button
        label="Delete Bulk"
        size="medium"
        onClick={handleBulkDeleteButtonClick}
        className={buttonClasses.deleteButton}
      >
        <DeleteSweepIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleBulkDeleteCloseClick}
        aria-label="Delete Organization Subscriptions"
      >
        {isLoading && <LoadingOverlay open />}
        {!isLoading && (
          <DialogInner>
            <DialogTitle>Delete Organization Subscriptions</DialogTitle>
            <SimpleForm
              record={records}
              onSubmit={handleSubmit}
              toolbar={
                <BulkDeleteToolbar
                  handleCloseClick={handleBulkDeleteCloseClick}
                  loading={isDeleteButtonDisabled}
                  handleSubmit={handleSubmit}
                  records={records}
                  setInvalidMessage={setInvalidMessage}
                  toolbarInfo="Total Records to Delete"
                />
              }
            >
              <DialogContent sx={{ width: '100%' }}>
                <Box sx={{ marginTop: '-1rem' }}>
                  <AssetLink href="/assets/subscriptions_sample.csv">Download sample CSV</AssetLink>
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ marginBottom: '1rem' }}>
                  <IdListImport
                    onChange={handleIdListChange}
                    setIsLoading={setIsLoading}
                    toolbarInfo="Total Records to Delete"
                    buttonClassName={buttonClasses.deleteButton}
                  />
                  <CsvImport
                    onChange={handleIdListChange}
                    setIsLoading={setIsLoading}
                    title="CSV File Upload for Deletion"
                    buttonClassName={buttonClasses.deleteButton}
                  />
                </Box>
                <TextInput
                  className={invalidMessage ? classes.textInputError : ''}
                  source="topics"
                  validate={validateTopics}
                  label="Topics"
                  fullWidth
                  isRequired
                  multiline
                  resettable
                  rows={10}
                  onChange={handleTopicsChange}
                />
                {invalidMessage && (
                  <>
                    <FormHelperText id="topics-helper-text" error sx={{ marginTop: '-1rem', marginLeft: '1rem' }}>
                      Input must be &apos;company:&apos; followed by a number, one per line.
                    </FormHelperText>
                    <Snackbar
                      open={showNotification}
                      autoHideDuration={6000}
                      message="The form is not valid. Please check for errors"
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                      onClose={handleCloseNotification}
                    >
                      <SnackbarContent
                        style={{
                          backgroundColor: '#ef5350',
                        }}
                        message="The form is not valid. Please check for errors"
                      />
                    </Snackbar>
                  </>
                )}
              </DialogContent>
            </SimpleForm>
          </DialogInner>
        )}
      </Dialog>
    </Box>
  );
};

export default BulkDelete;
