import SchemaIcon from '@mui/icons-material/Schema';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import { TopicsMetadataList } from './List';
import { isLocationTopic, isWildcardTopic, parseTopic } from './TopicMetadataDisplayModel';

export const TopicsMetadataResource: ResourceDefinition = {
  name: 'topics_metadata',
  section: ResourceSections.ALERTS,
  icon: SchemaIcon,

  list: TopicsMetadataList,
  permissions: {
    list: ['alerts:read'],
  },

  extension: {
    idKey: ['topic'],
    operations: {
      [GET_LIST]: {
        operationName: 'signals_topics_metadata',
      },
      [GET_ONE]: {
        operationName: 'signals_topics_metadata',
      },
      [GET_MANY]: {
        operationName: 'signals_topics_metadata',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_topics_metadata',
      },
    },

    transformResource(_ctx, resource) {
      Object.assign(resource, {
        computed: {
          isLocation: resource.topic && isLocationTopic(resource.topic),
          isWildcard: resource.topic && isWildcardTopic(resource.topic),
          ...parseTopic(resource.topic),
        },
      });

      return resource;
    },
  },
};
