import { useState } from 'react';
import { Button, Confirm, useRecordContext, useNotify } from 'react-admin';
import { Box } from '@mui/material';
import { client } from '../../../data/api';
import gql from 'graphql-tag';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { getOrgId, generateErrorMessage, buttonStyles } from 'src/utils/organizationSubscriptions';

export const DeleteAll = ({ refresh }: { refresh: () => void }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const record = useRecordContext();
  const notify = useNotify();
  const buttonClasses = buttonStyles();

  const handleDeleteAll = async () => {
    const orgId = record?.org_id || getOrgId();
    if (!orgId) {
      setOpen(false);
      setIsLoading(false);
      return notify('No organization id found');
    }
    try {
      const { data } = await client.mutate<{
        signals_delete_company_commodities: { affected_rows: number };
      }>({
        mutation: gql`
          mutation DeleteAllCompanyCommodities($where: company_commodities_bool_exp!) {
            signals_delete_company_commodities(where: $where) {
              affected_rows
            }
          }
        `,
        variables: {
          where: {
            org_id: {
              _eq: orgId,
            },
          },
        },
      });

      setIsLoading(false);
      setOpen(false);
      const numAffectedRows = data?.signals_delete_company_commodities?.affected_rows || 0;
      notify(`${numAffectedRows.toLocaleString()} company commodities deleted`);

      return refresh();
    } catch (error) {
      setOpen(false);
      setIsLoading(false);
      const errorMessage = generateErrorMessage({
        error,
        prependMessage: 'Error deleting company commodities\n',
      });
      return notify(errorMessage, { type: 'error' });
    }
  };
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    await handleDeleteAll();
  };

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem', minWidth: '8.5rem' }}>
      <>
        <Button label="Delete All" onClick={handleClick} size="medium" className={buttonClasses.deleteButton}>
          <DeleteForeverIcon />
        </Button>
        {!isLoading && (
          <Confirm
            isOpen={open}
            title={`Delete all company commodities for Org Id #${record && record.id}`}
            content="Are you sure you want to delete all company commodities for this organization?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
            confirm="Delete All"
            className={buttonClasses.confirmDeleteButton}
          />
        )}
      </>

      {isLoading && <LoadingOverlay open />}
    </Box>
  );
};

export default DeleteAll;
