import type { UserIdentity } from 'react-admin';
import decodeJwt, { JwtPayload, InvalidTokenError } from 'jwt-decode';

import localStorage from './localStorage';
import { CraftAdminPermission } from 'src/types';

const TOKEN_STORAGE_KEY = 'authToken';

export function restoreToken() {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
}

export function storeToken(token: string) {
  localStorage.setItem(TOKEN_STORAGE_KEY, token);
}

export function removeToken() {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
}

export function parseToken(token: string) {
  return decodeJwt<JwtPayload & { permissions?: CraftAdminPermission[] }>(token);
}

export function toUserIdentity(token: string): UserIdentity {
  const parsed = parseToken(token);

  if (!parsed.sub) {
    throw new Error('Invalid JWT: missing sub');
  }

  if (parsed.exp && Date.now() > parsed.exp * 1000) {
    throw new InvalidTokenError('Invalid JWT: expired');
  }

  return {
    id: parsed.sub,
  };
}

export function toApiAuth(token: string) {
  return `Bearer ${token}`;
}

export function toUserPermissions(token: string): CraftAdminPermission[] {
  const parsed = parseToken(token);

  if (!parsed.permissions) {
    throw new Error('Invalid JWT: missing permissions');
  }

  return parsed.permissions;
}
