import { useDataProvider } from 'react-admin';

export const useUnicityValidator = (resource: string, field: string) => {
  const dataProvider = useDataProvider();
  return async (value: string, values: { id: string }) => {
    if (!value) return undefined;
    const isFieldUnique = await dataProvider.getList(resource, {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'id', order: 'ASC' },
      filter: {
        'id@_nin': [values.id],
        [field]: [value],
      },
    });
    if (isFieldUnique.data.length) {
      return { message: `craft.validation.${field}.already_used`, args: { id: isFieldUnique.data[0].id } };
    }

    return undefined;
  };
};

export default useUnicityValidator;
