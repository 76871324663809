import { Snackbar, SnackbarContent } from '@mui/material';

type BulkErrorNotificationProps = {
  showNotification: boolean;
  handleCloseNotification: () => void;
  message: string;
};

export const BulkErrorNotification: React.FC<BulkErrorNotificationProps> = ({
  showNotification,
  handleCloseNotification,
  message,
}: BulkErrorNotificationProps) => (
  <Snackbar
    open={showNotification}
    autoHideDuration={6000}
    message={message}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    onClose={handleCloseNotification}
  >
    <SnackbarContent
      style={{
        backgroundColor: '#ef5350',
      }}
      message={message}
    />
  </Snackbar>
);
