import buildDataProvider from 'ra-data-hasura';

import { client } from './api';
import { buildQuery, resolveIntrospection } from './buildQuery';
import { CraftAdminUserPermissions } from 'src/types';

export const buildProvider = async (userPermissions: CraftAdminUserPermissions = new Set()) => {
  const hasuraProvider = buildDataProvider(
    {
      client,
      // @ts-expect-error types exposed by ra-data-hasura are incomplete
      buildQuery: (...args) => buildQuery(userPermissions, ...args),
      resolveIntrospection,
    },
    {
      aggregateFieldName: () => 'NO_COUNT',
    },
  );

  return hasuraProvider;
};

export default buildProvider;
