import { useCallback, useState } from 'react';
import { AutocompleteInput, AutocompleteInputProps, useRecordContext } from 'react-admin';

type ChoiceValue = { id: string; text: string; selected_text: string };

export const LocationAutocompleteInput = (props: AutocompleteInputProps) => {
  const record = useRecordContext();
  const [choices, setChoices] = useState<ChoiceValue[]>([]);
  const fetcher = useCallback(async (value: string) => {
    if (!value) return;
    const val = (await fetch(`${process.env._Data_CraftAdminLocationService_Url}?q=${value}`, {
      method: 'GET',
    })) as unknown as { results: ChoiceValue[] };
    setChoices(val.results);
  }, []);
  if (!record?.id) return null;
  return <AutocompleteInput {...props} shouldRenderSuggestions={fetcher} choices={choices} />;
};

export default LocationAutocompleteInput;
