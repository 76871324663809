import React, { useState } from 'react';
import { Button, SaveButton, useNotify, SimpleForm, FileInput, FileField } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import BulkToolbar from 'src/resources/signals/organizationSubscriptions/BulkToolbar';
import trim from 'lodash/trim';
import { CompanyCommodityType } from './types';

type CompanyCommodityKey = keyof CompanyCommodityType;

const useStyles = makeStyles({
  saveButton: {
    backgroundColor: '#478b60',
    '&:hover': {
      backgroundColor: '#3c7b53',
    },
  },
});

const CsvImportToolbar = ({ handleCloseClick }: { handleCloseClick: () => void }) => {
  const classes = useStyles();

  const buttonComponents = <SaveButton alwaysEnable className={classes.saveButton} label="Apply" />;

  return <BulkToolbar handleCloseClick={handleCloseClick} buttonComponents={buttonComponents} />;
};

const validateCompanyId = ({ rowNumber, companyId }: { rowNumber: number; companyId: string }): void => {
  const companyIdRegEx = /^\d+$/;
  if (!companyIdRegEx.test(companyId)) {
    throw new Error(`Invalid company_id: ${companyId} (row number: ${rowNumber}))`);
  }
};

const validateCommodityId = ({ rowNumber, commodityId }: { rowNumber: number; commodityId: string }): void => {
  const commodityIdRegEx = /^[a-z\d\s_]+$/;
  if (!commodityIdRegEx.test(commodityId)) {
    throw new Error(`Invalid commodityId: ${commodityId} (row number: ${rowNumber}))`);
  }
};

const COLUMN_NAMES = {
  COMPANY_ID: 'company_id',
  COMMODITY_ID: 'commodity_id',
};

const validateData = {
  [COLUMN_NAMES.COMMODITY_ID]: ({ rowNumber, data }: { data: string; rowNumber: number }): string => {
    validateCommodityId({ rowNumber, commodityId: data });
    return data;
  },
  [COLUMN_NAMES.COMPANY_ID]: ({ rowNumber, data }: { data: string; rowNumber: number }): string => {
    validateCompanyId({ rowNumber, companyId: data });
    return data;
  },
};

const parseCsvData = (csvData: string) => {
  const records: CompanyCommodityType[] = [];
  const lines = csvData.split('\n');
  const headers: CompanyCommodityKey[] = lines[0]
    .split(',')
    .map((header) => trim(header).toLowerCase() as CompanyCommodityKey);
  lines.slice(1).forEach((line) => {
    const record: Partial<CompanyCommodityType> = {};
    if (!line?.length) return;
    const values = line.split(',').map((cell) => trim(cell).toLowerCase());
    headers.forEach((header: CompanyCommodityKey, index: number) => {
      const value = values[index];
      if (!value || value === '') {
        throw new Error(`Empty value on row ${index + 2}`);
      }
      if (!validateData[header]) {
        throw new Error(`Invalid header: ${header}`);
      }
      const normalizedValue = validateData[header]({ data: value, rowNumber: index + 2 });

      if (typeof normalizedValue === 'string') {
        record[header] = normalizedValue;
      } else {
        throw new Error(`Invalid value type for header: ${header} on line ${index + 2}`);
      }
    });
    records.push(record as CompanyCommodityType);
  });

  return records;
};

const CsvImport = ({
  onChange,
  setIsLoading,
  title,
  buttonClassName = '',
}: {
  onChange: (records: CompanyCommodityType[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  title: string;
  buttonClassName?: string;
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const notify = useNotify();

  const handleCsvImportButtonClick = () => {
    setShowDialog(true);
  };

  const handleCsvImportCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit: SubmitHandler<FieldValues> = async (data) => {
    setIsLoading(true);
    const csvFile = data.csvFile?.rawFile;

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          // Doing reader.readAsText(file) will render the
          // contents of the file as a string using the
          // event.target.result property.
          const csvData = event?.target?.result as string;
          const records = parseCsvData(csvData);

          setIsLoading(false);
          setShowDialog(false);
          resolve(true);
          onChange(records);

          setIsLoading(false);
          setShowDialog(false);
          resolve(true);
        } catch (error) {
          setIsLoading(false);
          setShowDialog(true);
          const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
          notify(errorMessage, { type: 'error' });
          resolve(true);
        }
      };
      reader.onerror = (error) => {
        setIsLoading(false);
        setShowDialog(true);
        const errorMessage = error.target?.error?.message ?? 'An error occurred while reading the file';
        notify(`Error: ${errorMessage}`, { type: 'error' });
        resolve(true);
      };

      if (csvFile) {
        reader.readAsText(csvFile);
      } else {
        setIsLoading(false);
        setShowDialog(false);
      }
    });
  };

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem' }}>
      <Button onClick={handleCsvImportButtonClick} label="CSV File Upload" size="medium" className={buttonClassName}>
        <FileUploadIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCsvImportCloseClick} aria-label="CSV Import">
        <DialogTitle>{title}</DialogTitle>
        <SimpleForm
          onSubmit={handleSubmit}
          warnWhenUnsavedChanges
          toolbar={<CsvImportToolbar handleCloseClick={handleCsvImportCloseClick} />}
        >
          <DialogContent sx={{ width: '100%' }}>
            <FileInput accept=".csv" source="csvFile" fullWidth>
              <FileField source="src" title="title" fullWidth />
            </FileInput>
          </DialogContent>
        </SimpleForm>
      </Dialog>
    </Box>
  );
};

export default CsvImport;
