import { Box, Card } from '@mui/material';
import { Labeled, DateField } from 'react-admin';
import { CraftTwoColumns } from 'src/components/CraftTwoColumns';

export const UsersEditAside = () => (
  <Box ml={2} minWidth="25rem">
    <Card sx={{ p: 2 }}>
      <CraftTwoColumns
        left={
          <>
            <Labeled label="Created" fullWidth>
              <DateField source="created_at" showTime />
            </Labeled>
            <Labeled label="Updated">
              <DateField source="updated_at" showTime />
            </Labeled>
          </>
        }
        right={
          <Labeled label="Last login">
            <DateField source="last_sign_in_at" showTime />
          </Labeled>
        }
      />
    </Card>
  </Box>
);

export default UsersEditAside;
