import { CraftCreate } from 'src/components/CraftCreate';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  useNotify,
  useRedirect,
  useGetOne,
  useGetManyReference,
  CheckboxGroupInput,
  TransformData,
  required,
} from 'react-admin';
import {
  ORGANIZATION_CONFIG_EMAIL_ALERTS_GROUPINGS,
  ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE,
  ORGANIZATION_ALERTS_CONFIG_DATASETS,
  ORGANIZATION_RISK_CONFIG_DATASETS,
} from 'src/utils/defaults/Constants';
import { handleCheckboxGroupInputClick, checkboxGroupInputDefaultCursorSx } from 'src/utils/checkboxGroupInput';
import { orgConfigListRoute } from 'src/utils/routeHelpers';

const selectLastPathname = () => {
  const pathnameArr = window.location.pathname.split('/');
  return pathnameArr[pathnameArr.length - 1];
};

const formatForGraphQL = (datasets: string[]) => {
  const jsonString = JSON.stringify(datasets);
  return jsonString.replace('[', '{').replace(']', '}');
};

const transformValues: TransformData = (values) => {
  const result = {
    ...values,
    updated_at: new Date().toISOString(),
  };
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }
  result.datasets = values.datasets.length ? formatForGraphQL(values.datasets) : '{}';
  return result;
};

export const OrgConfigCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const maybeOrgId = selectLastPathname();
  if (Number.isNaN(parseInt(maybeOrgId, 10)) || maybeOrgId === 'create') {
    notify('Invalid organization id. A valid organization id is required to create an organization configuration.', {
      type: 'error',
    });
    redirect('/organizations');
  }

  const onSuccess = (data: { org_id: string | number }) => {
    notify('Organization Configuration created', { type: 'success' });
    redirect(orgConfigListRoute(data.org_id));
  };

  const { data = {}, isLoading, error } = useGetOne('organizations', { id: maybeOrgId });
  const choices = isLoading ? [] : [data];
  // Don't allow organization configurations to be created for organizations that don't exist
  if (error || (!isLoading && !data)) {
    notify('Unable to find organization. A valid organization is required to create an organization configuration.', {
      type: 'error',
    });
    redirect('/organizations');
  }

  const {
    data: orgConfigData,
    isLoading: orgConfigIsLoading,
    error: orgConfigError,
  } = useGetManyReference('organization_config', { id: maybeOrgId, target: 'org_id' });

  // Don't allow organization configurations to be created for organizations that already have one
  if (orgConfigError || (!orgConfigIsLoading && orgConfigData?.length)) {
    notify(
      'An organization configuration already exists.  You cannot create multiple configurations for an organizations',
      {
        type: 'error',
      },
    );
    redirect(orgConfigListRoute(maybeOrgId));
  }

  return (
    <CraftCreate mutationOptions={{ onSuccess }} transform={transformValues}>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />}>
        <h2>Add an organization configuration</h2>
        <SelectInput
          source="org_id"
          label="Organization"
          choices={choices}
          fullWidth
          optionText="name"
          optionValue="id"
          isLoading={isLoading}
          defaultValue={maybeOrgId}
          disabled
        />
        <TextInput source="api_client_id" label="API Client ID" />
        <BooleanInput source="all_alerts_access" label="Show All Craft Alerts" defaultValue />
        <BooleanInput source="enable_alerts_processing" label="Enable Alerts Processing" />
        <BooleanInput source="send_email_alerts" label="Send Email Alerts" />
        <SelectInput
          source="email_alerts_grouping"
          label="Email Grouping"
          choices={ORGANIZATION_CONFIG_EMAIL_ALERTS_GROUPINGS}
          defaultValue="ALL"
          validate={required()}
        />
        <SelectInput
          source="email_alerts_schedule"
          label="Email Timezone"
          choices={ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE}
          defaultValue="EU"
          validate={required()}
        />
        <CheckboxGroupInput
          defaultValue={[
            'pnl',
            'news',
            'locations',
            'jobs',
            'financial_scores_and_ratios',
            'employees',
            'balance_sheet',
          ]}
          row={false}
          label="Alerts"
          source="datasets"
          choices={ORGANIZATION_ALERTS_CONFIG_DATASETS}
          onClick={handleCheckboxGroupInputClick}
          sx={checkboxGroupInputDefaultCursorSx}
        />
        <CheckboxGroupInput
          row={false}
          label="Risks"
          source="datasets"
          choices={ORGANIZATION_RISK_CONFIG_DATASETS}
          onClick={handleCheckboxGroupInputClick}
          sx={checkboxGroupInputDefaultCursorSx}
        />
      </SimpleForm>
    </CraftCreate>
  );
};

export default OrgConfigCreate;
