import gql from 'graphql-tag';
import { CheckboxGroupInput, FormTab, FormTabProps, required, useNotify } from 'react-admin';
import { Box, Grid, GridProps } from '@mui/material';
import { FieldValues, UseFormSetValue, useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import client from 'src/data/api';
import { CraftPageSection } from 'src/components/CraftPageSection';
import BooleanInput, { BooleanInputProps } from 'src/inputs/BooleanInput';
import NestedInputWrapper from 'src/inputs/NestedInputWrapper';
import {
  API_CLIENT_AVAILABILITY_READONLY_FIELD_PATHS,
  API_CLIENT_DEFAULT_FIELD_PATHS,
} from 'src/utils/defaults/Constants';

const fieldPoliciesByField: Partial<Record<string, { filters: { id: string; name: string }[] }>> = {
  locations: {
    filters: [{ id: 'hqOnly', name: 'HQ Only' }],
  },
};

function getFieldInputProps(
  fieldPath: string,
  fieldValueMap: Record<string, boolean>,
  setValue: UseFormSetValue<FieldValues>,
) {
  const result: Partial<BooleanInputProps> = {};

  if (API_CLIENT_DEFAULT_FIELD_PATHS.has(fieldPath) || API_CLIENT_AVAILABILITY_READONLY_FIELD_PATHS.has(fieldPath)) {
    result.readOnly = true;
  }

  if (API_CLIENT_DEFAULT_FIELD_PATHS.has(fieldPath)) {
    result.defaultValue = true;
  }

  switch (fieldPath) {
    case 'securityRatings.factors':
      result.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget;

        if (fieldValueMap.securityRatingsFactorsAvailable !== checked) {
          setValue('fields.securityRatingsFactorsAvailable', checked);
        }
      };
      break;
    default:
  }

  return result;
}

const FieldPolicyInputGroupWrapper = ({ children }: Pick<GridProps, 'children'>) => (
  <Grid container item flex={1} pl={2} flexDirection="column" justifyContent="flex-start" borderLeft="1px solid #aaa">
    {children}
  </Grid>
);

interface FieldParams {
  name: string;
  isDeprecated?: boolean;
  children?: FieldParams[];
}

const CompanyFieldsInputs = ({
  fields,
  sourcesByField,
}: {
  fields?: FieldParams[] | undefined;
  sourcesByField: Partial<Record<string, string[]>>;
}) => {
  const { watch, setValue } = useFormContext();
  const fieldValueMap: Record<string, boolean> = watch('fields', {});

  if (!fields) return null;

  const genFieldSwitch = (field: FieldParams, parentValuePath?: string) => {
    const isRootField = !parentValuePath;
    // we use "/" instead of "." to avoid conversion of form values to obj.
    const valuePath = `${isRootField ? 'fields.' : `${parentValuePath}/`}${field.name}`;

    const fieldSlashPath = valuePath.split('.')[1];
    const fieldPath = fieldSlashPath.replaceAll('/', '.');
    const fieldIsChecked = !!fieldValueMap[fieldSlashPath];

    const genChildrenFields = () =>
      !!field.children && (
        <NestedInputWrapper>{field.children.map((child) => genFieldSwitch(child, valuePath))}</NestedInputWrapper>
      );

    const genSourceCheckboxes = () => {
      const sources = sourcesByField[fieldPath]?.map((src) => ({ id: src, name: src.split(':')[1] }));
      if (!sources) return null;

      const defaultValue = [sources.find((src) => src.name === 'craft')?.id ?? sources[0].id];

      return (
        <FieldPolicyInputGroupWrapper>
          <CheckboxGroupInput
            label="Sources"
            source={`sources.${fieldSlashPath}`}
            choices={sources}
            defaultValue={defaultValue}
            validate={required()}
            fullWidth
            sx={{ m: 0 }}
            helperText={false}
          />
        </FieldPolicyInputGroupWrapper>
      );
    };

    const genPolicyFiltersInputs = () => {
      const policies = fieldPoliciesByField[fieldPath]?.filters;
      if (!policies) return null;

      return (
        <FieldPolicyInputGroupWrapper>
          <CheckboxGroupInput
            label="Filters"
            source={`rowLevelAccessPolicy.${fieldSlashPath}.filters`}
            choices={policies}
            fullWidth
            sx={{ m: 0 }}
            helperText={false}
          />
        </FieldPolicyInputGroupWrapper>
      );
    };

    const inputProps = getFieldInputProps(fieldPath, fieldValueMap, setValue);

    return (
      <Box key={valuePath}>
        <Grid container alignItems="stretch">
          <BooleanInput
            source={valuePath}
            label={field.isDeprecated ? `${field.name} (DEPRECATED)` : field.name}
            helperText={false}
            sx={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
            {...inputProps}
          />
          {fieldIsChecked && genSourceCheckboxes()}
          {fieldIsChecked && genPolicyFiltersInputs()}
        </Grid>
        {fieldIsChecked && genChildrenFields()}
      </Box>
    );
  };

  return <>{fields.map((field) => genFieldSwitch(field))}</>;
};

const FieldsTab = (props: Omit<FormTabProps, 'label'>) => {
  const notify = useNotify();

  const {
    data: fieldsData,
    loading: fieldsLoading,
    error: fieldsError,
  } = useQuery<{ externalApiClientFields: FieldParams[] }>(
    gql`
      query externalApiClientFields {
        externalApiClientFields
      }
    `,
    { client },
  );

  const {
    data: fieldSourcesData,
    loading: fieldSourcesLoading,
    error: fieldSourcesError,
  } = useQuery<{
    external_api_policies: { field_type: string }[];
  }>(
    gql`
      query external_api_policies {
        external_api_policies(distinct_on: [field_type], where: { field_type: { _ilike: "%:%" } }) {
          field_type
        }
      }
    `,
    { client },
  );

  if (fieldsError || fieldSourcesError) {
    notify('Error fetching data. Please refresh the page to try again.', { type: 'error' });
  }

  const sourcesByField: Partial<Record<string, string[]>> = {};
  fieldSourcesData?.external_api_policies.reduce((acc, src) => {
    const fieldPath = src.field_type.split(':')[0];
    acc[fieldPath] ||= [];
    acc[fieldPath]?.push(src.field_type);
    return acc;
  }, sourcesByField);

  return (
    <FormTab label="Fields" {...props}>
      <CraftPageSection title="Company Fields" loading={fieldsLoading || fieldSourcesLoading}>
        <CompanyFieldsInputs fields={fieldsData?.externalApiClientFields} sourcesByField={sourcesByField} />
      </CraftPageSection>
    </FormTab>
  );
};

export default FieldsTab;
