import { Chip, ChipProps, Stack } from '@mui/material';
import { useRecordContext } from 'ra-core';
import { Labeled } from 'react-admin';

/**
 * Displays an inline list of chips for the
 * string[] field.
 *
 * Needed, as react-admin only easily allows to work with
 * arrays with non-primitive values.
 */
const StringArrayChipsField = ({ source, label, ...rest }: ChipProps & { source?: string; label?: string }) => {
  const record = useRecordContext();

  if (record && source && record[source]) {
    let content = (
      <Stack direction="row" gap={1} flexWrap="wrap">
        {(record[source] as string[]).map((text) => (
          <Chip key={text} label={text} {...rest} />
        ))}
      </Stack>
    );

    if (label) {
      content = <Labeled label={label}>{content}</Labeled>;
    }

    return content;
  }

  return null;
};

export default StringArrayChipsField;
