import { useFormContext } from 'react-hook-form';
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Box, Button } from '@mui/material';
import { ChangeEvent, useState, Dispatch, SetStateAction } from 'react';
import NoteType from '../../../models/NoteType';
import { getUserId } from '../../../utils/editNotesUtils';

const NotesEditToolbar = ({ setIsToolbarShown }: { setIsToolbarShown: Dispatch<SetStateAction<boolean>> }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const form = useFormContext();

  const clearForm = () => {
    form.reset({ message: '' });
    setIsToolbarShown(false);
  };

  const onSuccess = () => {
    clearForm();
    refresh();
    notify(`New Note added`, { type: 'success' });
  };

  return (
    <Toolbar sx={{ border: 0 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'right',
          justifyContent: 'space-between',
        }}
      >
        <SaveButton mutationOptions={{ onSuccess }} type="button" />
        <Button variant="outlined" onClick={clearForm}>
          Cancel
        </Button>
      </Box>
    </Toolbar>
  );
};

export const NotesEditForm = () => {
  const record = useRecordContext();
  const [isToolbarShown, setIsToolbarShown] = useState(false);

  const transform = (data: NoteType) => ({
    ...data,
    author_id: getUserId(),
    noteable_id: record.id,
    noteable_type: 'Company',
    created_at: new Date(),
  });

  const toggleToolbar = (event: ChangeEvent<HTMLInputElement>) => {
    setIsToolbarShown(event.target.value !== '');
  };

  return (
    <Create resource="notes" redirect={false} transform={transform}>
      <SimpleForm toolbar={isToolbarShown ? <NotesEditToolbar setIsToolbarShown={setIsToolbarShown} /> : false}>
        <TextInput
          source="message"
          label="Add a note"
          fullWidth
          onChange={toggleToolbar}
          multiline
          sx={{ maxHeight: '150px', overflowY: 'scroll' }}
        />
      </SimpleForm>
    </Create>
  );
};

export default NotesEditForm;
