import { Box, Card } from '@mui/material';
import { DateField, Labeled } from 'react-admin';

import { CraftTwoColumns } from 'src/components/CraftTwoColumns';

export const SignalsEditAside = () => (
  <Box ml={2} minWidth="25rem" sx={{ marginTop: { xs: '2rem', sm: '2rem', md: 0 } }}>
    <Card sx={{ p: 2 }}>
      <CraftTwoColumns
        left={
          <Box>
            <Labeled label="Created">
              <DateField source="signal_created_at" showTime emptyText="-" />
            </Labeled>
          </Box>
        }
        right="&nbsp;"
      />
      <CraftTwoColumns
        left={
          <Box>
            <Labeled label="Validated">
              <DateField source="validated_at" showTime emptyText="-" />
            </Labeled>
          </Box>
        }
        right={
          <Box>
            <Labeled label="Published">
              <DateField source="publicated_at" showTime emptyText="-" />
            </Labeled>
          </Box>
        }
      />
    </Card>
  </Box>
);
