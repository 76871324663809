import { Box } from '@mui/material';
import {
  EmailField,
  FormTab,
  Labeled,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useUpdate,
  useRefresh,
  useNotify,
  useResourceContext,
} from 'react-admin';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import { UserRoleInput } from 'src/inputs/UserRoleInput';
import { generateRandomString } from '@aws-amplify/core';
import { UserRolesEnum } from 'src/utils/UserRolesEnum';

const EditAdminUsersTabbedForm = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [update] = useUpdate();

  const handleDeleteUser = () => {
    if (record?.id) {
      update(
        resource,
        { id: record?.id, data: { encrypted_password: generateRandomString(), admin_role_id: UserRolesEnum.DEFAULT } },
        {
          onSuccess: () => {
            refresh();
          },
          onError: () => notify('An error occurred while trying to delete the user', { type: 'error' }),
        },
      );
    }
  };

  const dialogContent = `You are about to delete the user "${record?.email}". Are you sure?`;
  const dialogTitle = `Delete User #${record?.id}`;

  return (
    <CraftTabbedForm
      formType="edit"
      deleteOptions={{
        deletePermission: 'user:delete',
        onConfirmDelete: handleDeleteUser,
        dialogContent,
        dialogTitle,
      }}
    >
      <FormTab label="Details">
        <CraftPageSection title="User details">
          <CraftTwoColumns
            left={
              <Box display="flex" flexDirection="column" gap={2.5}>
                <Labeled label="ID">
                  <TextField source="id" />
                </Labeled>
                <Labeled label="Email">
                  <EmailField source="email" />
                </Labeled>
              </Box>
            }
            right={<TextInput fullWidth source="name" />}
          />
        </CraftPageSection>
        <CraftPageSection sx={{ mt: 3 }} title="Roles and Permissions">
          <UserRoleInput
            isRequired // excludes "empty" option
            validate={required()}
          />
        </CraftPageSection>
      </FormTab>
    </CraftTabbedForm>
  );
};

export default EditAdminUsersTabbedForm;
