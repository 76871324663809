import { AvatarProps, Stack } from '@mui/material';
import { ReferenceField, ReferenceFieldProps } from 'react-admin';
import CompanyLogoField from 'src/fields/CompanyLogoField';
import NameIdReferenceField from 'src/fields/NameIdReferenceField';

export const CompanyReferenceField = ({
  logoProps = {},
  ...rest
}: Omit<ReferenceFieldProps, 'reference'> & { logoProps?: AvatarProps }) => (
  <ReferenceField label="Company" reference="companies" sortable={false} {...rest}>
    <Stack direction="row" alignItems="center">
      <CompanyLogoField sx={{ mr: 1, width: '2rem', height: '2rem' }} {...logoProps} />
      <NameIdReferenceField />
    </Stack>
  </ReferenceField>
);
