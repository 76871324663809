import { useCallback, useEffect } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  ChoicesContextProvider,
  Identifier,
  RaRecord,
  ResourceContextProvider,
  useDataProvider,
  UseGetListHookValue,
  useRecordContext,
  useReferenceArrayInputController,
  useReferenceManyFieldController,
  useRegisterMutationMiddleware,
} from 'react-admin';
import difference from 'lodash/difference';

export interface ReferenceManyToManyAutocompleteFieldParams {
  resource: string;
  source: string;
  reference: string;
  through: string;
  throughTarget: string;
  referenceTarget: string;
  perPage?: number;
  optionText: string;
  label: string;
  maxSelectedOptions?: number;
}

export interface ReferenceManyToManyAutocompleteFieldInnerParams {
  reference: string;
  referenceTarget: string;
  throughTarget: string;
  refetch: (() => void) | UseGetListHookValue<RaRecord>['refetch'];
  through: string;
  id: Identifier;
  data: RaRecord[];
  label: string;
  optionText: string;
  perPage?: number;
  maxSelectedOptions?: number;
}

const DEFAULT_PER_PAGE = 10;

export const ReferenceManyToManyAutocompleteFieldInner = (props: ReferenceManyToManyAutocompleteFieldInnerParams) => {
  const {
    reference,
    through,
    data,
    id,
    label,
    optionText,
    throughTarget,
    referenceTarget,
    perPage = DEFAULT_PER_PAGE,
    refetch,
    maxSelectedOptions,
  } = props;
  const referenceResourceName = `${through}_ids`;
  const ids = data.map((record) => record[referenceTarget]);
  const newRecord = {
    id,
    [referenceResourceName]: ids,
  };
  const controllerProps = useReferenceArrayInputController({
    record: newRecord,
    reference,
    resource: referenceResourceName,
    source: referenceResourceName,
    perPage,
  });
  const dataProvider = useDataProvider();

  const middleware = useCallback(
    // react admin doesn't contain proper type definition, that is why any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (resource: any, params: any, options: any, next: (arg0: any, arg1: any, arg2: any) => void) => {
      const newIds = controllerProps.selectedChoices.map((i) => i.id);
      const added = difference(newIds, ids);
      if (added.length) {
        const objectToAdd = added.map((addId) => ({
          [referenceTarget]: addId,
          [throughTarget]: id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        }));
        await Promise.all(objectToAdd.map((object) => dataProvider.create(through, { data: object })));
      }
      const removed = difference(ids, newIds);
      if (removed.length) {
        const idsToRemove = data
          .filter((record) => removed.includes(record[referenceTarget]))
          .map((record) => record.id);
        await dataProvider.deleteMany(through, { ids: idsToRemove });
      }
      if (added.length || removed.length) {
        refetch();
      }
      next(resource, params, options);
    },
    [controllerProps, ids, data],
  );

  useRegisterMutationMiddleware(middleware);

  const extraProps: AutocompleteArrayInputProps = {};
  if (maxSelectedOptions !== undefined) {
    // best available method of limiting max selected choices
    extraProps.getOptionDisabled = () => controllerProps.selectedChoices.length >= maxSelectedOptions;
  }

  return (
    <ResourceContextProvider value={referenceResourceName}>
      <ChoicesContextProvider value={controllerProps}>
        <AutocompleteArrayInput
          label={label}
          optionText={optionText}
          defaultValue={ids}
          defaultChecked
          filterToQuery={(q: string) => ({ [optionText]: q })}
          {...extraProps}
        />
      </ChoicesContextProvider>
    </ResourceContextProvider>
  );
};

export const ReferenceManyToManyAutocompleteField = (props: ReferenceManyToManyAutocompleteFieldParams) => {
  const { source, through, throughTarget, resource, perPage = DEFAULT_PER_PAGE } = props;
  const record = useRecordContext();
  if (!record) return null;

  const { data, refetch } = useReferenceManyFieldController({
    page: 1,
    perPage,
    record,
    source,
    reference: through,
    resource,
    target: throughTarget,
  });
  useEffect(() => {
    refetch();
  }, [record]);
  return data ? (
    <ReferenceManyToManyAutocompleteFieldInner {...props} id={record.id} data={data} refetch={refetch} />
  ) : null;
};
