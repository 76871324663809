import { client } from '../data/api';
import gql from 'graphql-tag';

export const checkSlugExists = async (slug: string) => {
  const response = await client.query<{ companies: { slug: string; id: number }[] }>({
    query: gql`
      query CheckSlugUniqueness($slug: String!) {
        companies(limit: 1, where: { slug: { _eq: $slug } }) {
          id
          slug
        }
      }
    `,
    variables: {
      slug,
    },
  });

  return response;
};

export const updateSlug = async (id: number, slug: string) => {
  const slugUpdateResponse = await client.mutate<{ companies: { slug: string }[] }>({
    mutation: gql`
      mutation UpdateCompanySlug($slug: String!, $id: Int!) {
        update_companies(where: { id: { _eq: $id } }, _set: { slug: $slug }) {
          affected_rows
        }
      }
    `,
    variables: {
      id,
      slug,
    },
  });

  return slugUpdateResponse;
};

export const GenerateSlug = async (name: string) => {
  let translatedTitle = name
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\x00-\x7F]/g, '?')
    .replace(/[^\w?#@$_\-+]/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-|-+$/g, '');

  if (/^[0-9]*$/g.test(translatedTitle)) {
    translatedTitle = `company-${translatedTitle}`;
  } else if (!/^[a-zA-Z0-9#@$_\-+ ]*$/g.test(translatedTitle)) {
    translatedTitle = 'company';
  }

  const existingSlug = await checkSlugExists(translatedTitle);
  if (existingSlug.data.companies.length !== 0) {
    const random = Math.random().toString(36).substring(3);
    translatedTitle = `${translatedTitle}-${random}`;
  }
  return translatedTitle;
};
