import trim from 'lodash/trim';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

export const getOrgId = (): string => {
  const url = window.location.pathname;
  const parts = url.split('/');
  const orgConfigIndex = parts.indexOf('organization_config');
  return parts[orgConfigIndex + 1];
};

export const normalizeTopic = (topic: string): string => trim(topic);

export const formatTopics = (formData: FieldValues, topicsKey = 'topics') => {
  if (!formData[topicsKey]) return [];
  const topicsArr = formData[topicsKey].split('\n');
  return topicsArr.filter((topic: string) => !!topic).map(normalizeTopic);
};

export const validateTopics = (topics: string) => {
  const topicRegEx = /^((company:\d+(\s+)?(\n)?)|\n)+$/;
  if (!topicRegEx.test(topics)) {
    return "Input must be 'company:' followed by a number, one per line.";
  }
  return undefined;
};

export const generateErrorMessage = ({
  error,
  prependMessage = '',
}: {
  error: unknown;
  prependMessage?: string;
}): string => {
  let errorMessage = prependMessage;
  if (error instanceof Error) {
    errorMessage += `${error.message}`;
  } else {
    errorMessage += `${JSON.stringify(error)}`;
  }

  return errorMessage;
};

export const buttonStyles = makeStyles({
  deleteButton: {
    height: '2rem',
    backgroundColor: '#ca483f',
    '&:hover': {
      backgroundColor: '#bb3d35',
    },
    color: '#ffffff',
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.26)',
    },
  },
  saveButton: {
    height: '2rem',
    backgroundColor: '#3c92dc',
    '&:hover': {
      backgroundColor: '#67aae2',
    },
    color: '#ffffff',
  },
  confirmDeleteButton: {
    '& button.RaConfirm-confirmPrimary': {
      backgroundColor: '#ca483f',
      color: '#FFFFFF',
    },
    '& button.RaConfirm-confirmPrimary:hover': {
      backgroundColor: '#bb3d35',
      color: '#FFFFFF',
    },
  },
});
