import { ReactElement } from 'react';
import { StackProps, Stack } from '@mui/material';

import InfoTooltip, { InfoTooltipProps } from './InfoTooltip';

interface WithInfoTooltipProps extends InfoTooltipProps {
  containerProps?: StackProps;
  children: ReactElement;
}

export const WithInfoTooltip = ({ children, sx, containerProps, ...rest }: WithInfoTooltipProps) => (
  <Stack direction="row" {...containerProps}>
    {children}
    <InfoTooltip sx={{ mt: '0.2em', ...sx }} {...rest} />
  </Stack>
);

export default WithInfoTooltip;
