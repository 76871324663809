import React from 'react';
import { FormTab, FormTabProps } from 'react-admin';

import UnauthorizedFormTabContent from 'src/components/UnauthorizedFormTabContent';
import CompanySubscriptionSetsSection from './sections/CompanySubscriptionSets';
import { usePermissions } from 'src/hooks';

const SubscriptionsTab = (props: Omit<FormTabProps, 'label'>) => {
  const { permissions } = usePermissions();

  return (
    <FormTab label="Subscriptions" {...props}>
      {!permissions.has('companySubscriptionSet:read') ? (
        <UnauthorizedFormTabContent />
      ) : (
        <CompanySubscriptionSetsSection />
      )}
    </FormTab>
  );
};

export default SubscriptionsTab;
