import decodeJwt, { JwtPayload } from 'jwt-decode';

import { restoreToken } from './auth';

export const getUserId = () => {
  const token = restoreToken();
  if (token) {
    const decoded = decodeJwt<JwtPayload>(token);
    if (decoded?.sub && decoded.sub.length > 0) {
      return decoded.sub.split(':').pop();
    }
    return null;
  }
  return null;
};
