import gql from 'graphql-tag';

import CompanySubscriptionSetsCreate from './Create';
import CompanySubscriptionSetsEdit from './Edit';
import { ResourceDefinition, ResourceSections } from 'src/types';

export const CompanySubscriptionSetsResource: ResourceDefinition = {
  name: 'company_subscription_sets',
  section: ResourceSections.CLIENT_API,

  create: CompanySubscriptionSetsCreate,
  edit: CompanySubscriptionSetsEdit,

  permissions: {
    create: ['companySubscriptionSet:edit'],
    edit: ['companySubscriptionSet:edit'],
  },

  extension: {
    extraFields: gql`
      {
        data_packages_xrefs: data_packages {
          id
          data_package_id
        }
      }
    `,
  },
};
