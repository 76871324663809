import { ReferenceManyField, SimpleList } from 'react-admin';
import { Box } from '@mui/material';
import NoteCard from './NoteCard';
import NotesEditForm from './NotesEditForm';

export const NotesSection = () => (
  <Box>
    <Box sx={{ marginBottom: '20px' }}>
      <NotesEditForm />
    </Box>

    <Box>
      <ReferenceManyField
        label="Notes"
        reference="notes"
        target="noteable_id"
        filter={{ noteable_type: 'Company' }}
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <SimpleList primaryText={<NoteCard />} linkType={false} />
      </ReferenceManyField>
    </Box>
  </Box>
);

export default NotesSection;
