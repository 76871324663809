import { SelectField } from 'react-admin';
import { Center } from 'src/components/Center';
import { AUDIT_ENTRY_TYPE_ICON_OPTIONS, AUDIT_ENTRY_TYPE_OPTIONS } from './AuditEntryEnums';

export const AuditEntryTypeField = () => (
  <Center justifyContent="start">
    <SelectField
      label="Type Icon"
      choices={AUDIT_ENTRY_TYPE_ICON_OPTIONS}
      source="type"
      color="lightgray"
      mr={1}
      variant="subtitle2"
    />
    <SelectField label="Type" choices={AUDIT_ENTRY_TYPE_OPTIONS} source="type" variant="subtitle2" />
  </Center>
);
