import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';

export const PublishedAlertsClientsResource: ResourceDefinition = {
  name: 'published_alerts_clients',
  section: ResourceSections.ALERTS,
  permissions: {
    list: ['alerts:read'],
  },
  extension: {
    idKey: ['client'],
    operations: {
      [GET_LIST]: {
        operationName: 'signals_find_published_alerts_clients',
      },
      [GET_ONE]: {
        operationName: 'signals_find_published_alerts_clients',
      },
      [GET_MANY]: {
        operationName: 'signals_find_published_alerts_clients',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_find_published_alerts_clients',
      },
    },
  },
};
