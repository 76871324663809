import { Grid, GridProps } from '@mui/material';
import { ReactNode } from 'react';

/**
 * Helper container component that renders its children as two
 * columns on md screens or higher.
 */
export const CraftTwoColumns = ({
  left,
  right,
  leftSX = {},
  rightSX = {},
  ...rest
}: Omit<GridProps, 'left' | 'right'> & {
  left: ReactNode;
  right: ReactNode;
  leftSX?: GridProps['sx'];
  rightSX?: GridProps['sx'];
}) => (
  <Grid container spacing={4} {...rest}>
    <Grid item container flexDirection="column" justifyContent="flex-start" sx={leftSX} md={6}>
      {left}
    </Grid>
    <Grid item container flexDirection="column" justifyContent="flex-start" sx={rightSX} md={6}>
      {right}
    </Grid>
  </Grid>
);

export default CraftTwoColumns;
