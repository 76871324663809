import { Stack, Typography } from '@mui/material';
import { FormTab, TextInput, SelectInput, RedirectionSideEffect } from 'react-admin';

import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { OrganizationReferenceField } from 'src/resources/portal/organizations/OrganizationReferenceField';
import { ORGANIZATION_SUBSCRIPTION_STATUSES } from 'src/utils/defaults/Constants';
import { orgSubscriptionsListRoute } from 'src/utils/routeHelpers';

const redirect: RedirectionSideEffect = (_basePath, _id, data) =>
  orgSubscriptionsListRoute(data?.org_id).replace(/^\//, '');

export const OrgSubEdit = () => (
  <CraftEdit
    redirect={redirect}
    mutationMode="pessimistic"
    header={
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h5">
            <OrganizationReferenceField source="org_id" />
          </Typography>
        </Stack>
      </Stack>
    }
  >
    <CraftTabbedForm
      formType="edit"
      deleteOptions={{
        deletePermission: 'alerts:delete',
        hideDeleteButton: true,
      }}
    >
      <FormTab label="Organization Subscription">
        <CraftPageSection title="Organization Subscription">
          <TextInput source="id" disabled />
          <SelectInput source="status" choices={ORGANIZATION_SUBSCRIPTION_STATUSES} isRequired />
          <TextInput source="topic" />
          <TextInput source="created_at" disabled />
        </CraftPageSection>
      </FormTab>
    </CraftTabbedForm>
  </CraftEdit>
);

export default OrgSubEdit;
