import { Typography } from '@mui/material';
import { MouseEvent } from 'react';
import {
  DateField,
  FunctionField,
  Labeled,
  ReferenceManyField,
  SingleFieldList,
  SortPayload,
  TextField,
  TextInput,
} from 'react-admin';
import CraftList, { useCraftList } from 'src/components/CraftList';
import { WrapperStackField } from 'src/fields/WrapperStackField';
import { TopicDisplayModel } from './TopicMetadataDisplayModel';
import { TopicMetadataField } from './TopicMetadataField';
import { TopicMetadata, TopicRelations } from './TopicMetadataTypes';
import PublishedAlertsListButton from '../publishedAlerts/PublishedAlertsListButton';

export const TopicsMetadataFilters = [
  <TextInput key="topicFilter" label="Topic Search" source="topic@_ilike" alwaysOn />,
  <TextInput key="nameFilter" label="Name Search" source="lookup_string@_ilike" alwaysOn />,
];

const defaultSort: SortPayload = {
  field: 'topic',
  order: 'DESC',
};

const columnStyles = {
  relations: {
    minWidth: '30vw',
    p: 2,
  },
};

const chipProps = {
  size: 'small',
} as const;

export const TopicsMetadataList = () => {
  const list = useCraftList();
  const handleTopicClick = (_: MouseEvent, model: TopicDisplayModel) => {
    list.current?.setFilters(
      {
        'topic@_ilike': model.topic,
      },
      [],
    );
  };

  return (
    <CraftList
      ref={list}
      title="Topics metadata"
      filters={TopicsMetadataFilters}
      sort={defaultSort}
      columnStyles={columnStyles}
      dataGridProps={{ size: 'small' }}
    >
      <WrapperStackField source="topic" label="Topic" sortBy="topic" stackProps={{ direction: 'column' }}>
        <Labeled label="Tag">
          <FunctionField
            render={(r: TopicMetadata) => (
              <TopicMetadataField
                record={{ topic: r.topic, metadata: r }}
                chipProps={chipProps}
                onClick={handleTopicClick}
              />
            )}
          />
        </Labeled>
        <Labeled label="ID">
          <Typography>
            <TextField source="topic" variant="code" />
          </Typography>
        </Labeled>
      </WrapperStackField>
      <WrapperStackField label="Names" stackProps={{ direction: 'column' }}>
        <Labeled label="Default">
          <TextField source="metadata.name" emptyText="-" />
        </Labeled>
        <Labeled label="Filter">
          <TextField source="metadata.filterName" emptyText="-" />
        </Labeled>
        <Labeled label="Subscription">
          <TextField source="metadata.subscriptionName" emptyText="-" />
        </Labeled>
      </WrapperStackField>
      <WrapperStackField
        label="Relations"
        stackProps={{ direction: 'column', gap: 1 }}
        headerClassName="column-relations"
        cellClassName="column-relations"
      >
        <Labeled label="Parents" sx={{ mb: 2, '.RaLabeled-label': { mb: 1 } }}>
          <ReferenceManyField
            label="Parent topics"
            reference="topics_relations"
            source="topic"
            target="child"
            sort={{ field: 'parent', order: 'ASC' }}
          >
            <SingleFieldList linkType={false} sx={{ gap: 1 }}>
              <FunctionField
                render={(r: TopicRelations) => (
                  <TopicMetadataField
                    record={{ topic: r.parent, metadata: r.parent_metadata }}
                    chipProps={chipProps}
                    onClick={handleTopicClick}
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceManyField>
        </Labeled>
        <Labeled label="Children" sx={{ mb: 2, '.RaLabeled-label': { mb: 1 } }}>
          <ReferenceManyField
            label="Children topics"
            reference="topics_relations"
            source="topic"
            target="parent"
            sort={{ field: 'child', order: 'ASC' }}
          >
            <SingleFieldList linkType={false} sx={{ gap: 1 }}>
              <FunctionField
                render={(r: TopicRelations) => (
                  <TopicMetadataField
                    record={{ topic: r.child, metadata: r.child_metadata }}
                    chipProps={chipProps}
                    onClick={handleTopicClick}
                  />
                )}
              />
            </SingleFieldList>
          </ReferenceManyField>
        </Labeled>
      </WrapperStackField>
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <WrapperStackField label="Actions">
        <FunctionField
          render={(r: TopicMetadata) => (
            <PublishedAlertsListButton resource="signals" filter={{ signal_topic: r.topic }} />
          )}
        />
      </WrapperStackField>
    </CraftList>
  );
};
