import gql from 'graphql-tag';
import { UPDATE_MANY } from 'react-admin';

import PeopleIcon from '@mui/icons-material/People';

import { ResourceDefinition, ResourceSections } from 'src/types';
import UsersEdit from './Edit';
import UsersList from './List';

export const AdminUsersResource: ResourceDefinition = {
  name: 'admin_users',
  section: ResourceSections.COMPANIES,
  icon: PeopleIcon,
  options: { label: 'Craft Admin Users' },

  list: UsersList,
  edit: UsersEdit,
  permissions: {
    list: ['user:read'],
    edit: ['user:edit'],
  },

  extension: {
    operations: {
      [UPDATE_MANY]: {
        operationName: 'update_admin_users',
      },
    },
    extraFields: gql`
      {
        admin_role {
          code
        }
      }
    `,
  },
};
