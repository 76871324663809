import { RaThemeOptions } from 'react-admin';
import { blueGrey } from '@mui/material/colors';

export const theme: RaThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0057BA',
      light: '#C1DEFF',
      dark: '#00144B',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: blueGrey['50'],
    },
    border: {
      main: blueGrey['100'],
    },
    text: {
      primary: '#555866',
      secondary: '#8A8C95',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    fontWeightBold: 500,
    code: {
      fontFamily: 'monospace',
      fontSize: '0.875rem',
      border: `1px solid ${blueGrey['100']}`,
      padding: '0 0.25rem',
      display: 'inline-block',
      borderRadius: 4,
      background: blueGrey['50'],
    },
    body2: {
      lineHeight: 1.5,
      fontSize: '1rem',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        /**
         * Aligns small MUI/React Admin height
         */
        sizeSmall: {
          lineHeight: 1.5,
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'medium',
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          // prevent List header text from wrapping
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: 8,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          code: 'code',
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          '&.RaMenuItemLink-active': {
            background: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
  },
};

export default theme;
