import React from 'react';

import CraftEdit from 'src/components/CraftEdit';
import OrgsEditAside from './EditAside';
import DetailsTab from '../formTabs/Details';
import FeatureFlagsTab from '../formTabs/FeatureFlags';
import InternalSettingsTab from '../formTabs/InternalSettingsTab';
import SubscriptionTab from '../formTabs/Subscription';
import UsersListViewTab from '../formTabs/UsersListViewTab';
import EditOrganizationsTabbedForm from './EditOrganizationsTabbedForm';

export const PortalOrgsEdit = () => (
  <CraftEdit header="Edit Organization" aside={<OrgsEditAside />}>
    <EditOrganizationsTabbedForm>
      <DetailsTab mode="edit" />
      <FeatureFlagsTab />
      <InternalSettingsTab />
      <SubscriptionTab mode="edit" />
      <UsersListViewTab />
    </EditOrganizationsTabbedForm>
  </CraftEdit>
);

export default PortalOrgsEdit;
