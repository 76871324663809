import {
  DateField,
  TextField,
  TextInput,
  ArrayField,
  WrapperField,
  EditButton,
  TopToolbar,
  CreateButton,
  FunctionField,
  SelectField,
  SingleFieldList,
} from 'react-admin';

import ClearFiltersButton from 'src/components/ClearFiltersButton';
import CraftList from 'src/components/CraftList';
import AuditBasedStatusFilterInput from 'src/inputs/AuditBasedStatusFilterInput';
import IntegerInput from 'src/inputs/IntegerInput';
import BulkSoftDeleteButton from 'src/components/BulkSoftDeleteButton';
import { usePermissions } from 'src/hooks';
import { ExternalApiClientRecord } from 'src/types';
import { CraftDateInput } from 'src/components/CraftDateInput';
import { EXTERNAL_API_CLIENT_TYPES } from 'src/utils/defaults/Constants';

const filters = [
  <IntegerInput key="idFilter" label="ID" source="id" alwaysOn />,
  <TextInput key="nameFilter" source="name" alwaysOn />,
  <TextInput key="tokenFilter" source="token" alwaysOn />,
  <CraftDateInput key="createdAtFrom" label="Newer than" source="created_at@_gte" disallowFuture alwaysOn />,
  <CraftDateInput key="createdAtTo" label="Older than" source="created_at@_lte" alwaysOn />,
  <AuditBasedStatusFilterInput
    key="statusFilter"
    label="Status"
    source="archived_at@_is_null"
    deletedLabel="Archived"
    alwaysOn
  />,
];

const ListActions = ({ canCreate }: { canCreate: boolean }) => (
  <TopToolbar>
    <ClearFiltersButton />
    {canCreate && <CreateButton />}
  </TopToolbar>
);

export const ExternalApiClientsList = () => {
  const { permissions } = usePermissions();

  const canEdit = permissions.has('api:edit');
  const canDelete = permissions.has('api:delete');

  const bulkActionButtons = canDelete && (
    <BulkSoftDeleteButton label="Delete Selected" data={{ archived_at: new Date().toISOString() }} />
  );

  return (
    <CraftList
      title="External API Clients"
      filters={filters}
      filterDefaultValues={{ 'archived_at@_is_null': true }}
      actions={<ListActions canCreate={canEdit} />}
      showClearButton={false}
      deletePermission="api:delete"
      dataGridProps={{
        bulkActionButtons,
      }}
    >
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <ArrayField source="portal_organizations" label="Portal Org. ID" sortable={false}>
        <SingleFieldList linkType={false}>
          <TextField source="id" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="portal_organizations" label="Portal Org. Name" sortable={false}>
        <SingleFieldList linkType={false}>
          <TextField source="name" />
        </SingleFieldList>
      </ArrayField>
      <FunctionField
        source="token"
        render={(rec: ExternalApiClientRecord) => (
          <TextField source="token" record={{ token: rec.token && `...${rec.token.slice(-7)}` }} />
        )}
      />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <SelectField source="client_type" choices={EXTERNAL_API_CLIENT_TYPES} />
      {canEdit && (
        <WrapperField label="Actions">
          <EditButton />
        </WrapperField>
      )}
    </CraftList>
  );
};

export default ExternalApiClientsList;
