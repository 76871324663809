import { Backdrop, CircularProgress } from '@mui/material';
import { FC } from 'react';

/**
 * When included on the page and provided with "open: true",
 * will render the overlay with a loading spinner over the page.
 */
export const LoadingOverlay: FC<{ open: boolean }> = ({ open }) => (
  <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default LoadingOverlay;
