import { QueryResponse } from 'ra-data-hasura/dist/buildQuery';
import uniq from 'lodash/uniq';

import { buildTopic, isLocationTopic, isWildcardTopic, parseTopic } from '../topicsMetadata/TopicMetadataDisplayModel';
import { TopicTypes } from '../topicsMetadata/TopicMetadataTypes';
import { notNil } from 'src/utils/notNil';

export type TieredTopicMap = {
  primary: string | null;
  secondary: string[];
};

export type DerivedTopicsMap = {
  [TopicTypes.COMPANY]: TieredTopicMap;
  [TopicTypes.RESOURCE]: TieredTopicMap;
  [TopicTypes.CLASS]: TieredTopicMap;
  [TopicTypes.DATASET]: TieredTopicMap;

  /**
   * There's no "primary" column storing a specific location of a signal
   */
  [TopicTypes.LOCATION]: Omit<TieredTopicMap, 'primary'>;

  rest: string[];
};

export type DerivedTopicsMapKeys = keyof DerivedTopicsMap;

/**
 * These topics correspond to a specific top-level field in the signal record.
 */
const PRIMARY_TOPIC_FIELDS = {
  [TopicTypes.CLASS]: 'signal_class',
  [TopicTypes.COMPANY]: 'company_id',
  [TopicTypes.DATASET]: 'dataset',
  [TopicTypes.RESOURCE]: 'resource_id',
};

export function mapFactory(): DerivedTopicsMap {
  return {
    [TopicTypes.DATASET]: {
      primary: null,
      secondary: [],
    },
    [TopicTypes.CLASS]: {
      primary: null,
      secondary: [],
    },
    [TopicTypes.COMPANY]: {
      primary: null,
      secondary: [],
    },
    [TopicTypes.RESOURCE]: {
      primary: null,
      secondary: [],
    },
    [TopicTypes.LOCATION]: {
      secondary: [],
    },

    rest: [],
  };
}

/**
 * Derives topics from raw signal record into a model
 * that is ready for presentation/edit purposes.
 */
export function fromSignal(signal: QueryResponse['data']) {
  const primaries = Object.fromEntries(
    Object.entries(PRIMARY_TOPIC_FIELDS).map(([type, field]) => [
      type,
      signal[field] ? buildTopic(type, signal[field]) : null,
    ]),
  );

  const predefinedSource = signal.payload?.signal_predefined_topics ?? [];
  const predefined = predefinedSource.filter((t: string) => !isWildcardTopic(t));
  const all: string[] = [...Object.values(primaries).filter(notNil), ...predefined];

  return all.reduce((acc, next) => addTopicToMap({ map: acc, topic: next, primaries }), mapFactory());
}

/**
 * Converts derived topics map into an object that can be merged
 * into a signal record.
 */
export function toSignal(map: DerivedTopicsMap) {
  return {
    dataset: map.dataset.primary ? parseTopic(map.dataset.primary).value : null,
    signal_class: map.class.primary ? parseTopic(map.class.primary).value : null,

    company_id: map.company.primary ? parseInt(parseTopic(map.company.primary).value, 10) : null,
    resource_id: map.resource.primary ? parseTopic(map.resource.primary).value : null,

    payload: {
      signal_predefined_topics: uniq(
        [
          ...map.class.secondary,
          map.class.secondary.length > 0 ? buildTopic(TopicTypes.CLASS, '*') : null,

          ...map.company.secondary,
          map.company.secondary.length > 0 ? buildTopic(TopicTypes.COMPANY, '*') : null,

          ...map.dataset.secondary,
          map.dataset.secondary.length > 0 ? buildTopic(TopicTypes.DATASET, '*') : null,

          ...map.resource.secondary,
          map.resource.secondary.length > 0 ? buildTopic(TopicTypes.RESOURCE, '*') : null,

          ...map.location.secondary,
          map.location.secondary.length > 0 ? buildTopic(TopicTypes.LOCATION, '*') : null,

          ...map.rest,
        ].filter(notNil),
      ),
    },
  };
}

/**
 * Adds a given topic to the given derived topics map.
 */
export function addTopicToMap({
  map,
  topic,
  primaries,
}: {
  map: DerivedTopicsMap;
  topic: string;
  primaries: Record<string, string | null>;
}) {
  const { resolvedType } = parseTopic(topic);

  if (resolvedType) {
    // 1. push to one of the predefined slots
    // 2. gather all location topics together
    // 3. everything else goes to "rest"
    if (resolvedType in map) {
      const tieredMap = map[resolvedType as DerivedTopicsMapKeys] as TieredTopicMap;

      if (primaries[resolvedType] === topic) {
        tieredMap.primary = topic;
      } else {
        tieredMap.secondary.push(topic);
      }
    } else if (isLocationTopic(topic)) {
      map[TopicTypes.LOCATION].secondary.push(topic);
    } else {
      map.rest.push(topic);
    }
  } else {
    map.rest.push(topic);
  }

  return map;
}
