import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useNotify, useRedirect } from 'react-admin';

import client from 'src/data/api';
import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import FieldsTab from './formTabs/Fields';
import FeaturesTab from './formTabs/Features';
import RisksTab from './formTabs/Risks';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { externalApiClientSchema } from './validation';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { resolveMutationParams } from './helpers';

export const ExternalApiClientsCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [createExternalApiClient, { loading: createExternalApiClientLoading }] = useMutation<{
    insert_external_api_clients_one: { id: number };
  }>(
    gql`
      mutation insert_external_api_clients_one($externalApiClientParams: external_api_clients_insert_input!) {
        insert_external_api_clients_one(object: $externalApiClientParams) {
          id
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = externalApiClientSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const { variables } = resolveMutationParams(values);
    const { data, errors } = await createExternalApiClient({ variables });

    if (errors) {
      notify(errors[0].message, { type: 'error' });
      return undefined;
    }

    const externalApiClientID = data?.insert_external_api_clients_one.id;
    if (!externalApiClientID) {
      notify('Unable to redirect to new API client: ID not returned', { type: 'error' });
      return redirect('list', 'external_api_clients');
    }

    notify('The API client has been successfully created', { type: 'success' });
    return redirect(`/external_api_clients/${externalApiClientID}`);
  };

  return (
    <CraftCreate header="Create External API Client">
      <CraftTabbedForm formType="create" onSubmit={handleSave} shouldUnregister>
        <DetailsTab mode="create" />
        <FieldsTab />
        <FeaturesTab />
        <RisksTab />
      </CraftTabbedForm>

      <LoadingOverlay open={createExternalApiClientLoading} />
    </CraftCreate>
  );
};

export default ExternalApiClientsCreate;
