import { ReactElement, ReactNode } from 'react';
import { Box, Card, Grid } from '@mui/material';
import { CreateBase, CreateProps } from 'react-admin';
import { CraftPageHeader } from './CraftPageHeader';

/**
 * Default layout for the "Create" pages.
 * Simply displays a box with expected padding at the moment.
 */
export const CraftCreate = ({
  header,
  children,
  ...rest
}: CreateProps & { header?: ReactNode; children: ReactElement | ReactElement[] }) => (
  <CreateBase {...rest}>
    {header && <CraftPageHeader>{header}</CraftPageHeader>}
    {/* A bit dirty, but cheaper than conditional properties */}
    {!header && <Box m={2} />}
    <Box mx={2}>
      <Grid container mr={2}>
        <Grid item md={8}>
          <Card>{children}</Card>
        </Grid>
        <Grid item md={4} />
      </Grid>
    </Box>
  </CreateBase>
);

export default CraftCreate;
