import z from 'zod';

import { dateTime } from 'src/utils/validation';

export const externalApiClientSchema = z.object({
  name: z.string().trim().nonempty(),
  requests_limit: z.number().int().nullable(),
  start_date: dateTime.nullish(),
  end_date: dateTime.nullish(),
  client_type: z.string().trim().nonempty(),

  fields: z.record(z.string(), z.boolean().optional()),
  sources: z.record(z.string(), z.array(z.string()).optional()).optional(),
  features: z.record(z.string(), z.boolean().optional()),
  rowLevelAccessPolicy: z
    .object({
      locations: z
        .object({
          filters: z.array(z.enum(['hqOnly'])).optional(),
        })
        .optional(),
    })
    .optional(),
  risks: z.object({
    esg: z.object({
      enabled: z.boolean(),
      ecovadis: z.object({
        enabled: z.boolean(),
        value: z.string().trim().nullish(),
      }),
    }),
    cybersecurity: z.object({
      enabled: z.boolean(),
      lab1: z.object({
        enabled: z.boolean(),
        value: z.string().trim().nullish(),
      }),
    }),
  }),
});
