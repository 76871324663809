import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import {
  Datagrid,
  ListToolbar,
  Pagination,
  ListProps,
  DatagridProps,
  useListController,
  ListContextProvider,
  ResourceContextProvider,
  ResourceContextValue,
} from 'react-admin';

import { ClearFiltersButton } from 'src/components/ClearFiltersButton';
import { Center } from './Center';
import { usePermissions } from 'src/hooks';
import { CraftAdminPermission } from 'src/types';
import ListInfoSection from './ListInfoSection';

const ResourceContextWrapper = ({
  resource,
  children,
}: PropsWithChildren & { resource: ResourceContextValue | undefined; children: ReactElement }) =>
  resource ? <ResourceContextProvider value={resource}>{children}</ResourceContextProvider> : children;

export type CraftStandaloneListProps = {
  title?: string;
  children: JSX.Element | JSX.Element[];
  infoItems?: ReactElement;
  dataGridProps?: DatagridProps;
  columnStyles?: SxProps<Theme>;
  CustomPaginationComponent?: JSX.Element;
  /**
   * Permission checked when displaying the **default** bulk action buttons.
   */
  deletePermission?: CraftAdminPermission;
};

/**
 * Non-page version of a Craft List that can be embedded on custom pages.
 */
export const CraftStandaloneList = ({
  title,
  children,
  filters,
  actions,
  infoItems,
  dataGridProps = {},
  columnStyles = {},
  CustomPaginationComponent,
  deletePermission,
  ...rest
}: Omit<ListProps, 'children'> & CraftStandaloneListProps) => {
  const { permissions } = usePermissions();

  const canDelete = !deletePermission || permissions.has(deletePermission);
  const showDefaultBulkActions = dataGridProps.bulkActionButtons === undefined;

  const listController = useListController({
    disableSyncWithLocation: true,
    ...rest,
  });

  const finalDatagridProps = useMemo<DatagridProps>(
    () => ({
      ...dataGridProps,
      ...(showDefaultBulkActions && !canDelete && { bulkActionButtons: false }),
      sx: {
        ...dataGridProps.sx,
        ...makeColumnStyles(columnStyles),
      },
    }),
    [dataGridProps, columnStyles],
  );
  const hasToolbar = (Array.isArray(filters) ? filters.length > 0 : !!filters) || actions;

  return (
    <ResourceContextWrapper resource={rest.resource}>
      <ListContextProvider value={listController}>
        <Box>
          {title && (
            <Typography variant="h6" fontWeight="bolder">
              {title}
            </Typography>
          )}
          {hasToolbar && <ListToolbar filters={filters ?? []} actions={actions ?? false} />}
          <ClearFiltersButton />
        </Box>

        {infoItems && <ListInfoSection>{infoItems}</ListInfoSection>}

        {/* TODO: scrollable box can be a component */}
        <Box sx={{ overflowX: 'auto' }}>
          {finalDatagridProps.bulkActionButtons !== false && (
            <Center mt={3} p={1}>
              <Typography color={(theme) => theme.palette.text.secondary}>Select rows for more actions</Typography>
            </Center>
          )}
          <Datagrid
            size="medium"
            isRowSelectable={(record) => !record.deleted_at && !record.archived_at}
            {...finalDatagridProps}
            rowSx={(record) => ({
              backgroundColor: record.deleted_at || record.archived_at ? '#fcb1b1bb' : undefined,
              '&.MuiTableRow-hover:hover': {
                backgroundColor: record.deleted_at || record.archived_at ? '#fcb1b1ff' : undefined,
              },
            })}
          >
            {children}
          </Datagrid>
        </Box>
        {CustomPaginationComponent ? <div>{CustomPaginationComponent}</div> : <Pagination />}
      </ListContextProvider>
    </ResourceContextWrapper>
  );
};

function makeColumnStyles(columns: SxProps<Theme>) {
  if (!columns) {
    return {};
  }

  return Object.fromEntries(Object.entries(columns).map(([key, styles]) => [`& .column-${key}`, styles]));
}

export default CraftStandaloneList;
