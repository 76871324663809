import {
  BooleanInput,
  FormTab,
  TextInput,
  regex,
  SelectInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  ReferenceManyField,
  SingleFieldList,
  FunctionField,
} from 'react-admin';
import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
} from 'ra-input-rich-text';
import { Center } from 'src/components/Center';

import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { CraftTwoColumns } from 'src/components/CraftTwoColumns';
import { ReferenceManyToManyAutocompleteField } from 'src/inputs/ReferenceManyToManyInput';
import useUnicityValidator from 'src/utils/useUnicityValidator';
import LogoInput from 'src/inputs/LogoInput';
import useEditSubmit from './useEditSubmit';
import { COREDB_COMPANY_STATUSES, SEARCH_ENGINE_COMPANY_TYPE_DEFAULTS } from 'src/utils/defaults/Constants';
import { FormDialog } from 'src/components/FormDialog';
import { validateUrl } from '../../utils/editValidationUtils';
import { formatSiteDomain } from 'src/utils/formatSiteDomain';
import LocationAutocompleteInput from 'src/inputs/LocationAutocompleteInput';

export const CompaniesEditForm = () => {
  const onSubmit = useEditSubmit();
  const validateDunsUnicity = useUnicityValidator('companies', 'duns');
  const validateSerinUnicity = useUnicityValidator('companies', 'siren_code');

  const validateDuns = [regex(/^\d{9}$/, 'Should be a number of 9 digits.'), validateDunsUnicity];
  return (
    <CraftTabbedForm
      formType="edit"
      deleteOptions={{
        deletePermission: 'company:delete',
      }}
      onSubmit={onSubmit}
    >
      <FormTab label="General">
        <CraftPageSection title="General Information">
          <CraftTwoColumns
            left={
              <>
                <TextInput fullWidth source="name" validate={required()} />
                <TextInput fullWidth source="legal_name" />
                <SelectInput fullWidth source="state" choices={COREDB_COMPANY_STATUSES} validate={required()} />
              </>
            }
            right={
              <>
                <TextInput fullWidth source="slug" disabled InputProps={{ endAdornment: <FormDialog /> }} />
                <TextInput fullWidth source="registered_location" disabled />
                <LocationAutocompleteInput fullWidth source="registered_location" />
                <SelectInput
                  fullWidth
                  source="company_type"
                  choices={SEARCH_ENGINE_COMPANY_TYPE_DEFAULTS}
                  validate={required()}
                />
              </>
            }
          />
          <CraftTwoColumns
            left={
              <>
                <TextInput fullWidth source="site" />
                <BooleanInput fullWidth source="product_or_division" disabled sx={{ my: 1 }} />
                <ReferenceInput source="stock_market_id" reference="stock_markets">
                  <AutocompleteInput
                    label="Stock market"
                    optionValue="id"
                    optionText="name"
                    filterToQuery={(q) => ({ name: q })}
                  />
                </ReferenceInput>
                <ReferenceInput source="parent_company_id" reference="companies">
                  <AutocompleteInput
                    label="Parent company"
                    optionValue="id"
                    optionText={(item) => {
                      if (!item.id) return null;
                      return `#${item.id} ${item.name} (${
                        COREDB_COMPANY_STATUSES.find((i) => i.id === item.state)?.name
                      })`;
                    }}
                    filterToQuery={(q) => {
                      if (!Number.isNaN(parseInt(q, 10))) {
                        return { id: q };
                      }
                      return { name: q };
                    }}
                  />
                </ReferenceInput>
              </>
            }
            right={
              <>
                <TextInput fullWidth source="site" label="Site domain" disabled format={formatSiteDomain} />
                <TextInput fullWidth source="duns" validate={validateDuns} />
                <TextInput fullWidth source="ticker" />
                <TextInput fullWidth source="investor" disabled />
              </>
            }
          />
        </CraftPageSection>
        <CraftPageSection title="Links">
          <CraftTwoColumns
            left={
              <>
                <TextInput fullWidth source="linkedin" validate={validateUrl('linkedin')} />
                <TextInput fullWidth source="crunchbase" validate={validateUrl('crunchbase')} />
                <TextInput fullWidth source="facebook" validate={validateUrl('facebook')} />
                <TextInput fullWidth source="youtube" validate={validateUrl('youtube')} />
                <TextInput fullWidth source="instagram" validate={validateUrl('instagram')} />

                <ReferenceManyField label="Twitter" reference="twitter_accounts" source="id" target="company_id">
                  <SingleFieldList linkType={false}>
                    <FunctionField
                      label="Twitter"
                      render={(row: { primary: boolean }) =>
                        row && row.primary ? (
                          <TextInput fullWidth label="Twitter Primary" source="twitter_url" disabled />
                        ) : null
                      }
                    />
                  </SingleFieldList>
                </ReferenceManyField>
              </>
            }
            right={
              <>
                <TextInput fullWidth source="newsroom_url" />
                <TextInput fullWidth source="careers_page" />
                <TextInput fullWidth source="yahoo_finance" />

                {/* Need to use Array Input */}
                <TextInput source="additional_sites" disabled />
              </>
            }
          />
        </CraftPageSection>
        <CraftPageSection title="Company Details">
          <LogoInput source="logo" />
          <TextInput fullWidth source="description" multiline />
          <RichTextInput
            toolbar={
              <RichTextInputToolbar>
                <LevelSelect />
                <FormatButtons />
                <ListButtons />
                <LinkButtons />
                <QuoteButtons />
                <ClearButtons />
              </RichTextInputToolbar>
            }
            source="full_description"
          />
          <ReferenceManyToManyAutocompleteField
            label="Companies Tags"
            source="id"
            resource="companies"
            reference="tags"
            referenceTarget="tag_id"
            through="companies_tags"
            throughTarget="company_id"
            optionText="name"
          />
          <ReferenceManyToManyAutocompleteField
            label="Client Tags"
            source="id"
            resource="companies"
            reference="client_tags"
            referenceTarget="client_tag_id"
            through="companies_client_tags"
            throughTarget="company_id"
            optionText="name"
          />
          <TextInput source="founded_year" />
        </CraftPageSection>
        <CraftPageSection title="Employees">
          <TextInput source="employees_count" disabled />
        </CraftPageSection>
        <CraftPageSection title="Company Codes">
          <CraftTwoColumns
            left={
              <>
                <TextInput fullWidth source="duns" validate={validateDuns} />
                <TextInput fullWidth source="sic" />
              </>
            }
            right={
              <>
                <TextInput
                  fullWidth
                  source="siren_code"
                  validate={[regex(/^\d{9}$/, 'Should be a number of 9 digits.'), validateSerinUnicity]}
                />
                <TextInput fullWidth source="naisc" />
              </>
            }
          />
        </CraftPageSection>
        <CraftPageSection title="Enabled Components">
          <BooleanInput fullWidth source="show_on_craftco" />
          <BooleanInput fullWidth source="news_enabled" />
          <BooleanInput fullWidth source="blogs_enabled" />
        </CraftPageSection>
      </FormTab>
      <FormTab label="Metrics">
        <Center width={1} minHeight="10rem">
          Coming soon
        </Center>
      </FormTab>
      <FormTab label="QA History">
        <Center width={1} minHeight="10rem">
          Coming soon
        </Center>
      </FormTab>
    </CraftTabbedForm>
  );
};

export default CompaniesEditForm;
