import { useState } from 'react';
import CraftEdit from 'src/components/CraftEdit';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { RaRecord, SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin';
import { companyCommodityListRoute } from 'src/utils/routeHelpers';
import { SharedInputs } from './SharedInputs';
import { transformValues } from './transformValues';
import { CommodityChoices } from './types';

export const CompanyCommoditiesEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [publishedCommodities, setPublishedCommodities] = useState<CommodityChoices>([]);

  const onSuccess = (data: RaRecord) => {
    notify('Company Commodity updated', { type: 'success' });
    redirect(companyCommodityListRoute(data.org_id));
  };

  return (
    <CraftEdit mutationOptions={{ onSuccess }} transform={transformValues} mutationMode="pessimistic">
      <SimpleForm toolbar={<CraftSimpleFormToolbar />}>
        <h2>Edit a company commodity</h2>
        <TextInput source="org_id" label="Organization" fullWidth disabled />
        <SharedInputs publishedCommodities={publishedCommodities} setPublishedCommodities={setPublishedCommodities} />
      </SimpleForm>
    </CraftEdit>
  );
};

export default CompanyCommoditiesEdit;
