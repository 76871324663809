import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { TopicsRelationsResource } from '../topicsRelations/resource';

const cache: Record<string, Promise<string>> = {};

export const useTopicMetadataChain = () => {
  const provider = useDataProvider();

  const resolveParent = useCallback(async (topicName: string) => {
    if (!cache[topicName]) {
      cache[topicName] = provider
        .getManyReference(TopicsRelationsResource.name, {
          target: 'child',
          id: topicName,
          pagination: {
            page: 1,
            perPage: 1,
          },
          sort: {
            field: 'parent',
            order: 'ASC',
          },
          filter: {},
        })
        // Could be missing, expected for "root" topics
        .then((res) => res?.data[0]?.parent);
    }

    return cache[topicName];
  }, []);

  const resolveChain = useCallback(async (topicName: string) => {
    const chain = [topicName];
    let parent = await resolveParent(topicName);

    while (parent) {
      chain.push(parent);
      parent = await resolveParent(parent);
    }

    return chain;
  }, []);

  return {
    resolveChain,
    resolveParent,
  };
};
