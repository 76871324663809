import { FC, useMemo } from 'react';
import { ReferenceField, useRecordContext } from 'react-admin';
import NameIdReferenceField from 'src/fields/NameIdReferenceField';

const UserReference = () => (
  <ReferenceField source="meta.userId" reference="users">
    <NameIdReferenceField />
  </ReferenceField>
);

const RENDERERS: Record<string, FC> = {
  'user:invite': UserReference,
  'user:create': UserReference,
  'user:delete': UserReference,
  'user:undelete': UserReference,
  'user:role:change': UserReference,
  'user:team:change': UserReference,
};

export const AuditEntryMetaField = () => {
  const record = useRecordContext();
  return useMemo(() => {
    const Renderer = RENDERERS[record.type];
    return Renderer ? <Renderer /> : null;
  }, [record]);
};
