import React, { useState, useEffect } from 'react';
import { Button, useNotify, SimpleForm, Confirm, useRecordContext } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import { DeleteSweep as DeleteSweepIcon, RemoveCircle as RemoveCircleIcon } from '@mui/icons-material';
import { client } from '../../../data/api';
import gql from 'graphql-tag';
import CsvImport from './CsvImport';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { getOrgId, generateErrorMessage, buttonStyles } from 'src/utils/organizationSubscriptions';
import BulkToolbar from 'src/resources/signals/organizationSubscriptions/BulkToolbar';
import trim from 'lodash/trim';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import CompanyCommoditiesTable, {
  getUpdateRecords,
  resetErrorMessages,
  validateCompanyCommodities,
} from './CompanyCommoditiesTable';
import { CompanyCommodityType } from './types';
import { BulkErrorNotification } from './BulkErrorNotification';
import { AssetLink } from 'src/resources/signals/organizationSubscriptions/AssetLink';
import { DialogInner } from 'src/resources/signals/organizationSubscriptions/DialogInner';

const CustomDeleteButton = ({
  handleSubmit,
  loading,
  setInvalidMessage,
  setShowError,
}: {
  handleSubmit: () => void;
  loading: boolean;
  setInvalidMessage: React.Dispatch<React.SetStateAction<string>>;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);
  const buttonClasses = buttonStyles();

  const handleClick = () => {
    resetErrorMessages();
    const invalidMessage = validateCompanyCommodities();
    if (invalidMessage) {
      setInvalidMessage(invalidMessage);
    } else {
      setInvalidMessage('');
      setShowError(false);
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    setInvalidMessage('');
    setShowError(false);
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    handleSubmit();
  };

  return (
    <>
      <Button
        label="Delete Bulk"
        onClick={handleClick}
        disabled={loading}
        size="medium"
        className={buttonClasses.deleteButton}
      >
        <RemoveCircleIcon />
      </Button>
      <Confirm
        isOpen={open}
        title="Delete Organization Subscriptions"
        content="Are you sure you want to delete these organization subscriptions?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirm="Delete"
        className={buttonClasses.confirmDeleteButton}
      />
    </>
  );
};

const BulkDeleteToolbar = ({
  handleCloseClick,
  handleSubmit,
  records,
  loading,
  setInvalidMessage,
  setShowError,
  toolbarInfo,
}: {
  handleCloseClick: () => void;
  handleSubmit: () => void;
  setInvalidMessage: React.Dispatch<React.SetStateAction<string>>;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  records: CompanyCommodityType[];
  loading: boolean;
  toolbarInfo: string;
}) => {
  const buttonComponents = (
    <CustomDeleteButton
      loading={loading}
      handleSubmit={handleSubmit}
      setInvalidMessage={setInvalidMessage}
      setShowError={setShowError}
    />
  );

  const infoComponents = (
    <Typography variant="body2" color="textSecondary">
      {toolbarInfo}: {records.length?.toLocaleString()}
    </Typography>
  );

  return (
    <BulkToolbar
      handleCloseClick={handleCloseClick}
      infoComponents={infoComponents}
      buttonComponents={buttonComponents}
    />
  );
};

const BulkDelete = ({ refresh }: { refresh: () => void }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [records, setRecords] = useState<CompanyCommodityType[]>([{ company_id: '', commodity_id: '' }]);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const record = useRecordContext();
  const notify = useNotify();
  const buttonClasses = buttonStyles();

  useEffect(() => {
    if (invalidMessage) {
      setShowNotification(true);
      setShowError(true);
    }
  }, [invalidMessage]);

  const handleCloseNotification = () => {
    setShowNotification(false);
    setInvalidMessage('');
  };

  const handleBulkDeleteButtonClick = () => {
    setIsLoading(false);
    setShowDialog(true);
  };

  const handleBulkDeleteCloseClick = () => {
    setIsLoading(false);
    setShowDialog(false);
    setInvalidMessage('');
    setShowError(false);
    const updatedRecords = getUpdateRecords();
    setRecords(updatedRecords);
    resetErrorMessages();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const finalRecords = getUpdateRecords();

    try {
      const orgId = record?.org_id || getOrgId();
      if (!orgId) {
        throw new Error('No organization found');
      }

      const objects = uniqWith(
        Object.values(finalRecords).map((companyCommodity) => ({
          company_id: { _eq: trim(companyCommodity.company_id) },
          commodity_id: { _eq: trim(companyCommodity.commodity_id) },
        })),
        isEqual,
      );

      if (!objects.length) {
        throw new Error('No records to delete');
      }

      const { data } = await client.mutate<{
        signals_delete_company_commodities: { affected_rows: number };
      }>({
        mutation: gql`
          mutation SignalsDeleteCompanyCommodities($where: company_commodities_bool_exp!) {
            signals_delete_company_commodities(where: $where) {
              affected_rows
            }
          }
        `,
        variables: {
          where: {
            org_id: {
              _eq: orgId,
            },
            _or: objects,
          },
        },
      });

      const numAffectedRows = data?.signals_delete_company_commodities?.affected_rows || 0;
      setShowDialog(false);
      notify(
        `Successfully deleted ${numAffectedRows.toLocaleString()} company commodit${numAffectedRows > 1 ? 'ies' : 'y'}`,
      );
      setRecords([{ company_id: '', commodity_id: '' }]);
      refresh();
    } catch (error) {
      setIsLoading(false);
      const errorMessage = generateErrorMessage({
        error,
        prependMessage: 'Error deleting company commodities\n',
      });
      notify(errorMessage, { type: 'error' });
    }
  };

  const handleCsvUpload = (csvRecords: CompanyCommodityType[]) => {
    setRecords(csvRecords);
    setIsLoading(false);
    setShowError(false);
  };

  const isDeleteButtonDisabled = isLoading || !records.length;

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem', minWidth: '10rem' }}>
      <Button
        label="Delete Bulk"
        size="medium"
        onClick={handleBulkDeleteButtonClick}
        className={buttonClasses.deleteButton}
      >
        <DeleteSweepIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleBulkDeleteCloseClick}
        aria-label="Delete Organization Subscriptions"
      >
        {isLoading && <LoadingOverlay open />}
        {!isLoading && (
          <DialogInner>
            <Box key={records.length}>
              <DialogTitle>Delete Organization Subscriptions</DialogTitle>
              <SimpleForm
                record={records}
                toolbar={
                  <BulkDeleteToolbar
                    handleCloseClick={handleBulkDeleteCloseClick}
                    loading={isDeleteButtonDisabled}
                    records={records}
                    setInvalidMessage={setInvalidMessage}
                    setShowError={setShowError}
                    toolbarInfo="Total Records to Delete"
                    handleSubmit={handleSubmit}
                  />
                }
              >
                <DialogContent sx={{ width: '100%' }}>
                  <Box sx={{ marginTop: '-1rem' }}>
                    <AssetLink href="/assets/commodities_sample.csv">Download sample CSV</AssetLink>
                  </Box>
                  <Box display="flex" justifyContent="space-between" sx={{ marginBottom: '1rem' }}>
                    {' '}
                    <CsvImport
                      onChange={handleCsvUpload}
                      setIsLoading={setIsLoading}
                      title="CSV File Upload for Deletion"
                      buttonClassName={buttonClasses.deleteButton}
                    />
                  </Box>
                  <CompanyCommoditiesTable
                    setRecords={setRecords}
                    records={records}
                    setInvalidMessage={setInvalidMessage}
                    setShowError={setShowError}
                    showError={showError}
                  />
                  {invalidMessage && (
                    <BulkErrorNotification
                      message={invalidMessage}
                      showNotification={showNotification}
                      handleCloseNotification={handleCloseNotification}
                    />
                  )}
                </DialogContent>
              </SimpleForm>
            </Box>
          </DialogInner>
        )}
      </Dialog>
    </Box>
  );
};

export default BulkDelete;
