import CraftEdit from 'src/components/CraftEdit';
import { RaRecord, SimpleForm, useNotify, useRedirect } from 'react-admin';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { userSubscriptionsListRoute } from 'src/utils/routeHelpers';
import { transformValues } from './transformValues';
import { SharedInputs } from './SharedInputs';

export const UserSubscriptionsEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = async (record: RaRecord) => {
    notify('User Subscriptions updated', { type: 'success' });
    redirect(record.user_id ? userSubscriptionsListRoute(record.user_id) : '/organization_config');
  };

  return (
    <CraftEdit mutationMode="pessimistic" mutationOptions={{ onSuccess }} transform={transformValues}>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />}>
        <h2>Edit a User Subscription</h2>
        <SharedInputs />
      </SimpleForm>
    </CraftEdit>
  );
};

export default UserSubscriptionsEdit;
