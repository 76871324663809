import { ReactNode } from 'react';
import { Link } from '@mui/material';

export const AssetLink = ({ href, children }: { href: string; children: ReactNode }) => (
  <Link href={href} target="_blank" download style={{ fontSize: '0.875rem', lineHeight: '1.43' }}>
    {children}
  </Link>
);

export default AssetLink;
