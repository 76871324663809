export default {
  craft: {
    validation: {
      duns: {
        already_used: 'DUNS is already used by %{id}',
      },
      siren_code: {
        already_used: 'SIREN is already used by %{id}',
      },
    },
  },
};
