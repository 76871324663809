import { useListContext } from 'ra-core';
import { forwardRef, useImperativeHandle } from 'react';

export const ListRefHandle = forwardRef((_, ref) => {
  const listContext = useListContext();
  useImperativeHandle(ref, () => listContext, [listContext]);
  return null;
});

ListRefHandle.displayName = 'ListRefHandle';
