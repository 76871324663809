import { gql } from '@apollo/client';
import { AuthProvider } from 'react-admin';

import { client } from './data/api';
import { removeToken, restoreToken, storeToken, toUserIdentity, toUserPermissions } from './utils/auth';

export enum AUTH_OPERATION_NAME {
  LOGIN = 'login',
  LOGOUT = 'logout',
}

const authProvider: AuthProvider = {
  login: async (cognitoToken) => {
    try {
      const { data } = await client.mutate<{
        login: {
          token?: string;
        };
      }>({
        mutation: gql`
          mutation ${AUTH_OPERATION_NAME.LOGIN}($cognitoToken: String) {
            login(cognitoToken: $cognitoToken) {
              token
            }
          }
        `,
        variables: { cognitoToken },
        context: { credentials: 'include' },
      });

      if (data?.login.token) {
        storeToken(data.login.token);
        return Promise.resolve();
      }

      return Promise.reject(new Error('Authentication failed'));
    } catch (e) {
      return Promise.reject(new Error('Authentication failed'));
    }
  },
  logout: async () => {
    try {
      const { data } = await client.mutate<{
        logout: boolean;
      }>({
        mutation: gql`
          mutation ${AUTH_OPERATION_NAME.LOGOUT} {
            logout
          }
        `,
        context: { credentials: 'include' },
      });

      if (!data?.logout) throw new Error('Logout failed');

      removeToken();
      return Promise.resolve();
    } catch (e) {
      removeToken();
      // return resolved to avoid user being unable to logout
      return Promise.resolve();
    }
  },
  checkError: async (error) => {
    try {
      const maybeStatus = error?.status ?? error?.body?.status ?? 0;
      const maybeGraphQLErrors = error?.graphQLErrors ?? error?.body?.graphQLErrors ?? [];
      const maybeErrorCode = maybeGraphQLErrors[0]?.extensions?.code ?? '';

      if (
        maybeStatus === 401 ||
        maybeStatus === 403 ||
        maybeErrorCode === 'INVALID_JWT' ||
        maybeErrorCode === 'UNAUTHORIZED'
      ) {
        if (maybeErrorCode === 'INVALID_JWT') {
          const { reason } = maybeGraphQLErrors[0].extensions;
          if (reason) throw new Error(reason);
        }
        throw new Error('session_expired');
      }

      // other error code (404, 500, etc): no need to log out
      return Promise.resolve();
    } catch (e) {
      const queryParams = e instanceof Error ? `?logout_reason=${e.message}` : '';
      return Promise.reject({ redirectTo: `/login${queryParams}` });
    }
  },
  checkAuth: () => {
    const maybeToken = restoreToken();

    if (maybeToken) {
      return Promise.resolve();
    }

    return Promise.reject({ redirectTo: '/login' });
  },
  getPermissions: async () => {
    const maybeToken = restoreToken();

    if (maybeToken) {
      return toUserPermissions(maybeToken);
    }

    return Promise.reject(new Error('Authentication failed'));
  },
  getIdentity: async () => {
    const maybeToken = restoreToken();

    if (maybeToken) {
      return toUserIdentity(maybeToken);
    }

    return Promise.reject(new Error('Authentication failed'));
  },
};

export const isAuthOperationName = (val?: string): val is AUTH_OPERATION_NAME =>
  !!val && !!Object.values(AUTH_OPERATION_NAME).find((name) => name === val);

export default authProvider;
