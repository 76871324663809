import styled from '@emotion/styled';
import { Box } from '@mui/material';

const NestedInputWrapper = styled(Box)`
  margin-left: 8px;
  padding-left: 24px;
  border-left: 1px solid #aaa;
`;

export default NestedInputWrapper;
