import { FC, MouseEvent } from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { Chip, ChipProps } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from 'react-router-dom';
import { PreferredTopicName, TopicMetadata, TopicTypes } from './TopicMetadataTypes';
import { toDisplayModel, TopicDisplayModel } from './TopicMetadataDisplayModel';
import { CompanyReferenceField } from 'src/resources/companies/CompanyReferenceField';

type TopicComponentProps = ChipProps & { model: TopicDisplayModel };

const CUSTOM_TOPIC_COMPONENTS: Record<string, FC<TopicComponentProps>> = {
  [TopicTypes.COMPANY]: ({ model, ...rest }: TopicComponentProps) => {
    // We can't use useCreatePath here
    // because companies details page is tabbed
    const link = `/companies/${model.value}`;
    const maybeIcon = model.isWildcard
      ? {}
      : {
          icon: (
            <Link to={link} onClick={(e) => e.stopPropagation()}>
              <LaunchIcon fontSize="small" />
            </Link>
          ),
        };

    return (
      <Chip
        color={model.color}
        label={
          <CompanyReferenceField
            record={{ id: model.value, name: model.displayValue }}
            logoProps={{ sx: { width: '1.125rem', height: '1.125rem', mr: 0.5, fontSize: '0.75rem' } }}
            source="id"
            link={false}
          />
        }
        sx={{
          '.MuiChip-label': {
            pl: 0.5,
          },
          '.MuiChip-icon': {
            order: 1,
            margin: '0 0.5rem 0 0',
            display: 'flex',
            alignItems: 'center',
          },
        }}
        {...maybeIcon}
        {...rest}
      />
    );
  },
};

/**
 * Renders a topics_metadata resource as a customized
 * chip, taking into account internal presentation logic.
 */
export const TopicMetadataField = ({
  preferredName = PreferredTopicName.DEFAULT,
  showType = true,
  chipProps = {},
  onClick,
  topicMetadata,
  displayModel: model,
  ...rest
}: Omit<FunctionFieldProps, 'render' | 'onClick'> & {
  preferredName?: PreferredTopicName;
  showType?: boolean;
  chipProps?: ChipProps;
  onClick?: (e: React.MouseEvent, model: TopicDisplayModel) => void;

  /**
   * Allows using this field outside of resource context, passing
   * the topic metadata directly.
   */
  topicMetadata?: TopicMetadata;

  /**
   * Allows using this field outside of resource context, passing
   * the topic display model directly.
   */
  displayModel?: TopicDisplayModel;
}) => (
  <FunctionField
    render={(meta: { topic: string; metadata?: TopicMetadata }) => {
      let displayModel: TopicDisplayModel;

      if (model) {
        displayModel = model;
      } else {
        let source: typeof meta;

        if (meta.metadata?.name) {
          source = {
            topic: meta.topic,
            // @ts-expect-error - we can use this field from topic_metadata or signals directly
            metadata: meta,
          };
        } else {
          source = topicMetadata
            ? {
                topic: topicMetadata.topic,
                metadata: topicMetadata,
              }
            : meta;
        }

        displayModel = toDisplayModel(source, preferredName);
      }

      const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        if (chipProps.onClick) {
          chipProps.onClick(e);
        }

        onClick?.(e, displayModel);
      };

      if (displayModel.type in CUSTOM_TOPIC_COMPONENTS) {
        const componentType = displayModel.type as keyof typeof CUSTOM_TOPIC_COMPONENTS;
        const Component = CUSTOM_TOPIC_COMPONENTS[componentType];

        return <Component model={displayModel} onClick={handleClick} {...chipProps} />;
      }

      return (
        <Chip
          color={displayModel.color}
          label={showType ? displayModel.fullName : displayModel.displayValue}
          onClick={handleClick}
          {...chipProps}
        />
      );
    }}
    {...rest}
  />
);
