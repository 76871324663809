import { Box } from '@mui/material';
import { Loading, TabbedForm, TabbedFormProps, usePermissions, useRecordContext } from 'react-admin';

import { CraftAdminPermission } from 'src/types';
import FormToolbar from 'src/components/FormToolbar';

interface DeleteOptionsProps {
  hideDeleteButton?: boolean;
  deletePermission: CraftAdminPermission;
  dialogContent?: React.ReactNode;
  dialogTitle?: string;
  onConfirmDelete?: () => void;
}
interface CraftTabbedFormProps extends TabbedFormProps {
  formType: 'create' | 'edit'; // TODO: would be better to get this context from React-Admin
  deleteOptions?: DeleteOptionsProps;
}

export const CraftTabbedForm = (props: CraftTabbedFormProps) => {
  const { formType: viewType, deleteOptions, ...baseProps } = props;
  const { permissions } = usePermissions<CraftAdminPermission[]>();
  const record = useRecordContext();

  const resourceIsDeletedOrArchived = !!record?.deleted_at || !!record?.archived_at;

  const showDeleteButton =
    viewType === 'edit' &&
    !!record &&
    !deleteOptions?.hideDeleteButton &&
    (!deleteOptions?.deletePermission || permissions.includes(deleteOptions?.deletePermission)) &&
    !resourceIsDeletedOrArchived;

  if (resourceIsDeletedOrArchived) baseProps.disabled = true;

  baseProps.toolbar ??= (
    <FormToolbar
      disableSave={!!baseProps.disabled}
      deleteOptions={{
        showDeleteButton,
        dialogTitle: deleteOptions?.dialogTitle,
        dialogContent: deleteOptions?.dialogContent,
        onConfirmDelete: deleteOptions?.onConfirmDelete,
      }}
    />
  );

  if (viewType === 'edit' && !record) {
    return (
      <Box py={12} px={8}>
        <Loading />
      </Box>
    );
  }

  return <TabbedForm {...baseProps} />;
};

export default CraftTabbedForm;
