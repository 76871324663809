import { DateTimeInput, FormTab, SelectInput, TextInput, UpdateButton } from 'react-admin';
import { Stack, Typography } from '@mui/material';

import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { JsonInput } from 'src/inputs/JsonInput';
import { SignalsEditAside } from './EditAside';
import { PUBLICATION_STATES, VALIDATION_STATES } from 'src/utils/defaults/Constants';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import { SignalDerivedTopicsInput } from './SignalDerivedTopicsInput';
import { RawField } from 'src/fields/RawField';
import { PUBLISH_SIGNAL_PROPS, UNPUBLISH_SIGNAL_PROPS } from './SignalsEnums';

export const SignalsEdit = () => (
  <CraftEdit
    header={
      <Stack direction="column">
        <Typography variant="h5">Edit Signal</Typography>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
          <UpdateButton {...PUBLISH_SIGNAL_PROPS} />
          <UpdateButton {...UNPUBLISH_SIGNAL_PROPS} />
        </Stack>
      </Stack>
    }
    redirect={false}
    mutationMode="pessimistic"
    aside={<SignalsEditAside />}
  >
    <CraftTabbedForm
      formType="edit"
      deleteOptions={{
        deletePermission: 'alerts:delete',
      }}
    >
      <FormTab label="Details">
        <CraftPageSection title="Details">
          <TextInput source="id" disabled />
          <TextInput source="signal_title" />
          <TextInput source="signal_text" multiline />
          <TextInput source="source_url" multiline />
          <DateTimeInput source="date" helperText="Should indicate when the event occured" />
        </CraftPageSection>
        <CraftPageSection title="State">
          <CraftTwoColumns
            left={
              <>
                <Typography variant="body2">Validation</Typography>
                <SelectInput label="State" source="validation_state" choices={VALIDATION_STATES} />
              </>
            }
            right={
              <>
                <Typography variant="body2">Publication</Typography>
                <SelectInput source="publication_state" label="State" choices={PUBLICATION_STATES} />
              </>
            }
          />
        </CraftPageSection>
      </FormTab>
      <FormTab label="Topics">
        <CraftPageSection title="Topics">
          <Typography variant="caption" mb={4}>
            Data on a signal which will outline the topics it belongs to when it is published.
          </Typography>
          <SignalDerivedTopicsInput />
        </CraftPageSection>
      </FormTab>
      <FormTab label="Payload">
        <CraftPageSection title="Payload">
          <Typography variant="caption">
            Certain types of payloads are validated by the Signals Validation Service. See this{' '}
            <a href="https://github.com/craft-machine/signals-validation-service/tree/main/schemas">source</a> for more
            details.
          </Typography>
          <JsonInput source="payload" label=" " />
        </CraftPageSection>
      </FormTab>
      <FormTab label="Raw">
        <CraftPageSection title="Raw">
          <Typography variant="caption">Raw signal representation.</Typography>
          <RawField />
        </CraftPageSection>
      </FormTab>
    </CraftTabbedForm>
  </CraftEdit>
);
