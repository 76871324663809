import React, { forwardRef, useState } from 'react';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { useLogout } from 'react-admin';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';

// It's important to pass the ref to allow Material UI to manage the keyboard navigation
export const LogoutButton: React.FC<MenuItemProps> = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const logout = useLogout();

  const handleLogout = () => {
    setLoading(true);
    logout();
  };

  return (
    <MenuItem
      onClick={handleLogout}
      ref={ref}
      // It's important to pass the props to allow Material UI to manage the keyboard navigation
      {...props}
    >
      <ListItemIcon>{loading ? <CircularProgress size="1em" /> : <LogoutIcon fontSize="small" />}</ListItemIcon>
      <ListItemText>Logout</ListItemText>
    </MenuItem>
  );
});

LogoutButton.displayName = 'LogoutButton';

export default LogoutButton;
