import { Box } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { DateField, ReferenceField, TextField } from 'react-admin';

export const NoteCard = () => (
  <Box
    sx={{
      display: 'flex',
    }}
  >
    <Box
      sx={{
        width: '7%',
      }}
    >
      <Box>
        <ChatBubbleIcon sx={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.54)', paddingTop: '0.3rem' }} />
      </Box>
      <Box
        sx={{
          height: '70%',
          width: 0,
          alignItems: 'center',
          borderRight: '1.3px solid rgba(0, 0, 0, 0.54)',
          marginLeft: '8px',
        }}
      />
    </Box>
    <Box
      sx={{
        width: '93%',
      }}
    >
      <Box>
        <ReferenceField source="author_id" reference="admin_users">
          <TextField
            source="email"
            sx={{
              fontWeight: 900,
              fontSize: '1rem',
            }}
          />
        </ReferenceField>
      </Box>
      <Box>
        <DateField source="created_at" showTime sx={{ display: 'block' }} />
      </Box>
      <Box>
        <TextField
          source="message"
          sx={{
            display: 'block',
            marginTop: '10px',
            marginLeft: '10px',
            overflowWrap: 'break-word',
          }}
        />
      </Box>
    </Box>
  </Box>
);

export default NoteCard;
