import { Avatar, AvatarProps } from '@mui/material';
import { getCompanyProfileBaseUrl } from '../resources/companies/CompanyHelper';
import { FunctionField } from 'react-admin';

export const CompanyLogoField = (props: AvatarProps) => (
  <FunctionField
    label="Logo"
    render={(company: { id: number; logo: string; name?: string }) => (
      <Avatar
        src={`${getCompanyProfileBaseUrl(company.id)}/normal_${company.logo}`}
        alt={company.name ?? ''}
        {...props}
      />
    )}
  />
);

export default CompanyLogoField;
