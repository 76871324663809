import React from 'react';
import { Button, useCreatePath } from 'react-admin';
import { Link } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';

import { PublishedAlertsResourceName } from './resource';
import { SignalsResourceName } from '../signals/resource';

type PublishedAlertsListButtonProps = {
  filter?: unknown;
  resource?: typeof SignalsResourceName | typeof PublishedAlertsResourceName;
};

const PublishedAlertsListButton = ({
  filter,
  resource = PublishedAlertsResourceName,
}: PublishedAlertsListButtonProps) => {
  const createPath = useCreatePath();
  const path = createPath({
    resource,
    type: 'list',
  });

  return (
    <Button
      startIcon={<ListIcon />}
      component={Link}
      to={{
        pathname: path,
        ...(filter ? { search: `filter=${encodeURIComponent(JSON.stringify(filter))}` } : {}),
      }}
      label="Signals"
    />
  );
};

export default PublishedAlertsListButton;
