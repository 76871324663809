import SubdirectoryArrowRight from '@mui/icons-material/SubdirectoryArrowRight';
import { Paper, Typography, Box, Card } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  FunctionField,
  Labeled,
  Loading,
  SelectField,
  ShowBase,
  TabProps,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';

import CraftTwoColumns from 'src/components/CraftTwoColumns';
import { CraftPageHeader } from 'src/components/CraftPageHeader';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { DATA_PACKAGE_KINDS } from 'src/utils/defaults/Constants';
import { DataPackageRecord } from 'src/types';

type DataPackageApiFieldXref = DataPackageRecord['api_fields_xrefs'][number];

const renderApiFieldNameField = (fieldXref: DataPackageApiFieldXref) => {
  const { path } = fieldXref.api_field;
  const pathSections = path.split('.');
  const nestedLevel = pathSections.length - 1;
  const pathName = pathSections.at(-1);

  return (
    <Typography>
      {nestedLevel > 0 && (
        <Box component="span" sx={{ ml: nestedLevel * 2, mr: 1 }}>
          <SubdirectoryArrowRight />
        </Box>
      )}
      {pathName}
    </Typography>
  );
};

const DataPackageApiFieldsShowTab = (props: Omit<TabProps, 'children' | 'label'>) => (
  <TabbedShowLayout.Tab label="Fields" {...props}>
    <CraftPageSection title="API Fields" m={2}>
      <Paper sx={{ mb: 2 }}>
        <ArrayField label={false} source="api_fields_xrefs" sort={{ field: 'api_field.path', order: 'ASC' }}>
          <Datagrid bulkActionButtons={false}>
            <FunctionField label="Name" source="api_field.path" sortable={false} render={renderApiFieldNameField} />
            <ChipField label="Sources" source="policies.sources" emptyText=" " sortable={false} />
            <ChipField label="Filters" source="policies.filters" emptyText=" " sortable={false} />
          </Datagrid>
        </ArrayField>
      </Paper>
    </CraftPageSection>
  </TabbedShowLayout.Tab>
);

const ShowContent = () => {
  const record = useRecordContext();

  if (!record) return <Loading />;

  return (
    <>
      <CraftPageHeader>Data Package Info</CraftPageHeader>

      <Card sx={{ mx: 2 }}>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="Details">
            <CraftPageSection title="General" m={2}>
              <CraftTwoColumns
                left={
                  <>
                    <Labeled>
                      <TextField label="Name" source="name" />
                    </Labeled>
                    <Labeled>
                      <SelectField label="Kind" source="kind" choices={DATA_PACKAGE_KINDS} />
                    </Labeled>
                  </>
                }
                leftSX={{ gap: 2 }}
                right={
                  <Labeled>
                    <BooleanField label="Deprecated" source="is_deprecated" />
                  </Labeled>
                }
              />
            </CraftPageSection>
          </TabbedShowLayout.Tab>

          <DataPackageApiFieldsShowTab />
        </TabbedShowLayout>
      </Card>
    </>
  );
};

export const DataPackagesShow = () => (
  <ShowBase>
    <ShowContent />
  </ShowBase>
);

export default DataPackagesShow;
