import { ReferenceInput, AutocompleteInput, AutocompleteInputProps } from 'react-admin';

export const UserRoleInput = (props: AutocompleteInputProps) => (
  <ReferenceInput source="admin_role_id" reference="admin_roles">
    <AutocompleteInput
      sx={{ width: '15rem;' }}
      label="Role"
      optionText="display_name"
      filterToQuery={(q: string) => ({ display_name: q })}
      {...props}
    />
  </ReferenceInput>
);

export default UserRoleInput;
