import { TransformData } from 'react-admin';

export const transformValues: TransformData = (values) => {
  const result = {
    ...values,
  };
  if (result.commodity_id) {
    result.commodity_id = result.commodity_id.toLowerCase().replaceAll(' ', '_');
  }
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }

  return result;
};
