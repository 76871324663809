import { useCallback } from 'react';
import { useNotify } from 'react-admin';
import { useRecordContext, useSaveContext } from 'ra-core';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import omit from 'lodash/omit';

import { config } from 'src/config/config.local';
import { formatValidationMessage } from '../../utils/formatters';
import { editFormValidation } from '../../utils/editValidationUtils';
import { formatSiteDomain } from 'src/utils/formatSiteDomain';

const fieldsToOmit = ['activated_at', 'current_stage', 'inc_5000'] as const;

export const useEditSubmit = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const { save } = useSaveContext();

  const saveCallback = useCallback<SubmitHandler<FieldValues>>(
    async (values) => {
      if (!save) return;
      // Social Links Validation - Check if links are already in use by other companies
      const [errors, warnings] = await editFormValidation(values);

      const warningMessage = formatValidationMessage('Warning -', warnings);
      const errorMessage = formatValidationMessage('Unable to update the company -', errors);

      if (errorMessage) {
        notify(errorMessage, { type: 'error' });
        return;
      }
      if (warningMessage) {
        notify(warningMessage, { type: 'warning' });
      }

      // clear values from empty strings;
      // todo: remove this logic when all fields would be implemented
      const omittedValues = omit(values, fieldsToOmit);
      const newValues = Object.fromEntries(
        Object.entries(omittedValues).filter(([key, value]) => [key, key.includes('_id') && !value ? null : value]),
      );

      newValues.site_domain = formatSiteDomain(newValues.site) ?? newValues.site_domain;

      if (newValues.logo && typeof newValues.logo !== 'string') {
        const formData = new FormData();
        formData.append('id', record.id.toString());
        formData.append('logo', newValues.logo.rawFile);
        //   'https://admin-uploader.data.qa.craft.co/api'
        const resp = await fetch(config.logo.uploaderUrl, {
          method: 'POST',
          body: formData,
        });
        const body = await resp.json();
        newValues.logo = body.filename;
      }

      await save(newValues);
    },
    [save, record],
  );
  return saveCallback;
};

export default useEditSubmit;
