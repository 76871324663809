import React, { createElement, useMemo, ReactNode } from 'react';
import startCase from 'lodash/startCase';
import { MenuItemLink, MenuItemLinkProps, MenuProps, useResourceDefinitions } from 'react-admin';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useCraftSidebarContext } from '../CraftSidebarContext';
import BackButton from '../BackButton';
import { resourcesBySection } from 'src/resources';
import CustomMenuStyled from './CustomMenuStyled';

const TEMPORARILY_DO_NOT_SEE = new Set(['companies', 'company_requests', 'users']);

const PERMANENTLY_DO_NOT_SEE = new Set(['organization_subscriptions', 'user_config']);

/**
 * Custom sidebar menu that has two states: "root" and "page", first is default view with
 * all resources (that have "list" page defined),
 * the second one displays sections that are visible on the
 * current page (see {@link useCraftSidebarContext} for more details).
 *
 * When "page" state is displayed, also shows the "back" button (see {@link BackButton} for details)
 * to navigate back to the current root view.
 */
export const CraftAdminMenu = (props: MenuProps) => {
  const resourceDefs = useResourceDefinitions();
  const { items: contextItems } = useCraftSidebarContext();
  const showContextItems = contextItems.length > 0;

  const menuSection = (section: string): ReactNode[] | null => {
    const resourceSection = resourcesBySection[section]
      .filter((resource) => !!resource.list)
      .map((resource) => {
        const resDef = resourceDefs[resource.name];
        if (!resDef) return null;

        if (
          TEMPORARILY_DO_NOT_SEE.has(resDef.name.toLowerCase()) ||
          PERMANENTLY_DO_NOT_SEE.has(resDef.name.toLowerCase())
        ) {
          return null;
        }

        const itemProps: MenuItemLinkProps = {
          to: `/${resDef.name}`,
          primaryText: startCase(resDef.options?.label || resDef.name),
        };

        if (resDef.icon) {
          itemProps.leftIcon = createElement(resDef.icon);
        }

        return <MenuItemLink key={resDef.name} {...itemProps} />;
      })
      .filter((resource) => resource);

    if (!resourceSection.length) {
      return null;
    }

    return [
      <Typography ml={2} variant="caption" className="section-title" key={`section-${section}`}>
        {startCase(section)}
      </Typography>,
      resourceSection,
      <Box key={`section-${section}-spacer`} mb={2} />,
    ];
  };

  // Convert resource definitions to <MenuItemLink /> props
  const resourceItems = useMemo(() => {
    const sections = Object.keys(resourcesBySection);

    // This is an Array instead of React.Fragment as the latter is not supported by MUI Menu.
    return sections.flatMap((section) => menuSection(section));
  }, [resourceDefs]);

  const handleContextItemClick = (item: MenuItemLinkProps) => {
    if (item.key) {
      const el = document.getElementById(item.key.toString());

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  return (
    <CustomMenuStyled {...props}>
      {showContextItems && [
        // This is an Array instead of React.Fragment as the latter is not supported by MUI Menu.
        <BackButton key="back-button" startIcon={<ArrowBackIcon />} label="Back" behavior="root" size="medium" />,
        ...contextItems.map((item) => (
          <MenuItemLink key={item.key} {...item} onClick={() => handleContextItemClick(item)} />
        )),
      ]}
      {!showContextItems &&
        (resourceItems.some((el) => el) ? (
          resourceItems
        ) : (
          <Box>
            <Typography m={2}>Nothing to see here...</Typography>
            <Typography m={2}>Please contact IT Support to request access.</Typography>
          </Box>
        ))}
    </CustomMenuStyled>
  );
};

export default CraftAdminMenu;
