import { FormTab, FormTabProps, RadioButtonGroupInput, RadioButtonGroupInputProps } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { CraftPageSection } from 'src/components/CraftPageSection';
import BooleanInput from 'src/inputs/BooleanInput';
import NestedInputWrapper from 'src/inputs/NestedInputWrapper';

const CustomRadioButtonGroupInput = (props: RadioButtonGroupInputProps) => (
  <RadioButtonGroupInput
    label={false}
    row={false}
    sx={{ m: 0 }}
    helperText={false}
    format={(val) => (val == null ? '' : val)} // TODO: not needed in newer versions of React-admin
    {...props}
  />
);

const RisksTab = (props: Omit<FormTabProps, 'label'>) => {
  const formCtx = useFormContext();

  const handleBoundInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget;

    switch (e.currentTarget.name) {
      case 'risks.esg.enabled':
        formCtx.setValue('risks.esg.ecovadis.enabled', checked);
        formCtx.setValue('risks.esg.ecovadis.value', checked ? 'free' : null);
        break;
      case 'risks.esg.ecovadis.enabled':
        formCtx.setValue('risks.esg.enabled', checked);
        formCtx.setValue('risks.esg.ecovadis.value', checked ? 'free' : null);
        break;
      case 'risks.esg.ecovadis.value':
        formCtx.setValue('risks.esg.enabled', !!value);
        formCtx.setValue('risks.esg.ecovadis.enabled', !!value);
        break;
      case 'risks.cybersecurity.enabled':
        formCtx.setValue('risks.cybersecurity.lab1.enabled', checked);
        formCtx.setValue('risks.cybersecurity.lab1.value', checked ? 'free' : null);
        break;
      case 'risks.cybersecurity.lab1.enabled':
        formCtx.setValue('risks.cybersecurity.enabled', checked);
        formCtx.setValue('risks.cybersecurity.lab1.value', checked ? 'free' : null);
        break;
      case 'risks.cybersecurity.lab1.value':
        formCtx.setValue('risks.cybersecurity.enabled', !!value);
        formCtx.setValue('risks.cybersecurity.lab1.enabled', !!value);
        break;
      default:
    }
  };

  return (
    <FormTab label="Risks" {...props}>
      <CraftPageSection title="Risks">
        <BooleanInput source="risks.esg.enabled" label="ESG" helperText={false} onChange={handleBoundInputChange} />
        <NestedInputWrapper>
          <BooleanInput
            source="risks.esg.ecovadis.enabled"
            label="Ecovadis"
            helperText={false}
            onChange={handleBoundInputChange}
          />
          <NestedInputWrapper>
            <CustomRadioButtonGroupInput
              source="risks.esg.ecovadis.value"
              choices={[
                { id: 'free', name: 'Free data' },
                { id: 'all', name: 'All data' },
              ]}
              onChange={handleBoundInputChange}
            />
          </NestedInputWrapper>
        </NestedInputWrapper>

        <BooleanInput
          source="risks.cybersecurity.enabled"
          label="Cyber security"
          helperText={false}
          onChange={handleBoundInputChange}
        />
        <NestedInputWrapper>
          <BooleanInput
            source="risks.cybersecurity.lab1.enabled"
            label="Lab1"
            helperText={false}
            onChange={handleBoundInputChange}
          />
          <NestedInputWrapper>
            <CustomRadioButtonGroupInput
              source="risks.cybersecurity.lab1.value"
              choices={[
                { id: 'free', name: 'Free data' },
                { id: 'all', name: 'All data' },
              ]}
              onChange={handleBoundInputChange}
            />
          </NestedInputWrapper>
        </NestedInputWrapper>
      </CraftPageSection>
    </FormTab>
  );
};

export default RisksTab;
