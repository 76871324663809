import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import {
  Button,
  DeleteWithConfirmButton,
  FunctionField,
  ListControllerResult,
  TextField,
  WrapperField,
  useListContext,
  useRecordContext,
} from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftStandaloneList from 'src/components/CraftStandaloneList';
import IntegerInput from 'src/inputs/IntegerInput';
import { usePermissions } from 'src/hooks';
import { CompanySubscriptionRecord, CompanySubscriptionSetRecord } from 'src/types';
import { Center } from 'src/components/Center';
import { useCompanySubscriptionSetContext } from '../../Context';
import { useAddCompanySubscriptionsDialogContext } from './AddCompanySubscriptionsDialog';

interface ListContextConsumerProps {
  setContext: React.Dispatch<React.SetStateAction<ListControllerResult | undefined>>;
}

const ListContextConsumer = ({ setContext }: ListContextConsumerProps) => {
  const listCtx = useListContext();

  useEffect(() => {
    setContext(listCtx);
  }, []);

  return null;
};

const filters = [<IntegerInput key="id" label="Company ID" source="company_id" alwaysOn />];

export const CompanySubscriptionsList = () => {
  const companySubscriptionSetRecord = useRecordContext<CompanySubscriptionSetRecord>();
  const { currentSubscriptionCounts, currentSubscriptionCountsRefetch } = useCompanySubscriptionSetContext();
  const addCompanySubscriptionsDialogCtx = useAddCompanySubscriptionsDialogContext();
  const { permissions } = usePermissions();

  const [listCtx, setListCtx] = useState<ListControllerResult>();

  useEffect(() => {
    addCompanySubscriptionsDialogCtx.setOnSuccess(() => {
      currentSubscriptionCountsRefetch();
      listCtx?.refetch();
    });
  }, [currentSubscriptionCountsRefetch, listCtx]);

  if (companySubscriptionSetRecord.all_companies) {
    // "All Companies" sets don't have explicit company subscriptions, so this component shouldn't
    // be rendered
    return null;
  }

  const currentSubscriptionCountsLoading = currentSubscriptionCounts === undefined;
  const availableSubscriptionSlotCount = !currentSubscriptionCountsLoading
    ? companySubscriptionSetRecord.company_limit - currentSubscriptionCounts.total
    : undefined;

  const Actions = permissions.has('companySubscription:edit') && (
    <Button
      label="Add"
      startIcon={<AddIcon />}
      onClick={addCompanySubscriptionsDialogCtx.setOpen}
      disabled={currentSubscriptionCountsLoading || (availableSubscriptionSlotCount ?? 0) < 1}
    />
  );

  const onDeleteSuccess = () => {
    currentSubscriptionCountsRefetch();
  };

  return (
    <CraftPageSection title="Company Subscriptions">
      {currentSubscriptionCountsLoading ? (
        <Typography color="primary" component="span">
          Loading overview...
        </Typography>
      ) : (
        <Stack component={Paper} px={1} direction="row" alignItems="center" gap={3}>
          <Typography component="span">Resolved: {currentSubscriptionCounts.resolved}</Typography>
          <Typography component="span">Processing: {currentSubscriptionCounts.reserved}</Typography>
          <Typography component="span" color="primary" fontWeight="700">
            Total: {currentSubscriptionCounts.total}
          </Typography>
        </Stack>
      )}

      <Typography mt={3} mb={1}>
        Resolved Subscriptions
      </Typography>
      <Paper sx={{ width: '100%', p: 2 }}>
        <CraftStandaloneList
          resource="company_subscriptions"
          filter={{ company_subscription_set_id: companySubscriptionSetRecord.id }}
          filters={filters}
          actions={Actions}
          dataGridProps={{
            empty: <Center m={4}>No company subscriptions found</Center>,
            bulkActionButtons: false, // TODO: enable bulk delete when BulkDeleteWithConfirmButton is fixed
          }}
        >
          <TextField label="Company ID" source="company_id" />
          <WrapperField label="Actions">
            <FunctionField
              render={(rec: CompanySubscriptionRecord) => (
                <DeleteWithConfirmButton
                  confirmTitle={`Delete Subscription for Company ID ${rec.company_id}?`}
                  redirect={false}
                  disabled={!permissions.has('companySubscription:delete')}
                  mutationOptions={{ onSuccess: onDeleteSuccess }}
                />
              )}
            />
          </WrapperField>

          <ListContextConsumer setContext={setListCtx} />
        </CraftStandaloneList>
      </Paper>
    </CraftPageSection>
  );
};

export default CompanySubscriptionsList;
