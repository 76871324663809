import { regex } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import validateCompanyLinks from './validateCompanyLinks';

const urlRegex = {
  linkedin: /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/,
  crunchbase: /^((https?:\/\/)?((www|\w\w)\.)?crunchbase\.com\/).*/,
  facebook: /^((https?:\/\/)?((www|\w\w)\.)?facebook\.com\/).*/,
  youtube:
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
  instagram: /^(https?:\/\/)?(www\.)?instagram.com\/.*/,
  general: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})\/?.*$/,
};

export const validateUrl = (link: keyof typeof urlRegex) => regex(urlRegex[link], `Incorrect ${link} Link`);

export const editFormValidation = async (values: FieldValues) => {
  const errors: { [k: string]: string } = {};
  const warnings: { [k: string]: string } = {};

  const socialLinks = [
    'linkedin',
    'crunchbase',
    'facebook',
    'youtube',
    'instagram',
    'newsroom_url',
    'careers_page',
    'yahoo_finance',
  ];

  const validLinks: { [k: string]: string } = {};
  socialLinks.forEach((link: string) => {
    if (values[link]) {
      validLinks[link] = values[link];
    }
  });

  for (const [link, value] of Object.entries(validLinks)) {
    const linkRegex = link in urlRegex ? urlRegex[link as keyof typeof urlRegex] : urlRegex.general;
    if (value && !linkRegex.test(value)) {
      errors[link] = `Incorrect ${link} link.`;
    }
  }

  if (Object.keys(errors).length === 0) {
    const repeatedLinks = await validateCompanyLinks(validLinks, Number(values.id));
    if (repeatedLinks && Object.keys(repeatedLinks).length > 0) {
      for (const [link, value] of Object.entries(repeatedLinks)) {
        warnings[link] = `${link} link is also used by ${value.toString()}.`;
      }
    }
  }

  return [errors, warnings];
};
