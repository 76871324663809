import React from 'react';
import { FormHelperText } from '@mui/material';
import { InputHelperText, InputProps, Labeled, useInput } from 'react-admin';
import ReactJson, { InteractionProps, ReactJsonViewProps } from 'react-json-view';

export const defaultOptions: Omit<ReactJsonViewProps, 'src'> = {
  theme: 'ocean',
  collapsed: false,
  iconStyle: 'square',
  indentWidth: 4,
  style: {
    fontSize: '12px',
    width: '100%',
    padding: '1rem',
    borderRadius: '4px',
  },
};

type Props = {
  source: string;
  label: string;
  helperText?: string;
  jsonString?: boolean;
  reactJsonOptions?: Omit<ReactJsonViewProps, 'src'>;
} & Omit<InputProps, 'label'>;

export const JsonInput: React.FC<Props> = (props) => {
  const {
    field: { value, onChange },
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput(props);

  const { source, label, helperText, jsonString = false, reactJsonOptions } = props;

  function change(updatedSrc: unknown) {
    let updatedValue = updatedSrc;

    if (jsonString) {
      updatedValue = updatedSrc == null || typeof updatedSrc !== 'object' ? null : JSON.stringify(updatedSrc);
    }

    onChange(updatedValue);
  }

  function onEdit(edit: InteractionProps) {
    change(edit.updated_src);

    if (reactJsonOptions?.onEdit) {
      reactJsonOptions.onEdit(edit);
    }
  }

  function onAdd(add: InteractionProps) {
    change(add.updated_src);

    if (reactJsonOptions?.onAdd) {
      reactJsonOptions.onAdd(add);
    }
  }

  function onDelete(del: InteractionProps) {
    change(del.updated_src);

    if (reactJsonOptions?.onDelete) {
      reactJsonOptions.onDelete(del);
    }
  }

  let src = value;

  if (jsonString) {
    src = value ? JSON.parse(value) : value;
  }

  return (
    <>
      <Labeled source={source} label={label} isRequired={isRequired}>
        <ReactJson
          {...defaultOptions}
          {...reactJsonOptions}
          src={src || {}}
          onEdit={reactJsonOptions?.onEdit === false ? false : onEdit}
          onAdd={reactJsonOptions?.onAdd === false ? false : onAdd}
          onDelete={reactJsonOptions?.onDelete === false ? false : onDelete}
        />
      </Labeled>
      {helperText && (
        <FormHelperText error={(isTouched || isSubmitted) && !!error}>
          {error?.message && (
            <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={helperText} />
          )}
        </FormHelperText>
      )}
    </>
  );
};
