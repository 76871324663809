import gql from 'graphql-tag';

import { client } from '../data/api';
import { HasuraBoolExpression } from './HasuraTypes';

const checkLinkUniqueness = async (links: { [k: string]: string }, id: number) => {
  const query = gql`
    query CheckLinksUniqueness($where: companies_bool_exp!) {
      companies(limit: 20, where: $where) {
        id
        linkedin
        crunchbase
        facebook
        youtube
        instagram
        newsroom_url
        careers_page
        yahoo_finance
      }
    }
  `;

  const where: HasuraBoolExpression = {
    id: { _neq: id },
  };

  Object.entries(links).forEach(([key, value]) => {
    where._or = where._or || [];
    where._or.push({ [key]: { _eq: value } });
  });

  const response = await client.query<{ companies: { id: string }[] }>({
    query,
    variables: { where },
  });

  return response;
};

export const validateCompanyLinks = async (links: { [k: string]: string }, id: number) => {
  const repeatedLinks: { [k: string]: string[] } = {};

  if (!links) return undefined;

  const isLinkUnique = await checkLinkUniqueness(links, id);
  const { companies } = isLinkUnique.data;

  if (companies.length > 0) {
    for (const [link, value] of Object.entries(links)) {
      const repeatedIds = companies.reduce((ids: string[], company: { [k: string]: string }) => {
        if (company[link] === value) {
          ids.push(company.id);
        }
        return ids;
      }, []);
      if (repeatedIds.length > 0) {
        repeatedLinks[link] = repeatedIds;
      }
    }
  }

  return repeatedLinks;
};

export default validateCompanyLinks;
