import { useEffect, useMemo } from 'react';
import {
  CheckboxGroupInput,
  CheckboxGroupInputProps,
  FormDataConsumer,
  Loading,
  required,
  useGetList,
  useNotify,
} from 'react-admin';
import { Box, Grid, GridProps } from '@mui/material';
import { get } from 'lodash';

import { ApiFieldRecord, DataPackageApiFieldRecord } from 'src/types';
import { DEFAULT_DATA_PACKAGE_NAME } from 'src/utils/defaults/Constants';
import NestedInputWrapper from 'src/inputs/NestedInputWrapper';
import BooleanInput from 'src/inputs/BooleanInput';

const fieldPoliciesByField: Partial<Record<string, { filters: { id: string; name: string }[] }>> = {
  locations: {
    filters: [{ id: 'hqOnly', name: 'HQ Only' }],
  },
};

const FieldPolicyInputGroupWrapper = ({ children }: Pick<GridProps, 'children'>) => (
  <Grid container item flex={1} pl={2} flexDirection="column" justifyContent="flex-start" borderLeft="1px solid #aaa">
    {children}
  </Grid>
);

const SourcesInput = ({ choices, ...rest }: CheckboxGroupInputProps) => {
  const defaultValue = [choices?.find((src) => src.name === 'craft')?.id ?? choices?.[0].id];

  return (
    <FieldPolicyInputGroupWrapper>
      <CheckboxGroupInput
        label="Sources"
        choices={choices ?? []}
        defaultValue={defaultValue}
        validate={required()}
        fullWidth
        sx={{ m: 0 }}
        helperText={false}
        {...rest}
      />
    </FieldPolicyInputGroupWrapper>
  );
};

const FiltersInput = (props: CheckboxGroupInputProps) => (
  <FieldPolicyInputGroupWrapper>
    <CheckboxGroupInput label="Filters" fullWidth sx={{ m: 0 }} helperText={false} {...props} />
  </FieldPolicyInputGroupWrapper>
);

const InputGroup = ({
  field,
  nestedFieldsByParentPath,
  sourcesByField,
  formData,
  canEdit,
}: {
  field: ApiFieldRecord;
  nestedFieldsByParentPath: Partial<Record<string, ApiFieldRecord[]>>;
  sourcesByField: Partial<Record<string, string[]>>;
  formData: Record<string, unknown>;
  canEdit: boolean;
}) => {
  // we prefix the value key as a workaround to prevent react-hook-form from converting "fields"
  // into an array
  const idKey = `id:${field.id}`;
  const valuePath = `fields.${idKey}`;
  const fieldIsChecked = !!get(formData, valuePath);

  const genChildrenFields = () => {
    const childrenFields = nestedFieldsByParentPath[field.path];
    return !childrenFields ? null : (
      <NestedInputWrapper>
        {childrenFields.map((child) => (
          <InputGroup
            key={field.id}
            field={child}
            nestedFieldsByParentPath={nestedFieldsByParentPath}
            sourcesByField={sourcesByField}
            formData={formData}
            canEdit={canEdit}
          />
        ))}
      </NestedInputWrapper>
    );
  };

  const sourceOptions = sourcesByField[field.path]?.map((src) => ({ id: src, name: src }));
  const policyOptions = fieldPoliciesByField[field.path]?.filters;

  return (
    <Box key={valuePath}>
      <Grid container alignItems="stretch">
        <BooleanInput
          source={valuePath}
          label={field.path.split('.').at(-1) ?? field.path}
          helperText={false}
          disabled={!canEdit}
          sx={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
        />
        {fieldIsChecked && sourceOptions && (
          <SourcesInput source={`fieldPolicies.${idKey}.sources`} choices={sourceOptions} disabled={!canEdit} />
        )}
        {fieldIsChecked && policyOptions && (
          <FiltersInput source={`fieldPolicies.${idKey}.filters`} choices={policyOptions} disabled={!canEdit} />
        )}
      </Grid>
      {fieldIsChecked && genChildrenFields()}
    </Box>
  );
};

export const ApiFieldSwitchInputGroup = ({ canEdit = true }: { canEdit?: boolean }) => {
  const notify = useNotify();

  // fetch API fields provided by default data package - these should be hidden
  const {
    data: defaultFieldsData,
    error: defaultFieldsError,
    isLoading: defaultFieldsLoading,
  } = useGetList<DataPackageApiFieldRecord>('data_packages_api_fields', {
    filter: { 'data_package#name': DEFAULT_DATA_PACKAGE_NAME },
  });

  const {
    data: fieldsData,
    error: fieldsError,
    isLoading: fieldsLoading,
  } = useGetList<ApiFieldRecord>(
    'api_fields',
    {
      // exclude fields in default data package
      filter: { 'id@_nin': defaultFieldsData?.map((fieldXref) => fieldXref.api_field_id) },
      sort: { field: 'path', order: 'ASC' },
      meta: { noLimit: true },
    },
    { enabled: !!defaultFieldsData },
  );

  const { rootFields, nestedFieldsByParentPath } = useMemo(() => {
    const result: {
      rootFields: ApiFieldRecord[];
      nestedFieldsByParentPath: Partial<Record<string, ApiFieldRecord[]>>;
    } = {
      rootFields: [],
      nestedFieldsByParentPath: {},
    };

    if (!fieldsData) return result;

    for (const field of fieldsData) {
      if (!field.path.includes('.')) {
        /* field is at root level */
        result.rootFields.push(field);
        continue;
      }

      /* field is nested */
      const parentPath = field.path.split('.').slice(0, -1).join('.');
      result.nestedFieldsByParentPath[parentPath] ||= [];
      result.nestedFieldsByParentPath[parentPath]?.push(field);
    }

    return result;
  }, [fieldsData]);

  const {
    data: fieldSourcesData,
    error: fieldSourcesError,
    isLoading: fieldSourcesLoading,
  } = useGetList<{ id: number; field_type: string }>('external_api_policies', {
    filter: { distinct_on: 'field_type', field_type: ':' },
    sort: { field: 'field_type', order: 'ASC' },
    meta: { noLimit: true },
  });

  const sourcesByField: Partial<Record<string, string[]>> = {};
  fieldSourcesData?.reduce((acc, rawSrc) => {
    // raw source format = <fieldPath>:<source>
    const [fieldPath, src] = rawSrc.field_type.split(':');
    acc[fieldPath] ||= [];
    acc[fieldPath]?.push(src);
    return acc;
  }, sourcesByField);

  useEffect(() => {
    if (!defaultFieldsError && !fieldsError && !fieldSourcesError) return;
    notify('There was a problem fetching API field data. Please try refreshing the page.', {
      type: 'error',
    });
  }, [fieldsError]);

  if (defaultFieldsLoading || !fieldsData || fieldsLoading || fieldSourcesLoading) {
    return <Loading />;
  }

  return (
    <FormDataConsumer>
      {({ formData }) =>
        rootFields.map((field) => (
          <InputGroup
            key={field.id}
            field={field}
            nestedFieldsByParentPath={nestedFieldsByParentPath}
            sourcesByField={sourcesByField}
            formData={formData}
            canEdit={canEdit}
          />
        ))
      }
    </FormDataConsumer>
  );
};

export default ApiFieldSwitchInputGroup;
