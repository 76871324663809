import { Box, Chip, Typography } from '@mui/material';
import { FunctionField, SelectField } from 'react-admin';

import { OrganizationReferenceField } from '../organizations/OrganizationReferenceField';
import { USER_ROLE_CHOICES } from './UserEnums';

export const UserRoleField = () => (
  <FunctionField
    render={() => (
      <Chip
        sx={{
          mr: 1,
          mb: 1,
          border: '1px solid rgba(0, 0, 0, 0.1)',
        }}
        size="medium"
        label={
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <SelectField source="roles_id" choices={USER_ROLE_CHOICES} variant="inherit" />
            <Typography component="span" variant="inherit">
              at
            </Typography>
            <OrganizationReferenceField source="organization_id" />
          </Box>
        }
      />
    )}
  />
);
