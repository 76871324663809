import ApartmentIcon from '@mui/icons-material/Apartment';
import { CREATE, GET_LIST, GET_ONE, GET_MANY_REFERENCE, GET_MANY, UPDATE, DELETE_MANY } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import OrgConfigCreate from './Create';
import OrgConfigEdit from './Edit';
import OrgConfigList from './List';

export const OrganizationConfigResource: ResourceDefinition = {
  name: 'organization_config',
  section: ResourceSections.ALERTS,
  icon: ApartmentIcon,

  list: OrgConfigList,
  edit: OrgConfigEdit,
  create: OrgConfigCreate,
  permissions: {
    create: ['alerts:edit'],
    list: ['alerts:read'],
    edit: ['alerts:edit'],
  },

  extension: {
    idKey: ['org_id'],
    operations: {
      [CREATE]: {
        operationName: 'signals_insert_organization_config',
      },
      [GET_LIST]: {
        operationName: 'signals_organization_config',
      },
      [GET_ONE]: {
        operationName: 'signals_organization_config',
      },
      [GET_MANY]: {
        operationName: 'signals_organization_config',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_organization_config',
      },
      [UPDATE]: {
        operationName: 'signals_update_organization_config',
      },
      [DELETE_MANY]: {
        operationName: 'signals_delete_organization_config',
      },
    },
  },
};
