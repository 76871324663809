import React from 'react';
import { Link } from 'react-router-dom';

interface StyledLinkProps {
  to: string;
  state?: { [key: string]: string };
  children: React.ReactNode;
}

export const StyledLink = ({ to, state, children }: StyledLinkProps) => (
  <Link to={to} state={state} style={{ padding: '0.5rem 0.25rem' }}>
    {children}
  </Link>
);

export default StyledLink;
