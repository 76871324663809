import { generateRandomString } from '@aws-amplify/core';
import {
  TextField,
  ExportButton,
  TopToolbar,
  DateField,
  WrapperField,
  EditButton,
  SearchInput,
  SortPayload,
  ReferenceField,
} from 'react-admin';
import BulkSoftDeleteButton from 'src/components/BulkSoftDeleteButton';
import { CraftList } from 'src/components/CraftList';
import { usePermissions } from 'src/hooks';
import { UserRoleInput } from 'src/inputs/UserRoleInput';
import { UserRolesEnum } from 'src/utils/UserRolesEnum';

const filters = [
  <SearchInput key="search" placeholder="Search" source="name,email@_ilike" alwaysOn />,
  <UserRoleInput key="user_role" alwaysOn />,
];

const defaultSort: SortPayload = {
  field: 'created_at',
  order: 'DESC',
};

const columnStyles = {
  created_at: {
    width: '10rem',
  },
  updated_at: {
    width: '10rem',
  },
};

const Actions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

export const UsersList = () => {
  const { permissions } = usePermissions();

  const canEdit = permissions.has('user:edit');
  const canDelete = permissions.has('user:delete');

  const bulkActionButtons = canDelete && (
    <BulkSoftDeleteButton
      label="Delete Selected"
      data={{ encrypted_password: generateRandomString(), admin_role_id: UserRolesEnum.DEFAULT }}
    />
  );

  return (
    <CraftList
      title="Admin Users"
      filters={filters}
      actions={<Actions />}
      sort={defaultSort}
      columnStyles={columnStyles}
      dataGridProps={{
        bulkActionButtons,
      }}
    >
      <TextField source="id" />
      <TextField source="email" />

      <ReferenceField source="admin_role_id" reference="admin_roles">
        <TextField source="display_name" />
      </ReferenceField>

      <DateField source="last_sign_in_at" />
      <DateField source="created_at" />
      <DateField source="updated_at" />

      {canEdit && (
        <WrapperField label="Actions">
          <EditButton />
        </WrapperField>
      )}
    </CraftList>
  );
};

export default UsersList;
