import { Box, Divider, Typography } from '@mui/material';
import { FormTab, FormTabProps, Loading, useGetList, useNotify } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import BooleanInput from 'src/inputs/BooleanInput';
import { DataPackageKind, DataPackageRecord } from 'src/types';
import { DATA_PACKAGE_KINDS } from 'src/utils/defaults/Constants';

const DataPackageSwitchInputGroup = ({
  kind,
  dataPackages,
  defaultDataPackageIdSet,
}: {
  kind: DataPackageKind;
  dataPackages: DataPackageRecord[];
  defaultDataPackageIdSet: Set<number> | undefined;
}) => (
  <>
    <Typography variant="body1" color="primary" mb={1}>
      {DATA_PACKAGE_KINDS.find(({ id }) => id === kind)?.name}
    </Typography>

    {dataPackages.map((pkg) => {
      const isDefaultPackage = !!defaultDataPackageIdSet?.has(pkg.id);
      // we prefix the value key with "id:" as a workaround to prevent react-hook-form from
      // converting "dataPackages" into an array
      const valuePath = `dataPackages.id:${pkg.id}`;

      return (
        <BooleanInput
          key={valuePath}
          source={valuePath}
          label={pkg.name}
          defaultValue={isDefaultPackage}
          readOnly={isDefaultPackage}
          helperText={false}
          sx={{ width: 'max-content', ml: 1 }}
        />
      );
    })}

    <Divider sx={{ my: 3 }} />
  </>
);

interface DataPackagesTabProps extends Omit<FormTabProps, 'label'> {
  label?: string;
  defaultDataPackageIdSet?: Set<number> | undefined;
}

const DataPackagesTab = ({ label = 'Data Packages', defaultDataPackageIdSet, ...rest }: DataPackagesTabProps) => {
  const notify = useNotify();

  const {
    data: dataPackages,
    error: dataPackagesError,
    isLoading: dataPackagesLoading,
  } = useGetList<DataPackageRecord>('data_packages', {
    filter: { 'kind@_neq': DataPackageKind.INTERNAL, 'deleted_at@_is_null': true },
    sort: { field: 'name', order: 'ASC' },
    meta: { noLimit: true },
  });

  if (dataPackagesError) {
    console.error(dataPackagesError);
    notify('Error fetching data packages data', { type: 'error' });
  }

  if (dataPackagesLoading || !defaultDataPackageIdSet) {
    return (
      <FormTab label={label} {...rest}>
        <Box width="100%" py={12}>
          <Loading />
        </Box>
      </FormTab>
    );
  }

  const standardDataPackages = dataPackages?.filter((pkg) => pkg.kind === DataPackageKind.STANDARD);
  const customDataPackages = dataPackages?.filter((pkg) => pkg.kind === DataPackageKind.CUSTOM);
  const dataPackagesFound = !!(standardDataPackages?.length || customDataPackages?.length);

  return (
    <FormTab label={label} {...rest}>
      {!dataPackagesFound ? (
        <Typography variant="h5" width="100%" my={4} textAlign="center">
          No Data Packages found
        </Typography>
      ) : (
        <CraftPageSection title="Data Packages">
          {standardDataPackages?.length ? (
            <DataPackageSwitchInputGroup
              kind={DataPackageKind.STANDARD}
              dataPackages={standardDataPackages}
              defaultDataPackageIdSet={defaultDataPackageIdSet}
            />
          ) : null}
          {customDataPackages?.length ? (
            <DataPackageSwitchInputGroup
              kind={DataPackageKind.CUSTOM}
              dataPackages={customDataPackages}
              defaultDataPackageIdSet={defaultDataPackageIdSet}
            />
          ) : null}
        </CraftPageSection>
      )}
    </FormTab>
  );
};

export default DataPackagesTab;
