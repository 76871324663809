import {
  TextField,
  TextInput,
  DateInput,
  SelectInput,
  SelectField,
  ReferenceInput,
  AutocompleteInput,
  ArrayField,
  SingleFieldList,
  FunctionField,
  SortPayload,
  Labeled,
  SearchInput,
  DateField,
  WrapperField,
  ReferenceField,
  ReferenceManyField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  EditButton,
  BulkUpdateButton,
  UpdateButton,
} from 'react-admin';
import { Chip, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { Center } from 'src/components/Center';
import { CraftList, useCraftList } from 'src/components/CraftList';
import { dateParser } from 'src/utils/dateUtil';
import { VALIDATION_STATES, PUBLICATION_STATES } from 'src/utils/defaults/Constants';
import { WrapperStackField } from 'src/fields/WrapperStackField';
import LabeledUrlField from 'src/fields/LabeledUrlField';
import { optionText, queryFilter } from '../topicsMetadata/TopicMetadataDisplayModel';
import { TopicMetadataField } from '../topicsMetadata/TopicMetadataField';
import { usePermissions } from 'src/hooks';
import { PUBLISH_SIGNAL_PROPS, UNPUBLISH_SIGNAL_PROPS } from './SignalsEnums';

const defaultSort: SortPayload = {
  field: 'date',
  order: 'DESC',
};

const shorterField = {
  width: '8rem',
};

const longerField = {
  minWidth: '12rem',
};

const columnStyles = {
  signal_title: {
    minWidth: '22rem',
  },
  state: {
    minWidth: '22rem',
  },
  source_url: {
    width: '20rem',
    wordBreak: 'break-all',
  },
  dataset: {
    verticalAlign: 'text-top',
  },
};

const SingleFieldListEmpty = <>-</>;

const SignalsListBulkActions = () => (
  <>
    <BulkUpdateButton {...PUBLISH_SIGNAL_PROPS} />
    <BulkUpdateButton {...UNPUBLISH_SIGNAL_PROPS} />
  </>
);

export const SignalsList = () => {
  const list = useCraftList();
  const { permissions } = usePermissions();

  const canEdit = permissions.has('alerts:edit');

  const filters = [
    <Tooltip
      key="search"
      title="Search by signal text or signal title"
      // @ts-expect-error defined/expected by react-admin's filter toolbar
      alwaysOn
      placement="bottom-start"
    >
      <SearchInput source="signal_text@_ilike,signal_title@_ilike" />
    </Tooltip>,
    <TextInput key="idFilter" label="ID" source="id@_eq" alwaysOn sx={shorterField} resettable />,
    <DateInput key="dateFrom" label="Newer than" source="date@_gte" alwaysOn parse={dateParser} />,
    <DateInput key="dateTo" label="Older than" source="date@_lte" alwaysOn parse={dateParser} />,
    <SelectInput
      key="validationState"
      label="Validation State"
      source="validation_state@_eq"
      alwaysOn
      sx={longerField}
      choices={VALIDATION_STATES}
      resettable
    />,
    <SelectInput
      key="publicationState"
      label="Publication State"
      source="publication_state@_eq"
      alwaysOn
      choices={PUBLICATION_STATES}
      sx={longerField}
      resettable
    />,
    <Center
      // @ts-expect-error defined/expected by react-admin's filter toolbar
      alwaysOn
      key="topicSearchDisclaimer"
      sx={{
        alignSelf: 'center',
        minHeight: 40,
        mb: 1,
        mr: -1,
        px: 1,
        backgroundColor: 'background.default',
        borderRadius: 1,
        cursor: 'help',
      }}
    >
      <Tooltip
        title={
          <>
            <Typography variant="body1" fontWeight="bolder">
              Topics on Signals
            </Typography>
            <Typography variant="caption">
              Topics do not exist for the records in this data source. This filter tries to emulate topics for
              convenience purposes.
            </Typography>
            <ul>
              <li>
                <Typography variant="caption">
                  Filtering only works for root-level fields, marked as &quot;primary&quot; in the table
                </Typography>
              </li>
              <li>
                <Typography variant="caption">
                  When multiple topics are used, all are combined with a logical &quot;AND&quot;
                </Typography>
              </li>
              <li>
                <Typography variant="caption">
                  Filter will only suggest topics from signals that have been previously published
                </Typography>
              </li>
              <li>
                <Typography variant="caption">
                  You can use topic names or IDs for the lookup. I.e. both &quot;News&quot; and &quot;dataset:news&quot;
                  will work. The latter will help targeting specific topics, you can lookup topic IDs from the
                  &quot;Topics Metadata&quot; page in the sidebar.
                </Typography>
              </li>
            </ul>
          </>
        }
      >
        <InfoIcon color="disabled" />
      </Tooltip>
    </Center>,
    <ReferenceArrayInput
      key="topicsFilter"
      reference="topics_metadata"
      // This field is specially recognised by
      // the "signals" resource params transform
      source="signal_topic"
      alwaysOn
      perPage={25}
      // We're using "derived" topics, so using wildcards
      // doesn't make sense here, as root signal columns
      // will never have wildcard values to match against these topics.
      // i.e. signal won't have  { company_id: "*" } even if it has a topic "company:*",
      // filtering by "company:*" only possible after topics are computed for a published signal.
      filter={{ 'topic@_nlike': '%*' }}
      sort={{ field: 'topic', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        label="Topic"
        source="topic"
        optionValue="topic"
        optionText={optionText}
        filterToQuery={queryFilter}
        sx={{
          minWidth: '15rem',
        }}
      />
    </ReferenceArrayInput>,
    <ReferenceInput
      key="clientsFilter"
      reference="signals_clients"
      source="clients@_contains"
      alwaysOn
      sort={{ field: 'client', order: 'ASC' }}
      sx={longerField}
      resettable
    >
      <AutocompleteInput
        label="Client"
        optionText="client"
        source="client"
        filterToQuery={(q: string) => ({ 'client@_ilike': q })}
        sx={longerField}
      />
    </ReferenceInput>,
  ];

  return (
    <CraftList
      ref={list}
      title="Signals"
      filters={filters}
      sort={defaultSort}
      columnStyles={columnStyles}
      dataGridProps={{
        empty: <Center sx={{ p: 2 }}>No signals found</Center>,
        sx: {
          '.RaDatagrid-rowCell': { verticalAlign: 'text-top' },
        },
        bulkActionButtons: <SignalsListBulkActions />,
      }}
      paginationProps={{
        rowsPerPageOptions: [5, 10, 25, 50, 100],
      }}
    >
      <TextField source="id" label="ID" />
      <TextField label="Title" source="signal_title" variant="body2" />
      <WrapperField label="Source" sortBy="source_url@nulls_last">
        <LabeledUrlField label={LabeledUrlField.FieldLabels.TRUNCATED_HREF} source="source_url" target="_blank" />
      </WrapperField>

      <WrapperField label="Dataset" source="dataset">
        <ReferenceField
          label="Dataset"
          reference="topics_metadata"
          source="derived_topics_map.dataset.primary"
          emptyText="-"
        >
          <TopicMetadataField showType={false} />
        </ReferenceField>
      </WrapperField>
      <WrapperStackField label="Classes" sortBy="signal_class@nulls_last" stackProps={{ direction: 'column' }}>
        <Labeled label="Primary">
          <ReferenceField
            label="Primary"
            reference="topics_metadata"
            source="derived_topics_map.class.primary"
            emptyText="-"
          >
            <TopicMetadataField showType={false} />
          </ReferenceField>
        </Labeled>
        <Labeled label="Secondary">
          <ReferenceManyField
            label="Classes"
            reference="topics_metadata"
            source="derived_topics_map.class.secondary"
            target="id@_in"
            emptyText="-"
          >
            <SingleFieldList linkType={false} empty={SingleFieldListEmpty}>
              <TopicMetadataField showType={false} />
            </SingleFieldList>
          </ReferenceManyField>
        </Labeled>
      </WrapperStackField>
      <WrapperStackField label="Companies" sortBy="company_id@nulls_last" stackProps={{ direction: 'column' }}>
        <Labeled label="Primary">
          <ReferenceField
            label="Primary company"
            reference="topics_metadata"
            source="derived_topics_map.company.primary"
            emptyText="-"
          >
            <TopicMetadataField showType={false} />
          </ReferenceField>
        </Labeled>
        <Labeled label="Secondary">
          <ReferenceManyField
            label="Classes"
            reference="topics_metadata"
            source="derived_topics_map.company.secondary"
            target="id@_in"
            emptyText="-"
          >
            <SingleFieldList linkType={false} empty={SingleFieldListEmpty}>
              <TopicMetadataField showType={false} />
            </SingleFieldList>
          </ReferenceManyField>
        </Labeled>
      </WrapperStackField>
      <WrapperStackField label="Commodities" sortBy="resource_id@nulls_last" stackProps={{ direction: 'column' }}>
        <Labeled label="Primary">
          <ReferenceField
            label="Primary commodity"
            reference="topics_metadata"
            source="derived_topics_map.resource.primary"
            emptyText="-"
          >
            <TopicMetadataField showType={false} />
          </ReferenceField>
        </Labeled>
        <Labeled label="Secondary">
          <ReferenceManyField
            label="Secondary commodities"
            reference="topics_metadata"
            source="derived_topics_map.resource.secondary"
            target="id@_in"
            emptyText="-"
          >
            <SingleFieldList linkType={false} empty={SingleFieldListEmpty}>
              <TopicMetadataField showType={false} />
            </SingleFieldList>
          </ReferenceManyField>
        </Labeled>
      </WrapperStackField>

      <ReferenceManyField
        label="Locations"
        reference="topics_metadata"
        source="derived_topics_map.location.secondary"
        target="id@_in"
        emptyText="-"
        sortable={false}
      >
        <SingleFieldList linkType={false} empty={SingleFieldListEmpty}>
          <TopicMetadataField />
        </SingleFieldList>
      </ReferenceManyField>

      <ArrayField source="clients" emptyText="-" sortBy="clients@nulls_last">
        <SingleFieldList linkType={false} sx={{ gap: 1 }} empty={SingleFieldListEmpty}>
          <FunctionField
            render={(record: string) => (
              <Chip
                label={record}
                onClick={() => {
                  setListFilters(list, { 'clients@_contains': record });
                }}
              />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      <WrapperStackField label="State" source="state" sortBy="publicated_at@nulls_last">
        <Labeled label="Validation / By / Date">
          <WrapperStackField source="validation_state" stackProps={{ direction: 'row' }}>
            <SelectField source="validation_state" choices={VALIDATION_STATES} />
            <span>/</span>
            <TextField source="validated_by" label="Validated By" emptyText="-" />
            <span>/</span>
            <DateField source="validated_at" label="Validated At" emptyText="-" showDate showTime />
          </WrapperStackField>
        </Labeled>
        <Labeled label="Publication / By / Date">
          <WrapperStackField source="validation_state" stackProps={{ direction: 'row' }}>
            <SelectField source="publication_state" label="Publication State" choices={PUBLICATION_STATES} />
            <span>/</span>
            <TextField source="publicated_by" label="Published By" emptyText="-" />
            <span>/</span>
            <DateField source="publicated_at" label="Published At" emptyText="-" showDate showTime />
          </WrapperStackField>
        </Labeled>
      </WrapperStackField>
      <DateField source="date" label="Date" emptyText="-" showDate showTime />
      <DateField source="signal_created_at" label="Created At" emptyText="-" showDate showTime />
      {canEdit && (
        <WrapperField label="Actions">
          <EditButton />
          <UpdateButton {...PUBLISH_SIGNAL_PROPS} />
          <UpdateButton {...UNPUBLISH_SIGNAL_PROPS} />
        </WrapperField>
      )}
    </CraftList>
  );
};

export default SignalsList;

function setListFilters(listRef: ReturnType<typeof useCraftList>, filterData: Record<string, unknown>) {
  listRef.current?.setFilters(
    {
      ...listRef.current.filterValues,
      ...filterData,
    },
    listRef.current.displayedFilters,
  );
}
