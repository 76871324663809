import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { DocumentNode, IntrospectionField } from 'graphql';
import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'ra-core';
import type { FetchType, IntrospectionResult, IntrospectedResource } from 'ra-data-hasura/dist/types';

import { CraftAdminUserPermissions } from 'src/types';

export type ReactAdminQueryResult =
  | GetListResult
  | GetOneResult
  | GetManyResult
  | GetManyReferenceResult
  | UpdateResult
  | UpdateManyResult
  | CreateResult
  | DeleteResult
  | DeleteManyResult;

export type ReactAdminQueryParams =
  | GetListParams
  | GetOneParams
  | GetManyParams
  | GetManyReferenceParams
  | UpdateParams
  | UpdateManyParams
  | CreateParams
  | DeleteParams
  | DeleteManyParams;

/**
 * Enumerates all React Admin Operations with respective params types.
 * This is useful to avoid additional type casting when dealing with
 * arbitrary operations. Narrowing could be done by checking the "type" field.
 */
export type ReactAdminOperation =
  | {
      type: FetchType.GET_LIST;
      params: GetListParams;
    }
  | {
      type: FetchType.GET_ONE;
      params: GetOneParams;
    }
  | {
      type: FetchType.GET_MANY;
      params: GetManyParams;
    }
  | {
      type: FetchType.GET_MANY_REFERENCE;
      params: GetManyReferenceParams;
    }
  | {
      type: FetchType.UPDATE;
      params: UpdateParams;
    }
  | {
      type: FetchType.UPDATE_MANY;
      params: UpdateManyParams;
    }
  | {
      type: FetchType.CREATE;
      params: CreateParams;
    }
  | {
      type: FetchType.DELETE;
      params: DeleteParams;
    }
  | {
      type: FetchType.DELETE_MANY;
      params: DeleteManyParams;
    };

/**
 * Query context that is passed to all the factories used
 * to construct a GraphQL query from React Admin operation.
 */
export type BuildQueryContext = {
  /**
   * Outlines the permissions of a User running current operation.
   */
  permissions: CraftAdminUserPermissions;

  /**
   * Contains the introspection response for the API, including
   * GraphQL types, queries, mutations and the GraphQL Schema of the API.
   */
  introspectionResults: IntrospectionResult;

  /**
   * Specific GraphQL resource that is being queried right now.
   * Contains a map of React Admin operations (GET_LIST, GET_ONE, etc.)
   * to the respective introspected API query or mutation schema.
   */
  resource: IntrospectedResource;

  /**
   * Contains GraphQL query or mutation schema for the operation
   * React Admin is trying to perform right now.
   */
  queryType: IntrospectionField;

  /**
   * Contains the React Admin operation type and parameters.
   * Use "type" field to narrow the "params" type to avoid type casting.
   */
  operation: ReactAdminOperation;

  /**
   * Will contain query variables that will be sent to the API with
   * the query.
   */
  variables?: OperationVariables;
};

/**
 * A version of the {@link BuildQueryContext} that
 * has the "variables" field populated.
 */
export type BuildQueryContextWithVariables = BuildQueryContext & {
  variables: OperationVariables;
};

export type VariablesFactory = (ctx: BuildQueryContext) => Record<string, unknown>;
export type QueryFactory = (ctx: BuildQueryContextWithVariables) => DocumentNode;
export type ResponseParser = (response: ApolloQueryResult<unknown>) => ReactAdminQueryResult;
export type ResponseFactory = (ctx: BuildQueryContextWithVariables) => ResponseParser;

export type BuildQuery = (
  permissions: CraftAdminUserPermissions,
  introspectionResults: IntrospectionResult,
) => (
  aorFetchType: FetchType,
  resourceName: string,
  params: ReactAdminQueryParams,
) => {
  query: DocumentNode;
  variables: Record<string, unknown>;
  parseResponse: (res: ApolloQueryResult<unknown>) => ReactAdminQueryResult;
};

export type BuildQueryFactory = (
  buildVariablesImpl: VariablesFactory,
  buildGqlQueryImpl: QueryFactory,
  getResponseParserImpl: ResponseFactory,
) => BuildQuery;

/**
 * Custom implementation of the "buildQueryFactory" function from "ra-data-hasura" package.
 * The differences are:
 * - Using context pattern, so that all the factories can access more information about the query
 * - Stricter types
 */
export const buildQueryFactory: BuildQueryFactory =
  (buildVariablesImpl, buildGqlQueryImpl, getResponseParserImpl) => (permissions, introspectionResults) => {
    const knownResources = introspectionResults.resources.map((r) => r.type.name);

    return (aorFetchType, resourceName, params) => {
      const resource = introspectionResults.resources.find((r) => r.type.name === resourceName);

      if (!resource) {
        if (knownResources.length) {
          throw new Error(`Unknown resource ${resourceName}. Known resources are ${knownResources.join(', ')}`);
        } else {
          throw new Error(`Unknown resource ${resourceName}. No resources were found.`);
        }
      }

      const queryType = resource[aorFetchType];

      if (!queryType) {
        throw new Error(
          `No query or mutation matching fetch type ${aorFetchType} could be found for resource ${resource.type.name}`,
        );
      }

      const ctx = {
        permissions,
        resource,
        aorFetchType,
        queryType,
        introspectionResults,
        operation: {
          type: aorFetchType,
          params,
        },
      } as BuildQueryContext;

      const variables = buildVariablesImpl(ctx);
      ctx.variables = variables;

      const query = buildGqlQueryImpl(ctx as BuildQueryContextWithVariables);
      const parseResponse = getResponseParserImpl(ctx as BuildQueryContextWithVariables);

      return {
        query,
        variables,
        parseResponse,
      };
    };
  };
