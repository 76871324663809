import { Box, Card } from '@mui/material';
import { DateField, Labeled } from 'react-admin';
import { CraftTwoColumns } from 'src/components/CraftTwoColumns';

export const EditAside = () => (
  <Box ml={2} minWidth="25rem">
    <Card sx={{ p: 2 }}>
      <CraftTwoColumns
        left={
          <>
            <Box>
              <Labeled label="Created">
                <DateField showTime source="created_at" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Deleted">
                <DateField showTime source="archived_at" emptyText="N/A" />
              </Labeled>
            </Box>
          </>
        }
        right={
          <Box>
            <Labeled label="Updated">
              <DateField showTime source="updated_at" />
            </Labeled>
          </Box>
        }
      />
    </Card>
  </Box>
);

export default EditAside;
