import { useState } from 'react';
import { CraftCreate } from 'src/components/CraftCreate';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { SimpleForm, TextInput, useNotify, useRedirect, useGetOne } from 'react-admin';
import { companyCommodityListRoute } from 'src/utils/routeHelpers';
import { SharedInputs } from './SharedInputs';
import { transformValues } from './transformValues';
import { useLocation } from 'react-router-dom';
import { CommodityChoices } from './types';

export const CompanyCommoditiesCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const location = useLocation();
  const [publishedCommodities, setPublishedCommodities] = useState<CommodityChoices>([]);

  const { orgId } = location.state ?? {};

  const onSuccess = (data: { org_id: string | number }) => {
    notify('Company Commodity created', { type: 'success' });
    redirect(companyCommodityListRoute(data.org_id));
  };

  const { data = {}, isLoading, error } = useGetOne('organizations', { id: orgId });
  // Don't allow company commodities to be created for organizations that don't exist
  if (Number.isNaN(parseInt(orgId, 10)) || error || (!isLoading && !data)) {
    notify('Unable to find organization. A valid organization is required to create a company commodity.', {
      type: 'error',
    });
    redirect('/organizations');
  }

  return (
    <CraftCreate mutationOptions={{ onSuccess }} transform={transformValues}>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />}>
        <h2>Add a company commodity</h2>
        <TextInput source="org_id" label="Organization" fullWidth defaultValue={orgId} disabled required />
        <SharedInputs publishedCommodities={publishedCommodities} setPublishedCommodities={setPublishedCommodities} />
      </SimpleForm>
    </CraftCreate>
  );
};

export default CompanyCommoditiesCreate;
