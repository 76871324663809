import {
  TextField,
  TextInput,
  WrapperField,
  EditButton,
  TopToolbar,
  CreateButton,
  FunctionField,
  ShowButton,
  SelectInput,
  NullableBooleanInput,
  SelectField,
} from 'react-admin';

import ClearFiltersButton from 'src/components/ClearFiltersButton';
import CraftList from 'src/components/CraftList';
import AuditBasedStatusFilterInput from 'src/inputs/AuditBasedStatusFilterInput';
import BulkSoftDeleteButton from 'src/components/BulkSoftDeleteButton';
import { usePermissions } from 'src/hooks';
import { DataPackageKind, DataPackageRecord } from 'src/types';
import { DATA_PACKAGE_KINDS } from 'src/utils/defaults/Constants';

const filters = [
  <TextInput key="nameFilter" source="name" alwaysOn />,
  <AuditBasedStatusFilterInput key="statusFilter" source="deleted_at@_is_null" alwaysOn />,
  <SelectInput
    key="kindFilter"
    source="kind"
    choices={DATA_PACKAGE_KINDS}
    emptyText="All"
    emptyValue="all"
    parse={(val) => (val === 'all' ? undefined : val)}
    format={(val) => (!val ? 'all' : val)}
    alwaysOn
  />,
  <NullableBooleanInput key="isDeprecatedFilter" source="is_deprecated" label="Deprecated" nullLabel="None" alwaysOn />,
];

const ListActions = ({ canCreate }: { canCreate: boolean }) => (
  <TopToolbar>
    <ClearFiltersButton />
    {canCreate && <CreateButton />}
  </TopToolbar>
);

export const DataPackagesList = () => {
  const { permissions } = usePermissions();

  const canEdit = permissions.has('dataPackage:edit');
  const canDelete = permissions.has('dataPackage:delete');

  const bulkActionButtons = canDelete && (
    <BulkSoftDeleteButton label="Delete Selected" data={{ deleted_at: new Date().toISOString() }} />
  );

  return (
    <CraftList
      title="Data Packages"
      filters={filters}
      filterDefaultValues={{
        'deleted_at@_is_null': true,
        kind: DataPackageKind.STANDARD,
        is_deprecated: false,
      }}
      actions={<ListActions canCreate={canEdit} />}
      showClearButton={false}
      deletePermission="dataPackage:delete"
      dataGridProps={{
        bulkActionButtons,
      }}
    >
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <SelectField source="kind" choices={DATA_PACKAGE_KINDS} />
      <WrapperField label="Actions">
        <FunctionField
          render={(rec: DataPackageRecord) =>
            !canEdit || rec.kind === DataPackageKind.INTERNAL ? (
              <ShowButton />
            ) : (
              <EditButton disabled={!!rec.deleted_at} />
            )
          }
        />
      </WrapperField>
    </CraftList>
  );
};

export default DataPackagesList;
