import { Box, Card } from '@mui/material';
import { Labeled, TextField } from 'react-admin';
import { CraftTwoColumns } from 'src/components/CraftTwoColumns';
import { AuditEntriesEmbeddedList } from '../auditEntries/EmbeddedList';

export const UsersEditAside = () => (
  <Box ml={2} minWidth="25rem">
    <Card sx={{ p: 2 }}>
      <CraftTwoColumns
        left={
          <>
            <Labeled label="Created" fullWidth>
              <TextField source="created_at" />
            </Labeled>
            <Labeled label="Updated">
              <TextField source="updated_at" />
            </Labeled>
          </>
        }
        right={
          <>
            <Labeled label="Logins" fullWidth>
              <TextField source="logins_count" />
            </Labeled>
            <Labeled label="Last login">
              <TextField source="last_login" />
            </Labeled>
          </>
        }
      />
    </Card>
    <h3>Audit Entries</h3>
    <AuditEntriesEmbeddedList from="meta@_contains@actor.id" />
  </Box>
);

export default UsersEditAside;
