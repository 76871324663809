import CraftEdit from 'src/components/CraftEdit';
import EditAside from './EditAside';
import DetailsTab from '../formTabs/Details';
import SubscriptionsTab from '../formTabs/Subscriptions';
import FieldsTab from '../formTabs/Fields';
import FeaturesTab from '../formTabs/Features';
import RisksTab from '../formTabs/Risks';
import EditExternalApiClientsTabbedForm from './EditExternalApiClientsTabbedForm';

export const ExternalApiClientsEdit = () => (
  <CraftEdit header="Edit External API Client" aside={<EditAside />}>
    <EditExternalApiClientsTabbedForm>
      <DetailsTab mode="edit" />
      <SubscriptionsTab />
      <FieldsTab />
      <FeaturesTab />
      <RisksTab />
    </EditExternalApiClientsTabbedForm>
  </CraftEdit>
);

export default ExternalApiClientsEdit;
