import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormTab, TextField, FunctionField, WithRecord, FormTabProps, TextInput, DateField } from 'react-admin';
import { Center } from 'src/components/Center';

import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftStandaloneList from 'src/components/CraftStandaloneList';
import { usePermissions } from 'src/hooks';
import { UserReferenceField } from '../../users/UserReferenceField';
import AuditBasedStatusFilterInput from 'src/inputs/AuditBasedStatusFilterInput';

const userListFilters = [
  <TextInput key="search" label="Name/email" source="email,name" alwaysOn />,
  <AuditBasedStatusFilterInput key="status" source="deleted_at@_is_null" alwaysOn />,
];

const UsersListViewTab = (props: Omit<FormTabProps, 'label'>) => {
  const { permissions } = usePermissions();

  if (!permissions.has('organizationUser:read')) {
    return null;
  }

  return (
    <FormTab label="Users" {...props}>
      <WithRecord
        render={(record) => (
          <CraftPageSection title="Organization Users">
            <CraftStandaloneList
              resource="users"
              filters={userListFilters}
              filter={{ 'roles#organization_id': record.id }}
              perPage={10}
              dataGridProps={{
                bulkActionButtons: false,
                empty: <Center sx={{ marginTop: '2rem', marginBottom: '4rem' }}>No users found</Center>,
              }}
            >
              <UserReferenceField source="id" />
              <TextField source="email" label="Email" />
              <DateField source="created_at" label="Created at" showTime />
              <FunctionField
                sx={{ display: 'flex', justifyContent: 'center' }}
                render={(fieldRecord: Record<string, boolean>) =>
                  fieldRecord.verified ? <CheckCircleIcon /> : <CancelIcon />
                }
                label="Verified"
              />
              <TextField source="logins_count" label="Login Counts" />
            </CraftStandaloneList>
          </CraftPageSection>
        )}
      />
    </FormTab>
  );
};

export default UsersListViewTab;
