import { ReactNode } from 'react';
import { Box, Card, Grid } from '@mui/material';
import { EditBase, EditProps } from 'react-admin';
import { CraftPageHeader } from './CraftPageHeader';

/**
 * Default layout for the "Edit" pages,
 * have support for a custom header and aside components.
 */
export const CraftEdit = ({
  aside,
  header,
  children,
  ...rest
}: EditProps & { header?: ReactNode; aside?: ReactNode; children: ReactNode }) => (
  <EditBase {...rest}>
    {header && <CraftPageHeader>{header}</CraftPageHeader>}
    {/* A bit dirty, but cheaper than conditional properties */}
    {!header && <Box m={2} />}
    <Box mx={2}>
      <Grid container mr={2}>
        <Grid item md={aside ? 8 : 12}>
          <Card>{children}</Card>
        </Grid>
        {aside && (
          <Grid item md={4}>
            {aside}
          </Grid>
        )}
      </Grid>
    </Box>
  </EditBase>
);

export default CraftEdit;
