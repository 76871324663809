import { CREATE, GET_LIST, GET_ONE, GET_MANY, UPDATE, DELETE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import CompanyCommoditiesCreate from './Create';
import CompanyCommoditiesEdit from './Edit';

export const CompanyCommoditiesResource: ResourceDefinition = {
  name: 'company_commodities',
  section: ResourceSections.ALERTS,

  create: CompanyCommoditiesCreate,
  edit: CompanyCommoditiesEdit,
  permissions: {
    create: ['alerts:edit'],
    edit: ['alerts:edit'],
  },

  extension: {
    operations: {
      [CREATE]: {
        operationName: 'signals_insert_company_commodities',
      },
      [GET_LIST]: {
        operationName: 'signals_company_commodities',
      },
      [GET_ONE]: {
        operationName: 'signals_company_commodities',
      },
      [GET_MANY]: {
        operationName: 'signals_company_commodities',
      },
      [UPDATE]: {
        operationName: 'signals_update_company_commodities',
      },
      [DELETE]: {
        operationName: 'signals_delete_company_commodities',
      },
    },
  },
};
