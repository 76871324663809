export const PREFIX = 'craftAdmin:';

export default {
  getItem,
  setItem,
  removeItem,
  clear,
  keys,
};

export function getItem(key: string) {
  return localStorage.getItem(PREFIX + key);
}

export function setItem(key: string, value: string) {
  return localStorage.setItem(PREFIX + key, value);
}

export function removeItem(key: string) {
  return localStorage.removeItem(PREFIX + key);
}

export function keys() {
  const size = localStorage.length;
  return Array(size)
    .fill(0)
    .map((_, i) => localStorage.key(i));
}

export function clear(global = false): void {
  if (global) {
    localStorage.clear();
  }

  keys().forEach((key) => {
    if (key && key.startsWith(PREFIX)) {
      localStorage.removeItem(key);
    }
  });
}
