import { RaRecord, useRecordContext } from 'react-admin';

export const resolveUserName = (user: RaRecord) => {
  const { firstName, lastName, email, name } = user || {};
  const displayName = [firstName, lastName].filter((n) => !!n);

  return displayName.length ? displayName.join(' ') : name ?? email ?? 'Someone';
};

export const UserNameField = () => {
  const user = useRecordContext();
  return resolveUserName(user);
};
