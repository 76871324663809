import React, { useRef, FC } from 'react';
import { Button } from 'react-admin';
import { AddRounded as AddRoundedIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, FormHelperText } from '@mui/material';
import { CompanyCommodityType } from './types';
import { makeStyles } from '@mui/styles';

type DeleteRow = (index: number) => void;

type CompanyCommoditiesTableProps = {
  setRecords: React.Dispatch<React.SetStateAction<CompanyCommodityType[]>>;
  records: CompanyCommodityType[];
  setInvalidMessage: React.Dispatch<React.SetStateAction<string>>;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  showError: boolean;
};

type ValidateCompanyCommodities = () => string | undefined;

type TableRowProps = {
  sx: {
    display?: string;
    gridTemplateColumns?: string;
    gap?: string;
  };
  id: string;
  children: React.ReactNode;
};

const useStyles = makeStyles({
  companyCommodityInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    width: '100%',
    height: '2.5rem',
    padding: '0px 1rem',
    borderRadius: '4px',
    fontFamily: 'Gotham SSm A, Gotham SSm B, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    color: '#282828',
    outline: 'none',
    boxShadow: '0px 4px 20px 0px transparent',
    transition: '0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out',
    WebkitAppearance: 'none',
    '&:hover': {
      borderColor: '#555866',
    },
    '&:focus': {
      borderColor: '#46305d',
      borderWidth: '2px',
      outline: 'none',
    },
  },
});

const TableRowTyped: React.FC<TableRowProps> = ({ sx, id, children }) => (
  <TableRow sx={sx} id={id}>
    {children}
  </TableRow>
);

export const getUpdateRecords = () => {
  const companyIdInputs = document.querySelectorAll('.company-id-input') as NodeListOf<HTMLInputElement>;
  const commodityIdInputs = document.querySelectorAll('.commodity-id-input') as NodeListOf<HTMLInputElement>;

  const companyIds = Array.from(companyIdInputs).map((input) => input.value || '');
  const commodityIds = Array.from(commodityIdInputs).map((input) => input.value || '');
  return companyIds.map((companyId, index) => ({
    company_id: companyId,
    commodity_id: commodityIds[index],
  }));
};

export const showErrorMessage = (message: string, index: number, className: string, errorClassName: string) => {
  const commodityInputs = document.querySelectorAll(className) as NodeListOf<HTMLInputElement>;
  const currentElement = commodityInputs?.[index];
  if (currentElement) {
    const parent = currentElement.parentElement;

    let errorElement = parent?.nextElementSibling;
    while (errorElement && !errorElement.classList.contains(errorClassName)) {
      errorElement = errorElement.nextElementSibling;
    }

    if (errorElement) {
      errorElement.textContent = message;
      const wrapperElement = document.getElementById(`company-commodities-table-row-${index}`);
      wrapperElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

export const validateCompanyCommodities: ValidateCompanyCommodities = () => {
  const records = getUpdateRecords();
  let errorMessage;
  for (const [index, record] of records.entries()) {
    const { company_id: companyId, commodity_id: commodityId } = record;

    const normalizedCompanyId = companyId?.trim();
    const normalizedCommodityId = commodityId?.trim();

    if (!normalizedCompanyId) {
      errorMessage = 'company_id is required';
      showErrorMessage(errorMessage, index, '.company-id-input', 'company-id-error');
      break;
    }
    if (!normalizedCommodityId) {
      errorMessage = 'commodity_id is required';
      showErrorMessage(errorMessage, index, '.commodity-id-input', 'commodity-id-error');
      break;
    }
    if (!/^\d+$/.test(normalizedCompanyId)) {
      errorMessage = 'company_id must be a number';
      showErrorMessage(errorMessage, index, '.company-id-input', 'company-id-error');
      break;
    }
    if (!/^[a-z\d\s_]+$/i.test(normalizedCommodityId)) {
      errorMessage = 'commodity_id must contain only numbers, spaces, and underscores';
      showErrorMessage(errorMessage, index, '.commodity-id-input', 'commodity-id-error');
      break;
    }
  }
  return errorMessage;
};

export const resetErrorMessages = () => {
  const companyIdInputs = document.querySelectorAll('.company-id-error');
  companyIdInputs.forEach((element) => {
    const localElement = element;
    localElement.textContent = '';
  });
  const commodityIdInputs = document.querySelectorAll('.commodity-id-error');
  commodityIdInputs.forEach((element) => {
    const localElement = element;
    localElement.textContent = '';
  });
};

export const CompanyCommoditiesTable: FC<CompanyCommoditiesTableProps> = ({
  setRecords,
  records,
  setInvalidMessage,
  setShowError,
  showError,
}) => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const classes = useStyles();

  const addRow = () => {
    setInvalidMessage('');
    setShowError(false);
    const updatedRecords = getUpdateRecords();
    setRecords(() => [...updatedRecords, { company_id: '', commodity_id: '' }]);
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const deleteRow: DeleteRow = (index: number) => {
    setInvalidMessage('');
    setShowError(false);
    const updateRecords = getUpdateRecords();
    setRecords(updateRecords.filter((_, i) => i !== index));
  };

  const recordsWithKey = records.map((record, index) => ({ ...record, key: `company_commodities_${index}` }));
  return (
    <Box key={records.length}>
      <Button onClick={addRow} label="Add Row">
        <AddRoundedIcon />
      </Button>
      <TableContainer
        component={Paper}
        sx={{
          border: showError ? '1px solid #d32f2f' : 'none',
          boxShadow: showError
            ? '0px 2px 1px -1px rgba(211,47,47,0.2), 0px 1px 1px 0px rgba(211,47,47,0.14), 0px 1px 3px 0px rgba(211,47,47,0.12)'
            : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        }}
      >
        <Table>
          <TableBody>
            {recordsWithKey.map((row, index) => (
              <TableRowTyped
                key={row.key}
                id={`company-commodities-table-row-${index}`}
                sx={{ display: 'grid !important', gridTemplateColumns: '2fr 1fr' }}
              >
                <TableCell
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px',
                  }}
                >
                  <Box>
                    <label htmlFor={`[${index}].company_id`}>
                      Company Id
                      <input
                        required
                        type="text"
                        name={`[${index}].company_id`}
                        id={`[${index}].company_id`}
                        defaultValue={row.company_id}
                        className={`company-id-input ${classes.companyCommodityInput}`}
                      />
                    </label>
                    <FormHelperText
                      className="company-id-error"
                      error
                      sx={{ marginTop: '0.25rem', marginLeft: '0.25rem' }}
                    />
                  </Box>
                  <Box>
                    <label htmlFor={`[${index}].commodity_id`}>
                      Commodity Id
                      <input
                        type="text"
                        required
                        id={`[${index}].commodity_id`}
                        name={`[${index}].commodity_id`}
                        defaultValue={row.commodity_id}
                        className={`commodity-id-input ${classes.companyCommodityInput}`}
                      />
                    </label>
                    <FormHelperText
                      className="commodity-id-error"
                      error
                      sx={{ marginTop: '0.25rem', marginLeft: '0.25rem' }}
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px',
                  }}
                >
                  <Button sx={{ marginBottom: '0.5rem' }} onClick={() => deleteRow(index)} label="Delete">
                    <DeleteForeverIcon />
                  </Button>
                </TableCell>
              </TableRowTyped>
            ))}
          </TableBody>
        </Table>
        <div ref={bottomRef} />
      </TableContainer>
    </Box>
  );
};

export default CompanyCommoditiesTable;
