import { ApolloClient, ApolloError, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import apolloLogger from 'apollo-link-logger';

import { config } from '../config/config.local';
import { getResources, getExtension } from 'src/resources';
import { restoreToken, storeToken, toApiAuth } from 'src/utils/auth';
import { isAuthOperationName } from 'src/authProvider';

const handleTokenRefresh: () => Promise<string> = async () => {
  const query = /* GraphQL */ `
    mutation refreshToken {
      refreshToken {
        token
      }
    }
  `;

  const res = await fetch(config.api.url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
    credentials: 'include',
  });
  const { data, errors } = await res.json();

  if (!data) throw errors;
  return data.refreshToken.token;
};

let refreshingToken = false;
/**
 * When called, if token not currently being refreshed, initiates token refresh. Otherwise, if token
 * refresh is in progress, waits for it to finish.
 */
const waitForTokenRefresh = (() => {
  let tokenRefreshPromise: ReturnType<typeof handleTokenRefresh> | null;

  return async () => {
    if (!tokenRefreshPromise) {
      tokenRefreshPromise = handleTokenRefresh();
      refreshingToken = true;

      const accessToken = await tokenRefreshPromise;

      storeToken(accessToken);
      tokenRefreshPromise = null;
      refreshingToken = false;
    } else {
      await tokenRefreshPromise;
    }

    return restoreToken();
  };
})();

const httpLink = createHttpLink({
  uri: config.api.url,
});

const authLink = setContext((operation, { headers = {} }) => {
  if (isAuthOperationName(operation.operationName)) return { headers };
  // get the authentication token from local storage if it exists
  const token = restoreToken();

  if (token) {
    Object.assign(headers, {
      authorization: toApiAuth(token),
    });
  }

  return {
    headers,
  };
});

const errorLink = onError(({ forward, graphQLErrors, operation }) => {
  const gqlError = graphQLErrors?.[0];

  if (gqlError?.extensions?.code === 'INVALID_JWT') {
    if (gqlError.extensions.reason === 'user_updated') return undefined;
    // indicate to refreshTokenLink that token should be refreshed...
    operation.setContext({ shouldRefreshToken: true });
    // ...and retry operation
    return forward(operation);
  }

  return undefined;
});

const refreshTokenLink = setContext(async (operation, { shouldRefreshToken, headers = {} }) => {
  // this ensures that if the user clicks initiates a logout while a token refresh is in progress
  // that the new tokens are waited on and properly disposed of
  if (operation.operationName === 'logout' && refreshingToken) await waitForTokenRefresh();

  if (!shouldRefreshToken) return { headers };

  try {
    const accessToken = await waitForTokenRefresh();
    if (accessToken) Object.assign(headers, { authorization: toApiAuth(accessToken) });
  } catch (e) {
    throw Array.isArray(e) ? new ApolloError({ graphQLErrors: e }) : e;
  }

  return { headers };
});

const clientLink = from([
  authLink,
  ...(config.app.env === 'local' ? [apolloLogger] : []),
  errorLink,
  refreshTokenLink,
  httpLink,
]);

export const client = new ApolloClient({
  cache: new InMemoryCache({
    // This ensures that Apollo Cache is aware that
    // certain GraphQL types won't have a native "id" field
    // on them and will use the same substitute as the extended resources.
    typePolicies: Object.fromEntries(
      (getResources ? getResources() : [])
        .map((resource) => {
          const { name } = resource;
          const extension = getExtension(name);

          if (extension && extension.idKey) {
            return [name, { keyFields: extension.idKey }];
          }

          return [];
        })
        .filter((i) => i.length > 0),
    ),
  }),
  link: clientLink,
  connectToDevTools: true,
});

export default client;
