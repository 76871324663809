import { FC } from 'react';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import KeyIcon from '@mui/icons-material/Key';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import BadgeIcon from '@mui/icons-material/Badge';
import FolderIcon from '@mui/icons-material/Folder';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import TopicIcon from '@mui/icons-material/Topic';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DataObjectIcon from '@mui/icons-material/DataObject';

export enum AUDIT_ENTRY_TYPES {
  createOrganization = 'organization:create',
  updateOrganization = 'organization:update',
  deleteOrganization = 'organization:delete',

  inviteUser = 'user:invite',
  resendInvite = 'user:invite:resend',
  deleteUser = 'user:delete',
  createUser = 'user:create',
  undeleteUser = 'user:undelete',
  updateUser = 'user:update',
  changeUserRole = 'user:role:change',
  changeUserTeams = 'user:team:change',

  createTeam = 'team:create',
  deleteTeam = 'team:delete',

  portfolioCreate = 'portfolio:create',
  portfolioUpdateRename = 'portfolio:update:rename',
  portfolioArchive = 'portfolio:archive',
  portfolioUnarchive = 'portfolio:unarchive',
  portfolioCompanyAdd = 'portfolio:company:add',
  portfolioCompanyRequestAdd = 'portfolio:companyRequest:add',
  portfolioCompanyRemove = 'portfolio:company:remove',
  portfolioCompanyRequestRemove = 'portfolio:companyRequest:remove',
  portfolioCompanyImport = 'portfolio:company:import',
  portfolioCompanyRequestImportBulk = 'portfolio:companyRequest:import:bulk',
  portfolioShare = 'portfolio:share',
  portfolioMove = 'portfolio:portfolio:move',
  portfolioCompanyMove = 'portfolio:company:move',
  portfolioCompanyRequestMove = 'portfolio:companyRequest:move',

  attachmentDownload = 'attachment:download',
  caseDownload = 'case:download',

  portalObjectGenerate = 'portal:object:generate',

  createSupplierCase = 'supplierCase:create',
  updateSupplierCase = 'supplierCase:update',
  deleteSupplierCase = 'supplierCase:delete',
  sendSupplierCaseEmail = 'supplierCase:email:send',

  createSupplierCaseTask = 'supplierCase:task:create',
  updateSupplierCaseTask = 'supplierCase:task:update',
  deleteSupplierCaseTask = 'supplierCase:task:delete',

  createCasesView = 'cases:view:create',
  deleteCasesView = 'cases:view:delete',
  updateCasesView = 'cases:view:update',
}

export const AUDIT_ENTRY_TYPE_LABELS: Record<AUDIT_ENTRY_TYPES | 'default', string> = {
  'organization:create': 'Organization created',
  'organization:update': 'Organization updated',
  'organization:delete': 'Organization deactivated',

  'user:invite': 'User invited',
  'user:invite:resend': 'User invite resent',
  'user:delete': 'User deactivated',
  'user:create': 'User created',
  'user:undelete': 'User reactivated',
  'user:update': 'User updated',

  'user:role:change': 'User role changed',
  'user:team:change': 'User team changed',

  'team:create': 'Team created',
  'team:delete': 'Team deleted',

  'portfolio:create': 'Portfolio created',
  'portfolio:update:rename': 'Portfolio renamed',
  'portfolio:archive': 'Portfolio archived',
  'portfolio:unarchive': 'Portfolio unarchived',
  'portfolio:company:add': 'Company added to portfolio',
  'portfolio:companyRequest:add': 'Company request added to portfolio',
  'portfolio:company:remove': 'Company removed from portfolio',
  'portfolio:companyRequest:remove': 'Company request removed from portfolio',
  'portfolio:company:import': 'Company imported to portfolio',
  'portfolio:companyRequest:import:bulk': 'Company request imported to portfolio',
  'portfolio:share': 'Portfolio shared',
  'portfolio:portfolio:move': 'Portfolio moved',
  'portfolio:company:move': 'Company moved',
  'portfolio:companyRequest:move': 'Company request moved',

  'attachment:download': 'Attachment downloaded',
  'case:download': 'Case downloaded',

  'portal:object:generate': 'Portal object generated',

  'supplierCase:create': 'Supplier case created',
  'supplierCase:update': 'Supplier case updated',
  'supplierCase:delete': 'Supplier case deleted',
  'supplierCase:email:send': 'Supplier case email sent',

  'supplierCase:task:create': 'Supplier case task created',
  'supplierCase:task:update': 'Supplier case task updated',
  'supplierCase:task:delete': 'Supplier case task deleted',

  'cases:view:create': 'Cases view created',
  'cases:view:delete': 'Cases view deleted',
  'cases:view:update': 'Cases view updated',

  default: 'Unrecognized action',
};

export const AUDIT_ENTRY_TYPE_OPTIONS = Object.entries(AUDIT_ENTRY_TYPE_LABELS).map(([value, label]) => ({
  id: value,
  name: label,
}));

export const AUDIT_ENTRY_TYPE_ICONS: Record<AUDIT_ENTRY_TYPES, FC> = {
  'user:invite': PersonAddIcon,
  'user:invite:resend': PersonAddIcon,
  'user:create': PersonAddIcon,
  'user:undelete': PersonAddIcon,

  'user:delete': PersonRemoveIcon,
  'user:update': BadgeIcon,

  'user:role:change': KeyIcon,
  'user:team:change': KeyIcon,

  'team:create': GroupAddIcon,
  'team:delete': GroupAddIcon,

  'portfolio:create': FolderIcon,
  'portfolio:update:rename': FolderIcon,
  'portfolio:archive': FolderIcon,
  'portfolio:unarchive': FolderIcon,
  'portfolio:company:add': FolderIcon,
  'portfolio:companyRequest:add': FolderIcon,
  'portfolio:company:remove': FolderIcon,
  'portfolio:companyRequest:remove': FolderIcon,
  'portfolio:company:import': FolderIcon,
  'portfolio:companyRequest:import:bulk': FolderIcon,
  'portfolio:share': FolderIcon,
  'portfolio:portfolio:move': FolderIcon,
  'portfolio:company:move': FolderIcon,
  'portfolio:companyRequest:move': FolderIcon,

  'attachment:download': CloudDownloadIcon,
  'case:download': CloudDownloadIcon,

  'organization:create': CorporateFareIcon,
  'organization:update': CorporateFareIcon,
  'organization:delete': CorporateFareIcon,

  'cases:view:create': TopicIcon,
  'cases:view:delete': TopicIcon,
  'cases:view:update': TopicIcon,
  'supplierCase:create': TopicIcon,
  'supplierCase:update': TopicIcon,
  'supplierCase:delete': TopicIcon,
  'supplierCase:email:send': ContactMailIcon,
  'supplierCase:task:create': TopicIcon,
  'supplierCase:task:update': TopicIcon,
  'supplierCase:task:delete': TopicIcon,

  'portal:object:generate': DataObjectIcon,
};

export const AUDIT_ENTRY_TYPE_ICON_OPTIONS = Object.entries(AUDIT_ENTRY_TYPE_ICONS).map(([value, Icon]) => ({
  id: value,
  name: <Icon />,
}));

export const displayType = (type?: AUDIT_ENTRY_TYPES) =>
  AUDIT_ENTRY_TYPE_LABELS[type ?? 'default'] || AUDIT_ENTRY_TYPE_LABELS.default;
