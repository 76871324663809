import { styled } from '@mui/material';
import { Confirm } from 'react-admin';

const ConfirmDialog = styled(Confirm)`
  & .MuiDialogTitle-root {
    color: ${({ theme }) => theme.palette.primary.main};
    border-bottom: 0.071rem solid rgba(0, 0, 0, 0.05);
  }
  & .MuiDialogContent-root {
    padding: 1rem 1.429rem !important;
    border-bottom: 0.071rem solid rgba(0, 0, 0, 0.05);
  }
`;

export default ConfirmDialog;
