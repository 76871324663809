import gql from 'graphql-tag';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import { HasuraComparisonExpression } from '../../../utils/HasuraTypes';
import PublishedAlertsList from './List';

export const PublishedAlertsResourceName = 'published_alerts' as const;

export const PublishedAlertsResource: ResourceDefinition = {
  name: PublishedAlertsResourceName,
  section: ResourceSections.ALERTS,
  icon: CircleNotificationsIcon,

  list: PublishedAlertsList,
  permissions: {
    list: ['alerts:read'],
  },

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'signals_published_alerts',
      },
      [GET_ONE]: {
        operationName: 'signals_published_alerts',
      },
      [GET_MANY]: {
        operationName: 'signals_published_alerts',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_published_alerts',
      },
    },
    extraFields: gql`
      {
        topics {
          topic
          metadata {
            topic
            metadata
          }
        }
      }
    `,
    transformVariables(ctx) {
      if (ctx.operation.type === 'GET_LIST') {
        const filters =
          ctx.variables.where._and.filter(
            (obj: HasuraComparisonExpression) => !Object.prototype.hasOwnProperty.call(obj, 'clients'),
          ) || [];

        ctx.variables.where._and = [
          ...filters,
          { clients: { _contains: ctx.operation.params?.filter?.['clients@_contains'] } },
        ];
      }

      return ctx;
    },
  },
};
