import { TransformData } from 'react-admin';
import CraftEdit from 'src/components/CraftEdit';
import UsersEditAside from './EditAside';
import EditAdminUsersTabbedForm from './EditAdminUsersTabbedForm';

const transformValues: TransformData = (values) => {
  const result = {
    ...values,
    updated_at: new Date().toISOString(),
  };

  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }
  return result;
};

export const UsersEdit = () => (
  <CraftEdit aside={<UsersEditAside />} transform={transformValues}>
    <EditAdminUsersTabbedForm />
  </CraftEdit>
);

export default UsersEdit;
