import React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import { APP_BAR_HEIGHT } from 'src/utils/defaults/Constants';

import { CraftSidebarContextProvider } from './CraftSidebarContext';
import CraftAdminSidebar from './CraftAdminSidebar';
import { CraftAdminMenu } from './CraftAdminMenu';
import CraftAppBar from './CraftAppBar';

/**
 * Customized layout for React Admin with
 * custom AppBar, Sidebar and Menu.
 */
const CraftAdminLayout = (props: LayoutProps) => (
  <CraftSidebarContextProvider>
    <Layout
      {...props}
      menu={CraftAdminMenu}
      appBar={CraftAppBar}
      sidebar={CraftAdminSidebar as React.FC}
      {...{
        sx: {
          '& .RaLayout-appFrame': {
            marginTop: APP_BAR_HEIGHT,
          },

          '& .RaLayout-content': {
            borderLeft: '1px solid #ddd',
            boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .1)',
          },
        },
      }}
    />
  </CraftSidebarContextProvider>
);

export default CraftAdminLayout;
