import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { GET_LIST, GET_ONE, GET_MANY, UPDATE, UPDATE_MANY } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import { HasuraComparisonExpression } from 'src/utils/HasuraTypes';
import { visitBoolExpression } from 'src/utils/hasura';
import { isLocationTopic, parseTopic } from '../topicsMetadata/TopicMetadataDisplayModel';
import { TopicTypes } from '../topicsMetadata/TopicMetadataTypes';
import SignalsList from './List';
import { SignalsEdit } from './Edit';
import { fromSignal, toSignal } from './derivedTopicsMap';

export const SignalsResourceName = 'signals' as const;

export const SignalsResource: ResourceDefinition = {
  name: SignalsResourceName,
  section: ResourceSections.ALERTS,
  icon: EditNotificationsIcon,

  list: SignalsList,
  edit: SignalsEdit,

  permissions: {
    list: ['alerts:read'],
    create: ['alerts:edit'],
    edit: ['alerts:edit'],
  },

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'signals_signals',
      },
      [GET_ONE]: {
        operationName: 'signals_signals',
      },
      [GET_MANY]: {
        operationName: 'signals_signals',
      },
      [UPDATE]: {
        operationName: 'signals_update_signals',
      },
      [UPDATE_MANY]: {
        operationName: 'signals_update_signals',
      },
    },
    transformParams(ctx) {
      if (ctx.operation.type === UPDATE) {
        const signal = ctx.operation.params.data;

        if (signal.derived_topics_map) {
          const update = toSignal(signal.derived_topics_map);

          Object.assign(signal, {
            ...update,
            payload: {
              ...signal.payload,
              ...update.payload,
            },
          });
        }
      }

      if ('filter' in ctx.operation.params) {
        const { filter } = ctx.operation.params;

        // "signals" resource does not have "topics" per se,
        // as they are created only when the signal gets published and
        // moved over to "published_alerts" and "api_alerts".
        // Here, we still allowing for topic-based filtering via
        // translation of topic into root "signals" fields.
        if (filter?.signal_topic) {
          const addSignalTopicFilter = (topic: string, index = 0) => {
            const { value, type } = parseTopic(topic);
            const fieldMapping: Record<string, string> = {
              [TopicTypes.COMPANY]: 'company_id',
              [TopicTypes.DATASET]: 'dataset',
              [TopicTypes.CLASS]: 'signal_class',
              [TopicTypes.RESOURCE]: 'resource_id',
            };

            // This will leave "gaps" in _and/_or arrays
            // which are cleaned up later at the "transformVariables" stage.
            const boolExp = index === undefined ? '' : `_and#${index}#`;

            if (type) {
              if (fieldMapping[type]) {
                filter[`${boolExp}${fieldMapping[type]}@_eq`] = value;
              }

              // A workaround for location topics search, by using a payload
              // field inclusion filter.
              if (isLocationTopic(topic)) {
                filter[`${boolExp}payload@_contains@signal_predefined_topics`] = [topic];
              }
            } else {
              // Don't fail the whole query, but let's log this for debugging
              console.warn(`Signals resource: unknown topic type was used for filtering "${topic}"`);
            }
          };

          if (Array.isArray(filter.signal_topic)) {
            filter.signal_topic.forEach(addSignalTopicFilter);
          } else {
            addSignalTopicFilter(filter.signal_topic);
          }

          delete filter.signal_topic;
        }
      }

      return ctx;
    },
    transformVariables(ctx) {
      // Cleanup the "gaps" in _and/_or arrays left by the "transformParams" stage,
      // when multiple topic filters are used
      if ('where' in ctx.variables) {
        ctx.variables.where = visitBoolExpression(ctx.variables.where, (expr) => {
          if (expr._and) {
            Object.assign(expr, { _and: expr._and.filter(Boolean) });
          }

          if (expr._or) {
            Object.assign(expr, { _or: expr._or.filter(Boolean) });
          }

          return expr;
        });
      }

      if (ctx.operation.type === GET_LIST) {
        const filters =
          ctx.variables.where._and.filter(
            (obj: HasuraComparisonExpression) => !Object.prototype.hasOwnProperty.call(obj, 'clients'),
          ) || [];

        ctx.variables.where._and = [
          ...filters,
          { clients: { _contains: ctx.operation.params?.filter?.['clients@_contains'] } },
        ];
      }

      return ctx;
    },
    transformResource(_ctx, resource) {
      // As there are no topics per se in the "signals" resource,
      // but we'd really want to use something like that for referencing "topics_metadata",
      // we'll populate some helper fields with "generated" topic-like values.
      Object.assign(resource, {
        derived_topics_map: fromSignal(resource),
      });

      return resource;
    },
  },
};
