import React from 'react';
import { TextInput, BooleanInput } from 'react-admin';

export const SharedInputs = () => (
  <>
    <BooleanInput source="all_alerts_access" label="Show Organization Alerts" fullWidth />
    <BooleanInput source="send_email_alerts" fullWidth />
    <TextInput source="alerts_filter.company_id._in" label="Alerts Filter (Company Ids comma separated)" fullWidth />
  </>
);

export default SharedInputs;
