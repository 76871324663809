import gql from 'graphql-tag';
import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';

export const TopicsRelationsResource: ResourceDefinition = {
  name: 'topics_relations',
  section: ResourceSections.ALERTS,

  extension: {
    idKey: ['parent', 'child'],
    operations: {
      [GET_LIST]: {
        operationName: 'signals_topics_relations',
      },
      [GET_ONE]: {
        operationName: 'signals_topics_relations',
      },
      [GET_MANY]: {
        operationName: 'signals_topics_relations',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_topics_relations',
      },
    },
    extraFields: gql`
      {
        child_metadata {
          topic
          metadata
        }
        parent_metadata {
          topic
          metadata
        }
      }
    `,
  },
};
