import { TransformData } from 'react-admin';

export const transformValues: TransformData = (values) => {
  const result = {
    ...values,
  };
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }

  if (/^\d+$/.test(result?.company_id)) {
    result.topic = `${result.attribute}:${result.company_id}`;
  } else {
    throw new Error('Invalid company_id. A valid company_id is required to create a user subscription.');
  }
  delete result?.attribute;

  return result;
};

export default transformValues;
