import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';

export const PublishedAlertsTopicsResource: ResourceDefinition = {
  name: 'published_alerts_topics',
  section: ResourceSections.ALERTS,
  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'signals_published_alerts_topics',
      },
      [GET_ONE]: {
        operationName: 'signals_published_alerts_topics',
      },
      [GET_MANY]: {
        operationName: 'signals_published_alerts_topics',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_published_alerts_topics',
      },
    },
  },
};
