import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { AppBar, AppBarProps } from 'react-admin';

import { APP_BAR_HEIGHT } from 'src/utils/defaults/Constants';
import CraftLogo from './icons/CraftLogo';
import CraftUserMenu from './CraftUserMenu';

const useStyles = makeStyles({
  logo: {
    cursor: 'pointer',
  },
  logoLink: {
    color: 'currentcolor',
  },
  spacer: {
    flex: 1,
  },
});

/**
 * Custom app bar version with Craft's logo
 * and support for customization of the height.
 */
const CraftAppBar: FC<AppBarProps> = (props) => {
  const classes = useStyles();

  return (
    <AppBar
      {...props}
      position="fixed"
      color="primary"
      userMenu={<CraftUserMenu />}
      sx={{
        minHeight: APP_BAR_HEIGHT,
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0 0 1rem 0 rgba(0, 0, 0, 0.2)',
      }}
    >
      <Link to="/" className={classes.logoLink}>
        <CraftLogo height="24" color="#FFFFFF" className={classes.logo} />
      </Link>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CraftAppBar;
