import React from 'react';
import { Paper } from '@mui/material';
import {
  CreateButton,
  EditButton,
  FunctionField,
  NumberField,
  TextField,
  TextInput,
  WrapperField,
  useRecordContext,
} from 'react-admin';

import CraftStandaloneList from 'src/components/CraftStandaloneList';
import BulkSoftDeleteButton from 'src/components/BulkSoftDeleteButton';
import IntegerInput from 'src/inputs/IntegerInput';
import AuditBasedStatusFilterInput from 'src/inputs/AuditBasedStatusFilterInput';
import { usePermissions } from 'src/hooks';
import { CompanySubscriptionSetRecord } from 'src/types';
import { CraftPageSection, CraftPageSectionProps } from 'src/components/CraftPageSection';
import { Center } from 'src/components/Center';
import { companySubscriptionSetCreateRoute } from 'src/utils/routeHelpers';

const CompanySubscriptionSetsSection = (props: Omit<CraftPageSectionProps, 'title'>) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const Actions = permissions.has('companySubscriptionSet:edit') && (
    <CreateButton to={companySubscriptionSetCreateRoute(record.id)} />
  );

  const filters = [
    <IntegerInput key="id" label="ID" source="id" alwaysOn />,
    <TextInput key="name" source="name" alwaysOn />,
    <AuditBasedStatusFilterInput key="statusFilter" source="deleted_at@_is_null" alwaysOn />,
  ];

  return (
    <CraftPageSection title="Company Subscription Sets" {...props}>
      <Paper sx={{ width: '100%', p: 2 }}>
        <CraftStandaloneList
          resource="company_subscription_sets"
          filter={{ external_api_client_id: record.id }}
          filters={filters}
          filterDefaultValues={{ 'deleted_at@_is_null': true }}
          actions={Actions}
          dataGridProps={{
            isRowSelectable: (rec: CompanySubscriptionSetRecord) => !rec.deleted_at && !rec.all_companies,
            empty: <Center m={4}>No company subscription sets found</Center>,
            bulkActionButtons: permissions.has('companySubscriptionSet:delete') && <BulkSoftDeleteButton />,
          }}
        >
          <TextField label="ID" source="id" />
          <TextField source="name" />
          <NumberField source="company_limit" emptyText="N/A" />
          <WrapperField label="Actions">
            <FunctionField render={(rec: CompanySubscriptionSetRecord) => !rec.deleted_at && <EditButton />} />
          </WrapperField>
        </CraftStandaloneList>
      </Paper>
    </CraftPageSection>
  );
};

export default CompanySubscriptionSetsSection;
