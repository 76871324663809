import { SelectInput, SelectInputProps } from 'react-admin';

/**
 * Custom React-admin `SelectInput` to be used as a list "status" filter input for filter params
 * like `deleted_at@_is_null` and `archived_at@_is_null`.
 */
const AuditBasedStatusFilterInput = (
  props: Omit<SelectInputProps, 'choices' | 'format' | 'parse' | 'isRequired'> & {
    activeLabel?: string;
    deletedLabel?: string;
  },
) => {
  const { activeLabel = 'Active', deletedLabel = 'Deleted', ...rest } = props;

  const choices = [
    { id: 'any', name: 'All' },
    { id: 'active', name: activeLabel },
    { id: 'deleted', name: deletedLabel },
  ];

  return (
    <SelectInput
      label="Status"
      choices={choices}
      format={(val) => {
        if (typeof val !== 'boolean') return 'any';
        return val ? 'active' : 'deleted';
      }}
      parse={(val) => (val === 'any' ? null : val === 'active')}
      isRequired
      {...rest}
    />
  );
};

export default AuditBasedStatusFilterInput;
