import { Box, BoxProps } from '@mui/material';
import { ImageFieldProps, ReferenceField, ReferenceFieldProps } from 'react-admin';

import AvatarField from 'src/fields/AvatarField';
import NameIdReferenceField from 'src/fields/NameIdReferenceField';

export const OrganizationReferenceField = ({
  boxProps,
  logoProps,
  ...rest
}: Omit<ReferenceFieldProps, 'reference'> & { boxProps?: BoxProps; logoProps?: ImageFieldProps }) => (
  <ReferenceField label="Org" reference="organizations" {...rest}>
    <Box display="flex" flexDirection="row" alignItems="center" {...boxProps}>
      <AvatarField source="logo_url" label="Logo" sx={{ mr: 1 }} {...logoProps} />
      <NameIdReferenceField />
    </Box>
  </ReferenceField>
);
