import { SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin';
import { CraftCreate } from 'src/components/CraftCreate';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { GenerateSlug } from '../../utils/SlugGenerationUtil';

export const CompaniesCreate = () => {
  const transform = async (data: { name: string }) => ({
    ...data,
    slug: await GenerateSlug(data.name),
  });

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data: { id: string | number }) => {
    notify('The company has been successfully created', { type: 'success' });
    redirect(`/companies/${data.id}`);
  };

  return (
    <CraftCreate mutationOptions={{ onSuccess }} transform={transform}>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />}>
        <h2>Add a new company profile</h2>
        <TextInput source="name" label="Company name" fullWidth />
      </SimpleForm>
    </CraftCreate>
  );
};

export default CompaniesCreate;
