import ApartmentIcon from '@mui/icons-material/Apartment';

import { ResourceSections, ResourceDefinition } from '../../types';

import CompaniesCreate from './Create';
import CompaniesEdit from './Edit';
import CompaniesList from './List';

export const CompaniesResource: ResourceDefinition = {
  name: 'companies',
  icon: ApartmentIcon,
  section: ResourceSections.COMPANIES,

  list: CompaniesList,
  edit: CompaniesEdit,
  create: CompaniesCreate,
  permissions: {
    list: ['company:read'],
    create: ['company:edit'],
    edit: ['company:edit'],
  },
};
