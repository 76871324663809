import React, { FC } from 'react';

const EditIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5003 13.602L9.71631 14.0002L10.1138 11.2155L17.2733 4.056C17.5898 3.73953 18.019 3.56174 18.4666 3.56174C18.9141 3.56174 19.3433 3.73953 19.6598 4.056C19.9763 4.37247 20.1541 4.80169 20.1541 5.24925C20.1541 5.6968 19.9763 6.12603 19.6598 6.4425L12.5003 13.602Z"
      stroke="#19202E"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3416 13.6875V19.3125C17.3416 19.6109 17.223 19.897 17.012 20.108C16.8011 20.319 16.5149 20.4375 16.2166 20.4375H4.96655C4.66818 20.4375 4.38204 20.319 4.17106 20.108C3.96008 19.897 3.84155 19.6109 3.84155 19.3125V8.0625C3.84155 7.76413 3.96008 7.47798 4.17106 7.267C4.38204 7.05603 4.66818 6.9375 4.96655 6.9375H10.5916"
      stroke="#19202E"
      strokeWidth="1.125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
