import { Button, useListContext } from 'react-admin';

/**
 * Clears the filters from the current list context.
 * Only becomes visible if there're some filters applied.
 */
export const ClearFiltersButton = () => {
  const { filterValues, setFilters } = useListContext();

  const hanldeClearFilters = () => {
    setFilters({}, {}, false);
  };

  if (Object.keys(filterValues).length === 0) {
    return null;
  }

  return <Button onClick={hanldeClearFilters} label="Clear filters" />;
};

export default ClearFiltersButton;
