import {
  TextField,
  ExportButton,
  TopToolbar,
  DateField,
  WrapperField,
  EditButton,
  SearchInput,
  SortPayload,
} from 'react-admin';
import { ClearFiltersButton } from 'src/components/ClearFiltersButton';
import { CraftList } from 'src/components/CraftList';
import { OrganizationReferenceField } from '../organizations/OrganizationReferenceField';
import { CraftDateInput } from 'src/components/CraftDateInput';

const filters = [
  <SearchInput key="search" placeholder="Search" source="name,nickname@_ilike,email@_ilike" alwaysOn />,
  <CraftDateInput key="createdAtFrom" label="Newer than" source="created_at@_gte" disallowFuture alwaysOn />,
  <CraftDateInput key="createdAtTo" label="Older than" source="created_at@_lte" alwaysOn />,
];

const defaultSort: SortPayload = {
  field: 'created_at',
  order: 'DESC',
};

const columnStyles = {
  created_at: {
    width: '6rem',
    maxWidth: '6rem',
  },
  updated_at: {
    width: '6rem',
    maxWidth: '6rem',
  },
};

const Actions = () => (
  <TopToolbar>
    <ExportButton />
    <ClearFiltersButton />
  </TopToolbar>
);

export const PortalUsersList = () => (
  <CraftList
    title="Portal Users"
    filters={filters}
    actions={<Actions />}
    sort={defaultSort}
    columnStyles={columnStyles}
  >
    <TextField source="id" />
    <TextField source="name" />
    <TextField source="nickname" />
    <TextField source="email" />

    <OrganizationReferenceField source="organization_id" />

    <DateField source="created_at" showTime />
    <DateField source="updated_at" showTime />

    <WrapperField label="Actions" headerClassName="column-actions" cellClassName="column-actions">
      <EditButton />
    </WrapperField>
  </CraftList>
);

export default PortalUsersList;
