import { MouseEventHandler } from 'react';

export const handleCheckboxGroupInputClick: MouseEventHandler<HTMLDivElement> & MouseEventHandler<HTMLButtonElement> = (
  event,
) => {
  const target = event.target as HTMLElement;
  if (target.nodeName !== 'INPUT') {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const checkboxGroupInputDefaultCursorSx = {
  '& label': {
    cursor: 'default',
  },
};
