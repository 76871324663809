import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { TabbedFormProps, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useMutation } from '@apollo/client';

import client from 'src/data/api';
import { ExternalApiClientRecord } from 'src/types';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { externalApiClientSchema } from '../validation';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { resolveMutationParams, updateExternalApiClientGQL } from '../helpers';
import { EXTERNAL_API_CLIENT_FEATURES } from 'src/utils/defaults/Constants';
import ArchivedOrDeletedNotification from 'src/components/ArchivedOrDeletedNotification';

const featureValSet = new Set(EXTERNAL_API_CLIENT_FEATURES.map((feat) => feat.id));

const EditExternalApiClientsTabbedForm = ({ children, ...rest }: TabbedFormProps) => {
  const record = useRecordContext<ExternalApiClientRecord>();
  const notify = useNotify();
  const refresh = useRefresh();

  const [defaultValues, setDefaultValues] = useState<Record<string, unknown>>();

  useEffect(() => {
    if (!record || defaultValues) return;

    const result: {
      fields: Record<string, boolean>;
      sources: Record<string, string[]>;
      features: Record<string, boolean>;
      rowLevelAccessPolicy: {
        locations?: {
          filters?: string[];
        };
      };
      risks: Record<string, unknown>;
    } = { fields: {}, sources: {}, features: {}, rowLevelAccessPolicy: {}, risks: {} };

    // handle base policies (fields, sources, features)
    for (const policy of record.policies) {
      if (!policy.available) continue;

      if (policy.field_type.includes(':')) {
        // policy is for field source
        const fieldSlashPath = policy.field_type.split(':')[0].replaceAll('.', '/');
        result.sources[fieldSlashPath] ||= [];
        result.sources[fieldSlashPath].push(policy.field_type);
        continue;
      }

      const policySlashPath = policy.field_type.replaceAll('.', '/');

      if (featureValSet.has(policy.field_type)) {
        // policy is for feature
        result.features[policySlashPath] = true;
      }

      // policy is for field
      result.fields[policySlashPath] = true;
    }

    result.rowLevelAccessPolicy.locations = record.row_level_access_policy?.policies?.locations ?? {};

    // handle risks
    const rawRiskStrings = record.row_level_access_policy?.policies?.risk?.split(',');
    rawRiskStrings?.reduce((acc, str) => {
      const strParts = str.split(':');
      const value = strParts.at(-1);
      if (strParts[0] === 'esg') {
        result.risks.esg = { enabled: true, ecovadis: { enabled: true, value } };
      }
      if (strParts[0] === 'cybersecurity') {
        result.risks.cybersecurity = { enabled: true, lab1: { enabled: true, value } };
      }
      return acc;
    }, result.risks);

    setDefaultValues(result);
  }, [record]);

  const [updateExternalApiClient, { loading: updateLoading }] = useMutation(updateExternalApiClientGQL, {
    client,
    errorPolicy: 'all',
  });

  const [deleteExternalApiClient, { loading: deleteLoading }] = useMutation(
    gql`
      mutation deleteExternalApiClient($id: Int!) {
        deleteExternalApiClient(id: $id)
      }
    `,
    { client, errorPolicy: 'all' },
  );

  const handleSave = async (rawValues: Record<string, unknown>) => {
    if (!record) return undefined;

    const validationResult = externalApiClientSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const { mutation, variables } = resolveMutationParams(values, record);
    const { errors } = await updateExternalApiClient({ mutation, variables });

    if (errors) {
      notify('Error updating client. Please try again.', { type: 'error' });
      return undefined;
    }

    // set new default values to updated values
    setDefaultValues(rawValues);
    window.scroll(0, 0);
    notify('The API client has been successfully updated', { type: 'success' });
    refresh();

    return undefined;
  };

  const handleDelete = async () => {
    if (!record) return;

    try {
      const { errors } = await deleteExternalApiClient({ variables: { id: record.id } });
      if (errors) throw errors[0];

      notify('The API client has been successfully deleted', { type: 'success' });
      refresh();
    } catch (e) {
      notify('There was a problem deleting API client. Please try again.', { type: 'error' });
    }
  };

  return (
    <>
      <CraftTabbedForm
        formType="edit"
        defaultValues={defaultValues}
        onSubmit={handleSave}
        deleteOptions={{
          deletePermission: 'api:delete',
          onConfirmDelete: handleDelete,
          dialogTitle: 'Delete API Client',
          dialogContent: 'Are you sure you want to delete the client?',
        }}
        {...rest}
      >
        {children}
      </CraftTabbedForm>
      <LoadingOverlay open={updateLoading || deleteLoading} />
      <ArchivedOrDeletedNotification record={record} notificationText="This API Client is ARCHIVED" />
    </>
  );
};

export default EditExternalApiClientsTabbedForm;
