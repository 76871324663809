import { ArrayField, ArrayFieldProps, RecordContextProvider, useRecordContext } from 'react-admin';
import { get } from 'lodash';

/**
 * Behaves like {@link ArrayField}, but allows filtering, sorting and mapping
 * of the items before rendering them. You can specify item type as a generic.
 *
 * @example
 * <TransformedArrayField
 *   source="items"
 *   filter={item => item.enabled}
 *   sort={['name', 'id']}
 *   map={item => ({ ...item, name: item.fullName ?? item.name })}
 * >
 *   <TextField source="name" />
 * </TransformedArrayField>
 */
export const TransformedArrayField = <ItemType = unknown, MappedItemType = unknown>({
  source,
  filter,
  map,
  ...rest
}: Omit<ArrayFieldProps, 'source'> & {
  source: string;
  filter?: (item: ItemType) => boolean;
  map?: (item: ItemType) => MappedItemType;
}) => {
  const record = useRecordContext();
  let sourceArray = get(record, source) ?? [];

  if (filter) {
    sourceArray = sourceArray.filter(filter);
  }

  if (map) {
    sourceArray = sourceArray.map(map);
  }

  return (
    <RecordContextProvider value={{ [source]: sourceArray }}>
      <ArrayField source={source} {...rest} />
    </RecordContextProvider>
  );
};
