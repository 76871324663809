import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRefresh, useRecordContext, useNotify } from 'react-admin';
import { checkSlugExists, updateSlug } from 'src/utils/SlugGenerationUtil';
import EditIcon from 'src/components/icons/Edit';

export const FormDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [itemName, setItemName] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const record = useRecordContext();
  const notify = useNotify();
  const slug = record ? record.slug : '';
  const companyName = record ? record.name : '';
  const companyId = record ? record.id : '';
  const refresh = useRefresh();

  const editSlug = async () => {
    const existingSlug = await checkSlugExists(itemName);
    if (existingSlug.data.companies.length !== 0) {
      setErrorMessage(`The slug you provided is already used by company id: ${existingSlug.data.companies[0].id}`);
    } else {
      await updateSlug(Number(record.id), itemName);
      setOpen(false);
      refresh();
      notify('The slug has been successfully updated', { type: 'success' });
    }
  };

  const handleClose = () => {
    setErrorMessage('');
    setOpen(false);
    refresh();
  };
  return (
    <div>
      <Button variant="text" onClick={handleOpen}>
        <EditIcon />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Change Slug For: {companyName}, Id: {companyId}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="slug"
            defaultValue={slug}
            label="slug"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setItemName(e.target.value);
            }}
            helperText={errorMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={editSlug}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
