import { FunctionField } from 'react-admin';
import { CoreDBCompanyRow } from '../models/companies/CoreDBCompanyTypes';

export const CompanyCreatedAtField = () => (
  <FunctionField
    render={(record: CoreDBCompanyRow) =>
      `${record?.created_at || undefined}`.concat(record.CreatedBy ? `by ${record.CreatedBy[0].AdminUser}` : '')
    }
  />
);

export default CompanyCreatedAtField;
