import { COMPANY_LOGO_S3_BASE_URL } from '../../utils/defaults/Constants';
import { config } from '../../config/config.local';

export const getPortalCompanyProfilePageUrl = (slug: string): string => `${config.portal.url}/company/${slug}`;

export const getCompanyProfileBaseUrl = (companyId: number): string => {
  const d = (companyId % 5) + 1;
  const profileUrlPrefix = `${Math.floor(companyId / 100).toString()}xx`;
  return `${COMPANY_LOGO_S3_BASE_URL.replace('%d', d.toString())}${profileUrlPrefix}/${companyId}`;
};

export const getCraftCompanyProfilePageUrl = (slug: string) => `${config.craftco.url}/${slug}`;

export const getCraftInternalLinks = (slug: string) => [
  {
    label: 'portal',
    url: getPortalCompanyProfilePageUrl(slug),
    testId: 'portal',
  },
  {
    label: 'craft.co',
    url: getCraftCompanyProfilePageUrl(slug),
    testId: 'craft-co',
  },
];
