import noop from 'lodash/noop';
import { createContext, useContext, useState, useMemo, ReactNode } from 'react';
import { MenuItemLinkProps } from 'react-admin';

type CraftSidebarContextValue = {
  items: MenuItemLinkProps[];
  addItem(item: MenuItemLinkProps): void;
  removeItem(item: MenuItemLinkProps): void;
};

export const CraftSidebarContextDefaultValue = {
  items: [],
  addItem: noop,
  removeItem: noop,
};

/**
 * A context to support page-aware sidebar content.
 * Components within a page may register their own sidebar items
 * as needed for jumping through the page contents quickly.
 */
export const CraftSidebarContext = createContext<CraftSidebarContextValue>(CraftSidebarContextDefaultValue);

export const CraftSidebarContextProvider = ({ children }: { children: ReactNode }) => {
  const [items, setItems] = useState<MenuItemLinkProps[]>([]);
  const value = useMemo(
    () => ({
      items,
      addItem(item: MenuItemLinkProps) {
        setItems((prev) => [...prev, item]);
      },
      removeItem(item: MenuItemLinkProps) {
        setItems((prev) => prev.filter((old) => old !== item));
      },
    }),
    [setItems, items],
  );

  return <CraftSidebarContext.Provider value={value}>{children}</CraftSidebarContext.Provider>;
};

/**
 * @see {@link CraftSidebarContext}
 */
export const useCraftSidebarContext = () => useContext(CraftSidebarContext);
