import { RaRecord } from 'react-admin';
import { ChipProps } from '@mui/material';

import { ALERT_POLARITIES, ALERT_SEVERITIES } from '../publishedAlerts/PublishedAlertsTypes';

export interface TopicMetadata extends RaRecord {
  topic: string;
  metadata?: {
    name: string;
    filterName?: string;
    subscriptionName?: string;
  };
}

export interface TopicRelations extends RaRecord {
  parent: string;
  child: string;

  parent_metadata: TopicMetadata;
  child_metadata: TopicMetadata;
}

/**
 * Some of the common topics available on the Alerts,
 * this list is not exhaustive as data pipelines can inject additional
 * types of topics as needed.
 * @see signals-validation-service "compute_alert_topics" for default topic types
 */
export enum TopicTypes {
  LOCATION_CITY = 'location.city',
  LOCATION_ADMIN = 'location.admin',
  LOCATION_COUNTRY = 'location.country',
  LOCATION_SUB_REGION = 'location.sub_region',
  LOCATION_REGION = 'location.region',
  LOCATION = 'location',
  COMPANY = 'company',
  RESOURCE = 'resource',
  RESOURCE_TYPE = 'resource_type',
  VARIABLE = 'variable',
  DATASET = 'dataset',
  RULE_KEY = 'signal_rule_key',
  KEY = 'key',
  POLARITY = 'polarity',
  SEVERITY = 'severity',
  CLASS = 'class',
  KEYWORD = 'keyword',
}

export type TopicMetadataDisplayModel = {
  topic: string;
  metadata: TopicMetadata | undefined;
  type: string;
  value: string;
  color: NonNullable<ChipProps['color']>;
  displayType: string;
  displayValue: string;
  resolvedType: TopicTypes | undefined;
  fullName: string;
  isWildcard: boolean;
  isLocation: boolean;
};

export type LocationTopics =
  | TopicTypes.LOCATION
  | TopicTypes.LOCATION_CITY
  | TopicTypes.LOCATION_ADMIN
  | TopicTypes.LOCATION_COUNTRY
  | TopicTypes.LOCATION_SUB_REGION
  | TopicTypes.LOCATION_REGION;

export type EntityTopics = TopicTypes.COMPANY | TopicTypes.RESOURCE | TopicTypes.LOCATION | TopicTypes.KEYWORD;

export const LocationTopicsHierarchy: LocationTopics[] = [
  TopicTypes.LOCATION_CITY,
  TopicTypes.LOCATION_ADMIN,
  TopicTypes.LOCATION_COUNTRY,
  TopicTypes.LOCATION_SUB_REGION,
  TopicTypes.LOCATION_REGION,
  TopicTypes.LOCATION,
];

export const TOPIC_TYPE_NAMES: Record<TopicTypes, string> = {
  [TopicTypes.LOCATION_CITY]: 'City',
  [TopicTypes.LOCATION_ADMIN]: 'Country region',
  [TopicTypes.LOCATION_COUNTRY]: 'Country',
  [TopicTypes.LOCATION_SUB_REGION]: 'Sub-region',
  [TopicTypes.LOCATION_REGION]: 'Geographic region',
  [TopicTypes.LOCATION]: 'Location',
  [TopicTypes.COMPANY]: 'Company',
  [TopicTypes.RESOURCE]: 'Commodity',
  [TopicTypes.RESOURCE_TYPE]: 'Resource',
  [TopicTypes.VARIABLE]: 'Variable',
  [TopicTypes.DATASET]: 'Dataset',
  [TopicTypes.RULE_KEY]: 'Rule key',
  [TopicTypes.KEY]: 'Key',
  [TopicTypes.POLARITY]: 'Polarity',
  [TopicTypes.SEVERITY]: 'Severity',
  [TopicTypes.CLASS]: 'Class',
  [TopicTypes.KEYWORD]: 'Keyword',
};

/**
 * Topics may need to be presented in a different way to the user,
 * this enum allows to specify which name should be used for a given topic.
 * @see resolveTopicName
 */
export enum PreferredTopicName {
  DEFAULT = 'name',
  FILTER = 'filterName',
  SUBSCRIPTION = 'subscriptionName',
}

/**
 * These topics will have a special display as there's
 * a finite set of contextual values that they can express.
 */
export const ENUMERABLE_TOPICS = {
  [TopicTypes.POLARITY]: ALERT_POLARITIES,
  [TopicTypes.SEVERITY]: ALERT_SEVERITIES,
};

export type EnumeableTopics = keyof typeof ENUMERABLE_TOPICS;
