import { FunctionField, RaRecord, TextField, WrapperField } from 'react-admin';

type NameIdReferenceFieldProps = {
  resolveName?: (r: RaRecord) => string;
};

const defaultResolveName = (r: RaRecord) => r.name;

/**
 * Helper field to quickly render "Name (ID)" pair for the
 * record in the current record context.
 */
export const NameIdReferenceField = ({ resolveName = defaultResolveName }: NameIdReferenceFieldProps = {}) => (
  <WrapperField>
    <FunctionField render={resolveName} />
    <span>&nbsp;(</span>
    <TextField source="id" label="ID" variant="inherit" />
    <span>)</span>
  </WrapperField>
);

export default NameIdReferenceField;
