import { FormTab, FormTabProps, SelectInput, required } from 'react-admin';

import CraftTwoColumns from 'src/components/CraftTwoColumns';
import TextInput from 'src/inputs/TextInput';
import BooleanInput from 'src/inputs/BooleanInput';
import { DataPackageKind } from 'src/types';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { DATA_PACKAGE_KINDS } from 'src/utils/defaults/Constants';

// exlude "internal" from "kind" options
const dataPackageKindOptions = DATA_PACKAGE_KINDS.filter((kind) => kind.id !== DataPackageKind.INTERNAL);

const DetailsTab = ({ mode, ...rest }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => (
  <FormTab label="Details" {...rest}>
    <CraftPageSection title="General">
      <CraftTwoColumns
        left={
          <>
            <TextInput source="name" validate={required()} autoFocus={mode === 'create'} />
            <SelectInput
              source="kind"
              label="Kind"
              choices={dataPackageKindOptions}
              defaultValue="standard"
              validate={required()}
            />
          </>
        }
        right={mode === 'edit' && <BooleanInput source="is_deprecated" label="Deprecated" />}
      />
    </CraftPageSection>
  </FormTab>
);

export default DetailsTab;
