import { Stack, StackProps } from '@mui/material';
import { WrapperField, WrapperFieldProps } from 'ra-ui-materialui';

/**
 * Like {@link WrapperField}, but wraps children in a {@link Stack}.
 *
 * @example
 * <WrapperStackField label="Actions">
 *   <EditButton />
 *   <SaveButton />
 *   <DeleteButton />
 * </WrapperStackField>
 */
export const WrapperStackField = ({
  stackProps = {},
  children,
  ...rest
}: WrapperFieldProps & { stackProps?: StackProps }) => (
  <WrapperField {...rest}>
    <Stack direction="row" gap={1} flexWrap="wrap" {...stackProps}>
      {children}
    </Stack>
  </WrapperField>
);
