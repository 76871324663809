import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';

import { CompanySubscriptionSetSubscriptionCounts } from 'src/types';

interface CompanySubscriptionSetContextValue {
  currentSubscriptionCounts?: CompanySubscriptionSetSubscriptionCounts;
  setCurrentSubscriptionCounts: (subscriptionCounts: CompanySubscriptionSetSubscriptionCounts) => void;
  currentSubscriptionCountsRefetch: () => void;
  setCurrentSubscriptionCountsRefetch: (fn: () => void) => void;
}

const companySubscriptionSetContextDefaultValue = {
  setCurrentSubscriptionCounts: noop,
  currentSubscriptionCountsRefetch: noop,
  setCurrentSubscriptionCountsRefetch: noop,
};

const CompanySubscriptionSetContext = createContext<CompanySubscriptionSetContextValue>(
  companySubscriptionSetContextDefaultValue,
);

export const CompanySubscriptionSetContextProvider = ({ children }: PropsWithChildren) => {
  const [currentSubscriptionCounts, setCurrentSubscriptionCounts] =
    useState<CompanySubscriptionSetSubscriptionCounts>();
  const [currentSubscriptionCountsRefetch, setCurrentSubscriptionCountsRefetch] = useState<() => void>();

  const value = useMemo(
    () =>
      ({
        currentSubscriptionCounts,
        setCurrentSubscriptionCounts,
        currentSubscriptionCountsRefetch,
        setCurrentSubscriptionCountsRefetch: (fn) => setCurrentSubscriptionCountsRefetch(() => fn),
      } as CompanySubscriptionSetContextValue),
    [currentSubscriptionCounts, currentSubscriptionCountsRefetch],
  );

  return <CompanySubscriptionSetContext.Provider value={value}>{children}</CompanySubscriptionSetContext.Provider>;
};

export const useCompanySubscriptionSetContext = () => useContext(CompanySubscriptionSetContext);
