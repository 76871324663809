import { Card, CardProps } from '@mui/material';

/**
 * Default header for the admin pages (where one is needed).
 */
export const CraftPageHeader = ({ sx, ...rest }: CardProps) => (
  <Card
    sx={{
      py: 1,
      px: 2,
      mb: 2,
      borderRadius: 0,
      border: 'none',
      borderBottom: '1px solid',
      borderBottomColor: 'border.main',
      ...sx,
    }}
    {...rest}
  />
);
