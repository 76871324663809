import { CraftCreate } from 'src/components/CraftCreate';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { SimpleForm, TextInput, SelectInput, useNotify, useRedirect, useGetOne, SimpleFormProps } from 'react-admin';
import { ORGANIZATION_SUBSCRIPTION_STATUSES } from 'src/utils/defaults/Constants';
import { orgSubscriptionsListRoute } from 'src/utils/routeHelpers';
import { useLocation } from 'react-router-dom';

type OrgSubValues = {
  org_id?: string;
  status?: string;
  topic?: string;
};

const validateOrgSubCreation = (values: Pick<SimpleFormProps, 'validate'> & OrgSubValues) => {
  const errors: Record<string, string> = {};
  const pattern = /(\w+):(\w+)/;
  if (!values.org_id) {
    errors.org_id = 'The "org_id" field is required';
  }
  if (!values.status) {
    errors.status = 'The "status" field is required';
  }
  if (!values.topic) {
    errors.topic = 'The "topic" field is required';
  }
  if (!values.topic?.match(pattern)) {
    errors.topic = 'The "topic" must be in the format <entity>:<attribute>';
  }
  return errors;
};

export const OrgSubCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const location = useLocation();

  const { orgId } = location.state ?? {};

  if (Number.isNaN(parseInt(orgId, 10))) {
    notify('Invalid organization id. A valid organization id is required to create an organization subscription.', {
      type: 'error',
    });
    redirect('/organization_config');
  }

  const onSuccess = (data: { org_id: string | number }) => {
    notify('Organization Subscription created', { type: 'success' });
    redirect(orgSubscriptionsListRoute(data.org_id));
  };

  const { data = {}, isLoading, error } = useGetOne('organizations', { id: orgId });
  const choices = isLoading ? [] : [data];
  // Don't allow organization subscriptions to be created for organizations that don't exist
  if (error || (!isLoading && !data)) {
    notify('Unable to find organization. A valid organization is required to create an organization subscription.', {
      type: 'error',
    });
    redirect('/organization_config');
  }

  return (
    <CraftCreate mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />} validate={validateOrgSubCreation}>
        <h2>Add an organization subscription</h2>
        <SelectInput
          source="org_id"
          label="Organization"
          choices={choices}
          fullWidth
          optionText="name"
          optionValue="id"
          isLoading={isLoading}
          defaultValue={orgId}
          disabled
        />
        <TextInput source="topic" label="Topic" fullWidth isRequired />
        <SelectInput
          defaultValue="ACTIVE"
          source="status"
          fullWidth
          choices={ORGANIZATION_SUBSCRIPTION_STATUSES}
          isRequired
        />
      </SimpleForm>
    </CraftCreate>
  );
};

export default OrgSubCreate;
