/**
 * In production, we render config contents during CI/CD,
 * as a script which is loaded before the rest of the app.
 * Script puts all the necessary configuration in this object we export.
 *
 * @see frontend_config.tf
 * @see config.local.ts - reference configuration
 * @see public/config.js - where the configuration is rendered
 */
export const config = globalThis.CraftAdminConfig;
