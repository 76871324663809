import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';

export enum SignalsPublicationState {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  DENIED = 'DENIED',
}

export enum SignalsValidationState {
  VALID = 'VALID',
  INVALID = 'INVALID',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  OUTDATED = 'OUTDATED',
}

export const PUBLISHED_SIGNAL_FRAGMENT = {
  publication_state: SignalsPublicationState.PUBLISHED,
  validation_state: SignalsValidationState.VALID,
};

export const UNPUBLISHED_SIGNAL_FRAGMENT = {
  publication_state: SignalsPublicationState.DENIED,
  validation_state: SignalsValidationState.INVALID,
};

export const PUBLISH_SIGNAL_PROPS = {
  data: PUBLISHED_SIGNAL_FRAGMENT,
  startIcon: <SendIcon />,
  label: 'Publish',
};

export const UNPUBLISH_SIGNAL_PROPS = {
  data: UNPUBLISHED_SIGNAL_FRAGMENT,
  startIcon: <CancelScheduleSendIcon />,
  label: 'Unpublish',
};
