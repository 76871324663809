import SmartToyIcon from '@mui/icons-material/SmartToy';
import gql from 'graphql-tag';

import { ResourceDefinition, ResourceSections } from 'src/types';
import ExternalApiClientsCreate from './Create';
import ExternalApiClientsEdit from './Edit';
import ExternalApiClientsList from './List';

export const ExternalApiClientsResource: ResourceDefinition = {
  name: 'external_api_clients',
  section: ResourceSections.CLIENT_API,
  icon: SmartToyIcon,
  options: { label: 'External API Clients' },

  list: ExternalApiClientsList,
  create: ExternalApiClientsCreate,
  edit: ExternalApiClientsEdit,
  permissions: {
    list: ['api:read'],
    create: ['api:edit'],
    edit: ['api:edit'],
  },
  extension: {
    extraFields: gql`
      {
        portal_organizations {
          id
          name
        }
        policies {
          id
          field_type
          available
        }
        row_level_access_policy {
          id
          policies
        }
      }
    `,
  },
};
