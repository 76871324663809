import { TextInputProps as TextInputRaProps, TextInput as TextInputRA } from 'react-admin';

import WithInfoTooltip from 'src/components/WithInfoTooltip';
import { CommonInputProps } from './types';

interface TextInputProps extends TextInputRaProps, CommonInputProps {}

export const TextInput = ({ infoTooltip, sx, ...rest }: TextInputProps) => {
  const inputProps = {
    sx: {
      flex: infoTooltip ? 1 : undefined,
      ...sx,
    },
    ...rest,
  };

  if (!infoTooltip) return <TextInputRA {...inputProps} />;

  return (
    <WithInfoTooltip title={infoTooltip} sx={{ ml: 1 }}>
      <TextInputRA {...inputProps} />
    </WithInfoTooltip>
  );
};

export default TextInput;
