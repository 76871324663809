import { Box, Button } from '@mui/material';
import { Confirm, useRecordContext, useDelete } from 'react-admin';
import { useState } from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';

export const DeleteButton = ({ refresh }: { refresh: () => void }) => {
  const record = useRecordContext();

  const [deleteOrgSubsOpen, setDeleteOrgSubsOpen] = useState(false);
  const [deleteOneOrgSubs, { isLoading: isOrgSubLoading }] = useDelete(
    'organization_subscriptions',
    {
      id: record && record.id,
    },
    {
      onSettled: () => {
        setDeleteOrgSubsOpen(false);
        refresh();
      },
    },
  );
  const handleDeleteOrgSubsClick = () => setDeleteOrgSubsOpen(true);
  const handleDeleteOrgSubscriptionsClose = () => setDeleteOrgSubsOpen(false);
  const handleDeleteOrg = () => {
    deleteOneOrgSubs();
  };

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Button startIcon={<DeleteIcon />} size="small" onClick={handleDeleteOrgSubsClick}>
        Delete
      </Button>
      <Confirm
        isOpen={deleteOrgSubsOpen}
        loading={isOrgSubLoading}
        title={`Delete organization subscription #${record && record.id}`}
        content="Are you sure you want to delete this item?"
        onConfirm={handleDeleteOrg}
        onClose={handleDeleteOrgSubscriptionsClose}
      />
    </Box>
  );
};

export default DeleteButton;
