import { useEffect, useState } from 'react';
import { TabbedFormProps, useNotify, useRecordContext, useRedirect, useRefresh, useUpdate } from 'react-admin';
import { useMutation } from '@apollo/client';

import client from 'src/data/api';
import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import FieldsTab from './formTabs/Fields';
import EditAside from './EditAside';
import LoadingOverlay from 'src/components/LoadingOverlay';
import ArchivedOrDeletedNotification from 'src/components/ArchivedOrDeletedNotification';
import { DataPackageKind, DataPackageRecord } from 'src/types';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { dataPackageSchema, resolveMutationParams, updateDataPackageGQL } from './helpers';

const CustomTabbedForm = ({ children, ...rest }: TabbedFormProps) => {
  const record = useRecordContext<DataPackageRecord>();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [defaultValues, setDefaultValues] = useState<Record<string, unknown>>();

  useEffect(() => {
    if (!record || defaultValues) return;

    const initialDefaultVals: {
      fields: Record<string, boolean>;
      fieldPolicies: Record<string, unknown>;
    } = {
      fields: {},
      fieldPolicies: {},
    };

    for (const fieldXref of record.api_fields_xrefs) {
      // field entry keys are IDs prefixed with "id:"
      const idKey = `id:${fieldXref.api_field_id}`;
      initialDefaultVals.fields[idKey] = true;
      initialDefaultVals.fieldPolicies[idKey] = fieldXref.policies;
    }

    setDefaultValues(initialDefaultVals);
  }, [record]);

  const [softDeleteDataPackage, { isLoading: softDeleteDataPackageLoading }] = useUpdate();

  const [updateDataPackage, { loading: updateDataPackageLoading }] = useMutation(updateDataPackageGQL, {
    client,
    errorPolicy: 'all',
  });

  const handleSave = async (rawValues: Record<string, unknown>) => {
    if (!record) return undefined;

    const validationResult = dataPackageSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const { mutation, variables } = resolveMutationParams(values, record);
    const { errors } = await updateDataPackage({ mutation, variables });

    if (errors) {
      notify('Error updating Data Package. Please try again.', { type: 'error' });
      return undefined;
    }

    // set new default values to updated values
    setDefaultValues(rawValues);
    window.scroll(0, 0);
    notify('The Data Package has been successfully updated', { type: 'success' });
    refresh();

    return undefined;
  };

  const handleDelete = async () => {
    if (!record) return;

    try {
      await softDeleteDataPackage(
        'data_packages',
        { id: record.id, data: { deleted_at: new Date().toISOString() } },
        { returnPromise: true },
      );
      notify('The Data Package has been successfully deleted', { type: 'success' });
      refresh();
    } catch (e) {
      notify('There was a problem deleting the Data Package. Please try again.', { type: 'error' });
    }
  };

  // internal data packages should not be editable
  if (record?.kind === DataPackageKind.INTERNAL) {
    redirect('/data_packages');
    return null;
  }

  return (
    <>
      <CraftTabbedForm
        formType="edit"
        defaultValues={defaultValues}
        onSubmit={handleSave}
        deleteOptions={{
          deletePermission: 'dataPackage:delete',
          dialogTitle: `Delete the "${record?.name}" Package?`,
          onConfirmDelete: handleDelete,
        }}
        shouldUnregister
        {...rest}
      >
        {children}
      </CraftTabbedForm>

      <LoadingOverlay open={updateDataPackageLoading || softDeleteDataPackageLoading} />
      <ArchivedOrDeletedNotification record={record} notificationText="This Data Package is DELETED" />
    </>
  );
};

export const DataPackagesEdit = () => (
  <CraftEdit header="Edit Data Package" aside={<EditAside />} redirect={false}>
    <CustomTabbedForm>
      <DetailsTab mode="edit" />
      <FieldsTab />
    </CustomTabbedForm>
  </CraftEdit>
);

export default DataPackagesEdit;
