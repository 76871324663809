import z from 'zod';
import { isEqual } from 'lodash';

import { DataPackageApiFieldPolicies, DataPackageApiFieldRecord } from 'src/types';

export const apiFieldsConfigSchema = z.object({
  fields: z.record(z.string(), z.boolean().optional()).optional(),
  fieldPolicies: z
    .record(
      z.string(),
      z
        .object({
          filters: z.array(z.string()).optional(),
          sources: z.array(z.string()).optional(),
        })
        .nullish(),
    )
    .optional(),
});

type FormValues = z.infer<typeof apiFieldsConfigSchema>;

export interface ApiFieldXrefUpdateParams {
  id: number;
  api_field_id?: number;
  policies?:
    | {
        sources?: string[] | undefined;
        filters?: string[] | undefined;
      }
    | null
    | undefined;
}

export type NewApiFieldXrefParams = Omit<ApiFieldXrefUpdateParams, 'id'>;

export function resolveApiFieldsConfig(values: FormValues, existingApiFieldXrefs?: DataPackageApiFieldRecord[]) {
  const result: {
    apiFieldXrefsToAdd: NewApiFieldXrefParams[];
    apiFieldXrefsToUpdate: ApiFieldXrefUpdateParams[];
    apiFieldXrefIdsToRemove: number[];
  } = {
    apiFieldXrefsToAdd: [],
    apiFieldXrefsToUpdate: [],
    apiFieldXrefIdsToRemove: [],
  };

  if (!values.fields) return result;

  const existingFieldXrefsByFieldId: Partial<Record<string, DataPackageApiFieldRecord>> = {};
  for (const fieldXref of existingApiFieldXrefs ?? []) {
    existingFieldXrefsByFieldId[fieldXref.api_field_id] = fieldXref;
  }

  // collect fields to add or update
  for (const [enabledFieldIdKey, value] of Object.entries(values.fields)) {
    if (!value) continue;
    // field entry keys are IDs prefixed with "id:"
    const newFieldID = parseInt(enabledFieldIdKey.split(':')[1], 10);
    const policies = values.fieldPolicies?.[enabledFieldIdKey] ?? null;

    const existingApiFieldXref = existingFieldXrefsByFieldId[newFieldID];

    if (!existingApiFieldXref) {
      // field is being enabled
      result.apiFieldXrefsToAdd.push({ api_field_id: newFieldID, policies });
      continue;
    }

    // field is already enabled - check for updates
    if (isEqual(existingApiFieldXref.policies, policies)) continue;
    result.apiFieldXrefsToUpdate.push({ id: existingApiFieldXref.id, policies });
  }

  // collect fields to remove
  for (const [existingFieldID, existingFieldXref] of Object.entries(existingFieldXrefsByFieldId)) {
    // field entry keys are IDs prefixed with "id:"
    if (!values.fields[`id:${existingFieldID}`] && existingFieldXref) {
      result.apiFieldXrefIdsToRemove.push(existingFieldXref.id);
    }
  }

  return result;
}

export function getFieldComparisonDataMap(apiFieldsXrefs: DataPackageApiFieldRecord[]) {
  const result: Record<string, Required<DataPackageApiFieldPolicies>> = {};
  for (const xref of apiFieldsXrefs) {
    result[xref.api_field_id] = {
      filters: xref.policies?.filters ?? [],
      sources: xref.policies?.sources ?? [],
    };
  }
  return result;
}
