import { DateField, ReferenceField, TextField, TextInput } from 'react-admin';
import CraftList from 'src/components/CraftList';
import NameIdReferenceField from 'src/fields/NameIdReferenceField';

const filters = [
  <TextInput key="address" source="address" alwaysOn />,
  <TextInput key="city" source="city" alwaysOn />,
  <TextInput key="website" source="website" alwaysOn />,
];

export const CompanyRequestsList = () => (
  <CraftList title="Company Requests" filters={filters}>
    <TextField source="id" />
    <TextField source="address" />
    <TextField source="branchind" />
    <TextField source="website" />

    <TextField source="company_name" />
    <TextField source="legal_name" />

    <TextField source="country" />
    <TextField source="city" />
    <TextField source="zip_code" />

    <TextField source="duns" />
    <TextField source="out_of_business" />

    <ReferenceField source="company_id" reference="companies">
      <NameIdReferenceField />
    </ReferenceField>

    <ReferenceField source="bulk_request_id" reference="bulk_requests">
      <TextField source="id" />
    </ReferenceField>

    <ReferenceField source="organization_id" reference="organizations">
      <NameIdReferenceField />
    </ReferenceField>
    <TextField source="status" />
    <DateField source="created_at" showTime />
    <DateField source="updated_at" showTime />
  </CraftList>
);

export default CompanyRequestsList;
