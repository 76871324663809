import z from 'zod';
import gql from 'graphql-tag';
import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import {
  Button,
  CreateBase,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { noop } from 'lodash';

import client from 'src/data/api';
import { CompanySubscriptionSetRecord } from 'src/types';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { useCompanySubscriptionSetContext } from '../../Context';

interface AddCompanySubscriptionsDialogContextValue {
  open: boolean;
  setOpen: () => void;
  setClosed: () => void;
  onSuccess?: (() => void) | undefined;
  setOnSuccess: (fn: () => void) => void;
}

const addCompanySubscriptionsDialogContextDefaultValue = {
  open: false,
  setOpen: noop,
  setClosed: noop,
  setOnSuccess: noop,
};

const AddCompanySubscriptionsDialogContext = createContext<AddCompanySubscriptionsDialogContextValue>(
  addCompanySubscriptionsDialogContextDefaultValue,
);

export const AddCompanySubscriptionsDialogContextProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);
  const [onSuccess, setOnSuccess] = useState<() => void>();

  const value = useMemo(
    () =>
      ({
        open,
        setOpen: () => setOpen(true),
        setClosed: () => setOpen(false),
        onSuccess,
        setOnSuccess: (fn) => setOnSuccess(() => fn),
      } as AddCompanySubscriptionsDialogContextValue),
    [open, onSuccess],
  );

  return (
    <AddCompanySubscriptionsDialogContext.Provider value={value}>
      {children}
    </AddCompanySubscriptionsDialogContext.Provider>
  );
};

export const useAddCompanySubscriptionsDialogContext = () => useContext(AddCompanySubscriptionsDialogContext);

const CustomToolbar = ({ onCancel }: { onCancel: () => void }) => (
  <Toolbar>
    <Grid container justifyContent="space-between">
      <Button label="Cancel" onClick={onCancel} />
      <SaveButton />
    </Grid>
  </Toolbar>
);

const companySubscriptionsSchema = z.object({
  companyIds: z
    .string()
    .nonempty()
    .trim()
    .regex(/^[\d\n]+$/, 'Company IDs should only contain IDs, each on a separate line')
    .transform((val) => val.split(/\n+/)),
});

export const AddCompanySubscriptionsDialog = () => {
  const dialogCtx = useAddCompanySubscriptionsDialogContext();
  const { currentSubscriptionCounts } = useCompanySubscriptionSetContext();
  const companySubscriptionSetRecord = useRecordContext<CompanySubscriptionSetRecord>();
  const notify = useNotify();
  const refresh = useRefresh();

  const [createCompanySubscriptions] = useMutation<{
    insert_company_subscriptions: { returning: { id: string }[] };
  }>(
    gql`
      mutation insert_company_subscriptions($objects: [company_subscriptions_insert_input!]!) {
        insert_company_subscriptions(
          objects: $objects
          on_conflict: { constraint: company_subscriptions_ci_cssi_unique, update_columns: [] }
        ) {
          returning {
            id
          }
        }
      }
    `,
    { client, errorPolicy: 'all' },
  );

  if (companySubscriptionSetRecord?.all_companies) {
    // "All Companies" sets don't have explicit company subscriptions, so this component shouldn't
    // be rendered
    return null;
  }

  const availableSubscriptionSlotCount =
    companySubscriptionSetRecord?.company_limit !== undefined && currentSubscriptionCounts !== undefined
      ? companySubscriptionSetRecord.company_limit - currentSubscriptionCounts.total
      : undefined;

  const handleSubmit = async (rawValues: Record<string, unknown>) => {
    if (!companySubscriptionSetRecord) return undefined;

    const validationResult = companySubscriptionsSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const companyIdSet = new Set(values.companyIds);

    if (availableSubscriptionSlotCount === undefined) {
      notify('There was a problem validating the number of available slots. Please try again.', {
        type: 'error',
        autoHideDuration: 8000,
      });
      return undefined;
    }

    // check if number of entered company IDs exceeds the number of available slots
    if (companyIdSet.size > availableSubscriptionSlotCount) {
      return {
        companyIds: `${companyIdSet.size} unique IDs were entered, but only
          ${availableSubscriptionSlotCount} slots are available`,
      };
    }

    const subscriptionsToInsert = [...companyIdSet].map((companyID) => ({
      company_id: companyID,
      company_subscription_set_id: companySubscriptionSetRecord.id,
    }));

    const { errors } = await createCompanySubscriptions({ variables: { objects: subscriptionsToInsert } });

    if (errors) {
      notify('There was a problem adding the Company Subscriptions. Please try again.', { type: 'error' });
      return undefined;
    }

    refresh();
    notify('Company Subscriptions added successfully.', { type: 'success' });
    if (dialogCtx.onSuccess) dialogCtx.onSuccess();
    dialogCtx.setClosed();
    return undefined;
  };

  return (
    <Dialog open={dialogCtx.open} onClose={dialogCtx.setClosed} fullWidth>
      <DialogTitle>Add Company Subscriptions</DialogTitle>
      <Divider />

      <DialogContent>
        <Typography textAlign="center" color="primary" fontWeight="600">
          Available subscription slots: {availableSubscriptionSlotCount}
        </Typography>
        <CreateBase>
          <SimpleForm
            mode="onChange"
            onSubmit={handleSubmit}
            toolbar={<CustomToolbar onCancel={dialogCtx.setClosed} />}
            mt={2}
            mx={1}
          >
            <TextInput
              label="Company ID(s)"
              source="companyIds"
              multiline
              validate={required()}
              fullWidth
              minRows={15}
              maxRows={15}
              autoFocus
            />
          </SimpleForm>
        </CreateBase>
      </DialogContent>
    </Dialog>
  );
};
