import ApartmentIcon from '@mui/icons-material/Apartment';

import { GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';

export const AuditEntriesResource: ResourceDefinition = {
  name: 'audit_entries',
  section: ResourceSections.PORTAL,
  icon: ApartmentIcon,

  extension: {
    operations: {
      [GET_LIST]: {
        operationName: 'portal_audit_entries',
      },
      [GET_ONE]: {
        operationName: 'portal_audit_entries',
      },
      [GET_MANY]: {
        operationName: 'portal_audit_entries',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'portal_audit_entries',
      },
    },
  },
};
