import { Tooltip, TooltipProps } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

export type InfoTooltipProps = Omit<TooltipProps, 'children'>;

const InfoTooltip = (props: InfoTooltipProps) => (
  <Tooltip {...props}>
    <InfoIcon color="info" fontSize="small" />
  </Tooltip>
);

export default InfoTooltip;
