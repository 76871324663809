import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { FormGroupContext, Loading, MenuItemLinkClasses } from 'react-admin';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { useResolvedPath } from 'react-router';
import kebabCase from 'lodash/kebabCase';

import { useCraftSidebarContext } from './CraftSidebarContext';

export interface CraftPageSectionProps extends StackProps {
  title: string;
  loading?: boolean | undefined;
  caption?: ReactNode;
}

/**
 * Container component that should be used for the sections of any
 * given page.
 *
 * This component also registers respective section items in the sidebar,
 * so that when User navigates to a page with sections - they see
 * the sections in the sidebar.
 */
export const CraftPageSection = ({ title, loading, children, caption, ...rest }: CraftPageSectionProps) => {
  const maybeTabs = useContext(FormGroupContext);
  const resolvedPath = useResolvedPath('');

  const slug = useMemo(() => kebabCase(title), []);
  const { addItem, removeItem } = useCraftSidebarContext();

  useEffect(() => {
    const sidebarItem = {
      key: slug,
      primaryText: title,
      sx: {
        whiteSpace: 'inherit',
        [`&.${MenuItemLinkClasses.active}`]: {
          background: 'initial',
          color: 'text.secondary',
        },
      },
      to: {
        ...resolvedPath,
        hash: slug,
      },
    };

    if (maybeTabs) {
      sidebarItem.to.pathname = `${sidebarItem.to.pathname}/${maybeTabs}`;
    }

    addItem(sidebarItem);

    return () => {
      removeItem(sidebarItem);
    };
  }, []);

  return (
    <Stack width={1} {...rest}>
      <Typography id={slug} variant="h6" mb={2}>
        {title}
      </Typography>
      {caption && (
        <Typography variant="caption" mb={2}>
          {caption}
        </Typography>
      )}
      {loading ? (
        <Box width="100%" p={8}>
          <Loading />
        </Box>
      ) : (
        children
      )}
    </Stack>
  );
};

export default CraftPageSection;
