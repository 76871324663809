import gql from 'graphql-tag';
import { client } from '../../../data/api';
import { SignalsAlertsType } from './types';

export const fetchPublishedCommodities = async () => {
  const commodities = await client.query<{
    signals_alerts: SignalsAlertsType[];
  }>({
    query: gql`
      query PublishedCommodities {
        signals_alerts(
          where: { resource_id: { _is_null: false } }
          distinct_on: resource_id
          order_by: { resource_id: asc }
        ) {
          resource_id
        }
      }
    `,
    variables: {},
    fetchPolicy: 'network-only',
  });

  return commodities.data.signals_alerts ?? [];
};
