import { Link, LinkProps } from '@mui/material';
import { useRecordContext } from 'react-admin';

export enum FieldLabels {
  HREF = 'href',
  TRUNCATED_HREF = 'truncatedHref',
}

export const LabeledUrlField = ({
  source,
  label,
  ...rest
}: { source: string; label?: string | FieldLabels } & LinkProps) => {
  const record = useRecordContext();

  if (!record || !source) {
    return <span>URL Missing</span>;
  }

  let fieldLabel = label;
  const href = record[source];

  if (!href) {
    return <span>-</span>;
  }

  if (label === FieldLabels.HREF) {
    fieldLabel = href;
  }

  if (label === FieldLabels.TRUNCATED_HREF) {
    fieldLabel = href.length > 50 ? `${href.slice(0, 20)}[...]${href.slice(-20)}` : href;
  }

  if (!href.startsWith('http')) {
    return <span>{fieldLabel ?? href}</span>;
  }

  return (
    <Link href={href} {...rest}>
      {fieldLabel ?? href}
    </Link>
  );
};

LabeledUrlField.FieldLabels = FieldLabels;

export default LabeledUrlField;
