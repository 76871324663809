import gql from 'graphql-tag';
import z from 'zod';
import { Identifier } from 'ra-core';
import { get, isEqual, isEqualWith } from 'lodash';

import { externalApiClientSchema } from './validation';
import { ExternalApiPolicyRecord, ExternalApiClientRecord, ExternalApiRowLevelAccessPolicy } from 'src/types';

interface PolicyUpdateParams {
  id: number;
  available: boolean;
}

type FormValues = z.infer<typeof externalApiClientSchema>;

export function resolveMutationParams<R extends ExternalApiClientRecord | undefined>(
  values: FormValues,
  record?: R,
): R extends undefined
  ? ReturnType<typeof resolveCreateMutationParams>
  : ReturnType<typeof resolveUpdateMutationParams>;
export function resolveMutationParams(
  values: FormValues,
  record?: ExternalApiClientRecord,
): ReturnType<typeof resolveUpdateMutationParams> | ReturnType<typeof resolveCreateMutationParams> {
  // handle client params
  const externalApiClientParams = {
    name: values.name,
    requests_limit: values.requests_limit,
    start_date: values.start_date,
    end_date: values.end_date,
    client_type: values.client_type,
    ...(!record && { created_at: new Date().toISOString() }),
    updated_at: new Date().toISOString(),
  };

  const { policiesToInsert, policiesToUpdate } = resolvePolicyParams(values, record);
  const rowLevelAccessPolicyData = resolveRowLevelAccessPolicyParams(values, record);

  return record
    ? resolveUpdateMutationParams(
        record.id,
        externalApiClientParams,
        policiesToInsert,
        policiesToUpdate,
        rowLevelAccessPolicyData,
      )
    : resolveCreateMutationParams(externalApiClientParams, policiesToInsert, rowLevelAccessPolicyData);
}

function resolvePolicyParams(values: FormValues, record?: ExternalApiClientRecord) {
  const existingPoliciesByField: Partial<Record<string, ExternalApiPolicyRecord>> = {};
  const existingSourcesByField: Partial<Record<string, ExternalApiPolicyRecord[]>> = {};
  for (const policy of record?.policies ?? []) {
    if (policy.field_type.includes(':')) {
      // policy is for field source
      const fieldPath = policy.field_type.split(':')[0];
      existingSourcesByField[fieldPath] ||= [];
      existingSourcesByField[fieldPath]?.push(policy);
      continue;
    }

    existingPoliciesByField[policy.field_type] = policy;
  }

  const policiesToInsert: string[] = [];
  const policiesToUpdate: PolicyUpdateParams[] = [];

  // resolve all field and feature policies
  // source policies are resolved along with fields
  for (const newPolicies of [values.fields, values.features]) {
    for (const [slashPath, value] of Object.entries(newPolicies)) {
      const fieldTypeVal = slashPath.replaceAll('/', '.');
      const existingPolicy = existingPoliciesByField[fieldTypeVal];
      const isEnabled = !!value;

      if (existingPolicy) {
        if (existingPolicy.available !== isEnabled) {
          policiesToUpdate.push({ id: existingPolicy.id, available: isEnabled });
        }
      } else if (isEnabled) {
        // policy is new
        // no need to create disabled field policy
        policiesToInsert.push(fieldTypeVal);
      }

      // for fields with source options
      if (isEnabled && values.sources?.[fieldTypeVal]) {
        handleFieldSources(
          values.sources[fieldTypeVal] ?? [],
          existingSourcesByField[fieldTypeVal],
          policiesToInsert,
          policiesToUpdate,
        );
      }
    }
  }

  return { policiesToInsert, policiesToUpdate };
}

function handleFieldSources(
  newSources: string[],
  existingSourcePolicies: ExternalApiPolicyRecord[] | undefined,
  policiesToInsert: string[],
  policiesToUpdate: PolicyUpdateParams[],
) {
  // handle selected & existing sources
  for (const source of Object.values(newSources)) {
    const existingSourcePolicy = existingSourcePolicies?.find((policy) => policy.field_type === source);

    if (existingSourcePolicy) {
      if (existingSourcePolicy.available) continue; // existing policy already enabled
      policiesToUpdate.push({ id: existingSourcePolicy.id, available: true });
      continue;
    }

    policiesToInsert.push(source);
  }

  if (!existingSourcePolicies) return;

  // handle unselected existing sources
  for (const existingPolicy of existingSourcePolicies) {
    if (!existingPolicy.available || newSources.includes(existingPolicy.field_type)) {
      continue;
    }
    policiesToUpdate.push({
      id: existingPolicy.id,
      available: false,
    });
  }
}

function resolveRowLevelAccessPolicyParams(values: FormValues, record?: ExternalApiClientRecord) {
  const existingRowLevelAccessPolicy = record?.row_level_access_policy;

  /* handle risks */

  const existingRisksVal = existingRowLevelAccessPolicy?.policies?.risk;
  const existingRiskStrings = existingRisksVal?.split(',');

  // we use a Map to maintain insertion order for future comparison
  const newRisksMap = new Map<string, string>();

  existingRiskStrings?.forEach((rawRiskString) => {
    if (!get(values.risks, rawRiskString.replaceAll(':', '.'))) return;
    const valueStartIdx = rawRiskString.lastIndexOf(':');
    newRisksMap.set(rawRiskString.slice(0, valueStartIdx), rawRiskString.slice(valueStartIdx + 1));
  });

  if (values.risks.esg.ecovadis.value) {
    newRisksMap.set('esg:ecovadis', values.risks.esg.ecovadis.value);
  }
  if (values.risks.cybersecurity.lab1.value) {
    newRisksMap.set('cybersecurity:lab1', values.risks.cybersecurity.lab1.value);
  }

  const newRisksVal = [...newRisksMap.entries()].map(([path, val]) => `${path}:${val}`).join(',');

  // collect policy data for comparison
  const newRowLevelAccessPolicies: ExternalApiRowLevelAccessPolicy['policies'] = {
    // include policy data not managed via this UI
    ...existingRowLevelAccessPolicy?.policies,
    risk: newRisksVal,
    locations: {
      filters: values.rowLevelAccessPolicy?.locations?.filters ?? [],
    },
  };

  const policyHasChanges = !isEqualWith(existingRowLevelAccessPolicy?.policies, newRowLevelAccessPolicies, (a, b) => {
    if (!Array.isArray(a) || !Array.isArray(b)) return undefined;
    return isEqual([...a].sort(), [...b].sort());
  });

  return policyHasChanges
    ? {
        ...(existingRowLevelAccessPolicy
          ? { id: existingRowLevelAccessPolicy.id }
          : { external_api_client_id: record ? record.id : undefined, created_at: new Date().toISOString() }),
        policies: newRowLevelAccessPolicies,
        updated_at: new Date().toISOString(),
      }
    : null;
}

const genUpdateExternalApiClientGQL = (variableDeclarations: string[] = [], mutationSelections: string[] = []) => gql`
  mutation updateExternalApiClient(
    $externalApiClientID: Int!,
    $externalApiClientParams: external_api_clients_set_input!,
    ${variableDeclarations.join('\n,')}
  ) {
    update_external_api_clients(where: { id: { _eq: $externalApiClientID } }, _set: $externalApiClientParams) {
      affected_rows
    }
    ${mutationSelections.join('')}
  }
`;

export const updateExternalApiClientGQL = genUpdateExternalApiClientGQL();

const insertExternalApiPoliciesFragment = /* GraphQL */ `
  insert_external_api_policies(objects: $newExternalApiPolicies) {
    affected_rows
  }
`;

const updateExternalApiPoliciesFragment = /* GraphQL */ `
  update_external_api_policies_many(updates: $externalApiPoliciesUpdates) {
    affected_rows
  }
`;

const updateExternalApiRisksFragment = /* GraphQL */ `
  update_external_api_row_level_access_policies_by_pk(
    pk_columns: { id: $rowLevelAccessPolicyID },
    _set: $rowLevelAccessPolicyParams
  ) {
    id
  }
`;

const insertExternalApiRisksFragment = /* GraphQL */ `
  insert_external_api_row_level_access_policies_one(
    object: $rowLevelAccessPolicyParams
  ) {
    id
  }
`;

interface NewRiskParams {
  id?: number;
  policies: unknown;
}

function resolveUpdateMutationParams(
  externalApiClientID: Identifier,
  externalApiClientParams: Record<string, unknown>,
  policiesToInsert: string[],
  policiesToUpdate: PolicyUpdateParams[],
  rowLevelAccessPolicyData: NewRiskParams | null,
) {
  const variableDeclarations = [];
  const mutationSelections = [];
  if (policiesToInsert.length) {
    variableDeclarations.push('$newExternalApiPolicies: [external_api_policies_insert_input!]!');
    mutationSelections.push(insertExternalApiPoliciesFragment);
  }
  if (policiesToUpdate.length) {
    variableDeclarations.push('$externalApiPoliciesUpdates: [external_api_policies_updates!]!');
    mutationSelections.push(updateExternalApiPoliciesFragment);
  }
  if (rowLevelAccessPolicyData) {
    if (rowLevelAccessPolicyData.id) {
      variableDeclarations.push(
        '$rowLevelAccessPolicyID: Int!',
        '$rowLevelAccessPolicyParams: external_api_row_level_access_policies_set_input!',
      );
      mutationSelections.push(updateExternalApiRisksFragment);
    } else {
      variableDeclarations.push('$rowLevelAccessPolicyParams: external_api_row_level_access_policies_insert_input!');
      mutationSelections.push(insertExternalApiRisksFragment);
    }
  }

  const mutationDoc = genUpdateExternalApiClientGQL(variableDeclarations, mutationSelections);

  const nowISO = new Date().toISOString();
  const newExternalApiPolicies = policiesToInsert.map((fieldType) => ({
    external_api_client_id: externalApiClientID,
    field_type: fieldType,
    available: true,
    created_at: nowISO,
    updated_at: nowISO,
  }));
  const externalApiPoliciesUpdates = policiesToUpdate.map(({ id, ...params }) => ({
    where: { id: { _eq: id } },
    _set: { ...params, updated_at: nowISO },
  }));

  const { id: rowLevelAccessPolicyID, ...rowLevelAccessPolicyParams } = rowLevelAccessPolicyData ?? {};

  return {
    mutation: mutationDoc,
    variables: {
      externalApiClientID,
      externalApiClientParams,
      ...(newExternalApiPolicies.length && { newExternalApiPolicies }),
      ...(externalApiPoliciesUpdates.length && { externalApiPoliciesUpdates }),
      ...(rowLevelAccessPolicyData && { rowLevelAccessPolicyParams }),
      ...(rowLevelAccessPolicyID && { rowLevelAccessPolicyID }),
    },
  };
}

function resolveCreateMutationParams(
  externalApiClientParams: Record<string, unknown>,
  policiesToInsert: string[],
  rowLevelAccessPolicyData: Omit<NewRiskParams, 'id'> | null,
) {
  const extraParams: Record<string, unknown> = {};

  const nowISO = new Date().toISOString();

  if (policiesToInsert.length) {
    extraParams.policies = {
      data: policiesToInsert.map((fieldType) => ({
        field_type: fieldType,
        available: true,
        created_at: nowISO,
        updated_at: nowISO,
      })),
    };
  }
  if (rowLevelAccessPolicyData) {
    extraParams.row_level_access_policy = {
      data: rowLevelAccessPolicyData,
    };
  }

  return { variables: { externalApiClientParams: { ...externalApiClientParams, ...extraParams } } };
}
