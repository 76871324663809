import { Paper } from '@mui/material';
import { FormDataConsumer, Labeled } from 'react-admin';
import { ColorInput, ColorInputProps } from 'react-admin-color-picker';
import { get } from 'lodash';

const CraftColorInput = ({ source, label }: ColorInputProps) => (
  <FormDataConsumer>
    {({ formData }) => (
      <Paper variant="outlined" sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, marginBottom: 3 }}>
        <Labeled {...(label !== undefined ? { label } : null)} flex={3} mt={1} fontSize="1.3em">
          <ColorInput source={source} label={get(formData, source) ? '' : 'Select a color'} />
        </Labeled>
        <Paper
          sx={{
            flex: 1,
            backgroundColor: get(formData, source) || 'transparent',
          }}
        />
      </Paper>
    )}
  </FormDataConsumer>
);

export default CraftColorInput;
