import { FormTab, FormTabProps, SelectInput, required } from 'react-admin';
import { CraftDateInput } from 'src/components/CraftDateInput';

import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import TextInput from 'src/inputs/TextInput';
import IntegerInput from 'src/inputs/IntegerInput';
import { EXTERNAL_API_CLIENT_TYPES } from 'src/utils/defaults/Constants';

const DetailsTab = ({ mode, ...rest }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => (
  <FormTab label="Details" {...rest}>
    <CraftPageSection title="General">
      <CraftTwoColumns
        left={
          <>
            <TextInput source="name" validate={required()} fullWidth autoFocus={mode === 'create'} />
            <CraftDateInput source="start_date" fullWidth />
            <SelectInput
              source="client_type"
              choices={EXTERNAL_API_CLIENT_TYPES}
              validate={required()}
              fullWidth
              sx={{ mt: 0 }}
            />
          </>
        }
        right={
          <>
            <TextInput source="token" disabled fullWidth />
            <CraftDateInput source="end_date" fullWidth />
            <IntegerInput source="requests_limit" fullWidth />
          </>
        }
      />
    </CraftPageSection>
  </FormTab>
);

export default DetailsTab;
