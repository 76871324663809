import { Card } from '@mui/material';
import { ReferenceManyField, SimpleList } from 'react-admin';

import { AuditEntryAttributionField } from './AuditEntryAttributionField';
import { AuditEntryMetaField } from './AuditEntryMetaField';
import { AuditEntryTypeField } from './AuditEntryTypeField';

export const AuditEntriesEmbeddedList = ({ from }: { from: string }) => (
  <Card sx={{ px: 2 }}>
    <ReferenceManyField
      perPage={5}
      reference="audit_entries"
      sort={{ field: 'created_at', order: 'DESC' }}
      target={from}
      source="id"
      emptyText="No audit entries"
    >
      <SimpleList
        primaryText={<AuditEntryTypeField />}
        secondaryText={<AuditEntryAttributionField />}
        tertiaryText={<AuditEntryMetaField />}
        linkType={false}
      />
    </ReferenceManyField>
  </Card>
);
