import gql from 'graphql-tag';
import z from 'zod';
import { Identifier } from 'react-admin';

import { DataPackageKind, DataPackageRecord } from 'src/types';
import {
  NewApiFieldXrefParams,
  ApiFieldXrefUpdateParams,
  apiFieldsConfigSchema,
  resolveApiFieldsConfig,
} from 'src/utils/apiFieldsConfig';
import { DATA_PACKAGE_KINDS } from 'src/utils/defaults/Constants';

export const dataPackageSchema = apiFieldsConfigSchema.extend({
  name: z.string().trim().nonempty(),
  kind: z.enum(DATA_PACKAGE_KINDS.map(({ id }) => id) as [DataPackageKind, ...DataPackageKind[]]),
  is_deprecated: z.boolean().default(false),
});

type FormValues = z.infer<typeof dataPackageSchema>;

export function resolveMutationParams<R extends DataPackageRecord>(
  values: FormValues,
  record?: R,
): R extends undefined
  ? ReturnType<typeof resolveCreateMutationParams>
  : ReturnType<typeof resolveUpdateMutationParams>;
export function resolveMutationParams(values: FormValues, record?: DataPackageRecord) {
  const dataPackageParams = {
    name: values.name,
    kind: values.kind,
    is_deprecated: values.is_deprecated,
  };

  const { apiFieldXrefsToAdd, apiFieldXrefsToUpdate, apiFieldXrefIdsToRemove } = resolveApiFieldsConfig(
    values,
    record?.api_fields_xrefs,
  );

  return record
    ? resolveUpdateMutationParams(
        record.id,
        dataPackageParams,
        apiFieldXrefsToAdd,
        apiFieldXrefsToUpdate,
        apiFieldXrefIdsToRemove,
      )
    : resolveCreateMutationParams(dataPackageParams, apiFieldXrefsToAdd);
}

const genUpdateDataPackageGQL = (variableDeclarations: string[] = [], mutationSelections: string[] = []) => gql`
  mutation updateDataPackage(
    $dataPackageID: Int!,
    $dataPackageParams: data_packages_set_input!,
    ${variableDeclarations.join('\n,')}
  ) {
    update_data_packages(
      where: { id: { _eq: $dataPackageID } },
      _set: $dataPackageParams
    ) {
      affected_rows
    }
    ${mutationSelections.join('')}
  }
`;

export const updateDataPackageGQL = genUpdateDataPackageGQL();

const addApiFieldsFragment = /* GraphQL */ `
  insert_data_packages_api_fields(objects: $newApiFields) {
    affected_rows
  }
`;

const updateApiFieldsFragment = /* GraphQL */ `
  update_data_packages_api_fields_many(updates: $apiFieldUpdates) {
    affected_rows
  }
`;

const removeApiFieldsFragment = /* GraphQL */ `
  delete_data_packages_api_fields(
    where: {
      data_package_id: { _eq: $dataPackageID },
      id: { _in: $apiFieldXrefIdsToRemove }
    }
  ) {
    affected_rows
  }
`;

function resolveCreateMutationParams(
  dataPackageParams: Record<string, unknown>,
  apiFieldXrefsToAdd: NewApiFieldXrefParams[],
) {
  return {
    variables: {
      dataPackageParams: {
        ...dataPackageParams,
        api_fields: {
          data: apiFieldXrefsToAdd.map((field) => ({
            api_field_id: field.api_field_id,
            policies: field.policies,
          })),
        },
      },
    },
  };
}

function resolveUpdateMutationParams(
  dataPackageID: Identifier,
  dataPackageParams: Record<string, unknown>,
  apiFieldXrefsToAdd: NewApiFieldXrefParams[],
  apiFieldXrefsToUpdate: ApiFieldXrefUpdateParams[],
  apiFieldXrefIdsToRemove: number[],
) {
  const variableDeclarations = [];
  const mutationSelections = [];
  if (apiFieldXrefsToAdd.length) {
    variableDeclarations.push('$newApiFields: [data_packages_api_fields_insert_input!]!');
    mutationSelections.push(addApiFieldsFragment);
  }
  if (apiFieldXrefsToUpdate.length) {
    variableDeclarations.push('$apiFieldUpdates: [data_packages_api_fields_updates!]!');
    mutationSelections.push(updateApiFieldsFragment);
  }
  if (apiFieldXrefIdsToRemove.length) {
    variableDeclarations.push('$apiFieldXrefIdsToRemove: [Int!]');
    mutationSelections.push(removeApiFieldsFragment);
  }

  const mutationDoc = genUpdateDataPackageGQL(variableDeclarations, mutationSelections);

  const newApiFields = apiFieldXrefsToAdd.map((field) => ({
    data_package_id: dataPackageID,
    api_field_id: field.api_field_id,
    policies: field.policies,
  }));

  const apiFieldUpdates = apiFieldXrefsToUpdate.map((fieldXref) => ({
    where: { id: { _eq: fieldXref.id }, data_package_id: { _eq: dataPackageID } },
    _set: { policies: fieldXref.policies },
  }));

  return {
    mutation: mutationDoc,
    variables: {
      dataPackageID,
      dataPackageParams,
      ...(newApiFields.length && { newApiFields }),
      ...(apiFieldUpdates.length && { apiFieldUpdates }),
      ...(apiFieldXrefIdsToRemove.length && { apiFieldXrefIdsToRemove }),
    },
  };
}
