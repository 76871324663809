import { TransformData } from 'react-admin';

export const transformValues: TransformData = (values) => {
  const result = values;
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }
  if (!values?.alerts_filter?.company_id?._in) {
    result.alerts_filter = null;
  }
  return result;
};

export default transformValues;
