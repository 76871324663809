import styled from '@emotion/styled';
import { Menu } from 'react-admin';

const CustomMenuStyled = styled(Menu)`
  &.RaMenu-closed > .section-title {
    display: none;
  }
`;

export default CustomMenuStyled;
