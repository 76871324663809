const CraftLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { color } = props;
  const background = color ?? '#0076EA';
  const accent = color ?? '#00AEFF';
  const bar = color ?? '#00DFFF';

  return (
    <svg height="32" viewBox="0 0 100 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M92.8445 0H87.689V9.55556H92.8445V0Z" fill={background} />
      <path d="M92.8001 14.5332L92.8445 14.3555V31.4221H87.689V14.5332H92.8001Z" fill={background} />
      <path
        d="M15.6889 5.51098C18.7111 5.51098 21.2 6.75542 23.5111 9.46654L23.6889 9.64431H29.9556L29.5556 8.88876C26.6667 3.55542 21.5111 0.533203 15.3778 0.533203C6.88889 0.533203 0 7.64431 0 16.3554C0 24.6665 7.15556 31.9554 15.3333 31.9554C21.4222 31.9554 26.2667 29.1554 29.3333 23.911L29.7778 23.111H23.3778L23.2 23.2888C21.0222 25.7776 18.4444 26.9776 15.4222 26.9776C9.82222 26.9776 5.28889 22.0443 5.28889 15.9554C5.33333 10.311 10.0889 5.51098 15.6889 5.51098Z"
        fill={background}
      />
      <path
        d="M37.1554 10.6222V9.51113H32.4443V31.4667H37.4221V19.2C37.4221 16.8889 38.2221 14.1333 42.0443 14.0445H42.5332V8.93335L41.9999 8.97779C39.911 9.06668 38.311 9.64446 37.1554 10.6222Z"
        fill={background}
      />
      <path
        d="M61.7335 11.6C60.089 9.86668 57.7335 8.93335 54.9335 8.93335C48.4001 8.93335 43.4668 14.0445 43.4668 20.8C43.4668 27.1111 48.5779 32 55.0668 32C57.7779 32 60.1335 30.9778 61.7335 29.2V31.4667H66.7112V9.51113H61.7335V11.6ZM61.7779 20.5333C61.7779 24.3111 58.7557 27.3778 55.0224 27.3778C51.4224 27.3778 48.4001 24.2667 48.4001 20.5333C48.4001 16.7111 51.3779 13.6 55.0224 13.6C58.889 13.6 61.7779 16.5778 61.7779 20.5333Z"
        fill={background}
      />
      <path
        d="M79.3777 14.4001V31.4668H74.2222V14.5334L75.8666 9.55566H75.9111L74.2222 14.5334H79.3333L79.3777 14.4001Z"
        fill={background}
      />
      <path
        d="M76.8887 11.4667C76.9776 11.2445 77.0664 10.9778 77.1553 10.7556C77.0664 11.0223 76.9776 11.2445 76.8887 11.4667Z"
        fill={accent}
      />
      <path d="M99.7781 9.55566L98.0447 14.5334H92.8003L92.8447 14.3557L94.5336 9.55566H99.7781Z" fill={accent} />
      <path
        d="M94.5335 9.55566L92.8446 14.3557L92.8002 14.5334H79.3335L79.3779 14.4001L81.0668 9.55566H94.5335Z"
        fill={bar}
      />
      <path d="M94.5781 9.55566L92.8447 14.5334H92.8003L92.8447 14.3557L94.5336 9.55566H94.5781Z" fill={bar} />
      <path
        d="M84.2666 0.0444336V4.84443H82.6222C79.6888 4.84443 79.3777 6.04443 79.3777 6.93332V9.55554H75.8666L74.2222 14.5333H68.2666L69.911 9.55554H74.2222V6.93332C74.2222 2.66666 77.1111 0.0444336 81.7777 0.0444336H84.2666Z"
        fill={background}
      />
      <path d="M75.9111 9.55566L74.2222 14.5334L75.8666 9.55566H75.9111Z" fill={background} />
      <path d="M81.0666 9.55566L79.3777 14.4001L79.3333 14.5334H74.2222L75.9111 9.55566H81.0666Z" fill={accent} />
      <path d="M75.8666 9.55566H75.9111L74.2222 14.5334L75.8666 9.55566Z" fill={accent} />
      <path d="M81.1113 9.55566L79.3779 14.5334H79.3335L79.3779 14.4001L81.0668 9.55566H81.1113Z" fill={accent} />
    </svg>
  );
};

export default CraftLogo;
