import { Chip, ChipProps } from '@mui/material';
import { SelectField, useRecordContext } from 'react-admin';
import { COREDB_COMPANY_STATUSES } from '../utils/defaults/Constants';

export const StatusChip = (props: ChipProps) => {
  const record = useRecordContext();

  return <Chip color={record.color} label={record.name} {...props} />;
};

export const CompanyStatusField = ({ chip }: { chip?: ChipProps; label?: string }) => (
  <SelectField
    source="state"
    label="Status"
    choices={COREDB_COMPANY_STATUSES}
    optionText={<StatusChip {...chip} />}
    translateChoice={false}
  />
);

export default CompanyStatusField;
