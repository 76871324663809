import { CraftCreate } from 'src/components/CraftCreate';
import { SimpleForm, useNotify, useRedirect } from 'react-admin';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { userSubscriptionsListRoute } from 'src/utils/routeHelpers';
import { transformValues } from './transformValues';
import { SharedInputs } from './SharedInputs';
import { useSearchParams } from 'react-router-dom';

export const UserSubscriptionsCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('userId') || '';

  if (Number.isNaN(parseInt(userId, 10))) {
    notify('Invalid user id. A valid user id is required to create an user configuration.', {
      type: 'error',
    });
    redirect('/organization_config');
  }

  const onSuccess = async () => {
    notify('User Subscriptions created', { type: 'success' });
    redirect(userSubscriptionsListRoute(userId));
  };

  return (
    <CraftCreate mutationOptions={{ onSuccess }} transform={transformValues}>
      <SimpleForm toolbar={<CraftSimpleFormToolbar />}>
        <h2>Add a user subscription</h2>
        <SharedInputs userId={userId} />
      </SimpleForm>
    </CraftCreate>
  );
};

export default UserSubscriptionsCreate;
