import { useRecordContext } from 'react-admin';
import React, { FC } from 'react';
import { Box } from '@mui/material';

/**
 * Renders current record "as is", in its entirety.
 */
export const RawField: FC = () => {
  const record = useRecordContext();
  return (
    <Box
      component="pre"
      sx={{
        backgroundColor: 'background.default',
        padding: 2,
        borderRadius: 1,
        color: 'text.primary',
        border: '1px solid',
        borderColor: 'border.main',
        whiteSpace: 'pre-wrap',
      }}
    >
      {JSON.stringify(record, null, 2)}
    </Box>
  );
};
