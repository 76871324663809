import { DateInput, DateInputProps, useNotify } from 'react-admin';

interface CraftDateInputProps extends DateInputProps {
  disallowFuture?: boolean;
}

export const CraftDateInput = ({ disallowFuture = false, parse, ...rest }: CraftDateInputProps) => {
  const notify = useNotify();
  const today = new Date().toISOString();
  const parseAndDisallowFuture = (currentDate: string) => {
    if (currentDate) {
      const parsedDate = Date.parse(currentDate);
      const todayInSeconds = Date.parse(today);

      if (parsedDate > todayInSeconds) {
        notify("Setting today's date, future dates are not allowed for this field");
        if (parse) {
          return parse(today);
        }
        return today;
      }

      if (parse) {
        return parse(currentDate);
      }
      return currentDate;
    }
    return null;
  };

  const parseFunction = disallowFuture ? parseAndDisallowFuture : parse;
  return <DateInput {...(parseFunction ? { parse: parseFunction } : null)} {...rest} />;
};
