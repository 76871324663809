import { DeleteButton, DeleteButtonProps, SaveButton, SaveButtonProps, Toolbar } from 'react-admin';
import { BackButton } from './BackButton';

type CraftSimpleFormToolbarProps = {
  saveButtonProps?: SaveButtonProps;
  deleteButtonProps?: DeleteButtonProps;
};

/**
 * Toolbar for the <SimpleForm /> component that
 * implements the desired actions placement.
 */
export const CraftSimpleFormToolbar = ({ saveButtonProps, deleteButtonProps }: CraftSimpleFormToolbarProps) => (
  <Toolbar
    sx={{
      gap: '0.5rem',
      justifyContent: 'flex-end',
    }}
  >
    <SaveButton {...saveButtonProps} />
    <BackButton label="Cancel" />
    {deleteButtonProps && <DeleteButton {...deleteButtonProps} />}
  </Toolbar>
);

export default CraftSimpleFormToolbar;
