import { DialogActions, Box, Grid } from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { Button, Toolbar } from 'react-admin';

export const BulkToolbar = ({
  handleCloseClick,
  buttonComponents,
  infoComponents,
}: {
  handleCloseClick: () => void;
  buttonComponents?: React.ReactNode;
  infoComponents?: React.ReactNode;
}) => (
  <Toolbar>
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <CancelIcon />
          </Button>
          {buttonComponents}
        </DialogActions>
      </Grid>
      <Grid item justifyContent="flex-end">
        <Box>{infoComponents}</Box>
      </Grid>
    </Grid>
  </Toolbar>
);

export default BulkToolbar;
