import { ImageField, ImageFieldProps } from 'react-admin';

const avatarStyles = {
  width: '2rem',
  height: '2rem',
  overflow: 'hidden',
  borderRadius: '100vh',

  '& > .RaImageField-image': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    margin: 0,
  },
};

export const AvatarField = ({ sx, ...rest }: ImageFieldProps) => (
  <ImageField sx={{ ...avatarStyles, ...sx }} {...rest} />
);

export default AvatarField;
