import { FormTab, FormTabProps } from 'react-admin';

import { CraftPageSection } from 'src/components/CraftPageSection';
import BooleanInput from 'src/inputs/BooleanInput';

const InternalSettingsTab = (props: Omit<FormTabProps, 'label'>) => (
  <FormTab label="Internal Settings" {...props}>
    <CraftPageSection title="Admin access">
      <BooleanInput source="settings.data.internalOrganizationSettings.showAdminPanel" label="Enable Admin Panel" />
    </CraftPageSection>
  </FormTab>
);

export default InternalSettingsTab;
