import { CREATE, GET_LIST, GET_ONE, GET_MANY, UPDATE, DELETE, GET_MANY_REFERENCE, UPDATE_MANY } from 'react-admin';

import { ResourceDefinition, ResourceSections } from 'src/types';
import UserConfigCreate from './Create';
import UserConfigEdit from './Edit';
import UserConfigList from './List';

export const UserConfigResource: ResourceDefinition = {
  name: 'user_config',
  section: ResourceSections.ALERTS,

  list: UserConfigList,
  create: UserConfigCreate,
  edit: UserConfigEdit,

  permissions: {
    create: ['alerts:edit'],
    edit: ['alerts:edit'],
    list: ['alerts:read'],
  },

  extension: {
    idKey: ['user_id'],
    operations: {
      [CREATE]: {
        operationName: 'signals_insert_user_config',
      },
      [GET_LIST]: {
        operationName: 'signals_user_config',
      },
      [GET_ONE]: {
        operationName: 'signals_user_config',
      },
      [GET_MANY]: {
        operationName: 'signals_user_config',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'signals_user_config',
      },
      [UPDATE]: {
        operationName: 'signals_update_user_config',
      },
      [UPDATE_MANY]: {
        operationName: 'signals_update_user_config_many',
      },
      [DELETE]: {
        operationName: 'signals_delete_user_config',
      },
    },
  },
};
