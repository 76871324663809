import { TextInput, TextInputProps } from 'react-admin';

// This is the upper end of the range for a Postgres int
const MAX_ALLOWED_NUMBER = 2_147_483_647;
const MAX_ALLOWED_NUMBER_DIGITS = MAX_ALLOWED_NUMBER.toString().length;

const IntegerInput = (props: Omit<TextInputProps, 'parse'>) => (
  <TextInput
    parse={(val: string) => {
      if (!val) return null;
      const valStr = val.replaceAll(/\D/g, '');
      const valNum = parseInt(valStr, 10);
      if (Number.isNaN(valNum)) return null;
      // if val > max allowed number, remove digits at, or beyond, those of max allowed number
      return valNum > MAX_ALLOWED_NUMBER ? parseInt(valStr.slice(0, MAX_ALLOWED_NUMBER_DIGITS - 1), 10) : valNum;
    }}
    {...props}
  />
);

export default IntegerInput;
