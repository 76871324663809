import { CraftCreate } from 'src/components/CraftCreate';
import { SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { SharedInputs } from './SharedInputs';
import { FormHeader } from './FormHeader';
import { transformValues } from './transformValues';
import { useSearchParams } from 'react-router-dom';

export const UserConfigCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('userId') || '';

  if (Number.isNaN(parseInt(userId, 10))) {
    notify('Invalid user id. A valid user id is required to create an user configuration.', {
      type: 'error',
    });
    redirect('/organization_config');
  }

  const onSuccess = async () => {
    notify('User Config updated', { type: 'success' });
    redirect('/organization_config');
  };

  return (
    <CraftCreate
      mutationOptions={{ onSuccess }}
      transform={transformValues}
      header={<FormHeader record={{ user_id: userId }} />}
    >
      <SimpleForm toolbar={<CraftSimpleFormToolbar saveButtonProps={{ alwaysEnable: true }} />}>
        <h2>Edit a User Config</h2>
        <TextInput source="user_id" label="User" fullWidth defaultValue={userId} disabled required />
        <SharedInputs />
      </SimpleForm>
    </CraftCreate>
  );
};

export default UserConfigCreate;
