import type { ChipProps } from '@mui/material';
import startCase from 'lodash/startCase';
import compact from 'lodash/compact';
import {
  EnumeableTopics,
  ENUMERABLE_TOPICS,
  LocationTopicsHierarchy,
  PreferredTopicName,
  TopicMetadata,
  TopicMetadataDisplayModel,
  TopicTypes,
  TOPIC_TYPE_NAMES,
} from './TopicMetadataTypes';

/**
 * Predicate for topics that mean "any" value
 */
export const isWildcardTopic = (topic: string) => topic.endsWith('*');

/**
 * Coerces a given "raw" topic type to a {@link TopicTypes} member
 */
export const resolveTopicType = (type: string) => {
  const entry = Object.entries(TopicTypes).find(([, value]) => type === value);

  if (entry) {
    return entry[1];
  }

  return undefined;
};

export const parseTopic = (topic: string) => {
  const [type, value] = topic.split(':');

  return {
    type,
    resolvedType: resolveTopicType(type),
    value,
  };
};

export const buildTopic = (type: string, value: unknown) => `${type}:${value}`;

export const getTopicType = (topic: string) => parseTopic(topic).resolvedType;

export const isLocationTopic = (topic: string) =>
  // @ts-expect-error - resolve might return "null", but it won't be in the array
  LocationTopicsHierarchy.includes(getTopicType(topic));

// Excuse me, ESLint, but that's here is our data model.
// /* eslint-disable camelcase */

export const formatAlertLocation = ({
  name,
  admin1_code,
  feature_class,
  country_code,
}: {
  name: string;
  admin1_code?: string;
  feature_class?: string;
  country_code?: string;
}) => {
  const hasAdminName = country_code === 'US' && feature_class === 'P' && admin1_code ? admin1_code : null;
  const hasCountryCode = typeof country_code === 'string';

  return compact([name, hasAdminName ? admin1_code : null, hasCountryCode ? country_code : null]).join(', ');
};

/**
 * Converts given topic metadata to a model that is ready
 * for presentation purposes. You can specify the context
 * in which the topic is being displayed, so that the
 * display name is consistent for the context.
 */
export const toDisplayModel = (
  topic: { topic: string; metadata?: TopicMetadata },
  preferredName = PreferredTopicName.DEFAULT,
) => {
  const { type, value, resolvedType: maybeResolvedType } = parseTopic(topic.topic);
  const isLocation = isLocationTopic(topic.topic);
  const isWildcard = isWildcardTopic(topic.topic);

  const model: TopicMetadataDisplayModel = {
    type,
    value,
    topic: topic.topic,
    metadata: topic.metadata,
    color: 'default',
    displayType: type,
    displayValue: isWildcard ? '*' : startCase(value),
    resolvedType: maybeResolvedType,
    fullName: '',
    isWildcard,
    isLocation,
  };

  if (topic.metadata) {
    const maybeNames = topic?.metadata?.metadata;
    model.displayValue = maybeNames?.[preferredName] ?? maybeNames?.name ?? model.displayValue;

    if (isLocation && maybeNames) {
      model.displayValue = formatAlertLocation(maybeNames);
    }
  }

  if (maybeResolvedType) {
    model.displayType = TOPIC_TYPE_NAMES[maybeResolvedType];

    if (maybeResolvedType in ENUMERABLE_TOPICS) {
      const topicValues = ENUMERABLE_TOPICS[maybeResolvedType as EnumeableTopics];
      const maybeKnownValue = topicValues.find((v) => v.id.toString() === value);

      if (maybeKnownValue) {
        model.displayValue = maybeKnownValue.name;
        model.color = maybeKnownValue.color as NonNullable<ChipProps['color']>;
      }
    }
  }

  model.fullName = `${model.displayType}: ${model.displayValue}`;

  return model;
};

export type TopicDisplayModel = ReturnType<typeof toDisplayModel>;

export const resolveTopicTypeName = (topic: string) => {
  const type = resolveTopicType(topic);

  if (type) {
    return TOPIC_TYPE_NAMES[type];
  }

  return type;
};

export const resolveTopicName = (topic: TopicMetadata, preferredName = PreferredTopicName.DEFAULT) =>
  toDisplayModel({ topic: topic.topic, metadata: topic }, preferredName).fullName;

/**
 * Builds a React Admin filter for topic_metadata
 * records based on a given text query
 */
export const queryFilter = (q: string) => ({ 'lookup_string@_ilike,topic@_like': q });

/**
 * React Admin recomments "optionText" to have a stable reference,
 * so we're providing it as a separate utility function.
 */
export const optionText = (t: TopicMetadata) => resolveTopicName(t, PreferredTopicName.FILTER);
