import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';

import { ResourceDefinition } from './types';

import { CompaniesResource } from './resources/companies/resource';
import { AdminUsersResource } from './resources/adminUsers/resource';
import { ExternalApiClientsResource } from './resources/externalApiClients/resource';
import { ApiFieldsResource } from './resources/apiFields/resource';
import { DataPackagesResource } from './resources/dataPackages/resource';
import { DefaultDataPackagesResource } from './resources/defaultDataPackages/resource';
import { CompanySubscriptionSetsResource } from './resources/companySubscriptionSets/resource';

import { OrganizationConfigResource } from './resources/signals/organizationConfig/resource';
import { OrganizationSubscriptionsResource } from './resources/signals/organizationSubscriptions/resource';
import { CompanyCommoditiesResource } from './resources/signals/companyCommodities/resource';
import { UserConfigResource } from './resources/signals/userConfig/resource';
import { UserSubscriptionsResource } from './resources/signals/userSubscriptions/resource';
import { PublishedAlertsResource } from './resources/signals/publishedAlerts/resource';
import { PublishedAlertsTopicsResource } from './resources/signals/publishedAlertsTopics/resource';
import { PublishedAlertsClientsResource } from './resources/signals/publishedAlertsClients/resource';
import { SignalsResource } from './resources/signals/signals/resource';
import { SignalsClientsResource } from './resources/signals/signalsClients/resource';
import { TopicsMetadataResource } from './resources/signals/topicsMetadata/resource';
import { TopicsRelationsResource } from './resources/signals/topicsRelations/resource';
import { OrganizationsResource } from './resources/portal/organizations/resource';
import { OrganizationSettingsResource } from './resources/portal/organizationSettings/resource';
import { AuditEntriesResource } from './resources/portal/auditEntries/resource';
import { CompanyRequestsResource } from './resources/portal/companyRequests/resource';
import { UsersResource } from './resources/portal/users/resource';

export const resources: ResourceDefinition[] = [
  CompaniesResource,
  AdminUsersResource,
  ExternalApiClientsResource,
  ApiFieldsResource,

  DataPackagesResource,
  DefaultDataPackagesResource,
  CompanySubscriptionSetsResource,

  OrganizationConfigResource,
  OrganizationSubscriptionsResource,
  CompanyCommoditiesResource,
  UserConfigResource,
  UserSubscriptionsResource,
  PublishedAlertsResource,
  PublishedAlertsTopicsResource,
  PublishedAlertsClientsResource,
  SignalsResource,
  SignalsClientsResource,
  TopicsMetadataResource,
  TopicsRelationsResource,

  OrganizationsResource,
  OrganizationSettingsResource,
  AuditEntriesResource,
  CompanyRequestsResource,
  UsersResource,
];

export const resourceMap = keyBy(resources, 'name');

// Decoupling of the exports in the init phase
export const getResourceMap = () => resourceMap;
export const getResources = () => resources;

export const resourcesBySection = groupBy(resources, 'section');

export function getExtension(name: string) {
  return resourceMap?.[name]?.extension;
}

export function isExtendedResource(name: string) {
  return !!resourceMap?.[name]?.extension;
}

export default resources;
