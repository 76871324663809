import { useMemo } from 'react';
import { usePermissions as usePermissionsRA } from 'react-admin';

import { CraftAdminPermission } from 'src/types';

/**
 * Wrapper around the React-admin hook of the same name. Transforms permissions into a typed `Set`.
 */
export const usePermissions = () => {
  const { error, isLoading, permissions } = usePermissionsRA();
  const permissionsSet = new Set<CraftAdminPermission>(permissions);

  return useMemo(
    () => ({
      error,
      isLoading,
      permissions: permissionsSet,
    }),
    [error, isLoading, permissions],
  );
};
