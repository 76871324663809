import { makeStyles } from '@mui/styles';
import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button as MuiButton, Card } from '@mui/material';
import { Amplify, Auth, Hub } from 'aws-amplify';
import React, { useState, FC, useEffect } from 'react';
import { Form, useLogin, useNotify } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { config } from '../../config/config.local';
import CraftLogo from '../../components/icons/CraftLogo';
import LoadingOverlay from 'src/components/LoadingOverlay';
import localStorage from '../../utils/localStorage';

Amplify.configure(config.amplify);

const logoutMessagesByReason: Partial<Record<string, string>> = {
  session_expired: 'Your session has ended. Please log back in to continue.',
  user_updated:
    'You have been logged out due to changes to your account. Please log in again for these changes to be applied.',
};

const useStyles = makeStyles({
  loginCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 10rem)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginCard: {
    width: 400,
    padding: '2.5rem',
    boxShadow: 'none',
    position: 'relative',
  },
  loginLogo: {
    margin: '1.5rem',
  },
  input: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  inputLabel: {
    fontSize: '0.875rem !important',
  },
});

const Login: FC = () => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const navigate = useNavigate();

  // display logout message
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const maybeLogoutReason = searchParams.get('logout_reason');
    const logoutMessage = logoutMessagesByReason[maybeLogoutReason ?? ''];
    if (!logoutMessage) return;

    navigate('/login'); // remove url query string
    notify(logoutMessage, { type: 'info', autoHideDuration: 15000 });
  }, [location.search]);

  const showErrorSnackBar = (message: string, type: 'warning' | 'error' | 'success' = 'warning') => {
    notify(message, { type });
  };

  const loginUser = async (cognitoToken: string) => {
    setLoading(true);

    try {
      await login(cognitoToken, location.state ? (location.state as { nextPathname: string }).nextPathname : '/');
    } catch (e) {
      showErrorSnackBar('ra.auth.sign_in_error', 'warning');
    }

    setLoading(false);
  };

  const listenForCognitoAuth = () => {
    Hub.listen('auth', ({ payload: { event } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case 'signIn':
          Auth.currentAuthenticatedUser()
            .then((currentUser) => {
              loginUser(currentUser.signInUserSession.idToken.jwtToken);
            })
            .catch(() => {
              showErrorSnackBar('User not authorized!', 'warning');
              setLoading(false);
            });
          break;
        case 'signOut':
          localStorage.clear(true);
          break;
      }
    });
  };

  useEffect(() => {
    listenForCognitoAuth();
    const code = new URLSearchParams(location.search).get('code');
    code && setLoading(true);
  }, []);

  const handleGoogleOAuth = () => {
    setLoading(true);
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  const classes = useStyles();

  return (
    <Form noValidate>
      <Box>
        <Box className={classes.loginCardContainer}>
          <Box className={classes.loginLogo} color="primary.main">
            <CraftLogo height="38" />
          </Box>

          <Card className={classes.loginCard}>
            <MuiButton
              type="button"
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleOAuth}
              fullWidth
              sx={{ my: 2, py: 1 }}
            >
              Sign in with Google
            </MuiButton>
            <LoadingOverlay open={loading} />
          </Card>
        </Box>
      </Box>
    </Form>
  );
};

export default Login;
