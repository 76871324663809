import { HasuraBoolExpression } from './HasuraTypes';

/**
 * Visits all parts of Hasura bool expression where comparisons could be made
 */
export const visitBoolExpression = (expr: HasuraBoolExpression, cb: (expr: HasuraBoolExpression) => void) => {
  cb(expr);

  if (expr._and) {
    expr._and.forEach((subExpr) => {
      visitBoolExpression(subExpr, cb);
    });
  }

  if (expr._or) {
    expr._or.forEach((subExpr) => {
      visitBoolExpression(subExpr, cb);
    });
  }

  if (expr._not) {
    visitBoolExpression(expr._not, cb);
  }

  return expr;
};
