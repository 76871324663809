import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Stack, Typography, Button, Box } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import {
  BooleanInput,
  DateField,
  FormTab,
  Labeled,
  TextField,
  TextInput,
  WithRecord,
  EditButton,
  WrapperField,
  TransformData,
  SelectField,
  SelectInput,
  CheckboxGroupInput,
  useRefresh,
  ExportButton,
  BulkExportButton,
  downloadCSV,
  Exporter,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput,
  DeleteButton,
  EmailField,
  ReferenceField,
  BooleanField,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import { Center } from 'src/components/Center';
import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftStandaloneList from 'src/components/CraftStandaloneList';
import { CompanyReferenceField } from 'src/resources/companies/CompanyReferenceField';
import { OrganizationReferenceField } from 'src/resources/portal/organizations/OrganizationReferenceField';
import {
  ORGANIZATION_SUBSCRIPTION_STATUSES,
  ORGANIZATION_CONFIG_EMAIL_ALERTS_GROUPINGS,
  ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE,
  ORGANIZATION_ALERTS_CONFIG_DATASETS,
  ORGANIZATION_RISK_CONFIG_DATASETS,
} from 'src/utils/defaults/Constants';
import OrganizationSubscriptionPagination, {
  Filter,
  FilterValues,
  OnFilterChange,
} from 'src/resources/signals/organizationSubscriptions/OrganizationSubscriptionPagination';
import { handleCheckboxGroupInputClick, checkboxGroupInputDefaultCursorSx } from 'src/utils/checkboxGroupInput';
import BulkImportOrgSubs from 'src/resources/signals/organizationSubscriptions/BulkImport';
import DeleteAllOrgSubs from 'src/resources/signals/organizationSubscriptions/DeleteAll';
import DeleteBulkOrgSubs from 'src/resources/signals/organizationSubscriptions/BulkDelete';
import DeleteOrgSub from 'src/resources/signals/organizationSubscriptions/DeleteButton';
import gql from 'graphql-tag';
import { client } from '../../../data/api';
import { getOrgId } from 'src/utils/organizationSubscriptions';
import jsonExport from 'jsonexport/dist';
import { Notify } from 'src/utils/NotifyType';
import startCase from 'lodash/startCase';
import {
  companyCommodityCreateRoute,
  companyCommodityListRoute,
  orgSubscriptionsCreateRoute,
  userConfigCreateRoute,
} from 'src/utils/routeHelpers';
import DeleteAllCompanyCommodities from 'src/resources/signals/companyCommodities/DeleteAll';
import BulkImportCompanyCommodities from 'src/resources/signals/companyCommodities/BulkImport';
import BulkDeleteCompanyCommodities from 'src/resources/signals/companyCommodities/BulkDelete';
import { Link } from 'react-router-dom';
import { StyledLink } from './StyledLink';
import { usePermissions } from 'src/hooks';
import { hiddenSpan } from './hiddenSpan';
import { CraftDateInput } from 'src/components/CraftDateInput';
import PublishedAlertsListButton from '../publishedAlerts/PublishedAlertsListButton';
import { WrapperStackField } from 'src/fields/WrapperStackField';

type OrganizationSubscription = {
  id: string;
  topic: string;
  org_id: string;
  status: string;
  created_at: string;
  company_id: string;
};

type Companies = {
  [key: string]: {
    name: string;
  };
};

type CompanyCommodity = {
  id: string;
  commodity_id: string;
  company_id: string;
  org_id: string;
};

const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.blur();
  }
};

const subscriptionsFilters = [
  <TextInput key="topic" source="topic" alwaysOn onKeyDown={handleKeyDown} />,
  <CraftDateInput key="createdAtFrom" label="Newer than" source="created_at@_gte" disallowFuture alwaysOn />,
  <CraftDateInput key="createdAtTo" label="Older than" source="created_at@_lte" alwaysOn />,
];

const UpdateSetAndEditButton = ({
  setUserIdsWithConfigsSet,
}: {
  setUserIdsWithConfigsSet: React.Dispatch<React.SetStateAction<Set<string>>>;
}) => {
  const record = useRecordContext();
  if (!record) return null;

  useEffect(() => {
    setUserIdsWithConfigsSet((prevVal) => {
      const newSet = new Set(prevVal);
      newSet.add(record.id.toString());
      return newSet;
    });
  }, [record.user_id]);

  return <EditButton resource="user_config" />;
};

const formatArrayForGraphQL = (datasets: string[]) => {
  const jsonString = JSON.stringify(datasets);
  return jsonString.replace('[', '{').replace(']', '}');
};

const transformValues: TransformData = (values) => {
  const result = {
    ...values,
    updated_at: new Date().toISOString(),
  };
  for (const key in result) {
    // convert empty strings set by react-hook-form for empty fields
    if (result[key] === '') result[key] = null;
  }
  result.datasets = values.datasets.length ? formatArrayForGraphQL(values.datasets) : '{}';
  return result;
};

const convertFilterToWhere = (filterValues: FilterValues = {}) => {
  const whereVariables: Record<string, Record<string, string>>[] = [];
  Object.entries(filterValues).forEach(([maybeField, value]) => {
    if (maybeField.indexOf('@') !== -1) {
      const [field, operator] = maybeField.split('@');
      whereVariables.push({ [field]: { [operator]: value } });
    } else {
      whereVariables.push({ [maybeField]: { _ilike: `%${value}%` } });
    }
  });

  return whereVariables;
};

const fetchOrgSubAggregate: OnFilterChange = async (filterValues, filter) => {
  const orgId = filter?.org_id;
  if (!orgId) {
    return 0;
  }

  const orgSubAggregate = await client.query({
    query: gql`
      query SignalsOrganizationSubscriptionsAggregate($where: organization_subscriptions_bool_exp) {
        signals_organization_subscriptions_aggregate(where: $where) {
          aggregate {
            count(distinct: false)
          }
        }
      }
    `,
    variables: {
      where: {
        _and: [
          {
            org_id: {
              _eq: orgId,
            },
          },
          ...convertFilterToWhere(filterValues),
        ],
      },
    },
    fetchPolicy: 'network-only',
  });

  return orgSubAggregate?.data?.signals_organization_subscriptions_aggregate?.aggregate?.count ?? 0;
};

const HandleOrganizationSubscriptionExporter =
  (notify: Notify): Exporter =>
  (organizationSubscriptions, fetchRelatedRecords) => {
    fetchRelatedRecords(organizationSubscriptions, 'company_id', 'companies').then((companies: Companies) => {
      const data = organizationSubscriptions.map((organizationSubscription: OrganizationSubscription) => ({
        Company: companies[organizationSubscription.company_id]?.name ?? '',
        Topic: organizationSubscription.topic,
        Status: organizationSubscription.status,
        'Created at': organizationSubscription.created_at,
      }));
      return jsonExport(
        data,
        {
          headers: ['Company', 'Topic', 'Status', 'Created at'],
        },
        (error, csv) => {
          if (error) {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            notify(errorMessage, { type: 'error' });
          } else {
            downloadCSV(csv, 'organization_subscriptions');
          }
        },
      );
    });
  };

const HandleCompanyCommodityExporter =
  (notify: Notify): Exporter =>
  async (companyCommodities, fetchRelatedRecords, dataProvider) => {
    let orgName = '';
    if (companyCommodities[0]?.org_id) {
      try {
        const result = await dataProvider.getOne('organizations', { id: companyCommodities[0].org_id });
        orgName = result?.data?.name ?? '';
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
        notify(errorMessage, { type: 'error' });
      }
    }
    fetchRelatedRecords(companyCommodities, 'company_id', 'companies').then((companies: Companies) => {
      const data = companyCommodities.map((companyCommodity: CompanyCommodity) => ({
        Org: `${orgName} (${companyCommodity.org_id})`,
        Company: `${
          companies[companyCommodity.company_id]?.name ? `${companies[companyCommodity.company_id].name} ` : ''
        }(${companyCommodity.company_id})`,
        Commodity: companyCommodity.commodity_id,
      }));
      return jsonExport(
        data,
        {
          headers: ['Org', 'Company', 'Commodity'],
        },
        (error, csv) => {
          if (error) {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            notify(errorMessage, { type: 'error' });
          } else {
            downloadCSV(csv, 'company_commodities');
          }
        },
      );
    });
  };

const fetchCommodityChoices = async () => {
  const orgId = getOrgId();
  if (!orgId) {
    return [];
  }

  const commodityChoices = await client.query<{
    signals_company_commodities: CompanyCommodity[];
  }>({
    query: gql`
      query SignalsCompanyCommodities(
        $where: company_commodities_bool_exp
        $distinct_on: [company_commodities_select_column!]
        $order_by: [company_commodities_order_by!]
      ) {
        signals_company_commodities(where: $where, distinct_on: $distinct_on, order_by: $order_by) {
          commodity_id
        }
      }
    `,
    variables: {
      where: {
        org_id: {
          _eq: orgId,
        },
      },
      distinct_on: ['commodity_id'],
      order_by: [{ commodity_id: 'asc' }],
    },
  });

  return commodityChoices.data.signals_company_commodities.map((companyCommodity) => ({
    id: companyCommodity.commodity_id,
    name: startCase(companyCommodity.commodity_id.replaceAll('_', ' ')),
  }));
};

export const OrgConfigEdit = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const canEdit = permissions.has('alerts:edit');
  const canDelete = permissions.has('alerts:delete');

  const handleOrganizationSubscriptionExporter = HandleOrganizationSubscriptionExporter(notify);
  const handleCompanyCommodityExporter = HandleCompanyCommodityExporter(notify);

  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState<FilterValues>({});
  const [filter, setFilter] = useState<Filter>({});
  const [commodityChoices, setCommodityChoices] = useState<{ id: string; name: string }[]>([]);
  const [userIdsWithConfigsSet, setUserIdsWithConfigsSet] = useState(new Set<string>());

  const orgId = getOrgId();

  const listFilter = useMemo(() => ({ org_id: orgId }), []);
  const orgUsersFilter = useMemo(() => ({ organization_id: orgId }), []);

  const commodityDeleteButtonRedirectTo = companyCommodityListRoute(orgId);

  const handleOnFilterChange = useCallback(
    async (updatedFilterValues: FilterValues, updatedFilter: Filter = {}) => {
      const count = await fetchOrgSubAggregate(updatedFilterValues, updatedFilter);
      setFilter(updatedFilter);
      setFilterValues(updatedFilterValues);
      if (typeof count === 'number') {
        setTotal(count);
      }
    },
    [filterValues, filter],
  );

  const commodityFilters = [
    <ReferenceInput
      key="companyFilter"
      reference="companies"
      source="company_id"
      alwaysOn
      perPage={5}
      sort={{ field: 'score', order: 'DESC' }}
    >
      <AutocompleteInput
        key="companyAutoCompleteFilter"
        label="Company"
        source="company_id"
        optionText="name"
        disableClearable
        filterToQuery={(q) => {
          if (!Number.isNaN(parseInt(q, 10))) {
            return { id: q };
          }
          return { name: q };
        }}
      />
    </ReferenceInput>,
    <SelectArrayInput key="commodityFilter" source="commodity_id" alwaysOn choices={commodityChoices} />,
  ];

  useEffect(() => {
    (async () => {
      const data = await fetchCommodityChoices();
      setCommodityChoices(data);
    })();
  }, []);

  useEffect(() => {
    handleOnFilterChange(filterValues, filter);
  }, [filterValues, filter, handleOnFilterChange]);

  const handleRefresh = useCallback(async () => {
    (async () => {
      const filterOverride = !filter?.org_id ? listFilter : filter;
      refresh();
      const count = await fetchOrgSubAggregate(filterValues, filterOverride);
      if (typeof count === 'number') {
        setTotal(count);
      }
    })();
  }, [filterValues, filter, refresh]);

  useEffect(() => {
    (async () => {
      const filterOverride = !filter?.org_id ? listFilter : filter;
      const count = await fetchOrgSubAggregate(filterValues, filterOverride);
      if (typeof count === 'number') {
        setTotal(count);
      }
    })();
  }, []);

  return (
    <CraftEdit
      transform={transformValues}
      header={
        <Stack gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h5">
              <OrganizationReferenceField source="org_id" />
            </Typography>
          </Stack>
          <Labeled label="Created">
            <DateField source="created_at" showTime />
          </Labeled>
        </Stack>
      }
      mutationMode="pessimistic"
    >
      <WithRecord
        render={(record) => (
          <CraftTabbedForm
            formType="edit"
            deleteOptions={{ deletePermission: 'alerts:delete', hideDeleteButton: true }}
            mutationMode="pessimistic"
          >
            <FormTab label="Config">
              <CraftPageSection title="Organization config">
                <TextInput source="id" disabled />
                <TextInput source="api_client_id" label="API Client ID" />
                <BooleanInput source="all_alerts_access" label="Show All Craft Alerts" />
                <BooleanInput source="enable_alerts_processing" label="Enable Alerts Processing" />
                <BooleanInput source="send_email_alerts" label="Send Email Alerts" />
                <SelectInput
                  source="email_alerts_grouping"
                  label="Email Grouping"
                  choices={ORGANIZATION_CONFIG_EMAIL_ALERTS_GROUPINGS}
                  isRequired
                />
                <SelectInput
                  source="email_alerts_schedule"
                  label="Email Timezone"
                  choices={ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE}
                  isRequired
                />
                <CheckboxGroupInput
                  row={false}
                  label="Alerts"
                  source="datasets"
                  choices={ORGANIZATION_ALERTS_CONFIG_DATASETS}
                  onClick={handleCheckboxGroupInputClick}
                  sx={checkboxGroupInputDefaultCursorSx}
                />
                <CheckboxGroupInput
                  row={false}
                  label="Risks"
                  source="datasets"
                  choices={ORGANIZATION_RISK_CONFIG_DATASETS}
                  onClick={handleCheckboxGroupInputClick}
                  sx={checkboxGroupInputDefaultCursorSx}
                />
              </CraftPageSection>
            </FormTab>
            <FormTab label="Subscriptions">
              <CraftPageSection title="Organization Subscriptions">
                <CraftStandaloneList
                  resource="organization_subscriptions"
                  perPage={10}
                  filters={subscriptionsFilters}
                  filter={listFilter}
                  actions={
                    <>
                      {canDelete && <DeleteBulkOrgSubs refresh={handleRefresh} />}
                      {canDelete && <DeleteAllOrgSubs refresh={handleRefresh} />}
                      {canEdit && <BulkImportOrgSubs refresh={handleRefresh} />}
                      {canEdit && (
                        <StyledLink to={orgSubscriptionsCreateRoute} state={{ orgId }}>
                          <Button startIcon={<AddRoundedIcon />} sx={{ height: '2rem' }}>
                            Create
                          </Button>
                        </StyledLink>
                      )}
                      <Box sx={{ px: 0.5, py: 1 }}>
                        <ExportButton
                          resource="organization_subscriptions"
                          maxResults={5000}
                          size="medium"
                          sx={{ height: '2rem' }}
                        />
                      </Box>
                    </>
                  }
                  dataGridProps={{
                    empty: canEdit ? (
                      <Center sx={{ marginTop: '1rem' }}>
                        No organization subscriptions found. Click
                        <StyledLink to={orgSubscriptionsCreateRoute} state={{ orgId }}>
                          here
                        </StyledLink>{' '}
                        to create an organization subscription
                      </Center>
                    ) : (
                      <Center sx={{ marginTop: '1rem' }}>No organization subscriptions found.</Center>
                    ),
                    bulkActionButtons: <BulkExportButton resource="organization_subscriptions" label="Bulk Export" />,
                  }}
                  CustomPaginationComponent={
                    <OrganizationSubscriptionPagination total={total} onFilterChange={handleOnFilterChange} />
                  }
                  exporter={handleOrganizationSubscriptionExporter}
                >
                  <TextField source="org_id" label="Org Id" />
                  <CompanyReferenceField source="company_id" />
                  <TextField source="topic" />
                  <SelectField source="status" choices={ORGANIZATION_SUBSCRIPTION_STATUSES} />
                  <DateField source="created_at" showTime />
                  <WrapperStackField label="Actions" stackProps={{ gap: 1 }}>
                    {canEdit && <EditButton resource="organization_subscriptions" />}
                    {canDelete && <DeleteOrgSub refresh={handleRefresh} />}
                    <FunctionField
                      render={(r: OrganizationSubscription) => (
                        <PublishedAlertsListButton filter={{ 'topics#topic@_eq': r.topic }} />
                      )}
                    />
                  </WrapperStackField>
                </CraftStandaloneList>
              </CraftPageSection>
            </FormTab>
            <FormTab
              label="Commodity Alerts"
              disabled={!record?.datasets.find((dataset: string) => dataset === 'news_commodities')}
            >
              <CraftPageSection title="Commodity Alerts">
                {record?.datasets.find((dataset: string) => dataset === 'news_commodities') ? (
                  <CraftStandaloneList
                    resource="company_commodities"
                    perPage={10}
                    filters={commodityFilters}
                    filter={{ org_id: record.org_id }}
                    exporter={handleCompanyCommodityExporter}
                    actions={
                      <>
                        {canDelete && <BulkDeleteCompanyCommodities refresh={refresh} />}
                        {canDelete && <DeleteAllCompanyCommodities refresh={refresh} />}
                        {canEdit && <BulkImportCompanyCommodities refresh={refresh} />}
                        {canEdit && (
                          <StyledLink to={companyCommodityCreateRoute} state={{ orgId }}>
                            <Button startIcon={<AddRoundedIcon />} sx={{ height: '2rem' }}>
                              Create
                            </Button>
                          </StyledLink>
                        )}
                        <Box sx={{ px: 0.5, py: 1 }}>
                          <ExportButton
                            resource="company_commodities"
                            maxResults={5000}
                            size="medium"
                            sx={{ height: '2rem' }}
                          />
                        </Box>
                      </>
                    }
                    dataGridProps={{
                      empty: canEdit ? (
                        <Center sx={{ marginTop: '1rem' }}>
                          No commodities found. Click
                          <StyledLink to={companyCommodityCreateRoute} state={{ orgId }}>
                            here
                          </StyledLink>{' '}
                          to create a company commodity
                        </Center>
                      ) : (
                        <Center sx={{ marginTop: '1rem' }}>No commodities found.</Center>
                      ),
                      bulkActionButtons: <BulkExportButton resource="company_commodities" label="Bulk Export" />,
                    }}
                  >
                    <TextField source="org_id" label="Org Id" />
                    <CompanyReferenceField source="company_id" />
                    <TextField source="commodity_id" />
                    {canEdit || canDelete ? (
                      <WrapperField label="Actions">
                        {canEdit && <EditButton resource="company_commodities" />}
                        {canDelete && (
                          <DeleteButton resource="company_commodities" redirect={commodityDeleteButtonRedirectTo} />
                        )}
                      </WrapperField>
                    ) : (
                      hiddenSpan
                    )}
                  </CraftStandaloneList>
                ) : (
                  <Box>
                    <Typography variant="body1">Commodity Alerts are not enabled for this organization.</Typography>
                    <Typography variant="body1">
                      Please enable Commodity Alerts in the Organization Config tab to view Commodity Alerts.
                    </Typography>
                    <Typography variant="body1">
                      Click <Link to={`/organization_config/${record.org_id}`}>here</Link> to edit the Organization
                      Config.
                    </Typography>
                  </Box>
                )}
              </CraftPageSection>
            </FormTab>
            <FormTab label="User Configs">
              <CraftPageSection title="User Configs">
                <CraftStandaloneList resource="users" perPage={10} filter={orgUsersFilter}>
                  <TextField source="id" label="User Id" />
                  <TextField source="name" label="Name" />
                  <EmailField source="email" label="Email" />
                  <TextField source="created_at" label="Created At" />
                  <TextField source="first_name" label="First Name" />
                  <TextField source="last_name" label="Last Name" />
                  <ReferenceField source="id" reference="user_config" label="Config Created At">
                    <TextField source="created_at" />
                  </ReferenceField>
                  <ReferenceField source="id" reference="user_config" label="Send Email Alerts">
                    <BooleanField source="send_email_alerts" />
                  </ReferenceField>
                  <ReferenceField source="id" reference="user_config" label="Show Organization Alerts">
                    <BooleanField source="all_alerts_access" />
                  </ReferenceField>
                  {canEdit ? (
                    <WrapperField label="Actions">
                      <ReferenceField source="id" reference="user_config" label={false}>
                        <FunctionField
                          render={() => <UpdateSetAndEditButton setUserIdsWithConfigsSet={setUserIdsWithConfigsSet} />}
                        />
                      </ReferenceField>
                      <WithRecord
                        render={(userRecord) =>
                          !userIdsWithConfigsSet.has(userRecord.id.toString()) ? (
                            <StyledLink to={userConfigCreateRoute(userRecord.id.toString())}>
                              <Button startIcon={<AddRoundedIcon />} sx={{ height: '2rem' }}>
                                Create
                              </Button>
                            </StyledLink>
                          ) : (
                            hiddenSpan
                          )
                        }
                      />
                    </WrapperField>
                  ) : (
                    hiddenSpan
                  )}
                </CraftStandaloneList>
              </CraftPageSection>
            </FormTab>
          </CraftTabbedForm>
        )}
      />
    </CraftEdit>
  );
};

export default OrgConfigEdit;
