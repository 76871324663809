import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useNotify, useRedirect } from 'react-admin';

import client from 'src/data/api';
import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import LoadingOverlay from 'src/components/LoadingOverlay';
import { CompanySubscriptionSetRecord } from 'src/types';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { companySubscriptionSetSchema } from './helpers';
import { useSearchParams } from 'react-router-dom';

export const CompanySubscriptionSetsCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();

  const externalApiClientID = parseInt(searchParams.get('externalApiClientID') ?? '', 10);

  if (Number.isNaN(externalApiClientID)) {
    notify('Invalid ID received for External API Client', { type: 'error' });
    redirect('list', 'external_api_clients');
  }

  const [createCompanySubscriptionSet, { loading: createCompanySubscriptionSetLoading }] = useMutation<{
    insert_company_subscription_sets: { returning: CompanySubscriptionSetRecord[] };
  }>(
    gql`
      mutation insert_company_subscription_sets(
        $companySubscriptionSetParams: company_subscription_sets_insert_input!
      ) {
        insert_company_subscription_sets(objects: [$companySubscriptionSetParams]) {
          returning {
            id
          }
        }
      }
    `,
    {
      client,
      errorPolicy: 'all',
    },
  );

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = companySubscriptionSetSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const variables = {
      companySubscriptionSetParams: {
        external_api_client_id: externalApiClientID,
        name: values.name,
        company_limit: values.company_limit,
      },
    };

    const { data, errors } = await createCompanySubscriptionSet({
      variables,
    });

    if (errors) {
      notify(errors[0].message, { type: 'error' });
      return undefined;
    }

    const companySubscriptionSetID = data?.insert_company_subscription_sets.returning[0].id;
    if (!companySubscriptionSetID) {
      notify('Unable to redirect to new company subscription set: ID not returned', { type: 'error' });
      return redirect('list', 'external_api_clients', externalApiClientID);
    }

    notify('The Company Subscription Set has been successfully created', { type: 'success' });
    return redirect('edit', 'company_subscription_sets', companySubscriptionSetID);
  };

  return (
    <CraftCreate header="Create Company Subscription Set">
      <CraftTabbedForm formType="create" onSubmit={handleSave} shouldUnregister>
        <DetailsTab mode="create" />
      </CraftTabbedForm>

      <LoadingOverlay open={createCompanySubscriptionSetLoading} />
    </CraftCreate>
  );
};

export default CompanySubscriptionSetsCreate;
