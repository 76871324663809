import { UserMenu, UserMenuProps } from 'react-admin';

import LogoutButton from './LogoutButton';

export const CraftUserMenu = (props: UserMenuProps) => (
  <UserMenu {...props}>
    <LogoutButton />
  </UserMenu>
);

export default CraftUserMenu;
