import { DateField } from 'react-admin';
import { UserReferenceField } from '../users/UserReferenceField';

export const AuditEntryAttributionField = () => (
  <>
    <span>by </span>
    <UserReferenceField source="meta.actor.id" />
    <span> at </span>
    <DateField source="created_at" showTime />
  </>
);
