export const ALERT_POLARITIES = [
  { id: -1, name: 'Negative', color: 'error' },
  { id: 0, name: 'Neutral', color: 'default' },
  { id: 1, name: 'Positive', color: 'success' },
];

export const ALERT_SEVERITIES = [
  { id: 0, name: 'Minimal', color: 'default' },
  { id: 1, name: 'Low', color: 'default' },
  { id: 2, name: 'Medium', color: 'default' },
  { id: 3, name: 'High', color: 'warning' },
  { id: 4, name: 'Critical', color: 'error' },
];
