import { FunctionField, ReferenceManyField, SingleFieldList, useRecordContext } from 'react-admin';
import { COMPANY_STATUSES } from '../utils/defaults/Constants';
import InlineTextField from './InlineTextField';

const ConditionalNoteField = () => {
  const record = useRecordContext();
  const internalCompanyStates = [COMPANY_STATUSES.CLOSED, COMPANY_STATUSES.DELETED];
  const isInternalState = record && record.state && internalCompanyStates.includes(record.state);
  if (!isInternalState) return null;

  return (
    <ReferenceManyField
      label="Note"
      reference="audits"
      source="id"
      target="associated_id"
      sortBy="created_at"
      perPage={1}
    >
      <SingleFieldList linkType={false} style={{ marginTop: 0 }}>
        <FunctionField
          label="Note"
          render={(row: { comment: string }) =>
            row && row.comment ? <InlineTextField label="Note" source="comment" /> : null
          }
        />
      </SingleFieldList>
    </ReferenceManyField>
  );
};

export default ConditionalNoteField;
