import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  useCreatePath,
  useNotify,
} from 'react-admin';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import noop from 'lodash/noop';

import CraftTwoColumns from 'src/components/CraftTwoColumns';
import { isWildcardTopic, optionText, queryFilter } from '../topicsMetadata/TopicMetadataDisplayModel';
import { TopicTypes } from '../topicsMetadata/TopicMetadataTypes';
import { notNil } from 'src/utils/notNil';
import type { DerivedTopicsMapKeys } from './derivedTopicsMap';
import CraftPageSection from 'src/components/CraftPageSection';
import { useTopicMetadataChain } from '../topicsMetadata/useTopicMetadataChain';

/**
 * Allows editing a single specified tiered map for a given topic type,
 * works in conjunction with a virtual/computed "derived_topics_map" field
 *
 * @see derivedTopicsMap.ts
 */
const TieredTopicInput = ({
  type,
  primaryRequired,
  onSecondaryChange,
}: {
  type: TopicTypes | `${TopicTypes}`;
  primaryRequired?: boolean;
  onSecondaryChange?: (v: string[]) => void;
}) => {
  const sort = { field: 'topic', order: 'ASC' as const };
  const perPage = 25;
  const primarySource = `derived_topics_map.${type}.primary`;
  const secondarySource = `derived_topics_map.${type}.secondary`;

  const map = useWatch({ name: 'derived_topics_map' });
  const ofType = map?.[type as DerivedTopicsMapKeys];

  // Already selected in primary/secondary
  // should be excluded for both filters
  const excluded = [ofType?.primary, ...(ofType?.secondary ?? [])].filter(notNil);

  const scope: Record<string, string | string[]> = {
    'topic@_ilike': `${type}${type === 'location' ? '.' : ':'}%`,
    'topic@_nlike': '%*',
    'topic@_nin': excluded,
  };

  let helperText = 'Search with topic/name.';

  if (primaryRequired) {
    helperText = `Required. ${helperText}`;
  }

  return (
    <Stack>
      {ofType?.primary !== undefined && (
        <ReferenceInput
          reference="topics_metadata"
          source={primarySource}
          alwaysOn
          filter={scope}
          sort={sort}
          perPage={perPage}
        >
          <AutocompleteInput
            label="Primary"
            source="metadata.name"
            optionText={optionText}
            filterToQuery={queryFilter}
            noOptionsText="No matchees, try a different search term."
            helperText={helperText}
            validate={primaryRequired ? required() : () => null}
          />
        </ReferenceInput>
      )}
      <ReferenceArrayInput
        reference="topics_metadata"
        source={secondarySource}
        alwaysOn
        perPage={perPage}
        filter={scope}
        sort={sort}
      >
        <AutocompleteArrayInput
          label="Additional"
          source="topic"
          optionValue="topic"
          optionText={optionText}
          filterToQuery={queryFilter}
          noOptionsText="No matchees, try a different search term."
          onChange={onSecondaryChange || noop}
        />
      </ReferenceArrayInput>
    </Stack>
  );
};

/**
 * Single-purpose component that works with a virtual/computed "derived_topics_map" field
 * on "signals" resource.
 */
export const SignalDerivedTopicsInput: FC = () => {
  const { setValue } = useFormContext();
  const topicChain = useTopicMetadataChain();
  const createPath = useCreatePath();
  const notify = useNotify();

  const handleLocationChange = (v: string[]) => {
    async function addLocationParents() {
      // Resolve all parents of all given topics
      const allLocations = uniq(
        (await Promise.all(v.map((t) => topicChain.resolveChain(t))))
          .flatMap((t) => t)
          .filter((t) => !isWildcardTopic(t)),
      );

      if (difference(allLocations, v).length > 0) {
        notify('All parent locations are added automatically.', { type: 'info' });
        setValue(`derived_topics_map.location.secondary`, allLocations);
      }
    }

    addLocationParents().catch((err) => {
      console.error(err);
      notify('Failed to resolve location parent chain', { type: 'error' });
    });
  };

  return (
    <>
      <CraftTwoColumns
        left={
          <>
            <CraftPageSection
              title="Dataset"
              caption={
                <>
                  See{' '}
                  <Link to={createPath({ resource: 'organization_config', type: 'list' })}>Organization Config</Link>{' '}
                  managing access to specific datasets.
                </>
              }
            >
              <TieredTopicInput type="dataset" primaryRequired />
            </CraftPageSection>

            <CraftPageSection title="Class" caption="Classes (categories) associated with this signal.">
              <TieredTopicInput type="class" primaryRequired />
            </CraftPageSection>
          </>
        }
        right={
          <>
            <CraftPageSection title="Company" caption="Craft profiles associated with this signal.">
              <TieredTopicInput type="company" />
            </CraftPageSection>

            <CraftPageSection title="Resource" caption="Resource (commodity) associated with this signal.">
              <TieredTopicInput type="resource" />
            </CraftPageSection>
          </>
        }
      />

      <CraftPageSection
        title="Location"
        caption="Locations associated with this signal. When adding a location, all parent locations are added automatically to ensure correct filtering of the signals later."
      >
        <TieredTopicInput type="location" onSecondaryChange={handleLocationChange} />
      </CraftPageSection>

      <CraftPageSection
        title="Additional"
        caption={
          <>
            Additional topics are added from the signal&apos;s{' '}
            <Typography variant="code" fontSize="0.7rem">
              payload.signal_predefined_field
            </Typography>{' '}
            and can outline additional items outside of the above categories.
          </>
        }
      >
        <ReferenceArrayInput
          reference="topics_metadata"
          source="derived_topics_map.rest"
          alwaysOn
          perPage={25}
          // Should exclude everything that is editable above
          filter={{ 'topic@_nlike': '%*', 'topic@_nsimilar': '(company:|dataset:|class:|resource:|location)%' }}
          sort={{ field: 'topic', order: 'ASC' }}
        >
          <AutocompleteArrayInput
            label="Topic"
            source="topic"
            optionValue="topic"
            optionText={optionText}
            filterToQuery={queryFilter}
            noOptionsText="No matchees, try a different search term."
          />
        </ReferenceArrayInput>
      </CraftPageSection>
    </>
  );
};
