import React, { useState, ChangeEvent } from 'react';
import { Button, SaveButton, TextInput, SimpleForm } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Box, Typography } from '@mui/material';
import { DynamicFeed as DynamicFeedIcon } from '@mui/icons-material';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { formatTopics } from 'src/utils/organizationSubscriptions';
import { makeStyles } from '@mui/styles';
import BulkToolbar from './BulkToolbar';

const validateIdList = (idListStr: string) => {
  if (idListStr && !/^[0-9\n]+$/.test(idListStr)) {
    return 'Invalid input. Only numbers and newlines are allowed.';
  }
  return undefined;
};

const useStyles = makeStyles({
  saveButton: {
    backgroundColor: '#478b60',
    '&:hover': {
      backgroundColor: '#3c7b53',
    },
  },
});

const BulkImportFromIdListToolbar = ({
  handleCloseClick,
  records,
  toolbarInfo,
}: {
  handleCloseClick: () => void;
  records: { idListTopics: string };
  toolbarInfo: string;
}) => {
  const classes = useStyles();

  const buttonComponents = <SaveButton alwaysEnable className={classes.saveButton} label="Apply" />;

  const infoComponents = (
    <Typography variant="body2" color="textSecondary">
      {toolbarInfo}: {formatTopics(records, 'idListTopics').length?.toLocaleString()}
    </Typography>
  );

  return (
    <BulkToolbar
      handleCloseClick={handleCloseClick}
      infoComponents={infoComponents}
      buttonComponents={buttonComponents}
    />
  );
};

const IdListImport = ({
  onChange,
  setIsLoading,
  toolbarInfo,
  buttonClassName = '',
}: {
  onChange: (topics: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  toolbarInfo: string;
  buttonClassName?: string;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [records, setRecords] = useState<{ idListTopics: string }>({ idListTopics: '' });

  const handleIdListImportButtonClick = () => {
    setShowDialog(true);
  };

  const handleIdListImportCloseClick = () => {
    setShowDialog(false);
  };

  let debounceTimeout: NodeJS.Timeout;

  const handleIdListTopicsChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(debounceTimeout);
    const idListTopics = event?.target?.value ?? '';
    debounceTimeout = setTimeout(() => {
      setRecords({ idListTopics });
    }, 500);
  };

  const handleSubmit: SubmitHandler<FieldValues> = async (data) => {
    setIsLoading(true);
    setRecords({ idListTopics: data.idListTopics });
    const topicsArr = formatTopics(data, 'idListTopics').map((topic: string) => `company:${topic}`);
    onChange([...new Set(topicsArr)].join('\n'));
    setShowDialog(false);
  };

  return (
    <Box sx={{ display: 'block', margin: 0, px: '0.25rem', py: '0.5rem' }}>
      <Button onClick={handleIdListImportButtonClick} label="From ID List" size="medium" className={buttonClassName}>
        <DynamicFeedIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleIdListImportCloseClick} aria-label="ID List Import">
        <DialogTitle>Newline-separated list of company IDs</DialogTitle>
        <SimpleForm
          onSubmit={handleSubmit}
          warnWhenUnsavedChanges
          toolbar={
            <BulkImportFromIdListToolbar
              handleCloseClick={handleIdListImportCloseClick}
              records={records}
              toolbarInfo={toolbarInfo}
            />
          }
          record={records}
        >
          <DialogContent sx={{ width: '100%' }}>
            <TextInput
              label="Company ids"
              source="idListTopics"
              validate={validateIdList}
              isRequired
              multiline
              fullWidth
              resettable
              rows={10}
              onChange={handleIdListTopicsChange}
            />
          </DialogContent>
        </SimpleForm>
      </Dialog>
    </Box>
  );
};

export default IdListImport;
