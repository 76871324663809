import { Alert, Snackbar } from '@mui/material';
import { RaRecord } from 'ra-core';

interface ArchivedOrDeletedNotificationProps {
  record: RaRecord;
  notificationText?: string;
}
const ArchivedOrDeletedNotification = ({ record, notificationText }: ArchivedOrDeletedNotificationProps) => {
  if (!record?.deleted_at && !record?.archived_at) return null;

  return (
    <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert severity="warning">
        {notificationText ?? `This resource is currently `}
        {!notificationText && (record?.deleted_at ? 'DELETED' : 'ARCHIVED')}
      </Alert>
    </Snackbar>
  );
};

export default ArchivedOrDeletedNotification;
