import { Card, CardContent, Typography } from '@mui/material';

import CraftLogo from './icons/CraftLogo';

const Homepage = () => (
  <Card sx={{ padding: 10 }}>
    <CardContent sx={{ mt: 4, textAlign: 'center' }}>
      <Typography variant="h5" color="#0057BA" mb={2}>
        Internal Admin
      </Typography>
      <CraftLogo height="4rem" />
      <Typography variant="body1" mt={4}>
        Good luck. And don&apos;t break anything 😈
      </Typography>
    </CardContent>
  </Card>
);

export default Homepage;
