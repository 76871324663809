import { ReferenceField, ReferenceFieldProps } from 'react-admin';

import NameIdReferenceField from 'src/fields/NameIdReferenceField';
import { resolveUserName } from './UserNameField';

export const UserReferenceField = (props: Omit<ReferenceFieldProps, 'reference'>) => (
  <ReferenceField label="User" reference="users" {...props}>
    <NameIdReferenceField resolveName={resolveUserName} />
  </ReferenceField>
);
