import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useEffect } from 'react';
import { Alert, Paper, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormDataConsumer, FormTab, FormTabProps, SelectInput, TextField, required } from 'react-admin';

import CraftTwoColumns from 'src/components/CraftTwoColumns';
import Labeled from 'src/components/Labeled';
import TextInput from 'src/inputs/TextInput';
import IntegerInput from 'src/inputs/IntegerInput';
import BooleanInput from 'src/inputs/BooleanInput';
import ArrayInput from 'src/inputs/ArrayInput';
import tooltips from '../tooltips';
import { config } from 'src/config/config.local';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { COMMENT_ACCESS_TYPE, SERVICE_PROVIDER_CERTIFICATE_EXPIRATIONS } from 'src/utils/defaults/Constants';

const baseSamlCallbackURL = `${config.portal.url}${config.portal.sso.samlCallbackRoute}`;
const baseSamlEntryURL = `${config.portal.url}${config.portal.sso.samlEntryRoute}`;

const RiskCategoryChartInput = () => {
  const formContext = useFormContext();
  const riskCategoryFieldValue: boolean = formContext.watch('settings.data.featureFlags.riskCategoryField');

  useEffect(() => {
    formContext.setValue('settings.data.featureFlags.riskCategoryChart', riskCategoryFieldValue);
  }, [riskCategoryFieldValue]);

  return (
    <BooleanInput
      source="settings.data.featureFlags.riskCategoryChart"
      label="Risk Category Chart"
      readOnly={!riskCategoryFieldValue}
    />
  );
};

const FeatureFlagsTab = (props: Omit<FormTabProps, 'label'>) => (
  <FormDataConsumer>
    {({ formData }) => (
      <FormTab label="Feature Flags" {...props}>
        <CraftPageSection title="Data Feature Flags">
          <CraftTwoColumns
            left={
              <>
                <BooleanInput
                  source="settings.data.featureFlags.sensitive"
                  label="Sensitive Client"
                  infoTooltip={tooltips.sensitive}
                />
                <BooleanInput source="settings.data.featureFlags.firstPartyData" label="First Party Data" />
              </>
            }
            right={
              <BooleanInput source="british_steel" label="Multi Factor Chart" infoTooltip={tooltips.britishSteel} />
            }
          />
        </CraftPageSection>

        <CraftPageSection title="Case Management Feature Flags">
          <CraftTwoColumns
            left={
              <>
                <BooleanInput
                  source="settings.data.featureFlags.caseManagementEnabled"
                  label="Enable Case Management"
                  infoTooltip={tooltips.caseManagementEnabled}
                />
                <BooleanInput
                  source="settings.data.featureFlags.gsca"
                  label="Show GSCA Compliance"
                  infoTooltip={tooltips.gsca}
                />
                <BooleanInput
                  source="settings.data.featureFlags.caseManagementLocations"
                  label="Show Country Location"
                  infoTooltip={tooltips.caseManagementLocations}
                />
                <TextInput
                  source="settings.data.featureFlags.internalMailDomains"
                  label="Internal Email Domains"
                  infoTooltip={tooltips.internalMailDomains}
                  multiline
                  fullWidth
                  format={(val) => (Array.isArray(val) ? val.join('\n') : val)}
                />
              </>
            }
            right={
              <>
                <BooleanInput source="settings.data.featureFlags.riskCategoryField" label="Risk Category Field" />
                <RiskCategoryChartInput />
              </>
            }
          />
        </CraftPageSection>

        <CraftPageSection title="Other Feature Flags">
          <BooleanInput source="alerts" label="Show Alerts" />
          <SelectInput
            source="settings.data.featureFlags.defaultCommentAccess"
            choices={COMMENT_ACCESS_TYPE}
            defaultValue="public"
            isRequired
          />
          <CraftTwoColumns
            left={
              <>
                <BooleanInput
                  source="settings.data.featureFlags.customJobBusinessUnitEnabled"
                  label="Custom Job Business Unit"
                  infoTooltip={tooltips.customJobBusinessUnitEnabled}
                />
                <IntegerInput
                  source="settings.data.featureFlags.maxPortfolioDepth"
                  label="Max Portfolio Depth"
                  defaultValue={3}
                />
              </>
            }
            right={
              <BooleanInput
                source="settings.data.featureFlags.portfolioCheckEnabled"
                label="Portfolio Check Enabled"
                infoTooltip={tooltips.portfolioCheckEnabled}
              />
            }
          />
          <BooleanInput
            source="sso_enabled"
            label="Single Sign-on (SSO)"
            infoTooltip={tooltips.sso}
            readOnly={formData.settings?.data.featureFlags.totpMFAEnabled}
          />
          {formData.sso_enabled && (
            <CraftTwoColumns
              mb={2}
              left={
                <>
                  <TextInput
                    source="settings.data.samlSettings.accountName"
                    label="SSO Account Name"
                    validate={required()}
                    fullWidth
                  />
                  <TextInput
                    source="settings.data.samlSettings.entryPoint"
                    label="Entry Point"
                    validate={required()}
                    fullWidth
                  />
                  <TextInput
                    source="settings.data.samlSettings.issuer"
                    label="Issuer"
                    validate={required()}
                    fullWidth
                  />
                  {/* `format` prop is not supported on `ArrayInput` */}
                  <ArrayInput
                    source="settings.data.samlSettings.certs_transformed"
                    label="Cert(s)&thinsp;*"
                    defaultValue={[{ value: '' }]}
                  >
                    <TextInput source="value" label="Cert" placeholder="ABCDEF==" validate={required()} />
                  </ArrayInput>
                </>
              }
              right={
                <>
                  <SelectInput
                    source="settings.data.samlSettings.spCerts"
                    label="Craft SP Certificates"
                    choices={SERVICE_PROVIDER_CERTIFICATE_EXPIRATIONS}
                    defaultValue="current"
                    isRequired
                    margin="none"
                    fullWidth
                  />
                  <Paper sx={{ p: 2, width: '100%' }}>
                    <Stack spacing={2}>
                      <Labeled label="Callback URL">
                        <TextField
                          record={{
                            url: `${baseSamlCallbackURL}/${
                              formData.settings?.data?.samlSettings?.accountName || '...'
                            }`,
                          }}
                          source="url"
                          variant="code"
                          sx={{ overflow: 'auto' }}
                        />
                      </Labeled>
                      <Labeled label="SSO JIT URL" tooltip={tooltips.ssoJitURL}>
                        <TextField
                          record={{
                            url: `${baseSamlEntryURL}/${formData.settings?.data?.samlSettings?.accountName || '...'}`,
                          }}
                          source="url"
                          variant="code"
                          sx={{ overflow: 'auto' }}
                        />
                      </Labeled>
                    </Stack>
                  </Paper>
                </>
              }
            />
          )}

          <BooleanInput source="settings.data.certaSettings.enabled" label="Certa Surveys" />
          {formData.settings?.data?.certaSettings?.enabled && (
            <CraftTwoColumns
              left={
                <TextInput
                  source="settings.data.certaSettings.tenantName"
                  label="Certa Tenant Name"
                  validate={required()}
                  fullWidth
                />
              }
              right={null}
            />
          )}

          <BooleanInput
            source="settings.data.featureFlags.totpMFAEnabled"
            label="Enable Multi-Factor Authentication"
            infoTooltip={tooltips.mfa}
            disabled={formData.sso_enabled}
          />
        </CraftPageSection>

        <CraftPageSection title="Left Menu Options">
          <CraftTwoColumns
            left={
              <>
                <BooleanInput source="settings.data.featureFlags.companyRequests" label="Show Company Requests" />
                <BooleanInput source="settings.data.featureFlags.advancedSearch" label="Show Advanced Search" />
              </>
            }
            right={<BooleanInput source="settings.data.featureFlags.compareCompanies" label="Show Compare" />}
          />
        </CraftPageSection>

        <CraftPageSection title="Security Score Card">
          <BooleanInput
            source="settings.data.securityScoreCardReportDownload.enabled"
            label="Show SecurityScoreCard Report Download"
            helperText={false}
            sx={{ mb: 1 }}
          />
          {formData.settings?.data?.securityScoreCardReportDownload?.enabled && (
            <Paper sx={{ p: 2 }}>
              <Alert severity="info" sx={{ mb: 2 }}>
                Please note that these settings only control the UI components displayed in Portal (e.g. report download
                buttons). In order to enable access to the data associated with each item, you should enable relevant
                fields for the organization&apos;s API client. Hover over the{' '}
                <InfoIcon fontSize="small" sx={{ verticalAlign: 'top' }} /> by a setting for more details.
              </Alert>
              <CraftTwoColumns
                left={
                  <BooleanInput
                    source="settings.data.securityScoreCardReportDownload.companySummaryReportEnabled"
                    label="Enable Company Summary Report"
                    infoTooltip={tooltips.companySummaryReportEnabled}
                    helperText={false}
                  />
                }
                right={
                  <BooleanInput
                    source="settings.data.securityScoreCardReportDownload.companyIssueReportEnabled"
                    label="Enable Issue Report"
                    infoTooltip={tooltips.companyIssueReportEnabled}
                    helperText={false}
                  />
                }
              />
            </Paper>
          )}
        </CraftPageSection>
      </FormTab>
    )}
  </FormDataConsumer>
);

export default FeatureFlagsTab;
