import {
  ExportButton,
  TopToolbar,
  BooleanField,
  DateField,
  EditButton,
  WrapperField,
  NullableBooleanInput,
  ReferenceInput,
  SelectField,
  AutocompleteInput,
  downloadCSV,
  BulkExportButton,
  Exporter,
  useNotify,
  SortPayload,
} from 'react-admin';
import CraftList from 'src/components/CraftList';
import StringArrayChipsField from 'src/fields/StringArrayChipsField';
import { OrganizationReferenceField } from 'src/resources/portal/organizations/OrganizationReferenceField';
import {
  ORGANIZATION_CONFIG_EMAIL_ALERTS_GROUPINGS,
  ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE,
  ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE_OBJ,
} from 'src/utils/defaults/Constants';
import jsonExport from 'jsonexport/dist';
import { Notify } from 'src/utils/NotifyType';
import { usePermissions } from 'src/hooks';
import { CraftDateInput } from 'src/components/CraftDateInput';

type OrganizationConfig = {
  id: string;
  org_id: string;
  datasets: string[];
  all_alerts_access: boolean;
  enable_alerts_processing: boolean;
  send_email_alerts: boolean;
  email_alerts_grouping: string;
  email_alerts_schedule: string;
  created_at: string;
};

type Organizations = {
  [key: string]: {
    name: string;
  };
};

const filters = [
  <ReferenceInput key="orgFilter" reference="organizations" source="org_id" alwaysOn perPage={5}>
    <AutocompleteInput
      label="Organization"
      source="id"
      optionText="name"
      disableClearable
      filterToQuery={(q) => {
        if (!Number.isNaN(parseInt(q, 10))) {
          return { id: q };
        }
        return { name: q };
      }}
    />
  </ReferenceInput>,
  <CraftDateInput key="createdAtFrom" label="Newer than" source="created_at@_gte" disallowFuture alwaysOn />,
  <CraftDateInput key="createdAtTo" label="Older than" source="created_at@_lte" alwaysOn />,
  <NullableBooleanInput key="allAlertsAccess" source="all_alerts_access" label="Show All Alerts" alwaysOn />,
  <NullableBooleanInput
    key="enableAlertsProcessing"
    source="enable_alerts_processing"
    label="Enable Alerts Processing"
    alwaysOn
  />,
  <NullableBooleanInput key="sendEmailAlerts" source="send_email_alerts" label="Show Email Alerts" alwaysOn />,
];

const defaultSort: SortPayload = {
  field: 'created_at',
  order: 'DESC',
};

const columnStyles = {
  datasets: {
    minWidth: '20rem',
    width: '20rem',
  },
};

const OrgConfigAction = () => (
  <TopToolbar>
    <ExportButton maxResults={5000} />
  </TopToolbar>
);

const HandleExporter =
  (notify: Notify): Exporter =>
  (organizationConfigs, fetchRelatedRecords) => {
    fetchRelatedRecords(organizationConfigs, 'org_id', 'organizations').then((organizations: Organizations) => {
      const data = organizationConfigs.map((organizationConfig: OrganizationConfig) => ({
        Org: `${
          organizations[organizationConfig.org_id]?.name
            ? `${organizations[organizationConfig.org_id].name}`
            : '(unknown)'
        } (${organizationConfig.org_id})`,
        Datasets: `${organizationConfig.datasets}`,
        'Show All Craft Alerts': organizationConfig.all_alerts_access,
        'Enable Alerts Processing': organizationConfig.enable_alerts_processing,
        'Send Email Alerts': organizationConfig.send_email_alerts,
        'Email Grouping': organizationConfig.email_alerts_grouping,
        'Email Timezone': organizationConfig.email_alerts_schedule
          ? ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE_OBJ[organizationConfig.email_alerts_schedule]
          : '',
        'Created At': organizationConfig.created_at,
      }));
      return jsonExport(
        data,
        {
          headers: [
            'Org',
            'Datasets',
            'Show All Craft Alerts',
            'Enable Alerts Processing',
            'Send Email Alerts',
            'Email Grouping',
            'Email Timezone',
            'Created At',
          ],
        },
        (error, csv) => {
          if (error) {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            notify(errorMessage, { type: 'error' });
          } else {
            downloadCSV(csv, 'organization_config');
          }
        },
      );
    });
  };

export const OrgConfigList = () => {
  const notify = useNotify();
  const { permissions } = usePermissions();
  const canEdit = permissions.has('alerts:edit');
  const handleExporter = HandleExporter(notify);

  return (
    <CraftList
      title="Alerts Organizations"
      filters={filters}
      actions={<OrgConfigAction />}
      sort={defaultSort}
      columnStyles={columnStyles}
      dataGridProps={{ bulkActionButtons: <BulkExportButton label="Bulk Export" /> }}
      exporter={handleExporter}
    >
      <OrganizationReferenceField source="org_id" />
      <WrapperField label="Datasets" cellClassName="column-datasets" headerClassName="column-datasets">
        <StringArrayChipsField source="datasets" />
      </WrapperField>
      <BooleanField source="all_alerts_access" label="Show All Craft Alerts" />
      <BooleanField source="enable_alerts_processing" label="Enable Alerts Processing" />
      <BooleanField source="send_email_alerts" label="Send Email Alerts" />
      <SelectField
        source="email_alerts_grouping"
        label="Email Grouping"
        choices={ORGANIZATION_CONFIG_EMAIL_ALERTS_GROUPINGS}
      />
      <SelectField
        source="email_alerts_schedule"
        label="Email Timezone"
        choices={ORGANIZATION_CONFIG_EMAIL_ALERTS_TIMEZONE}
      />
      <DateField source="created_at" showTime />
      {canEdit ? (
        <WrapperField label="Actions">
          <EditButton />
        </WrapperField>
      ) : null}
    </CraftList>
  );
};

export default OrgConfigList;
