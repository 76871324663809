import { Box, Paper, Typography, styled } from '@mui/material';
import {
  ArrayInputProps as ArrayInputRaProps,
  ArrayInput as ArrayInputRA,
  SimpleFormIteratorProps,
  SimpleFormIterator,
} from 'react-admin';

import WithInfoTooltip from 'src/components/WithInfoTooltip';
import { CommonInputProps } from './types';

const LabelContent = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const InputContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledArrayInput = styled(ArrayInputRA)(() => ({
  margin: 0,
  '& .RaSimpleFormIterator-buttons': {
    justifyContent: 'center',
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'center',
  },
}));

const StyledSimpleFormIterator = styled(SimpleFormIterator)(({ theme }) => ({
  '&>ul>li:not(:last-of-type)': {
    marginBottom: theme.spacing(2),
  },
}));

interface ArrayInputProps extends Omit<ArrayInputRaProps, 'children'>, CommonInputProps {
  children: SimpleFormIteratorProps['children'];
}

export const ArrayInput = ({ label, infoTooltip, children, ...rest }: ArrayInputProps) => (
  <Box>
    {infoTooltip ? (
      <WithInfoTooltip title={infoTooltip} sx={{ mt: '0.3em', ml: 1 }}>
        <LabelContent>{label}</LabelContent>
      </WithInfoTooltip>
    ) : (
      <LabelContent>{label}</LabelContent>
    )}

    <InputContainer>
      <StyledArrayInput
        label={false}
        fullWidth={false}
        helperText={false} // form validation errors will continue to show
        {...rest}
      >
        <StyledSimpleFormIterator>{children}</StyledSimpleFormIterator>
      </StyledArrayInput>
    </InputContainer>
  </Box>
);

export default ArrayInput;
