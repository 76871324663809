export enum USER_ROLE {
  CRAFT_MANAGER = 'craft_admin',
  ORGANIZATION_ADMIN = 'organization_admin',
  ORGANIZATION_MEMBER = 'user',
  CRAFT_FOR_TEAMS_ADMIN = 'craft_for_teams_admin',
  CRAFT_FOR_TEAMS_MEMBER = 'craft_for_teams_member',
}

export const USER_ROLE_NAME = {
  [USER_ROLE.CRAFT_MANAGER]: 'Craft Manager',
  [USER_ROLE.ORGANIZATION_ADMIN]: 'Administrator',
  [USER_ROLE.ORGANIZATION_MEMBER]: 'Member',
  [USER_ROLE.CRAFT_FOR_TEAMS_ADMIN]: 'Administrator',
  [USER_ROLE.CRAFT_FOR_TEAMS_MEMBER]: 'Member',
};

export const USER_ROLE_DESCRIPTION = {
  [USER_ROLE.CRAFT_MANAGER]: 'Craft Premium Portal manager',
  [USER_ROLE.ORGANIZATION_ADMIN]: 'Can access features and perform administrative tasks.',
  [USER_ROLE.ORGANIZATION_MEMBER]: 'Can access features, but cannot perform administrative tasks.',
  [USER_ROLE.CRAFT_FOR_TEAMS_MEMBER]: 'Can access limited features, but cannot perform administrative tasks.',
  [USER_ROLE.CRAFT_FOR_TEAMS_ADMIN]: 'Can access limited features and perform administrative tasks.',
};

export const USER_ROLE_CHOICES = Object.entries(USER_ROLE).map(([key, value]) => ({
  id: value,
  key,
  name: USER_ROLE_NAME[value],
  description: USER_ROLE_DESCRIPTION[value],
}));
