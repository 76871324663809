import { FunctionField } from 'react-admin';
import { CoreDBCompanyRow } from '../models/companies/CoreDBCompanyTypes';

export const CompanyUpdatedAtField = () => (
  <FunctionField
    render={(record: CoreDBCompanyRow) =>
      `${record?.updated_at || undefined}`.concat(record.UpdatedBy ? `by ${record.UpdatedBy[0].AdminUser}` : '')
    }
  />
);

export default CompanyUpdatedAtField;
