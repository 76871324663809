import { Grid, IconButton, Paper } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  EditButton,
  FormTab,
  FormTabProps,
  FunctionField,
  RaRecord,
  ReferenceOneField,
  useNotify,
  useRecordContext,
} from 'react-admin';

import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import TextInput from 'src/inputs/TextInput';
import { CraftDateInput } from 'src/components/CraftDateInput';
import { ExternalApiClientEditTabs, externalApiClientEditRoute } from 'src/utils/routeHelpers';

const SubscriptionTab = ({ mode, ...rest }: Omit<FormTabProps, 'label'> & { mode: 'create' | 'edit' }) => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(record.company_data_api_key);
    notify('API key copied to clipboard', { type: 'success' });
  };

  return (
    <FormTab label="Subscription" {...rest}>
      <CraftPageSection title="API Client">
        {mode === 'create' && (
          <CraftTwoColumns
            left={<CraftDateInput label="Start date" source="subscription.startDate" fullWidth />}
            right={<CraftDateInput label="End date" source="subscription.endDate" fullWidth />}
          />
        )}
        {mode === 'edit' && (
          <Paper sx={{ p: 3 }}>
            <CraftTwoColumns
              left={
                <Grid container alignItems="flex-start">
                  <TextInput
                    label="API key"
                    source="company_data_api_key"
                    sx={{ flex: 1, mb: 0 }}
                    disabled
                    helperText={false}
                  />
                  <IconButton sx={{ borderRadius: 1 }} onClick={handleCopyApiKey}>
                    <ContentCopyIcon />
                  </IconButton>
                </Grid>
              }
              right={
                <Grid container height="100%" justifyContent="center" alignItems="center">
                  <ReferenceOneField
                    reference="external_api_clients"
                    target="token"
                    source="company_data_api_key"
                    emptyText="API client not configured"
                  >
                    <FunctionField
                      render={(rec: RaRecord) => (
                        <EditButton
                          label="Edit API client"
                          resource="external_api_clients"
                          record={{ id: rec.id }}
                          to={externalApiClientEditRoute(rec.id, ExternalApiClientEditTabs.SUBSCRIPTIONS, {
                            fromOrgId: record.id,
                          })}
                          variant="contained"
                        />
                      )}
                    />
                  </ReferenceOneField>
                </Grid>
              }
            />
          </Paper>
        )}
      </CraftPageSection>
    </FormTab>
  );
};

export default SubscriptionTab;
