import React from 'react';
import { Box, Paper, PaperProps, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const ListInfoSection = ({ children, sx, ...rest }: PaperProps) => {
  const sxFinal = {
    border: '1px solid #ddd',
    mt: 2,
    p: 1,
    ...sx,
  };

  return (
    <Paper sx={sxFinal} {...rest}>
      <Stack direction="row" alignItems="flex-start">
        <InfoIcon color="info" fontSize="small" sx={{ width: 'min-content' }} />
        <Box flex={1} ml={1} pt="0.1rem">
          <Typography fontSize="0.75rem">{children}</Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default ListInfoSection;
