import { Link, LinkProps } from '@mui/material';
import { useRecordContext, RaRecord } from 'react-admin';

import { config } from 'src/config/config.local';

type TargetCraftApps = 'portal' | 'craftco' | 'locationsAdmin' | 'keyPeopleAdmin' | 'newsAdmin';

const urlGenerators: Record<TargetCraftApps, (record: RaRecord) => string> = {
  portal: (record) => `${config.portal.url}/company/${record.slug}`,
  craftco: (record) => `${config.craftco.url}/${record.slug}`,
  locationsAdmin: (record) => `${config.locations.url}/admin/location_pages/locationpage/${record.id}/change/`,
  keyPeopleAdmin: (record) => `${config.keyPeople.url}/admin/core/position/?q=${record.id}`,
  newsAdmin: () => `${config.newsAdmin.url}`,
};

const defaultLabels: Record<TargetCraftApps, string> = {
  craftco: 'Craft.co',
  portal: 'Portal',
  locationsAdmin: 'Locations',
  keyPeopleAdmin: 'Key People',
  newsAdmin: 'News Admin',
};

export const CraftCompanyUrlField = ({ label, app, ...rest }: LinkProps & { label?: string; app: TargetCraftApps }) => {
  const record = useRecordContext();

  if (!record) {
    return <span>{label ?? defaultLabels[app]}</span>;
  }

  return (
    <Link href={urlGenerators[app](record)} {...rest}>
      {label ?? defaultLabels[app]}
    </Link>
  );
};

export default CraftCompanyUrlField;
