import { useMemo } from 'react';
import CraftEdit from 'src/components/CraftEdit';
import {
  useNotify,
  useRedirect,
  FormTab,
  TextField,
  WrapperField,
  EditButton,
  DeleteButton,
  TextInput,
} from 'react-admin';
import { CraftSimpleFormToolbar } from 'src/components/CraftSimpleFormToolbar';
import { SharedInputs } from './SharedInputs';
import { FormHeader } from './FormHeader';
import { transformValues } from './transformValues';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import { CraftPageSection } from 'src/components/CraftPageSection';
import CraftStandaloneList from 'src/components/CraftStandaloneList';
import { Center } from 'src/components/Center';
import { StyledLink } from 'src/resources/signals/organizationConfig/StyledLink';
import { userSubscriptionsCreateRoute, userSubscriptionsListRoute } from 'src/utils/routeHelpers';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { CompanyReferenceField } from 'src/resources/companies/CompanyReferenceField';
import { UserReferenceField } from 'src/resources/portal/users/UserReferenceField';
import { usePermissions } from 'src/hooks';
import { hiddenSpan } from 'src/resources/signals/organizationConfig/hiddenSpan';

const getUserId = (): string => {
  const url = window.location.pathname;
  const parts = url.split('/');
  const orgConfigIndex = parts.indexOf('user_config');
  return parts[orgConfigIndex + 1];
};

const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.blur();
  }
};

const filters = [<TextInput key="topic" source="topic" alwaysOn onKeyDown={handleKeyDown} />];

export const UserConfigEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const canEdit = permissions.has('alerts:edit');
  const canDelete = permissions.has('alerts:delete');

  const userId = getUserId();

  const listFilter = useMemo(() => ({ user_id: userId }), []);

  const onSuccess = async () => {
    notify('User Config updated', { type: 'success' });
    redirect('/organization_config');
  };

  return (
    <CraftEdit
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
      transform={transformValues}
      header={<FormHeader />}
    >
      <CraftTabbedForm
        formType="edit"
        deleteOptions={{
          deletePermission: 'alerts:delete',
        }}
        mutationMode="pessimistic"
        toolbar={
          <CraftSimpleFormToolbar
            {...(canDelete ? { deleteButtonProps: { redirect: () => 'organization_config' } } : {})}
          />
        }
      >
        <FormTab label="User Config" key="user_config">
          <CraftPageSection title="Edit a User Config">
            <h2>User Config</h2>
            <SharedInputs />
          </CraftPageSection>
        </FormTab>
        <FormTab label="User Subscriptions" key="user_subscriptions">
          <CraftPageSection title="User Subscriptions">
            <CraftStandaloneList
              resource="user_subscriptions"
              filter={listFilter}
              filters={filters}
              actions={
                canEdit ? (
                  <StyledLink to={userSubscriptionsCreateRoute(userId)}>
                    <Button startIcon={<AddRoundedIcon />} sx={{ height: '2rem' }}>
                      Create
                    </Button>
                  </StyledLink>
                ) : (
                  hiddenSpan
                )
              }
              dataGridProps={{
                empty: canEdit ? (
                  <Center sx={{ marginTop: '1rem' }}>
                    No user subscriptions found. Click
                    <StyledLink to={userSubscriptionsCreateRoute(userId)}>here</StyledLink> to create an user
                    subscription
                  </Center>
                ) : (
                  <Center sx={{ marginTop: '1rem' }}>No user subscriptions found.</Center>
                ),
              }}
            >
              <TextField source="topic" />
              <CompanyReferenceField source="company_id" />
              <UserReferenceField source="user_id" />
              <WrapperField label="Actions">
                {canEdit && <EditButton resource="user_subscriptions" />}
                {canDelete && (
                  <DeleteButton resource="user_subscriptions" redirect={userSubscriptionsListRoute(userId)} />
                )}
              </WrapperField>
            </CraftStandaloneList>
          </CraftPageSection>
        </FormTab>
      </CraftTabbedForm>
    </CraftEdit>
  );
};

export default UserConfigEdit;
