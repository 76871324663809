import { ReferenceField, TextField } from 'react-admin';
import { Stack, Typography, Box, Grid } from '@mui/material';

type RecordProps = {
  user_id?: string;
};

type FormHeaderProps = {
  record?: RecordProps;
};

export const FormHeader = ({ record }: FormHeaderProps) => (
  <Stack gap={1}>
    <Stack direction="row" alignItems="center" gap={1} {...record}>
      <Typography variant="h5">
        <ReferenceField label="User" reference="users" source="user_id">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Grid container>
              <Grid item xs={12}>
                <TextField source="email" />
              </Grid>
              <Grid item>
                <TextField source="first_name" />
                &nbsp;
                <TextField source="last_name" />
              </Grid>
            </Grid>
          </Box>
        </ReferenceField>
      </Typography>
    </Stack>
  </Stack>
);

export default FormHeader;
