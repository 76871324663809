import { Sidebar, SidebarProps } from 'react-admin';
import { useScrollTrigger } from '@mui/material';

import { APP_BAR_HEIGHT } from 'src/utils/defaults/Constants';

/**
 * Customized version of the sidebar that have a surface
 * (default one is transparent) and that supports
 * customizable app bar height.
 */
export const CraftAdminSidebar = (props: SidebarProps) => {
  const trigger = useScrollTrigger();

  return (
    <Sidebar
      sx={{
        '& .RaSidebar-fixed': {
          bgcolor: 'white',
          height: '100vh',
          width: 'calc(240px - 1px)',
          top: trigger ? 0 : APP_BAR_HEIGHT,
          transition: 'top 0.3s ease',
        },
      }}
      {...props}
    />
  );
};

export default CraftAdminSidebar;
