import { Stack } from '@mui/material';
import { TextField } from 'react-admin';

import CraftTwoColumns from 'src/components/CraftTwoColumns';
import LabeledUrlField from 'src/fields/LabeledUrlField';
import CraftCompanyUrlField from 'src/fields/CraftCompanyUrlField';
import InlineTextField from 'src/fields/InlineTextField';
import CompanyStatusField from 'src/fields/CompanyStatusField';
import ConditionalNoteField from 'src/fields/ConditionalNoteField';
import CompanyLogoField from 'src/fields/CompanyLogoField';

export const CompaniesEditHeader = () => (
  <Stack gap={1}>
    <Stack direction="row" alignItems="center" gap={1}>
      <CompanyLogoField />
      <TextField source="name" variant="h4" fontWeight="bolder" />
      <CompanyStatusField chip={{ size: 'medium' }} />
    </Stack>
    <Stack direction="row" gap={1}>
      <InlineTextField label="ID" source="id" />
      <ConditionalNoteField />
    </Stack>
    <CraftTwoColumns
      typography="caption"
      left={
        <Stack direction="row" gap={1} alignItems="center">
          <b>External:</b>
          <LabeledUrlField source="site" label="Website" target="_blank" />
          <span>•</span>
          <LabeledUrlField source="linkedin" label="LinkedIn" target="_blank" />
          <span>•</span>
          <CraftCompanyUrlField app="portal" target="_blank" />
          <span>•</span>
          <CraftCompanyUrlField app="craftco" target="_blank" />
        </Stack>
      }
      right={
        <Stack direction="row" gap={1}>
          <b>Admins:</b>
          <CraftCompanyUrlField app="locationsAdmin" target="_blank" />
          <span>•</span>
          <CraftCompanyUrlField app="keyPeopleAdmin" target="_blank" />
          <span>•</span>
          <CraftCompanyUrlField app="newsAdmin" target="_blank" />
        </Stack>
      }
    />
  </Stack>
);
