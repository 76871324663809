import { FormTab, FormTabProps } from 'react-admin';

import ApiFieldSwitchInputGroup from 'src/components/ApiFieldSwitchInputGroup';
import { usePermissions } from 'src/hooks';
import { CraftPageSection } from 'src/components/CraftPageSection';

export const FieldsTab = (props: Omit<FormTabProps, 'label'>) => {
  const { permissions } = usePermissions();

  const canEdit = permissions.has('dataPackageApiField:edit');

  return (
    <FormTab label="Fields" {...props}>
      <CraftPageSection title="Data Package Fields">
        <ApiFieldSwitchInputGroup canEdit={canEdit} />
      </CraftPageSection>
    </FormTab>
  );
};

export default FieldsTab;
