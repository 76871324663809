import { Box, BoxProps } from '@mui/material';

/**
 * Simple flex container that displays its
 * children centered, you can override the behavior
 * using any of the {@link BoxProps} as needed.
 */
export const Center = (props: BoxProps) => (
  <Box alignItems="center" justifyContent="center" display="flex" {...props} />
);
